import React from "react";

// Trasnlation 
import '../i18n'
import { useTranslation } from 'react-i18next'

//librerias
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";

/*   IMAGENES    */
import chica1 from '../assets/images/chica1.png'
import chica2 from '../assets/images/chica2.png'
import chica3 from '../assets/images/chica3.png'

/**
 * CarrusellPasos Componente funciona con la libreria swiper y maneja el carrusel de pasos a seguir para mejorar en tus KPI en a version mobile de la web --> {@link Pasos } <-- Vista contenedora del carrusel
 * @alias CarrusellPasos
 * @namespace
 */
const CarrusellPasos = () => {

  const { t } = useTranslation();

  return (
    <div className="carrusell carru_pasos pasos">
      <Swiper
        slidesPerView={1}
        loop={true}
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="paso">
            <div className="div_img">
              <img src={chica1} alt="" />
            </div>
            <div className="description">
              <div className="num"><span>1</span></div>
              <p className="prf_paso">{t('weGet1.label')}<b>{t('weGet2.label')}</b> </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="paso">
            <div className="div_img">
              <img src={chica2} alt="" />
            </div>
            <div className="description">
              <div className="num"><span>2</span></div>
              <p className="prf_paso">{t('weDesing1.label')}<b>{t('weDesing2.label')}</b> {t('weDesing3.label')}</p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="paso">
            <div className="div_img">
              <img src={chica3} alt="" />
            </div>
            <div className="description">
              <div className="num"><span>3</span></div>
              <p className="prf_paso">{t('weMeasure1.label')}<b>{t('weMeasure2.label')}</b></p>
            </div>
          </div>
        </SwiperSlide>

      </Swiper>
    </div>
  )
}

export default CarrusellPasos