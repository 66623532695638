import React from 'react'

// Router
import { useHistory } from 'react-router-dom';

// Trasnlation 
import '../i18n'
import { useTranslation } from 'react-i18next'


/**
 * Vista de redireccion a las rutas no existentes en nuestra web  
 * @alias  NotFound
 */
const NotFound = () => {

  const { t, i18n } = useTranslation();

  let history = useHistory();

  const Linkto = (link) => {
    history.push('/' + link);
  };

  return (
    <div className='NOT_FOUND'>
      <div className="div_text">

        <h3>Ooopss!</h3>
        <h1>
          {t('notfound.label')}
        </h1>

        <button className='button_not_found' onClick={() => Linkto('')}>
          {t('notfound_button.label')}
        </button>
      </div>
    </div>
  )
}

export default NotFound