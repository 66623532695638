/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

/**  Router   */
import { useHistory } from 'react-router-dom';

/**  Trasnlation        */
import '../i18n'
import { useTranslation } from 'react-i18next'

/**  Componente slider  */
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/scss/utilities/slider.scss";

/**  titulo  Blog  */
import tit_div9 from '../assets/images/tilte_blog.svg'

/**  Componentes */
import HTML2React from 'html2react';
import moment from 'moment'
import { getinApiBlog } from "../services/wp-api";

/**
 * @date 26/7/2023 - 20:26:51
 * Description:CarruselBlog componente es el carrusel en la vista home que recupera las ultimas 5 publicaciones y las muestra en modo de Card -->{@link View}<-- vista contenedora
 * @alias CarruselBlog
 * @namespace
 */
const CarruselBlog = () => {

  let history = useHistory();
  const Linkto = (link) => {
    history.push('/' + link);
  };

  /** Opciones de el Slider */
  let initSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };


  /**  constantes del estado */
  const { t } = useTranslation();
  const [articulos, setArticulos] = useState([])
  const [settings, setSettings] = useState(initSettings)
  let [ready, setReady] = useState(false)
  let [go, setGo] = useState(false)

  useEffect(() => {
    // setTimeout(() => {
    getPost();
    // }, 1000);
  }, [window.localStorage.getItem('lng')])

  useEffect(() => {
    loadAllMedias()
  }, [go])

  /**
  * Funcion que tare los post de la base de datos, los organiza y los inicializa en el estado 
  * @alias getPost
  */
  const getPost = async () => {
    var posted = [];
    var Base = 'getAll'
    if (window.localStorage.getItem('lng') === 'en') {
      Base = 'getAllEn'
    }
    await getinApiBlog(Base).then(async (res) => {
      res.forEach(async (post) => {
        // console.log(post);
        var dateString = post.date;
        var dateObj = new Date(dateString);
        var momentObj = moment(dateObj);
        var momentString = momentObj.format('LL');

        let pst = {
          id: post.id,
          date: momentString,
          likes: post.meta_box.likes,
          shares: post.meta_box.Shares,
          title: post.title.rendered,
          slug: post.slug,
          content: post.content.rendered,
          excerpt: post.excerpt.rendered,
          featured_media: post.featured_media,
          media: [],
          liked: false,
          disliked: false,
          autor: post.acf.autor
        }
        posted.push(pst);
      });
      setArticulos(posted);
      setGo(true);
    }).catch((err) => console.log(err))
  }

  /**
   * Funciones que organiza las imagenes de los post obtenidos y los injecta en el post del estado
   * @alias addMediatoPost
   * @alias loadAllMedias
   */
  const addMediatoPost = async (id, ready) => {
    let local_posts = [...articulos];
    local_posts.filter(async (data, i) => {
      if (data.id === id) {
        if (data.featured_media !== 0) {
          await getinApiBlog('getMedia', data.featured_media).then((res) => {
            data.media.push(res.source_url);
            if (ready) {
              setReady(true)
            }
            return false
          }).catch((err) => console.log(err));
        }
      }
      return false
    });
    setArticulos(local_posts)
    return false
  }
  const loadAllMedias = async () => {
    // console.log(posts);
    for (let index = 0; index < articulos.length; index++) {
      if ((index + 1) === articulos.length) {
        await addMediatoPost(articulos[index]['id'], true);
      } else {
        await addMediatoPost(articulos[index]['id'], false);
      }
    }
  }

  useEffect(() => {
    if (articulos.length <= 2) {
      setSettings({ ...settings, slidesToShow: 1 })
    } else {
      setSettings(initSettings)
    }
    if (articulos.length > 5) {
      let newArticulos = [...articulos]
      let Articls = newArticulos.slice(0, 5)
      setArticulos(Articls)
    }
  }, [articulos])

  //Redireccion
  const gotoPost = (post) => {
    history.push('/blog/' + post.slug)
  }

  return (
    <div className='contenedor'>
      {ready &&
        <div className="div9 div9_2">
          <div className="titulo">
            <img src={tit_div9} alt="Nuestro equipo" />
          </div>
          <div className="carrusell">
            <Slider {...settings}>
              {articulos && articulos.map((art, i) => (
                <div className="card_blog 3" key={art.id}>
                  <div className="head_card">
                    <figure>
                      {/* <img src={art.media[0]} /> */}
                      {art.media.length && (art.media.length > 0) ?
                        <div className="figure-bkp" style={{ backgroundImage: `url(${art.media[0]})` }}></div>
                        :
                        <div className="figure-bkp" style={{ backgroundImage: `url(assets/Images/blog_card1.png)` }}></div>
                      }
                    </figure>
                    {/* <img src={art.media[0]} alt="imagen alusiva al blog" /> */}

                  </div>
                  <div className="body_card">
                    <h5 className="subtitle_blog">{art.title.slice(0, 40)} ...</h5>
                    <p className="pr_card"><span>{t('author.label')}: </span>{art.autor}</p>
                    <p className="pr_card"><span>{art.date}</span></p>
                    {HTML2React(art.excerpt).slice(0, 100)}
                    <button className="boton_white" onClick={() => gotoPost(art)}>{t('seeMore.label')}</button>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <button className="boton" onClick={() => Linkto('blog')}>{t('lernMOre.label')}</button>
        </div>
      }
    </div>
  )
}

export default CarruselBlog