import React, { useState, useEffect } from "react";

/**    ROUTER     */
import { useHistory } from 'react-router-dom';

/**    TRANSLATION     */
import '../i18n'
import { useTranslation } from 'react-i18next'
// const URL_API = env.API_URL;

/**    IMAGENES    */
import tit_div9 from '../assets/images/tilte_blog.svg'

/**    SERVICES     */
import { getinApiBlog } from '../services/wp-api'
import HTML2React from 'html2react';
import moment from 'moment'
import { Helmet } from "react-helmet";

//componentes
import SocialContact from '../components/subComponents/SocialContact'

//   CARDS POR PAGINA
const Items_perpage = 6


/**
 * Blog componente que contiene los artuculos de blog constantemente 
 * @alias Blog
 */
const Blog = () => {

  let history = useHistory();
  const { t, i18n } = useTranslation();

  const Linkto = (link) => {
    history.push('/' + link);
  };

  /**
   * Funcion inicializadora de lenguaje de la vista 
   * @alias initLang
   */
  const initLang = () => {
    if (localStorage.getItem('lng')) {
      i18n.changeLanguage(localStorage.getItem('lng'))
    } else {
      window.localStorage.setItem('lng', 'es')
      i18n.changeLanguage('es')
    }
  }

  useEffect(() => {
    initLang()
  }, [])

  const [articulos, setArticulos] = useState([])
  let [go, setGo] = useState(false)
  let [ready, setReady] = useState(false)
  let [items, setItems] = useState([...articulos].splice(0, Items_perpage))
  let [pag, setPages] = useState(0)

  /**
   * Funciones que controlan paginado de posts
   * @alias nextPage
   * @alias prevPage
   */
  const nextPage = () => {
    const totalPost = articulos.length
    const nextP = pag + 1
    const priIndex = nextP * Items_perpage
    if (priIndex >= totalPost) return;
    setPages(nextP)
    setItems([...articulos].splice(priIndex, Items_perpage))
  }
  const prevPage = () => {
    const prevP = pag - 1
    if (prevP < 0) return;
    const priIndex = prevP * Items_perpage
    setItems([...articulos].splice(priIndex, Items_perpage))
    setPages(prevP)
  }

  useEffect(() => {
    getPost();
  }, [window.localStorage.getItem('lng')])

  useEffect(() => {
    loadAllMedias()
  }, [go])

  useEffect(() => {
    setItems([...articulos].splice(0, Items_perpage))
  }, [articulos])

  /**
 * Funcion que tare los post de la base de datos, los organiza y los inicializa en el estado 
 * @alias getPost
 */
  const getPost = async () => {
    var posted = [];
    var Base = 'getAll'
    if (window.localStorage.getItem('lng') === 'en') {
      Base = 'getAllEn'
    }
    await getinApiBlog(Base).then(async (res) => {
      res.forEach(async (post) => {
        // console.log(post);
        var dateString = post.date;
        var dateObj = new Date(dateString);
        var momentObj = moment(dateObj);
        var momentString = momentObj.format('LL');

        let pst = {
          id: post.id,
          date: momentString,
          likes: post.meta_box.likes,
          shares: post.meta_box.Shares,
          title: post.title.rendered,
          slug: post.slug,
          content: post.content.rendered,
          excerpt: post.excerpt.rendered,
          featured_media: post.featured_media,
          media: [],
          liked: false,
          disliked: false,
          autor: post.acf.autor
        }
        posted.push(pst);
      });
    }).catch((err) => console.log(err))
    setArticulos(posted);
    setGo(true);
  }

  /**
   * Funciones que organiza las imagenes de los post obtenidos y los injecta en el post del estado
   * @alias addMediatoPost
   * @alias loadAllMedias
   */
  const addMediatoPost = async (id, ready) => {
    let local_posts = [...articulos];
    local_posts.filter(async (data, i) => {
      if (data.id === id) {
        if (data.featured_media !== 0) {
          await getinApiBlog('getMedia', data.featured_media).then((res) => {
            data.media.push(res.source_url);
            if (ready) {
              setReady(true)
            }
            return false
          }).catch((err) => console.log(err));
        }
      }
      return false
    });
    setArticulos(local_posts)
    return false
  }
  const loadAllMedias = async () => {
    // console.log(posts);
    for (let index = 0; index < articulos.length; index++) {
      if ((index + 1) === articulos.length) {
        await addMediatoPost(articulos[index]['id'], true);
      } else {
        await addMediatoPost(articulos[index]['id'], false);
      }
    }
  }

  //redireccion
  const gotoPost = (post) => {
    history.push('/blog/' + post.slug)
  }

  return (
    <>
      <Helmet>
        <title>Blog - Kreative</title>
        <meta name="keywords" content="Novedades, Noticias, Artículos, marketing, pauta." />
        <meta name="description" content="Entérate de noticias y temas de relevancia personal y común. Un espacio para instruirte y cuidar no solo de ti sino de toda tu familia. Artículos de interés para todo tipo de público con relación con nuestra pagina " />
      </Helmet>
      <div className="contenedor pageBLog">
        <div className="Socialfloat">
          <SocialContact t={t} />
        </div>
        <div className="div9">
          <div className="titulo">
            <img src={tit_div9} alt="Blogo" />
          </div>
          <div className="flex">
            {(ready) && items.map((art, i) => (
              <div className="card_blog" key={art.id}>
                <div className="head_card">
                  <figure>
                    {(art.media.length > 0) ?
                      <div className="figure-bkp" style={{ backgroundImage: `url(${art.media[0]})` }}></div>
                      :
                      <div className="figure-bkp" style={{ backgroundImage: `url(assets/Images/blog_card1.png)` }}></div>
                    }
                  </figure>

                </div>
                <div className="body_card">
                  <h5 className="subtitle_blog">{art.title.slice(0, 40)} ...</h5>
                  <p className="pr_card"><span>{t('author.label')}: </span>{art.autor}</p>
                  <p className="pr_card"><span>{art.date}</span></p>
                  {HTML2React(art.excerpt)}
                  <button className="boton_white" onClick={() => gotoPost(art)}>{t('seeMore.label')}</button>
                </div>
              </div>
            ))}
          </div>
          <div className="butons_pagination">
            <div className="button_page" onClick={() => prevPage()}> <i className="icon icon-btn_left" /> </div>
            <div className="current_p">{pag + 1}</div>
            <div className="button_page" onClick={() => nextPage()}> <i className="icon icon-btn_right"></i></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Blog