import React from 'react'

/** importe de traducciones */
import '../../i18n'
import { useTranslation } from "react-i18next";

/** importe de clases css */
import '../../assets/scss/components/subComponents/card_servicos.scss'


/**
 * CardServicios Componente del carrusel y del div que mestra los servicos que presta kreative tree como empresa --> {@link DivServices} <-- Vista contenedora
 * @alias CardServicios
 * @namespace
 * @param {Component} children. - en este caso conponente de imagen SVG animado
 * @param {String} titulo. - titulo de la card
 * @param {String} linkes. - enlace al servicio de WhatsApp en español
 * @param {String} linken. - enlace al servicio de WhatsApp en ingles
 * @param {Boolean} dn. - define si es visible o no al renderizar
 */
const CardServicios = ({ children, titulo, linkes, linken, dn = false }) => {

  const { t } = useTranslation();

  return (
    <div className={dn === true ? "card_servicios dn" : "card_servicios"}>
      <div className="img_card">
        {children}
      </div>
      <p className="prf_card">{t(`${titulo}.label`)}</p>
      <a className="boton" href={(localStorage.getItem('lng') === 'es') ? linkes : linken} target='_blanck' >{t('Quote.label')}</a>
    </div>
  )
}

export default CardServicios