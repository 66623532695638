import React from 'react';
import ReactDOM from 'react-dom';
import './assets/scss/global/index.scss';
import App from './App';
import AppLanding from './AppLanding';
import { BrowserRouter } from 'react-router-dom';


import { debugContextDevtool } from 'react-context-devtool';

// const container = document.getElementById('root');


// ReactDOM.render(<BrowserRouter> <App /> </BrowserRouter>, container);

// import generateStore from './redux/store'

// // store 
// let store = generateStore()

// let WithRouter = () => <BrowserRouter><App /></BrowserRouter>
// let WhithReduxStore = () => <Provider store={store}><WithRouter /></Provider>
// ReactDOM.render(
//   <WhithReduxStore />, document.getElementById('root')
// );








(async () => {
  const container = document.getElementById('root');
  const RouteBase = window.location.pathname

  switch (RouteBase) {
    case '/paquete_basico':
      await import("./assets/scss/global/index.scss");
      ReactDOM.render(<BrowserRouter> <AppLanding /> </BrowserRouter>, container);
      break;
    case '/pauta_digital':
      await import("./assets/scss/global/index.scss");
      ReactDOM.render(<BrowserRouter> <AppLanding /> </BrowserRouter>, container);
      break;
    default:
      await import("./assets/scss/global/index.scss");
      ReactDOM.render(<BrowserRouter> <App /> </BrowserRouter>, container);
      break;
  }

  debugContextDevtool(container);
})();
