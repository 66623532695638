import React from "react";
import $ from 'jquery'
import env from '../environtments'

// Trasnlation 
import '../i18n'
import { useTranslation } from 'react-i18next'

// titulos 
import tit_div5 from '../assets/images/tit_price_mobile.svg'
import tit_div5ING from '../assets/images/dontnowmobile.svg'

/**Importacion de libreria de Carrusel */
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";

//components
import CardPlanBasic from "./subComponents/cardPlanBasic";
import CardPlanSilver from "./subComponents/cardPlanSilver";
import CardPlanGold from "./subComponents/cardPlanGold";
import CardPlanCustom from "./subComponents/cardPlanCustom";
import TerminosPLanes from "./subComponents/terminosPLanes";

/**
 * CarrusellPrices Componente funciona con la libreria swiper y maneja el carrusel de los planes precios en a version mobile de la web --> {@link Prices } <-- Vista contenedora del carrusel
 * @alias CarrusellPrices
 * @namespace
 * @param {Object} plan. - Objeto que contiene los planes con sus links, precios y piesas de inclusion en el mismo.
 */
const CarrusellPrices = ({ plans }) => {

  const { t } = useTranslation();
  /**
   * toogle de la vista de terminos 
   */
  const vewMore = () => {
    $('.prf_term.dn').toggleClass('ok')
  }

  return (
    <section className="div5 carrPrices" id="Dprices2">
      <div className="titulo">
        {(localStorage.getItem('lng') === 'es') ?
          <img src={tit_div5} alt="titulo - aun no sabes que necesitas?" /> : <img src={tit_div5ING} alt="title - don't know what you need yet?" />
        }
      </div>
      <p className="subtitle">
        <b>{t('canStart.label')}</b>
      </p>
      <div className="carrusell carru_prices planes">
        <Swiper
          slidesPerView={1}
          loop={true}
          cssMode={true}
          navigation={true}
          pagination={true}
          mousewheel={true}
          keyboard={true}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          className="mySwiper"
        >
          <SwiperSlide>
            <CardPlanBasic plan={plans.basic} />
          </SwiperSlide>
          <SwiperSlide>
            <CardPlanSilver plan={plans.silver} />
          </SwiperSlide>
          <SwiperSlide>
            <CardPlanGold plan={plans.gold} />
          </SwiperSlide>
          <SwiperSlide>
            <CardPlanCustom plan={plans.custom} />
          </SwiperSlide>
        </Swiper>
      </div>
      <TerminosPLanes func={vewMore} class1={"terminos_planes"} class2={"sub see_termin"} class3={"prf_term dn"} />
    </section>
  )
}

export default CarrusellPrices