import React from "react";
import ReactDOM from "react-dom";

const Modal = ({ onClose, children, title, classes }) => {
  const handleCloseClick = (e) => {
    e.preventDefault();
    onClose();
  };

  const modalContent = (
    <div className={"modal-overlay " + classes}>
      <div className="modal-wrapper">
        <div className="modal">
          <div className="modal-header">
            <a href="#" onClick={handleCloseClick} className="ex">
              x
            </a>
          </div>
          {title && <h1 className="title_modal">{title} </h1>}
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(
    modalContent,
    document.getElementById("modal-root")
  );
};

export default Modal