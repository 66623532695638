import React, { useState, useEffect } from "react";
import $ from 'jquery'

// Trasnlation 
import '../i18n'
import { useTranslation } from 'react-i18next'


/*   IMAGENES    */
//titulos
import tit_div8 from '../assets/images/casos_de_exito.svg'
import tit_div8ING from '../assets/images/successtories.svg'

//casos de exito redes
import red1 from '../assets/images/marca_cards/PortafolioKTS-luciagentes.png'
import red2 from '../assets/images/marca_cards/PortafolioKTS_RS_emilan.png'
import red3 from '../assets/images/marca_cards/PortafolioKTS-sirimiri.png'
import red4 from '../assets/images/marca_cards/PortafolioKTS-cleandaz.png'
import red5 from '../assets/images/marca_cards/PortafolioKTS-BBTtodo.png'
import red6 from '../assets/images/marca_cards/PortafolioKTS-Price.png'

//casos de exito web desing
import web1 from '../assets/images/Ds_web/Portafolio_sirimiri.png'
import web2 from '../assets/images/Ds_web/Portafolio_luciagentes.png'
import web3 from '../assets/images/Ds_web/Portafolio_directorio.png'
import web4 from '../assets/images/Ds_web/Portafolio_emipremium.png'
import web5 from '../assets/images/Ds_web/Portafolio_emilan.png'
import web6 from '../assets/images/Ds_web/Portafolio_nubi.png'

//casos de exito marks
import mark1a from '../assets/images/marca_cards/luciagentes_moc_mobile.png'
import mark2a from '../assets/images/marca_cards/emilan_moc_mobile.png'
import mark3a from '../assets/images/marca_cards/sirimiri_moc_mobile.png'
import mark4a from '../assets/images/marca_cards/anayiri_moc_mobile.png'
import mark5a from '../assets/images/marca_cards/bbttodo_moc_mobile.png'
import mark6a from '../assets/images/marca_cards/vending_moc_mobile.png'
import mark7a from '../assets/images/marca_cards/cleandaz_moc_mobile.png'

import mark1b from '../assets/images/marca_cards/LogoLuciagentes.png'
import mark2b from '../assets/images/marca_cards/LogoEmilan.png'
import mark3b from '../assets/images/marca_cards/LogoSirimiri_Vertical.png'
import mark4b from '../assets/images/marca_cards/LogoAnayiri_Vertical.png'
import mark5b from '../assets/images/marca_cards/LogoBBT_Vertical.png'
import mark6b from '../assets/images/marca_cards/LogoTEV.png'
import mark7b from '../assets/images/marca_cards/LogoCleanDaz_Vertical.png'

//component
import ModalsMarks from "../components/modals_marks";

//funtion
import { modalActive } from "../helpers/modalsMarksActives";

// constants
const redes = [
  { id: 1, nombre: 'luciagentes', imagen: red1 },
  { id: 2, nombre: 'emilan', imagen: red2 },
  { id: 3, nombre: 'sirimiri', imagen: red3 },
  { id: 4, nombre: 'cleandaz', imagen: red4 },
  { id: 5, nombre: 'bbttodo', imagen: red5 },
  { id: 6, nombre: 'price', imagen: red6 }
]

const webs = [
  { id: 1, nombre: 'sirimiri', imagen: web1 },
  { id: 2, nombre: 'luciagentes', imagen: web2 },
  { id: 3, nombre: 'directorio', imagen: web3 },
  { id: 4, nombre: 'emilan premium', imagen: web4 },
  { id: 5, nombre: 'emilan', imagen: web5 },
  { id: 6, nombre: 'nubi Emilan', imagen: web6 }
]

const marks = [
  { id: 1, nombre: 'luciagentes', imagen: mark1a, imagenBK: mark1b, slogan: 'Soluciones para agentes de seguros.' },
  { id: 2, nombre: 'emilan', imagen: mark2a, imagenBK: mark2b, slogan: 'Agencia de seguros.' },
  { id: 3, nombre: 'sirimiri', imagen: mark3a, imagenBK: mark3b, slogan: 'Tienda en línea.' },
  { id: 4, nombre: 'anayiri', imagen: mark4a, imagenBK: mark4b, slogan: 'Marca de mochilas Wayuu.' },
  { id: 5, nombre: 'BBT todo', imagen: mark5a, imagenBK: mark5b, slogan: 'Licorera.' },
  { id: 6, nombre: 'vending', imagen: mark6a, imagenBK: mark6b, slogan: 'Servicio de transporte de mercancias.' },
  { id: 7, nombre: 'cleandaz', imagen: mark7a, imagenBK: mark7b, slogan: 'Servicios de organización y limpieza.' }
]


/**
 * Portafolio Componente se muestra en la direccion portafolio en el router y muestra directamente todas las opciones de los proyectos que se han creado en nuestra empresa y  las opciones de servicios que agragamos a los encabezados de dichas opciones 
 * @alias Portafolio
 */
const Portafolio = () => {

  const { t, i18n } = useTranslation();

  const initLang = () => {
    if (localStorage.getItem('lng')) {
      i18n.changeLanguage(localStorage.getItem('lng'))
    } else {
      window.localStorage.setItem('lng', 'es')
      i18n.changeLanguage('es')
    }
  }

  useEffect(() => {
    initLang()
  }, [])

  /**
   *  constante que define las opciones del portafolio
   * */
  const [casosExito, setCasosExito] = useState(1)

  /**
   *  constante que define que odal mostrar al escoger la marca de dideño
   * */
  const [openModal, setOpenModal] = useState({
    luci: false,
    emi: false,
    siri: false,
    anay: false,
    bbt: false,
    vet: false,
    clean: false,
  });

  /**
   *  cambia las opciones y muestra el contenido diferencial del portafofio en laversion desktop de la web 
   * */
  const changeCaseSuccess = function (e, opt) {
    $('.case').removeClass('active')
    $(e.target).parents('.case').addClass('active')
    setCasosExito(opt)
  }


  return (

    <div className="contenedor">
      <section className="div8">
        <div className="titulo">
          {(localStorage.getItem('lng') === 'es') ?
            <img src={tit_div8} alt="casos de exito" /> : <img src={tit_div8ING} alt="succes stories" />
          }
        </div>
        <div className="steps_cases">
          <div className="success_stories">
            <div className="case active">
              <div className="div_imag" onClick={(e) => changeCaseSuccess(e, 1)}>
                <i className="icon icon-redes2"></i>
              </div>
              <div className="title_case">{t('socialMedia1.label')}</div>
              <div className="subtitle_case">{t('socialMedia2.label')}</div>
            </div>
            <div className="case">
              <div className="div_imag" onClick={(e) => changeCaseSuccess(e, 2)}>
                <i className="icon icon-desarrollo"></i>
              </div>
              <div className="title_case">{t('webDev1.label')}</div>
              <div className="subtitle_case">{t('webDev2.label')}</div>
            </div>
            <div className="case">
              <div className="div_imag" onClick={(e) => changeCaseSuccess(e, 3)}>
                <i className="icon icon-creacion"></i>
              </div>
              <div className="title_case">{t('brandingCreate1.label')}</div>
              <div className="subtitle_case">{t('brandingCreate2.label')}</div>
            </div>
          </div>
          {casosExito && casosExito === 1 &&
            <div className="socialnet_cards animate__animated animate__fadeIn">
              {redes.map((red, i) => (
                <div className="card_socialnet" key={'cred' + i}>
                  <img src={red.imagen} alt={'imagen card de' + red.nombre} />
                </div>
              ))}
            </div>
          }
          {casosExito && casosExito === 2 &&
            <div className="dev_web animate__animated animate__fadeIn">
              {webs.map((web, i) => (
                <div className="card_devweb" key={'cweb' + i}>
                  <img src={web.imagen} alt={'imagen card de' + web.nombre} />
                </div>
              ))}
            </div>
          }
          {casosExito && casosExito === 3 &&
            <div className="mark_cards animate__animated animate__fadeIn">
              {marks.map((mark, i) => (
                <div className={"card_mark clas_" + mark.nombre} key={'cmark' + i} onClick={() => modalActive(mark.nombre, setOpenModal)} >
                  <div className="flip-box-inner">
                    <div className="flip-box-front">
                      <img src={mark.imagen} alt={'imagen de marca' + mark.nombre} />
                      <button className="mobile" onClick={() => modalActive(mark.nombre, setOpenModal)}>{t('see.label')}</button>
                    </div>
                    <div className="flip-box-back" >
                      <div className="logo">
                        <img src={mark.imagenBK} alt={'imagen de logo' + mark.nombre} />
                      </div>
                      <p>{mark.slogan}</p>
                      <button >{t('see.label')}</button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          }
        </div>
      </section>
      <ModalsMarks openModal={openModal} modalActive={modalActive} setOpenModal={setOpenModal} />
    </div>
  )
}

export default Portafolio