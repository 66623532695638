import React, { Fragment } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import $ from 'jquery'
import env from '../../environtments'

// Trasnlation 
import '../../i18n'
import { useTranslation } from 'react-i18next'

//images
import bigLogo from '../../assets/images/Marcas/logo_horizontal_white.svg';
import bigLogo_vert from '../../assets/images/logo_vertical.svg'

//components
import ButtonX from "../Button_x";
import Langs from "../langs";


/**
 * Header Componente muestra el menu de navegaciion y el de idioma , igual que el logo principal y un boton de accion que envia al whatapp --> {@link App} <-- Vista contenedora
 * @alias Header
 * @namespace
 */
const HeaderLanding = () => {

  const { t } = useTranslation();

  const { pathname } = useLocation();

  let history = useHistory();

  const Linkto = (link) => {
    history.push('/' + link);
  };

  const checkear = function () {
    $('.nav_bar').toggleClass('active')
  }

  return (
    <Fragment>
      <div className="header headerLanding">
        {(pathname === '/paquete_basico' || pathname === '/pauta_digital') &&
          <a href='/' className="big_logo">
            <img src={bigLogo} alt="logo kreative horizontal dinamico" />
          </a>
        }
        <div className="logo" onClick={() => Linkto('')}>
          <i className="icon icon-logoK"></i>
        </div>

        <nav className="nav_bar">

        </nav>
        <a className="button_header" href={(localStorage.getItem('lng') === 'es') ? env.LINK_WHATS : env.LINK_WHATS_EN} target='_blanck' >{t('quoteNow.label')}</a>
        <Langs />
      </div>
      <div className="header_mobile headerLanding_mb">
        {pathname === '/' &&
          <div className="big_logo_mobile">
            <img src={bigLogo_vert} alt="logo kreative en vertical dinamico" />
          </div>
        }

        <div className="boton_menu">
          <label htmlFor="menu" className="div_menu_icon">
            <ButtonX openClose={checkear} />
          </label>
        </div>
        <input type="checkbox" name="menu" id="menu" />
        <nav className="nav_bar">
          <ul>
            <li onClick={() => Linkto('')}>
              <i className="icon icon-home"></i>
              <span className="nav_link">{t('inicio.label')}</span>
            </li>
            <li onClick={() => Linkto('#carrServ2')}>
              <a href="#carrServ2">
                <i className="icon icon-servic"></i>
                <span className="nav_link">{t('services.label')}</span>
              </a>
            </li>
            <li onClick={() => Linkto('#Dprices2')}>
              <a href="#Dprices2">
                <i className="icon icon-price"></i>
                <span className="nav_link">{t('prices.label')}</span>
              </a>
            </li>
            <li onClick={() => Linkto('nosotros')}>
              <i className="icon icon-your"></i>
              <span className="nav_link">{t('aboutUs.label')}</span>
            </li>
            <li onClick={() => Linkto('portafolio')}>
              <i className="icon icon-bulb"></i>
              <span className="nav_link">{t('portfoilo.label')}</span>
            </li>
            <li onClick={() => Linkto('blog')}>
              <i className="icon icon-blog "></i>
              <span className="nav_link">{t('blog.label')}</span>
            </li>
            <li onClick={() => Linkto('#contact')}>
              <a href="#contact">
                <i className="icon icon-contac"></i>
                <span className="nav_link">{t('contact.label')}</span>
              </a>
            </li>
          </ul>
        </nav>
        <div className="logo" onClick={() => Linkto('')}>
          <i className="icon icon-logoK_mobile"></i>
        </div>
        <span className=""><a className="button_header" href={(localStorage.getItem('lng') === 'es') ? env.LINK_WHATS : env.LINK_WHATS_EN} target='_blanck' >{t('quoteNow.label')}</a></span>
        <Langs />
      </div>
    </Fragment>
  )
}

export default HeaderLanding