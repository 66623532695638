import React from 'react'

// Trasnlation 
import '../i18n'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react';

/**
 * Componente que muestra los terminos politicas de privacidad  data de kreative tree
  * @alias PoliticasPrivacidad 
  */
const PoliticasPrivacidad = () => {

  const { t, i18n } = useTranslation();
  /**
   * Funcion inicializadora de lenguaje de la vista 
   * @alias initLang
   */
  const initLang = () => {
    if (localStorage.getItem('lng')) {
      i18n.changeLanguage(localStorage.getItem('lng'))
    } else {
      window.localStorage.setItem('lng', 'es')
      i18n.changeLanguage('es')
    }
  }

  useEffect(() => {
    initLang()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='terminos contenedor'>
      <div className={(localStorage.getItem('lng') === 'es') ? "img_term terminos_priv" : "img_term terminos_priv_ing"}>
        {/* <img src={terminos} alt="" /> */}
      </div>
      <p>{t('Polpriv_par1.label')}</p>
      <p>{t('Polpriv_par2.label')}</p>
      <p>{t('Polpriv_par3.label')}</p>
      <p>{t('Polpriv_par4.label')}</p>
      <p>{t('Polpriv_par5.label')}</p>
      <p><ul>
        <li>{t('Polpriv_par5_listP1.label')}</li>
        <li>{t('Polpriv_par5_listP2.label')}</li>
        <li>{t('Polpriv_par5_listP3.label')}</li>
        <li>{t('Polpriv_par5_listP4.label')}</li>
        <li>{t('Polpriv_par5_listP5.label')}</li>
        <li>{t('Polpriv_par5_listP6.label')}</li>
        <li>{t('Polpriv_par5_listP7.label')}</li>
      </ul>
      </p>
      <p>{t('Polpriv_par6.label')}</p>
      <p>{t('Polpriv_par7.label')}</p>
      <p><ul>
        <li>{t('Polpriv_par7_listP1.label')}</li>
        <li>{t('Polpriv_par7_listP2.label')}</li>
        <li>{t('Polpriv_par7_listP3.label')}</li>
        <li>{t('Polpriv_par7_listP4.label')}</li>
        <li>{t('Polpriv_par7_listP5.label')}</li>
        <li>{t('Polpriv_par7_listP6.label')}</li>
        <li>{t('Polpriv_par7_listP7.label')}</li>
      </ul>
      </p>
      <p>{t('Polpriv_par8.label')}</p>
      <h3>{t('Polpriv_subtitl1.label')}</h3>
      <p>{t('Polpriv_par9.label')}</p>
      <p>{t('Polpriv_par10.label')}</p>
      <h3>{t('Polpriv_subtitl2.label')}</h3>
      <p>{t('Polpriv_par11.label')}</p>
      <p>{t('Polpriv_par12.label')}</p>
      <p>
        <ul>
          <li>{t('Polpriv_par12_listP1.label')}</li>
          <li>{t('Polpriv_par12_listP2.label')}</li>
          <li>{t('Polpriv_par12_listP3.label')}</li>
        </ul>
      </p>
      <p>{t('Polpriv_par13.label')}
        <a href="/habeas-data" target='_blank' rel='noopener noreferrer' >https://www.kreativetreestudio.com/habeas-data</a>
        <span > {t('Polpriv_par13_2.label')}</span></p>
      <p className='dir'>{t('Polpriv_par13_3.label')}</p>
      <p>{t('Polpriv_par14.label')}</p>
      <p>{t('Polpriv_par15.label')}</p>
      <p>{t('Polpriv_par16.label')}</p>
      <p className='fecha'>{t('Polpriv_par17_creaPolPri.label')}</p>
      <p className='fecha'>{t('Polpriv_par18_updaPolPriv.label')}</p>

    </div>
  )
}

export default PoliticasPrivacidad