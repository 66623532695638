import React from "react";
import $ from 'jquery'

// Trasnlation 
import '../i18n'
import { useTranslation } from 'react-i18next'

// Titulos
import tit_div4 from '../assets/images/tit_services_mobile.svg'
import tit_div4ING from '../assets/images/wecanmobile.svg'

import { SERVICES } from '../helpers/listServices'

/**  
  * Componente Card 
  * {@link CardServicios}  
*/
import CardServicios from "../components/subComponents/cardServicios";

/**  
  * Componente DivService es el contenedor de las card de servicios en ingles y español en la version web de la web --> {@link View}<-- vista contenedora
  * @alias DivServices
*/
const DivServices = () => {

  const { t } = useTranslation();

  /** Muestra y oculta el resto de cars en mobile */
  const vewMoreService = () => {
    $('.card_servicios.dn').toggleClass('ok')
    if ($('#but_mas').html() === `<span>${t('seeMore.label')}</span>`) {
      $('#but_mas').html(`<span>${t('seeLess.label')}</span>`)
    } else {
      $('#but_mas').html(`<span>${t('seeMore.label')}</span>`)
    }
  }

  return (
    <div className='contenedor '>
      <div className="div4 divServicios" id="carrServ2">
        <div className="titulo">
          {(localStorage.getItem('lng') === 'es')
            ? <img src={tit_div4} alt="Asi podemos ayudarte" />
            : <img src={tit_div4ING} alt="we can hekp you like this" />}
        </div>
        <div className=" servicios">
          {SERVICES && SERVICES.map((serv, i) => (
            <CardServicios titulo={serv.title} linkes={serv.linkES} linken={serv.linkEN} key={serv.title + i} dn={serv.dn}>
              {serv.svg}
            </CardServicios>
          ))}
        </div>
        <button className="boton_white" onClick={() => vewMoreService()} id='but_mas'><span>{t('seeMore.label')}</span></button>
      </div>
    </div>
  )
}

export default DivServices