import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      className="animated"
      id="freepik_stories-design-thinking"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      {...props}
    >
      <style>
        {
          "@keyframes wind{0%{transform:rotate(0deg)}25%{transform:rotate(1deg)}75%{transform:rotate(-1deg)}}@keyframes floating{0%,to{opacity:1;transform:translateY(0)}50%{transform:translateY(-10px)}}svg#freepik_stories-design-thinking:not(.animated) .animable{opacity:0}"
        }
      </style>
      <g
        id="freepik--Floor--inject-2"
        className="animable"
        style={{
          transformOrigin: "250px 350.63px"
        }}
      >
        <ellipse
          cx={250}
          cy={350.63}
          rx={220.89}
          ry={127.53}
          style={{
            transformOrigin: "250px 350.63px"
          }}
          id="el3g0ryac203d"
          className="animable"
          fill="#f5f5f5"
        />
      </g>
      <g
        id="freepik--Shadows--inject-2"
        className="animable"
        style={{
          transformOrigin: "243.089px 348.495px"
        }}
      >
        <path
          d="M426.15 395.31l-54.48 31.45c-4.73 2.73-13 2.39-18.43-.76l-51.83-29.93c-5.46-3.15-6.05-7.91-1.33-10.64L354.56 354c4.73-2.73 13-2.39 18.43.76l51.84 29.93c5.45 3.13 6.04 7.89 1.32 10.62z"
          style={{
            transformOrigin: "363.115px 390.38px"
          }}
          id="elx2wx3v0y2f"
          className="animable"
          fill="#e0e0e0"
        />
        <ellipse
          cx={307.53}
          cy={418.7}
          rx={42.95}
          ry={26.66}
          style={{
            transformOrigin: "307.53px 418.7px"
          }}
          id="els9qm3qp934"
          className="animable"
          fill="#e0e0e0"
        />
        <ellipse
          cx={98.8}
          cy={388.11}
          rx={41.96}
          ry={24.22}
          style={{
            transformOrigin: "98.8px 388.11px"
          }}
          id="elmu80ke68xgd"
          className="animable"
          fill="#e0e0e0"
        />
        <path
          d="M268.28 252.74L86.2 357.87a8.29 8.29 0 000 14.36l99.37 57.37a22.83 22.83 0 0022.86 0l174.8-100.92a8.29 8.29 0 000-14.36l-106.65-61.58a8.31 8.31 0 00-8.3 0z"
          style={{
            transformOrigin: "234.715px 342.148px"
          }}
          id="el3oillb37uw"
          className="animable"
          fill="#e0e0e0"
        />
      </g>
      <g
        id="freepik--Desk--inject-2"
        className="animable"
        style={{
          transformOrigin: "234.715px 283.604px"
        }}
      >
        <path
          d="M214.25 424.41a7.27 7.27 0 01-6.58 0c-1.82-1.05-1.82-2.75 0-3.8a7.27 7.27 0 016.58 0c1.82 1.05 1.82 2.75 0 3.8z"
          style={{
            transformOrigin: "210.96px 422.51px"
          }}
          id="elfdayqwq2kit"
          className="animable"
          fill="#455a64"
        />
        <path
          style={{
            transformOrigin: "210.975px 370.265px"
          }}
          id="el2sjafnh46rs"
          className="animable"
          fill="#455a64"
          d="M206.32 318.13H215.63V422.4H206.32z"
        />
        <path
          d="M105.11 361.81a7.27 7.27 0 01-6.58 0c-1.82-1.05-1.82-2.75 0-3.8a7.27 7.27 0 016.58 0c1.82 1.05 1.82 2.75 0 3.8z"
          style={{
            transformOrigin: "101.82px 359.91px"
          }}
          id="elmhq96hstlp"
          className="animable"
          fill="#455a64"
        />
        <path
          style={{
            transformOrigin: "101.825px 307.665px"
          }}
          id="el3i5yxplgvdo"
          className="animable"
          fill="#455a64"
          d="M97.17 255.53H106.48V359.8H97.17z"
        />
        <path
          d="M376.39 329.84a7.27 7.27 0 01-6.58 0c-1.82-1-1.82-2.75 0-3.8a7.27 7.27 0 016.58 0c1.82 1.05 1.82 2.75 0 3.8z"
          style={{
            transformOrigin: "373.1px 327.94px"
          }}
          id="elx6egdiz6nkd"
          className="animable"
          fill="#455a64"
        />
        <path
          style={{
            transformOrigin: "373.105px 275.705px"
          }}
          id="el0d1n9afci3lj"
          className="animable"
          fill="#455a64"
          d="M368.45 223.57H377.76V327.84H368.45z"
        />
        <path
          d="M267.25 267.24a7.27 7.27 0 01-6.58 0c-1.82-1.05-1.82-2.75 0-3.8a7.27 7.27 0 016.58 0c1.82 1.05 1.82 2.75 0 3.8z"
          style={{
            transformOrigin: "263.96px 265.34px"
          }}
          id="elnxktn9iugkn"
          className="animable"
          fill="#455a64"
        />
        <path
          style={{
            transformOrigin: "263.965px 213.105px"
          }}
          id="elr6p89zpyd3"
          className="animable"
          fill="#455a64"
          d="M259.31 160.97H268.62V265.24H259.31z"
        />
        <path
          style={{
            transformOrigin: "135.38px 295.37px"
          }}
          id="elw27p956vclp"
          className="animable"
          fill="#7519ff"
          d="M73.76 256.71L73.76 262.87 197 334.03 197 327.86 73.76 256.71z"
        />
        <g id="elo3znvh1lqa">
          <path
            style={{
              transformOrigin: "135.38px 295.37px"
            }}
            className="animable"
            id="el0tg4k4tvze1l"
            opacity={0.3}
            d="M73.76 256.71L73.76 262.87 197 334.03 197 327.86 73.76 256.71z"
          />
        </g>
        <path
          style={{
            transformOrigin: "296.335px 273.595px"
          }}
          id="el4deccbglqrq"
          className="animable"
          fill="#7519ff"
          d="M395.67 213.16L197 327.86 197 334.03 395.67 219.32 395.67 213.16z"
        />
        <g id="elha0xlefjzc">
          <path
            style={{
              transformOrigin: "296.335px 273.595px"
            }}
            className="animable"
            id="elgntbqxufzc"
            fill="#fff"
            opacity={0.3}
            d="M395.67 213.16L197 327.86 197 334.03 395.67 219.32 395.67 213.16z"
          />
        </g>
        <path
          style={{
            transformOrigin: "234.715px 234.935px"
          }}
          id="elbpp2g4qkd7c"
          className="animable"
          fill="#7519ff"
          d="M272.43 142.01L73.76 256.71 197 327.86 395.67 213.16 272.43 142.01z"
        />
      </g>
      <g
        id="freepik--Papers--inject-2"
        className="animable"
        style={{
          transformOrigin: "100.17px 324.174px"
        }}
      >
        <path
          d="M69.5 324.45v60.21c0 4.52 3 9 9 12.51 12 6.91 31.37 6.91 43.34 0 6-3.46 9-8 9-12.51v-60.21z"
          style={{
            transformOrigin: "100.17px 363.401px"
          }}
          id="el3lrl4cgrql"
          className="animable"
          fill="#37474f"
        />
        <ellipse
          cx={100.15}
          cy={324.19}
          rx={30.65}
          ry={17.69}
          style={{
            transformOrigin: "100.15px 324.19px"
          }}
          id="el4jqwu5tn58i"
          className="animable"
          fill="#455a64"
        />
        <path
          d="M121.15 332.52a21.67 21.67 0 01-3 2.07c-.2.12-.41.24-.63.35a29.66 29.66 0 01-4.32 1.89c-.59.2-1.21.4-1.83.56a33.85 33.85 0 01-4.32 1c-1.25.21-2.53.36-3.8.45-.52 0-1 .07-1.55.08h-2.92c-1.21 0-2.42-.11-3.61-.25a36.75 36.75 0 01-4.52-.78c-.6-.14-1.18-.28-1.76-.46a29.35 29.35 0 01-6.78-2.8 22.05 22.05 0 01-2.64-1.78c-5.81-4.62-6.37-10.75-1.71-15.67a18.64 18.64 0 014.35-3.36 28.34 28.34 0 015.63-2.45c.37-.13.77-.25 1.15-.35a32.52 32.52 0 013.17-.75c1.16-.22 2.33-.4 3.53-.52a43.19 43.19 0 0111.46.32l.35.05a34.65 34.65 0 014 .9 29.83 29.83 0 015.29 2c.51.24 1 .51 1.49.79a22.63 22.63 0 012.23 1.46 16.19 16.19 0 012.85 2.72c3.74 4.64 3.07 10.22-2.11 14.53z"
          style={{
            transformOrigin: "100.137px 324.216px"
          }}
          id="eli8ocmjzss8i"
          className="animable"
          fill="#263238"
        />
        <path
          d="M101.09 246.32V265h9.58v-12.71s-3.57-5.21-9.58-5.97z"
          style={{
            transformOrigin: "105.88px 255.66px"
          }}
          id="elzctle2kb3ri"
          className="animable"
          fill="#7519ff"
        />
        <g id="elzu5vdd1fakf">
          <path
            d="M101.09 246.32V265h9.58v-12.71s-3.57-5.21-9.58-5.97z"
            style={{
              transformOrigin: "105.88px 255.66px"
            }}
            className="animable"
            id="elphrhvkay7qh"
            fill="#fff"
            opacity={0.3}
          />
        </g>
        <path
          d="M107.07 251v87.37c-1.25.21-2.53.36-3.8.45-.52 0-1 .07-1.55.08H98.8c-1.21 0-2.42-.11-3.61-.25a36.75 36.75 0 01-4.52-.78c-.6-.14-1.18-.28-1.76-.46V251z"
          style={{
            transformOrigin: "97.99px 294.95px"
          }}
          id="elkegchl3he7g"
          className="animable"
          fill="#7519ff"
        />
        <g id="elnvo8rhu5gwf">
          <path
            d="M107.07 251v87.37c-1.25.21-2.53.36-3.8.45-.52 0-1 .07-1.55.08H98.8c-1.21 0-2.42-.11-3.61-.25a36.75 36.75 0 01-4.52-.78c-.6-.14-1.18-.28-1.76-.46V251z"
            style={{
              transformOrigin: "97.99px 294.95px"
            }}
            className="animable"
            id="elj5dpjnw1e2"
            fill="#fff"
            opacity={0.7}
          />
        </g>
        <path
          d="M111.39 253.92v83.47a33.85 33.85 0 01-4.32 1c-1.25.21-2.53.36-3.8.45-.52 0-1 .07-1.55.08v-77.83a15.44 15.44 0 005.35-1.84 9.48 9.48 0 003.61-3.54 5.84 5.84 0 00.71-1.79z"
          style={{
            transformOrigin: "106.555px 296.42px"
          }}
          id="elhdiyehnldat"
          className="animable"
          fill="#7519ff"
        />
        <g id="elax2khl32whs">
          <path
            d="M111.39 253.92v83.47a33.85 33.85 0 01-4.32 1c-1.25.21-2.53.36-3.8.45-.52 0-1 .07-1.55.08v-77.83a15.44 15.44 0 005.35-1.84 9.48 9.48 0 003.61-3.54 5.84 5.84 0 00.71-1.79z"
            style={{
              transformOrigin: "106.555px 296.42px"
            }}
            className="animable"
            id="eludfpouteo7"
            fill="#fff"
            opacity={0.5}
          />
        </g>
        <path
          d="M102.17 261.46a.46.46 0 01-.08-.91 15.44 15.44 0 004.82-1.74c2.3-1.32 3.56-3.06 3.56-4.88s-1.26-3.56-3.56-4.89a18.13 18.13 0 00-8.91-2.13.46.46 0 110-.91 19.06 19.06 0 019.38 2.25c2.59 1.5 4 3.51 4 5.68s-1.43 4.18-4 5.68a16.69 16.69 0 01-5.11 1.84z"
          style={{
            transformOrigin: "104.426px 253.727px"
          }}
          id="ellib6ro61evd"
          className="animable"
          fill="#7519ff"
        />
        <g id="elgipu8occ52f">
          <path
            d="M102.17 261.46a.46.46 0 01-.08-.91 15.44 15.44 0 004.82-1.74c2.3-1.32 3.56-3.06 3.56-4.88s-1.26-3.56-3.56-4.89a18.13 18.13 0 00-8.91-2.13.46.46 0 110-.91 19.06 19.06 0 019.38 2.25c2.59 1.5 4 3.51 4 5.68s-1.43 4.18-4 5.68a16.69 16.69 0 01-5.11 1.84z"
            style={{
              transformOrigin: "104.426px 253.727px"
            }}
            className="animable"
            id="elrnimhwopzl"
            fill="#fff"
            opacity={0.5}
          />
        </g>
        <path
          d="M104.41 247.54c3.55 2 3.55 5.36 0 7.41a14.17 14.17 0 01-12.84 0c-3.55-2-3.55-5.37 0-7.41a14.17 14.17 0 0112.84 0z"
          style={{
            transformOrigin: "97.99px 251.245px"
          }}
          id="el0f57q39faoza"
          className="animable"
          fill="#7519ff"
        />
        <g id="elhd4kz5id0ft">
          <path
            d="M104.41 247.54c3.55 2 3.55 5.36 0 7.41a14.17 14.17 0 01-12.84 0c-3.55-2-3.55-5.37 0-7.41a14.17 14.17 0 0112.84 0z"
            style={{
              transformOrigin: "97.99px 251.245px"
            }}
            className="animable"
            id="el0sgx16wevqfr"
            fill="#fff"
            opacity={0.5}
          />
        </g>
        <path
          d="M121.2 278.43l-2 13.86 7.09 1 1.37-9.44a11.36 11.36 0 00-6.46-5.42z"
          style={{
            transformOrigin: "123.43px 285.86px"
          }}
          id="eljl7270l9qb"
          className="animable"
          fill="#7519ff"
        />
        <g id="ely8u5vklgm58">
          <path
            d="M121.2 278.43l-2 13.86 7.09 1 1.37-9.44a11.36 11.36 0 00-6.46-5.42z"
            style={{
              transformOrigin: "123.43px 285.86px"
            }}
            className="animable"
            id="el73wfujfpwfe"
            fill="#fff"
            opacity={0.3}
          />
        </g>
        <path
          d="M125.13 282.53l-.83 5.71-.06.41v.37l-.57 3.91-3.22 22.31-2.86 19.7a29.66 29.66 0 01-4.32 1.89c-.59.2-1.21.4-1.83.56a33.85 33.85 0 01-4.32 1c-1.25.21-2.53.36-3.8.45l3.8-26.32.35-2.4 4-27.44.3-2.06 9 1.31 4.41.64z"
          style={{
            transformOrigin: "114.25px 309.73px"
          }}
          id="el8s5kl5m1iwb"
          className="animable"
          fill="#7519ff"
        />
        <g id="elw0z1kixwuel">
          <path
            d="M125.13 282.53l-.83 5.71-.06.41v.37l-.57 3.91-3.22 22.31-2.86 19.7a29.66 29.66 0 01-4.32 1.89c-.59.2-1.21.4-1.83.56a33.85 33.85 0 01-4.32 1c-1.25.21-2.53.36-3.8.45l3.8-26.32.35-2.4 4-27.44.3-2.06 9 1.31 4.41.64z"
            style={{
              transformOrigin: "114.25px 309.73px"
            }}
            className="animable"
            id="eldt0xl07620b"
            fill="#fff"
            opacity={0.7}
          />
        </g>
        <path
          d="M128 285.17L123.25 318l-2.1 14.56a21.67 21.67 0 01-3 2.07c-.2.12-.41.24-.63.35a29.66 29.66 0 01-4.32 1.89l3.48-23.87 3-20.63.42-2.93a11.78 11.78 0 004.15-.78 7 7 0 003-2.22 4.59 4.59 0 00.75-1.27z"
          style={{
            transformOrigin: "120.6px 311.02px"
          }}
          id="elkeokd6hk5w"
          className="animable"
          fill="#7519ff"
        />
        <g id="elc6b84cz1bd">
          <path
            d="M128 285.17L123.25 318l-2.1 14.56a21.67 21.67 0 01-3 2.07c-.2.12-.41.24-.63.35a29.66 29.66 0 01-4.32 1.89l3.48-23.87 3-20.63.42-2.93a11.78 11.78 0 004.15-.78 7 7 0 003-2.22 4.59 4.59 0 00.75-1.27z"
            style={{
              transformOrigin: "120.6px 311.02px"
            }}
            className="animable"
            id="elu0lzv476gqm"
            fill="#fff"
            opacity={0.5}
          />
        </g>
        <path
          d="M120.38 289.75a.33.33 0 01-.29-.32.34.34 0 01.32-.36 11.73 11.73 0 003.76-.77c1.84-.73 3-1.88 3.16-3.23s-.56-2.77-2.11-4a13.7 13.7 0 00-6.38-2.53.34.34 0 11.1-.67 14.2 14.2 0 016.7 2.67c1.75 1.38 2.59 3 2.36 4.63s-1.5 2.94-3.58 3.77a12.23 12.23 0 01-4 .81z"
          style={{
            transformOrigin: "123.279px 283.805px"
          }}
          id="elvw6rkg67zzi"
          className="animable"
          fill="#7519ff"
        />
        <g id="elg46x8lkbrz">
          <path
            d="M120.38 289.75a.33.33 0 01-.29-.32.34.34 0 01.32-.36 11.73 11.73 0 003.76-.77c1.84-.73 3-1.88 3.16-3.23s-.56-2.77-2.11-4a13.7 13.7 0 00-6.38-2.53.34.34 0 11.1-.67 14.2 14.2 0 016.7 2.67c1.75 1.38 2.59 3 2.36 4.63s-1.5 2.94-3.58 3.77a12.23 12.23 0 01-4 .81z"
            style={{
              transformOrigin: "123.279px 283.805px"
            }}
            className="animable"
            id="eldrwv22o07n"
            fill="#fff"
            opacity={0.5}
          />
        </g>
        <path
          d="M123.53 279.69c2.4 1.9 2 4.35-.8 5.49a10.61 10.61 0 01-9.5-1.38c-2.4-1.89-2-4.35.79-5.48a10.62 10.62 0 019.51 1.37z"
          style={{
            transformOrigin: "118.38px 281.748px"
          }}
          id="eloo9rgkureyk"
          className="animable"
          fill="#7519ff"
        />
        <g id="elpa0zp657neo">
          <path
            d="M123.53 279.69c2.4 1.9 2 4.35-.8 5.49a10.61 10.61 0 01-9.5-1.38c-2.4-1.89-2-4.35.79-5.48a10.62 10.62 0 019.51 1.37z"
            style={{
              transformOrigin: "118.38px 281.748px"
            }}
            className="animable"
            id="elmd9ybv35etb"
            fill="#fff"
            opacity={0.5}
          />
        </g>
        <path
          d="M82.53 268.32l1.68 15.34 7.85-.86-1.14-10.45s-3.39-3.95-8.39-4.03z"
          style={{
            transformOrigin: "87.295px 275.99px"
          }}
          id="el8abj3cs52te"
          className="animable"
          fill="#7519ff"
        />
        <g id="elwha89h50vgg">
          <path
            d="M82.53 268.32l1.68 15.34 7.85-.86-1.14-10.45s-3.39-3.95-8.39-4.03z"
            style={{
              transformOrigin: "87.295px 275.99px"
            }}
            className="animable"
            id="elikgtevtij4d"
            fill="#fff"
            opacity={0.3}
          />
        </g>
        <path
          d="M95.19 338.63a36.75 36.75 0 01-4.52-.78c-.6-.14-1.18-.28-1.76-.46a29.35 29.35 0 01-6.78-2.8 22.05 22.05 0 01-2.64-1.78l-1.71-15.67L73 273.23l10-1.09 4.89-.54.69 6.34v.86l.27 2.41.2 1.91 3 27.11z"
          style={{
            transformOrigin: "84.095px 305.115px"
          }}
          id="el96vusbjisdu"
          className="animable"
          fill="#7519ff"
        />
        <g id="elj4qadgfahf8">
          <path
            d="M95.19 338.63a36.75 36.75 0 01-4.52-.78c-.6-.14-1.18-.28-1.76-.46a29.35 29.35 0 01-6.78-2.8 22.05 22.05 0 01-2.64-1.78l-1.71-15.67L73 273.23l10-1.09 4.89-.54.69 6.34v.86l.27 2.41.2 1.91 3 27.11z"
            style={{
              transformOrigin: "84.095px 305.115px"
            }}
            className="animable"
            id="elj5nl7bvj5i"
            fill="#fff"
            opacity={0.7}
          />
        </g>
        <path
          d="M95.19 338.63a36.75 36.75 0 01-4.52-.78l-1.76-16.07-1.15-10.45-3-27.72-.35-3.25a12.45 12.45 0 004.23-2c.11-.08.22-.15.31-.23a7.77 7.77 0 002.32-3 4.93 4.93 0 00.42-1.54l4 36.08 3.19 29.17c-1.29.01-2.5-.07-3.69-.21z"
          style={{
            transformOrigin: "91.645px 306.215px"
          }}
          id="el1r896etpdm2"
          className="animable"
          fill="#7519ff"
        />
        <g id="el68v682lk44b">
          <path
            d="M95.19 338.63a36.75 36.75 0 01-4.52-.78l-1.76-16.07-1.15-10.45-3-27.72-.35-3.25a12.45 12.45 0 004.23-2c.11-.08.22-.15.31-.23a7.77 7.77 0 002.32-3 4.93 4.93 0 00.42-1.54l4 36.08 3.19 29.17c-1.29.01-2.5-.07-3.69-.21z"
            style={{
              transformOrigin: "91.645px 306.215px"
            }}
            className="animable"
            id="ela8wkhq229r"
            fill="#fff"
            opacity={0.5}
          />
        </g>
        <path
          d="M84.78 280.63a.38.38 0 01-.4-.27.37.37 0 01.24-.47 12.71 12.71 0 003.8-1.86c1.77-1.29 2.65-2.82 2.48-4.32s-1.35-2.8-3.35-3.68a14.92 14.92 0 00-7.51-.95.36.36 0 01-.41-.33.39.39 0 01.33-.42 15.73 15.73 0 017.89 1c2.26 1 3.61 2.51 3.8 4.29s-.79 3.55-2.78 5a13.58 13.58 0 01-4 2z"
          style={{
            transformOrigin: "85.6509px 274.415px"
          }}
          id="eli0u6r16gba"
          className="animable"
          fill="#7519ff"
        />
        <g id="el9w1wfo8kzt">
          <path
            d="M84.78 280.63a.38.38 0 01-.4-.27.37.37 0 01.24-.47 12.71 12.71 0 003.8-1.86c1.77-1.29 2.65-2.82 2.48-4.32s-1.35-2.8-3.35-3.68a14.92 14.92 0 00-7.51-.95.36.36 0 01-.41-.33.39.39 0 01.33-.42 15.73 15.73 0 017.89 1c2.26 1 3.61 2.51 3.8 4.29s-.79 3.55-2.78 5a13.58 13.58 0 01-4 2z"
            style={{
              transformOrigin: "85.6509px 274.415px"
            }}
            className="animable"
            id="elpk6scuz4mx"
            fill="#fff"
            opacity={0.5}
          />
        </g>
        <path
          d="M85.36 269c3.09 1.36 3.39 4.08.67 6.07a11.7 11.7 0 01-10.53 1.15c-3.09-1.35-3.38-4.08-.66-6.07A11.68 11.68 0 0185.36 269z"
          style={{
            transformOrigin: "80.4328px 272.61px"
          }}
          id="el42fb05x9y5h"
          className="animable"
          fill="#7519ff"
        />
        <g id="elja0ht1ymef">
          <path
            d="M85.36 269c3.09 1.36 3.39 4.08.67 6.07a11.7 11.7 0 01-10.53 1.15c-3.09-1.35-3.38-4.08-.66-6.07A11.68 11.68 0 0185.36 269z"
            style={{
              transformOrigin: "80.4328px 272.61px"
            }}
            className="animable"
            id="elr5954kbjrc"
            fill="#fff"
            opacity={0.5}
          />
        </g>
      </g>
      <g
        id="freepik--Palette--inject-2"
        className="animable"
        style={{
          transformOrigin: "296.793px 192.649px",
          animation: "1.5s Infinite linear wind",
          animationDelay: "0s"
        }}
      >
        <path
          d="M326.57 204.37l-12 6.91a3.32 3.32 0 01-3-.13l-40.76-23.53c-.89-.51-1-1.28-.21-1.73l12-6.91a3.33 3.33 0 013 .13l40.75 23.53c.89.51.98 1.28.22 1.73z"
          style={{
            transformOrigin: "298.577px 195.131px"
          }}
          id="ellzdaopyf6fe"
          className="animable"
          fill="#263238"
        />
        <path
          style={{
            transformOrigin: "283.39px 186.35px"
          }}
          id="eljrhhpw7bkp"
          className="animable"
          fill="#7519ff"
          d="M291.8 185.88L282.58 191.2 274.98 186.82 284.2 181.5 291.8 185.88z"
        />
        <path
          style={{
            transformOrigin: "293.405px 192.13px"
          }}
          id="el0t3rrcwjlz6"
          className="animable"
          fill="#7519ff"
          d="M301.81 191.66L292.59 196.98 285 192.6 294.21 187.28 301.81 191.66z"
        />
        <path
          style={{
            transformOrigin: "303.415px 197.915px"
          }}
          id="ellz7x5ylnqn"
          className="animable"
          fill="#7519ff"
          d="M311.82 197.44L302.61 202.77 295.01 198.38 304.23 193.06 311.82 197.44z"
        />
        <path
          style={{
            transformOrigin: "313.435px 203.695px"
          }}
          id="elsf6ia07nrvc"
          className="animable"
          fill="#7519ff"
          d="M321.84 203.23L312.62 208.55 305.03 204.16 314.24 198.84 321.84 203.23z"
        />
        <g id="elar4a2zoou1p">
          <path
            style={{
              transformOrigin: "283.39px 186.35px"
            }}
            className="animable"
            id="eln11lt0n44vq"
            opacity={0.4}
            d="M291.8 185.88L282.58 191.2 274.98 186.82 284.2 181.5 291.8 185.88z"
          />
        </g>
        <g id="elq7x174qc0bh">
          <path
            style={{
              transformOrigin: "293.405px 192.13px"
            }}
            className="animable"
            id="el1vwzd1fazjwi"
            opacity={0.5}
            d="M301.81 191.66L292.59 196.98 285 192.6 294.21 187.28 301.81 191.66z"
          />
        </g>
        <g id="elk4uftl9rxh">
          <path
            style={{
              transformOrigin: "303.415px 197.915px"
            }}
            className="animable"
            id="elybbjko21ab9"
            opacity={0.6}
            d="M311.82 197.44L302.61 202.77 295.01 198.38 304.23 193.06 311.82 197.44z"
          />
        </g>
        <g id="eldsvo66dtt9v">
          <path
            style={{
              transformOrigin: "313.435px 203.695px"
            }}
            className="animable"
            id="elp283gsr5xts"
            opacity={0.8}
            d="M321.84 203.23L312.62 208.55 305.03 204.16 314.24 198.84 321.84 203.23z"
          />
        </g>
        <path
          d="M328.89 191.67l-10.15 9.38a3.37 3.37 0 01-3 .54l-44.94-14c-1-.3-1.24-1-.59-1.64l10.15-9.38a3.35 3.35 0 013-.54l44.94 14c.97.3 1.24 1.03.59 1.64z"
          style={{
            transformOrigin: "299.546px 188.809px"
          }}
          id="elilkvtr60p5"
          className="animable"
          fill="#37474f"
        />
        <path
          style={{
            transformOrigin: "282.795px 183.615px"
          }}
          id="elvbgnadir94c"
          className="animable"
          fill="#7519ff"
          d="M290.89 181.3L283.08 188.53 274.7 185.92 282.52 178.7 290.89 181.3z"
        />
        <path
          style={{
            transformOrigin: "293.845px 187.045px"
          }}
          id="eldee4xjc8jqb"
          className="animable"
          fill="#7519ff"
          d="M301.94 184.74L294.12 191.96 285.75 189.35 293.56 182.13 301.94 184.74z"
        />
        <path
          style={{
            transformOrigin: "304.885px 190.475px"
          }}
          id="elqze4yl97rze"
          className="animable"
          fill="#7519ff"
          d="M312.98 188.17L305.17 195.39 296.79 192.78 304.61 185.56 312.98 188.17z"
        />
        <path
          style={{
            transformOrigin: "315.925px 193.905px"
          }}
          id="elcla246d0hqd"
          className="animable"
          fill="#7519ff"
          d="M324.02 191.6L316.21 198.82 307.83 196.22 315.65 188.99 324.02 191.6z"
        />
        <g id="eljb5r7zihgb">
          <path
            style={{
              transformOrigin: "282.795px 183.615px"
            }}
            className="animable"
            id="elyzpxbfx8gy"
            fill="#fff"
            opacity={0.1}
            d="M290.89 181.3L283.08 188.53 274.7 185.92 282.52 178.7 290.89 181.3z"
          />
        </g>
        <g id="elunql6a4jbtd">
          <path
            style={{
              transformOrigin: "304.885px 190.475px"
            }}
            className="animable"
            id="elw9lu398gh6k"
            opacity={0.1}
            d="M312.98 188.17L305.17 195.39 296.79 192.78 304.61 185.56 312.98 188.17z"
          />
        </g>
        <g id="eleirckn4e5vi">
          <path
            style={{
              transformOrigin: "315.925px 193.905px"
            }}
            className="animable"
            id="elro5na3nhrva"
            opacity={0.2}
            d="M324.02 191.6L316.21 198.82 307.83 196.22 315.65 188.99 324.02 191.6z"
          />
        </g>
        <path
          d="M328.48 179.64l-8 11.27a3.33 3.33 0 01-2.78 1.13l-46.85-4.42c-1-.09-1.43-.76-.91-1.48l8-11.27a3.36 3.36 0 012.78-1.14l46.85 4.42c1.01.1 1.43.76.91 1.49z"
          style={{
            transformOrigin: "299.211px 182.886px"
          }}
          id="elj1sxpct0hm"
          className="animable"
          fill="#455a64"
        />
        <path
          style={{
            transformOrigin: "281.72px 181.24px"
          }}
          id="elbdxcxtnkop"
          className="animable"
          fill="#7519ff"
          d="M289.17 177.31L283 185.99 274.27 185.17 280.43 176.49 289.17 177.31z"
        />
        <path
          style={{
            transformOrigin: "293.23px 182.32px"
          }}
          id="elk1zn81427e8"
          className="animable"
          fill="#7519ff"
          d="M300.68 178.4L294.52 187.07 285.78 186.25 291.95 177.57 300.68 178.4z"
        />
        <path
          style={{
            transformOrigin: "304.745px 183.41px"
          }}
          id="el0e0f1xdyv1ii"
          className="animable"
          fill="#7519ff"
          d="M312.19 179.48L306.03 188.16 297.3 187.34 303.46 178.66 312.19 179.48z"
        />
        <path
          style={{
            transformOrigin: "316.255px 184.5px"
          }}
          id="elxg6q9qgx7rp"
          className="animable"
          fill="#7519ff"
          d="M323.7 180.57L317.54 189.25 308.81 188.42 314.97 179.75 323.7 180.57z"
        />
        <g id="el6wydvmfchg6">
          <path
            style={{
              transformOrigin: "281.72px 181.24px"
            }}
            className="animable"
            id="el3r4qrsk9lrl"
            fill="#fff"
            opacity={0.8}
            d="M289.17 177.31L283 185.99 274.27 185.17 280.43 176.49 289.17 177.31z"
          />
        </g>
        <g id="elah9e651vfrr">
          <path
            style={{
              transformOrigin: "293.23px 182.32px"
            }}
            className="animable"
            id="eleids4k2rbc"
            fill="#fff"
            opacity={0.6}
            d="M300.68 178.4L294.52 187.07 285.78 186.25 291.95 177.57 300.68 178.4z"
          />
        </g>
        <g id="el160lph2n89d">
          <path
            style={{
              transformOrigin: "304.745px 183.41px"
            }}
            className="animable"
            id="elz5plfjdsqm"
            fill="#fff"
            opacity={0.4}
            d="M312.19 179.48L306.03 188.16 297.3 187.34 303.46 178.66 312.19 179.48z"
          />
        </g>
        <g id="elaj1qveho4ue">
          <path
            style={{
              transformOrigin: "316.255px 184.5px"
            }}
            className="animable"
            id="el2bp3gb7o62"
            fill="#fff"
            opacity={0.2}
            d="M323.7 180.57L317.54 189.25 308.81 188.42 314.97 179.75 323.7 180.57z"
          />
        </g>
        <path
          d="M268.37 190.27a4 4 0 01-3.88-2.94 4 4 0 117.76-2.17l-.78.21a3.22 3.22 0 00-6.21 1.74 3.24 3.24 0 004 2.24 3.2 3.2 0 001.94-1.53l.7.4a4.05 4.05 0 01-3.51 2.05z"
          style={{
            transformOrigin: "268.296px 186.243px"
          }}
          id="eljmgk0cftnb"
          className="animable"
          fill="#e0e0e0"
        />
      </g>
      <g
        id="freepik--Book--inject-2"
        className="animable"
        style={{
          transformOrigin: "174.985px 271.005px",
          animation: "1.5s Infinite linear wind",
          animationDelay: "0s"
        }}
      >
        <path
          d="M211.69 284.5l-33.86 19.55-42.73-24.67 33.9-19.59a2.89 2.89 0 012.9 0l39.76 22.95a1 1 0 01.03 1.76z"
          style={{
            transformOrigin: "173.65px 281.725px"
          }}
          id="el8ml1zjyorb"
          className="animable"
          fill="#7519ff"
        />
        <g id="elcltnjy3x9ql">
          <path
            d="M211.69 284.5l-33.86 19.55-42.73-24.67 33.9-19.59a2.89 2.89 0 012.9 0l39.76 22.95a1 1 0 01.03 1.76z"
            style={{
              transformOrigin: "173.65px 281.725px"
            }}
            className="animable"
            id="el7p4a1lprch5"
            opacity={0.7}
          />
        </g>
        <path
          d="M209.35 283.78L177.83 302l-39.77-23 31.58-18.23a2.71 2.71 0 012.7 0l37 21.37a.94.94 0 01.01 1.64z"
          style={{
            transformOrigin: "173.943px 281.205px"
          }}
          id="el1cdmsvfqqct"
          className="animable"
          fill="#fff"
        />
        <path
          style={{
            transformOrigin: "193.825px 290.97px"
          }}
          id="el2cf11ne6i8d"
          className="animable"
          fill="#fff"
          d="M177.83 301.98L177.83 298.43 209.82 279.96 209.82 282.99 177.83 301.98z"
        />
        <path
          style={{
            transformOrigin: "157.945px 288.72px"
          }}
          id="el5en1cysy7v9"
          className="animable"
          fill="#e0e0e0"
          d="M177.83 301.98L177.83 298.43 138.06 275.46 138.06 279.01 177.83 301.98z"
        />
        <path
          d="M211.69 278.88l-33.86 19.55-42.73-24.67 33.9-19.59a2.89 2.89 0 012.9 0l39.76 23a1 1 0 01.03 1.71z"
          style={{
            transformOrigin: "173.628px 276.105px"
          }}
          id="elt1g6r9p5n1"
          className="animable"
          fill="#7519ff"
        />
        <g id="elra6s4jjat2">
          <path
            d="M211.69 278.88l-33.86 19.55-42.73-24.67 33.9-19.59a2.89 2.89 0 012.9 0l39.76 23a1 1 0 01.03 1.71z"
            style={{
              transformOrigin: "173.628px 276.105px"
            }}
            className="animable"
            id="elyhfnlsqas8e"
            opacity={0.5}
          />
        </g>
        <path
          d="M173.42 301.5a.76.76 0 01-.64 0 .74.74 0 01-.4-.67v-3.21a4.2 4.2 0 012.05-3.61l.12-.07a.35.35 0 01.38 0 .36.36 0 01.19.33.67.67 0 001.34 0 1.72 1.72 0 00-2.59-1.48l-.12.08a5.54 5.54 0 00-2.71 4.75v3.21a2.11 2.11 0 001.11 1.85 2.16 2.16 0 001 .24 2.06 2.06 0 001.17-.35.7.7 0 00.26-.37z"
          style={{
            transformOrigin: "173.75px 297.737px"
          }}
          id="elydqyg3fu8"
          className="animable"
          fill="#fff"
        />
        <path
          d="M167.09 297.85a.76.76 0 01-1-.71v-3.21a4.22 4.22 0 012-3.61l.13-.07a.39.39 0 01.38 0 .4.4 0 01.19.33.67.67 0 101.33 0 1.71 1.71 0 00-2.58-1.47l-.12.07a5.55 5.55 0 00-2.71 4.76v3.21a2.1 2.1 0 001.11 1.85 2.16 2.16 0 001 .24 2.13 2.13 0 001.16-.35.61.61 0 00.26-.37z"
          style={{
            transformOrigin: "167.418px 294.056px"
          }}
          id="elyscs5jckgte"
          className="animable"
          fill="#fff"
        />
        <path
          d="M160.76 294.2a.74.74 0 01-.63 0 .76.76 0 01-.41-.68v-3.21a4.2 4.2 0 012.06-3.6l.12-.07a.36.36 0 01.38 0 .38.38 0 01.19.33.67.67 0 101.33 0 1.71 1.71 0 00-2.58-1.47l-.12.07a5.55 5.55 0 00-2.71 4.75v3.21a2.09 2.09 0 001.11 1.85 2.05 2.05 0 001 .25 2.1 2.1 0 001.16-.35.64.64 0 00.26-.37z"
          style={{
            transformOrigin: "161.097px 290.446px"
          }}
          id="el735czw77ggy"
          className="animable"
          fill="#fff"
        />
        <path
          d="M154.44 290.54a.75.75 0 01-.64 0 .77.77 0 01-.4-.68v-3.21a4.19 4.19 0 012-3.6l.12-.08a.38.38 0 01.38 0 .36.36 0 01.19.33.67.67 0 00.67.67.66.66 0 00.66-.67 1.7 1.7 0 00-2.58-1.47l-.12.07a5.55 5.55 0 00-2.71 4.75v3.21a2.09 2.09 0 001.11 1.85 2.05 2.05 0 001 .25 2.14 2.14 0 001.17-.35.74.74 0 00.26-.37z"
          style={{
            transformOrigin: "154.715px 286.772px"
          }}
          id="elbeglwcouj9f"
          className="animable"
          fill="#fff"
        />
        <path
          d="M148.11 286.89a.79.79 0 01-.64 0 .77.77 0 01-.4-.68V283a4.23 4.23 0 012.05-3.61l.13-.07a.38.38 0 01.57.33.67.67 0 001.33 0 1.7 1.7 0 00-.86-1.49 1.68 1.68 0 00-1.72 0l-.12.07a5.55 5.55 0 00-2.71 4.75v3.21a2.08 2.08 0 001.11 1.85 2.07 2.07 0 002.15-.11.62.62 0 00.26-.36z"
          style={{
            transformOrigin: "148.445px 283.106px"
          }}
          id="elnepzl1gaab"
          className="animable"
          fill="#fff"
        />
        <path
          d="M141.78 283.24a.77.77 0 01-1-.71v-3.21a4.21 4.21 0 012.06-3.61l.12-.07a.36.36 0 01.38 0 .36.36 0 01.19.33.67.67 0 001.33 0 1.69 1.69 0 00-.86-1.49 1.71 1.71 0 00-1.72 0l-.12.08a5.55 5.55 0 00-2.71 4.75v3.21a2.11 2.11 0 001.11 1.85 2.17 2.17 0 001 .25 2 2 0 001.16-.36.62.62 0 00.26-.36z"
          style={{
            transformOrigin: "142.155px 279.434px"
          }}
          id="elhqaxqfilve9"
          className="animable"
          fill="#fff"
        />
        <path
          style={{
            transformOrigin: "173.935px 266.86px"
          }}
          id="el1fxp2wcm0w1"
          className="animable"
          fill="#e6e6e6"
          d="M216.06 270.04L179.45 291.18 131.81 263.68 168.42 242.54 216.06 270.04z"
        />
        <path
          style={{
            transformOrigin: "176.455px 262.655px"
          }}
          id="elwgbd9w8ubp"
          className="animable"
          fill="#fafafa"
          d="M218.16 269.35L179.91 287.35 134.75 255.95 173 237.96 218.16 269.35z"
        />
        <path
          d="M169.41 256.9a10 10 0 00-2.15 11.19c1.71 4 6 6.26 10.18 6.4a11.54 11.54 0 0010.6-6 8.17 8.17 0 00.58-6.17 10.11 10.11 0 00-3.87-5.13 14.53 14.53 0 00-8.08-2.36c-2.43-.05-5.36.17-7 2.17-.33.39.24 1 .57.57 1.38-1.65 3.68-1.9 5.7-1.93a15.19 15.19 0 017 1.39c3.67 1.82 6.28 5.9 4.88 10a10.43 10.43 0 01-9.08 6.59 11 11 0 01-10.1-4.73 9.22 9.22 0 011.36-11.42c.38-.34-.19-.91-.57-.57z"
          style={{
            transformOrigin: "177.69px 264.662px"
          }}
          id="elz6fdl8ym0zk"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M175.05 268.75a6.71 6.71 0 005.58-.84 5.53 5.53 0 002.39-4.86 5 5 0 00-1.13-2.93c-.33-.4-.9.17-.57.57a4.59 4.59 0 01-.19 5.71 5.78 5.78 0 01-5.87 1.58.4.4 0 00-.21.77z"
          style={{
            transformOrigin: "178.905px 264.502px"
          }}
          id="el660rs0i1bnm"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M167.33 270.68a8.2 8.2 0 01-5.52-4.08c-.43-.84-.76-2.14-.08-3 1-1.16 2.56-.17 3.19.79a3.37 3.37 0 01.64 1.45c0 .84-1.32-.37-1.58-.62a6.36 6.36 0 01-1.74-3.1c-.5-2.23.49-5 3-5.36 1-.12 2.36.24 2.67 1.34.16.58-.14 1.25-.84.84a2.28 2.28 0 01-.9-1.54 4.08 4.08 0 013.21-4.86 4.28 4.28 0 013.09.7c.34.23 1.24.93.84 1.38-.6.65-1.24-.41-1.31-.91a2.63 2.63 0 011.8-2.6 4.11 4.11 0 013.6.61 5.31 5.31 0 011.63 1.46c.49.81-.51 1.32-1.11.79-1.17-1 .08-2.43 1.21-2.65 1.48-.29 3 .67 4.15 1.47a14 14 0 013.17 3.06c.31.41 1 0 .7-.41a15.17 15.17 0 00-4.25-3.85c-1.48-.89-3.41-1.65-5-.64a2.3 2.3 0 00-.59 3.53c.95 1 3.07.39 2.68-1.19a3.87 3.87 0 00-1.57-1.85 6.36 6.36 0 00-2.52-1.22 3.94 3.94 0 00-4.18 1.6c-.75 1.12-.77 3.28.82 3.73a1.41 1.41 0 001.59-.67c.36-.72-.17-1.43-.68-1.9a4.86 4.86 0 00-7.72 1.59 4.62 4.62 0 00.32 4.46 1.71 1.71 0 001.92.75 1.38 1.38 0 00.83-1.36c0-1.56-1.61-2.45-3-2.48a4.26 4.26 0 00-3.85 2.3 6.1 6.1 0 00-.19 5 7.3 7.3 0 001.27 2.13 5.05 5.05 0 002 1.59 1 1 0 001.31-1 4.43 4.43 0 00-.93-2.22c-.92-1.26-2.82-2.07-4.13-.88-1.11 1-.85 2.73-.28 3.94a8.66 8.66 0 003.66 3.7 9.4 9.4 0 002.42.9c.5.11.72-.67.21-.78z"
          style={{
            transformOrigin: "173.867px 260.76px"
          }}
          id="elyozyq7hz8hk"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M172.71 264a.71.71 0 01-.1.14c.07-.05 0 0 0 0l-.06.06a.52.52 0 01-.11.1s-.07.08 0 0l-.1.06-.14.09h-.06a.48.48 0 01-.17.05h-.18s.1 0 0 0h-.33s.08 0 0 0h-.25c-.02 0 0 0 0 0l-.12-.08-.06-.05v-.05c-.07-.08 0 .05 0 0l-.07-.1v-.07-.14a.7.7 0 010-.14c0 .09 0 .05 0 0v-.2-.07c0-.02 0 .06 0 0a1 1 0 010-.17s0-.05 0 0l.06-.12v-.06c0-.05.05 0 0 0s.07-.07.1-.1h.05c-.05.07-.05 0 0 0l.12-.07s-.1 0 0 0H171.63c.1 0-.08 0 0 0l.23.07c.1 0-.07 0 0 0l.12.07.18.11s.1.08 0 0l.11.1.16.15.18.19.06.08v.05a.42.42 0 00.55.15.4.4 0 00.14-.55 2.75 2.75 0 00-1.69-1.24 1.42 1.42 0 00-1.53.77 1.8 1.8 0 000 1.6 1.61 1.61 0 001.48.8 2.08 2.08 0 001.71-1 .4.4 0 10-.69-.4z"
          style={{
            transformOrigin: "171.685px 263.835px"
          }}
          id="el6cig7lerc3"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M174.68 259.48a1.54 1.54 0 001.1 1.34 1.4 1.4 0 001.61-.52 1.64 1.64 0 000-1.62 1.32 1.32 0 00-1.55-.64 1.67 1.67 0 00-.87.62 1.36 1.36 0 00-.24 1 .42.42 0 00.5.28.43.43 0 00.28-.5v.05-.11a.25.25 0 000-.08.24.24 0 000-.08s0-.1.08-.14.06-.09 0 0l.12-.12h.07l.14-.08H176.07c.06 0 0 0 0 0a.81.81 0 00.15 0c.07 0 0 0 0 0h.16c.07 0-.07 0 0 0a.54.54 0 00.13.07c-.07 0-.06 0 0 0v.1a.75.75 0 01.07.12s0 .08 0 0a.74.74 0 010 .14.13.13 0 000 .06c0-.11 0 0 0 0v.21c0 .07 0-.1 0 0v.13a.25.25 0 000 .07c0 .08 0-.06 0 0a.54.54 0 01-.07.13v.05s.06-.06 0 0v.05s-.08.06 0 0h-.26.05-.38a.2.2 0 00-.08 0l-.14-.09c-.08-.05 0 0 0 0l-.07-.06-.06-.06s-.07-.09 0 0l-.05-.08c0-.05 0 0 0 0v-.08a.19.19 0 010-.08s0-.06 0 0a.42.42 0 00-.41-.4.41.41 0 00-.4.4z"
          style={{
            transformOrigin: "176.142px 259.44px"
          }}
          id="elj3s20o8l3v"
          className="animable"
          fill="#7519ff"
        />
      </g>
      <g
        id="freepik--device-1--inject-2"
        className="animable"
        style={{
          transformOrigin: "201.7px 148.67px"
        }}
      >
        <path
          d="M235.91 220.74L219.69 230a8.34 8.34 0 01-8.28 0l-10.4-6-1.18-.68a8.12 8.12 0 01-4.06-7V186.3l24.35-13.86V203a7 7 0 003.5 6.06l12.31 7.11a2.62 2.62 0 01-.02 4.57z"
          style={{
            transformOrigin: "216.514px 201.77px"
          }}
          id="el7yvbn221hyi"
          className="animable"
          fill="#e0e0e0"
        />
        <path
          d="M216.22 231a8.34 8.34 0 003.47-1.06l16.22-9.24a2.62 2.62 0 000-4.55l-8.41-4.85h-11.3z"
          style={{
            transformOrigin: "226.715px 221.15px"
          }}
          id="elq8q7qnw68l"
          className="animable"
          fill="#e6e6e6"
        />
        <path
          d="M235.62 214.18l.45.36a3.1 3.1 0 011.17 2.42v1.51h-1.63z"
          style={{
            transformOrigin: "236.425px 216.325px"
          }}
          id="ellh963xekv2a"
          className="animable"
          fill="#e6e6e6"
        />
        <path
          d="M222 174.76v27.5a6.78 6.78 0 003.39 5.88l10.24 6a2.54 2.54 0 010 4.41l-1.16.66-14.55 8.28a8.06 8.06 0 01-8 0l-10.08-5.95a6.77 6.77 0 01-3.39-5.87v-27.5z"
          style={{
            transformOrigin: "217.68px 201.656px"
          }}
          id="elaqirvizz19"
          className="animable"
          fill="#f0f0f0"
        />
        <path
          d="M222 202.26v-27.5l-23.57 13.41v27.51a6.77 6.77 0 003.39 5.87L225 207.92a6.78 6.78 0 01-3-5.66z"
          style={{
            transformOrigin: "211.715px 198.155px"
          }}
          id="el8k8vcqjdhl7"
          className="animable"
          fill="#e6e6e6"
        />
        <path
          d="M147.41 236.16l-2.68 1.55v4.9a9.54 9.54 0 01-4.88-1.35l-.21-.12a5.28 5.28 0 01-2.45-3.36 3.12 3.12 0 01-.14-.93v-6.67z"
          style={{
            transformOrigin: "142.23px 236.395px"
          }}
          id="elp6gqk3qa1fi"
          className="animable"
          fill="#e0e0e0"
        />
        <path
          d="M263.81 56.2l-.21-.12a9.51 9.51 0 00-4.88-1.35 3 3 0 00-1.57.42l-115.43 66.38a9.32 9.32 0 00-4.67 8.08v100.57l10.36 6 109.66-63a9.34 9.34 0 004.67-8.09V60.76h4.66a5.3 5.3 0 00-2.59-4.56z"
          style={{
            transformOrigin: "201.725px 145.455px"
          }}
          id="elby5vybn23kc"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M138 125.42a9.36 9.36 0 00-1 4.19v100.57l10.36 6 3.88-2.24V133.63z"
          style={{
            transformOrigin: "144.12px 180.8px"
          }}
          id="elxg3nvlh9vfr"
          className="animable"
          fill="#263238"
        />
        <path
          d="M141.71 232.87v6.67a3.06 3.06 0 004.59 2.65l115.43-66.38a9.32 9.32 0 004.67-8.08v-6.85z"
          style={{
            transformOrigin: "204.055px 201.74px"
          }}
          id="elj5huw70tlba"
          className="animable"
          fill="#f0f0f0"
        />
        <path
          d="M266.4 60.49a3.06 3.06 0 00-4.58-2.65l-115.44 66.38a9.34 9.34 0 00-4.67 8.09v100.56l124.69-72"
          style={{
            transformOrigin: "204.055px 145.153px"
          }}
          id="eln8oa538vui9"
          className="animable"
          fill="#455a64"
        />
        <path
          d="M259.71 65.12a3.4 3.4 0 00-1.52.35l-108.51 62.37a8.26 8.26 0 00-4.12 7.14v85.69a2.66 2.66 0 00.22 1.07 2.72 2.72 0 003.84 1.27l108.48-62.37a8.26 8.26 0 004.12-7.14V67.81a2.7 2.7 0 00-2.51-2.69z"
          style={{
            transformOrigin: "203.89px 144.244px"
          }}
          id="ele2zej223vdi"
          className="animable"
          fill="#7519ff"
        />
        <g id="elbagtghllqv">
          <path
            d="M259.71 65.12a3.4 3.4 0 00-1.52.35l-108.51 62.37a8.26 8.26 0 00-4.12 7.14v85.69a2.66 2.66 0 00.22 1.07 2.72 2.72 0 003.84 1.27l108.48-62.37a8.26 8.26 0 004.12-7.14V67.81a2.7 2.7 0 00-2.51-2.69z"
            style={{
              transformOrigin: "203.89px 144.244px"
            }}
            className="animable"
            id="elfll8tsrb49b"
            fill="#fff"
            opacity={0.5}
          />
        </g>
      </g>
      <g
        id="freepik--device-2--inject-2"
        className="animable"
        style={{
          transformOrigin: "253.384px 226.908px"
        }}
      >
        <path
          d="M305.1 238.21l-.85.48a5.44 5.44 0 00-1.2-.94l-33.11-19.11c-2.48-1.44-6.24-1.59-8.39-.35L203.11 252a3.84 3.84 0 00-.44.32l-1-.57v2.47a3.5 3.5 0 002 2.62L236.82 276c2.48 1.43 6.24 1.59 8.39.35l58.44-33.74a2.49 2.49 0 001.43-2.07z"
          style={{
            transformOrigin: "253.385px 247.318px"
          }}
          id="el8nz68ef633e"
          className="animable"
          fill="#263238"
        />
        <path
          d="M303.65 240.27L245.21 274c-2.15 1.25-5.91 1.09-8.39-.34l-33.11-19.12c-2.48-1.43-2.75-3.6-.6-4.84l58.44-33.7c2.15-1.25 5.91-1.09 8.39.34l33.11 19.12c2.49 1.4 2.76 3.54.6 4.81z"
          style={{
            transformOrigin: "253.384px 245px"
          }}
          id="el2gkj5ve706o"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M296.59 239.09l-45.85 26.47a4.53 4.53 0 01-4.09-.17L218 248.87c-1.21-.69-1.34-1.75-.29-2.35L263.6 220a4.56 4.56 0 014.09.17l28.61 16.52c1.21.74 1.34 1.79.29 2.4z"
          style={{
            transformOrigin: "257.15px 242.782px"
          }}
          id="el1ncody7mcnh"
          className="animable"
          fill="#455a64"
        />
        <ellipse
          cx={226.43}
          cy={260.68}
          rx={3.81}
          ry={2.2}
          style={{
            transformOrigin: "226.43px 260.68px"
          }}
          id="eltl9madcx3w"
          className="animable"
          fill="#263238"
        />
        <path
          d="M235.16 263.39l-4 2.3a1.77 1.77 0 01-1.58-.07c-.46-.27-.51-.68-.11-.91l4-2.3a1.77 1.77 0 011.58.07c.46.27.52.68.11.91z"
          style={{
            transformOrigin: "232.317px 264.05px"
          }}
          id="eln24tybuhd3c"
          className="animable"
          fill="#263238"
        />
        <path
          d="M238.94 265.57l-4 2.3a1.77 1.77 0 01-1.58-.07c-.46-.27-.51-.67-.11-.91l4-2.29a1.74 1.74 0 011.58.06c.46.27.51.68.11.91z"
          style={{
            transformOrigin: "236.095px 266.231px"
          }}
          id="elln37tpalqq"
          className="animable"
          fill="#263238"
        />
        <path
          d="M219.65 254.44l-4 2.29a1.71 1.71 0 01-1.57-.06c-.47-.27-.52-.68-.12-.91l4-2.3a1.77 1.77 0 011.58.07c.46.27.52.67.11.91z"
          style={{
            transformOrigin: "216.807px 255.1px"
          }}
          id="ellgd9x4s77z"
          className="animable"
          fill="#263238"
        />
        <path
          d="M223.43 256.62l-4 2.3a1.77 1.77 0 01-1.58-.07c-.46-.27-.51-.68-.11-.91l4-2.3a1.77 1.77 0 011.58.07c.47.29.52.68.11.91z"
          style={{
            transformOrigin: "220.589px 257.28px"
          }}
          id="elkvv1ja5hx2k"
          className="animable"
          fill="#263238"
        />
        <path
          d="M258.88 198.22l-6.78-.58-6.79.56 1.71 9.69v.1a2.81 2.81 0 001.45 1.72 8 8 0 007.21 0 2.72 2.72 0 001.48-1.84z"
          style={{
            transformOrigin: "252.095px 204.104px"
          }}
          id="ela1jwy98copb"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M256.91 195.15c2.66 1.54 2.66 4 0 5.56a10.59 10.59 0 01-9.62 0c-2.66-1.53-2.66-4 0-5.56a10.65 10.65 0 019.62 0z"
          style={{
            transformOrigin: "252.1px 197.934px"
          }}
          id="eldh5svswxa4"
          className="animable"
          fill="#455a64"
        />
        <path
          d="M252.1 176.63a1.88 1.88 0 00-1.89 1.89v18.72a.93.93 0 00.55.77 2.92 2.92 0 002.67 0 .94.94 0 00.56-.77v-18.72a1.89 1.89 0 00-1.89-1.89z"
          style={{
            transformOrigin: "252.1px 187.482px"
          }}
          id="elq4ahdjg6cn"
          className="animable"
          fill="#263238"
        />
      </g>
      <g
        id="freepik--Windows--inject-2"
        className="animable"
        style={{
          transformOrigin: "185.875px 121.585px",
          animation: "1.5s Infinite linear wind",
          animationDelay: "0s"
        }}
      >
        <path
          d="M201.6 47a2.59 2.59 0 00-1.13.26l-80.12 46.07a6.11 6.11 0 00-3 5.28v63.27a1.86 1.86 0 00.17.79 2 2 0 002.83.94l80.11-46.06a6.11 6.11 0 003.05-5.28V49a2 2 0 00-1.91-2z"
          style={{
            transformOrigin: "160.43px 105.437px"
          }}
          id="ele6z80hnc3kh"
          className="animable"
          fill="#e0e0e0"
        />
        <path
          d="M201.56 48a1 1 0 01.93 1v63.27a5.13 5.13 0 01-2.56 4.44l-80.12 46.06a1 1 0 01-.51.14 1 1 0 01-1-.62 1.15 1.15 0 01-.08-.41V98.61a5.13 5.13 0 012.56-4.44L201 48.11a1.44 1.44 0 01.61-.13m0-1a2.59 2.59 0 00-1.13.26l-80.13 46.09a6.11 6.11 0 00-3 5.28v63.27a1.86 1.86 0 00.17.79 2 2 0 001.83 1.21 2.09 2.09 0 001-.27l80.11-46.06a6.11 6.11 0 003.05-5.28V49a2 2 0 00-1.86-2z"
          style={{
            transformOrigin: "160.43px 105.43px"
          }}
          id="elk7ta4og2gm"
          className="animable"
          fill="#e0e0e0"
        />
        <path
          style={{
            transformOrigin: "119.585px 163.22px"
          }}
          id="elp40ioyzolf8"
          className="animable"
          fill="#e0e0e0"
          d="M118.18 163.53L120.99 165.5 120.99 160.94 118.18 163.53z"
        />
        <path
          style={{
            transformOrigin: "203.595px 49.55px"
          }}
          id="elisej9gs573b"
          className="animable"
          fill="#e0e0e0"
          d="M202.5 47.31L205.35 49.3 201.84 51.79 202.5 47.31z"
        />
        <path
          d="M204.34 48.94a2.44 2.44 0 00-1.12.26l-80.13 46.06a6.09 6.09 0 00-3 5.27v63.28a2.06 2.06 0 00.16.79 2 2 0 002.83.93l80.12-46a6.1 6.1 0 003-5.28V50.93a2 2 0 00-1.86-1.99z"
          style={{
            transformOrigin: "163.145px 107.368px"
          }}
          id="el5avumwmv8l3"
          className="animable"
          fill="#fff"
        />
        <path
          d="M204.3 49.91a1 1 0 01.93 1v63.29a5.15 5.15 0 01-2.56 4.44l-80.12 46.06a1 1 0 01-.51.14 1 1 0 01-1-.62 1.15 1.15 0 01-.08-.41v-63.28a5.13 5.13 0 012.56-4.43L203.68 50a1.38 1.38 0 01.62-.13m0-1a2.44 2.44 0 00-1.12.26l-80.09 46.13a6.09 6.09 0 00-3 5.27v63.28a2.06 2.06 0 00.16.79 2 2 0 001.84 1.2 1.92 1.92 0 001-.27l80.12-46a6.1 6.1 0 003-5.28V50.93a2 2 0 00-1.85-2z"
          style={{
            transformOrigin: "163.15px 107.335px"
          }}
          id="eloeoae77ub8f"
          className="animable"
          fill="#e0e0e0"
        />
        <path
          d="M160.2 118a20.6 20.6 0 01.18-2.58c-5.59 1.92-9.93-.8-10.82-6.83-6.27 5.09-11 14.22-11 21.9 0 8.76 6.15 12.31 13.74 7.93 4.4-2.54 8.3-7.19 10.82-12.35-1.83-1.6-2.92-4.35-2.92-8.07z"
          style={{
            transformOrigin: "150.84px 124.443px"
          }}
          id="el5yxj3j70nod"
          className="animable"
          fill="#7519ff"
        />
        <g id="el56buouibxht">
          <path
            d="M160.2 118a20.6 20.6 0 01.18-2.58c-5.59 1.92-9.93-.8-10.82-6.83-6.27 5.09-11 14.22-11 21.9 0 8.76 6.15 12.31 13.74 7.93 4.4-2.54 8.3-7.19 10.82-12.35-1.83-1.6-2.92-4.35-2.92-8.07z"
            style={{
              transformOrigin: "150.84px 124.443px"
            }}
            className="animable"
            id="el8gjphvzilzq"
            fill="#fff"
            opacity={0.7}
          />
        </g>
        <path
          d="M176.67 93a31.63 31.63 0 01-10.82 19.32 15.89 15.89 0 01.19 2.36 26.92 26.92 0 01-2.92 11.45c2.51 2.26 6.42 2.4 10.81-.14 7.59-4.38 13.74-15 13.74-23.8 0-7.69-4.67-11.37-11-9.19z"
          style={{
            transformOrigin: "175.395px 110.12px"
          }}
          id="elc4g6u8t6h5e"
          className="animable"
          fill="#7519ff"
        />
        <g id="elfpq0r7q6k5m">
          <path
            d="M176.67 93a31.63 31.63 0 01-10.82 19.32 15.89 15.89 0 01.19 2.36 26.92 26.92 0 01-2.92 11.45c2.51 2.26 6.42 2.4 10.81-.14 7.59-4.38 13.74-15 13.74-23.8 0-7.69-4.67-11.37-11-9.19z"
            style={{
              transformOrigin: "175.395px 110.12px"
            }}
            className="animable"
            id="elihsodky007"
            opacity={0.3}
          />
        </g>
        <path
          d="M165.85 112.3a21.87 21.87 0 01-2.73 1.89 16.58 16.58 0 01-2.74 1.27 20.6 20.6 0 00-.18 2.58c0 3.68 1.09 6.43 2.92 8.07a26.92 26.92 0 002.88-11.45 15.89 15.89 0 00-.15-2.36z"
          style={{
            transformOrigin: "163.1px 119.205px"
          }}
          id="eljq9dr053qd"
          className="animable"
          fill="#7519ff"
        />
        <g id="el5olcm9nshin">
          <path
            d="M165.85 112.3a21.87 21.87 0 01-2.73 1.89 16.58 16.58 0 01-2.74 1.27 20.6 20.6 0 00-.18 2.58c0 3.68 1.09 6.43 2.92 8.07a26.92 26.92 0 002.88-11.45 15.89 15.89 0 00-.15-2.36z"
            style={{
              transformOrigin: "163.1px 119.205px"
            }}
            className="animable"
            id="eljbhpnvkdqc"
            opacity={0.5}
          />
        </g>
        <path
          d="M163.12 106.59c2.51-5.16 6.42-9.81 10.81-12.35a15.72 15.72 0 012.74-1.24 20.73 20.73 0 00.18-2.58c0-8.76-6.15-12.31-13.73-7.93s-13.74 15-13.74 23.79a16.07 16.07 0 00.18 2.37 21 21 0 012.74-1.9c4.4-2.56 8.3-2.42 10.82-.16z"
          style={{
            transformOrigin: "163.115px 94.6319px"
          }}
          id="el7czugq7emes"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M160.38 115.46a28.66 28.66 0 012.74-8.87c-2.52-2.26-6.42-2.4-10.82.14a21 21 0 00-2.74 1.9c.89 6.03 5.23 8.75 10.82 6.83z"
          style={{
            transformOrigin: "156.34px 110.464px"
          }}
          id="el2i0l6upmduf"
          className="animable"
          fill="#7519ff"
        />
        <g id="elgckzm1saok4">
          <path
            d="M160.38 115.46a28.66 28.66 0 012.74-8.87c-2.52-2.26-6.42-2.4-10.82.14a21 21 0 00-2.74 1.9c.89 6.03 5.23 8.75 10.82 6.83z"
            style={{
              transformOrigin: "156.34px 110.464px"
            }}
            className="animable"
            id="el871v4g6un0b"
            fill="#fff"
            opacity={0.3}
          />
        </g>
        <path
          d="M163.12 106.59a9.29 9.29 0 012.73 5.71A31.63 31.63 0 00176.67 93a15.72 15.72 0 00-2.74 1.26c-4.39 2.52-8.3 7.17-10.81 12.33z"
          style={{
            transformOrigin: "169.895px 102.65px"
          }}
          id="elslgtc5w1f4p"
          className="animable"
          fill="#7519ff"
        />
        <g id="ela8g7d8hl1t">
          <path
            d="M163.12 106.59a9.29 9.29 0 012.73 5.71A31.63 31.63 0 00176.67 93a15.72 15.72 0 00-2.74 1.26c-4.39 2.52-8.3 7.17-10.81 12.33z"
            style={{
              transformOrigin: "169.895px 102.65px"
            }}
            className="animable"
            id="el21s901vq360h"
            opacity={0.7}
          />
        </g>
        <path
          d="M163.12 106.59a28.66 28.66 0 00-2.74 8.87 16.58 16.58 0 002.74-1.27 21.87 21.87 0 002.73-1.89 9.29 9.29 0 00-2.73-5.71z"
          style={{
            transformOrigin: "163.115px 111.025px"
          }}
          id="eleftl7twuah"
          className="animable"
          fill="#263238"
        />
        <path
          d="M250.59 98a2.06 2.06 0 00-.93.21l-66.2 38.06a5 5 0 00-2.52 4.36v52.29a1.76 1.76 0 00.13.66 1.66 1.66 0 002.34.77l66.21-38.06a5 5 0 002.51-4.36V99.66a1.64 1.64 0 00-1.54-1.66z"
          style={{
            transformOrigin: "216.535px 146.284px"
          }}
          id="elvyr894kvly"
          className="animable"
          fill="#e0e0e0"
        />
        <path
          d="M250.56 98.82a.84.84 0 01.76.84V152a4.23 4.23 0 01-2.11 3.66L183 193.67a.79.79 0 01-.42.12.84.84 0 01-.78-.51.8.8 0 01-.07-.34v-52.29a4.23 4.23 0 012.11-3.66l66.21-38.07a1.16 1.16 0 01.51-.1m0-.8a2.06 2.06 0 00-.93.21l-66.2 38.06a5 5 0 00-2.52 4.36v52.29a1.76 1.76 0 00.13.66 1.65 1.65 0 002.34.77l66.21-38.06a5 5 0 002.51-4.36V99.66a1.64 1.64 0 00-1.51-1.66z"
          style={{
            transformOrigin: "216.505px 146.296px"
          }}
          id="eli969evbs9ij"
          className="animable"
          fill="#e0e0e0"
        />
        <path
          style={{
            transformOrigin: "182.82px 194.045px"
          }}
          id="elvbte3wi17wo"
          className="animable"
          fill="#e0e0e0"
          d="M181.66 194.3L183.98 195.93 183.98 192.16 181.66 194.3z"
        />
        <path
          style={{
            transformOrigin: "252.24px 100.115px"
          }}
          id="el4led2g8vzcs"
          className="animable"
          fill="#e0e0e0"
          d="M251.33 98.27L253.69 99.91 250.79 101.96 251.33 98.27z"
        />
        <path
          d="M252.85 99.61a2 2 0 00-.93.22l-66.2 38.06a5 5 0 00-2.52 4.35v52.29a1.59 1.59 0 00.14.66 1.66 1.66 0 002.34.77l66.21-38.06a5 5 0 002.51-4.36v-52.29a1.64 1.64 0 00-1.55-1.64z"
          style={{
            transformOrigin: "218.8px 147.894px"
          }}
          id="ela0u8e77sp6j"
          className="animable"
          fill="#fff"
        />
        <path
          d="M252.82 100.41a.83.83 0 01.76.84v52.29a4.26 4.26 0 01-2.11 3.67l-66.21 38.06a.88.88 0 01-.42.11.85.85 0 01-.78-.51.8.8 0 01-.07-.34v-52.29a4.23 4.23 0 012.12-3.66l66.2-38.06a1.19 1.19 0 01.51-.11m0-.8a2 2 0 00-.93.22l-66.2 38.06a5 5 0 00-2.52 4.35v52.29a1.59 1.59 0 00.14.66 1.68 1.68 0 001.52 1 1.63 1.63 0 00.82-.22l66.21-38.06a5 5 0 002.51-4.36v-52.3a1.64 1.64 0 00-1.53-1.64z"
          style={{
            transformOrigin: "218.77px 147.9px"
          }}
          id="elglc0r59dlbm"
          className="animable"
          fill="#e0e0e0"
        />
        <path
          style={{
            transformOrigin: "221.14px 160.125px"
          }}
          id="elwwlgq94sfxc"
          className="animable"
          fill="#7519ff"
          d="M246.11 151.86L196.17 180.69 210.93 142.3 221.14 154.44 231.59 139.56 246.11 151.86z"
        />
        <g id="el6atj35rifab">
          <path
            style={{
              transformOrigin: "221.14px 160.125px"
            }}
            className="animable"
            id="el6sttocepkae"
            fill="#fff"
            opacity={0.4}
            d="M246.11 151.86L196.17 180.69 210.93 142.3 221.14 154.44 231.59 139.56 246.11 151.86z"
          />
        </g>
        <path
          d="M228.79 127.91a10.08 10.08 0 01-4.55 7.89c-2.52 1.45-4.56.27-4.56-2.63a10.05 10.05 0 014.56-7.89c2.51-1.45 4.55-.28 4.55 2.63z"
          style={{
            transformOrigin: "224.235px 130.539px"
          }}
          id="eltlyajv0mi8a"
          className="animable"
          fill="#7519ff"
        />
      </g>
      <g
        id="freepik--Character--inject-2"
        className="animable"
        style={{
          transformOrigin: "356.018px 285.425px"
        }}
      >
        <path
          d="M348.89 279.45l15 79.74a2.45 2.45 0 004.83-.81l-11-73.88z"
          style={{
            transformOrigin: "358.816px 320.303px"
          }}
          id="el8pkqwzjc0g8"
          className="animable"
          fill="#263238"
        />
        <path
          d="M377.7 331.15l-15.26 93.11a2.9 2.9 0 01-2.86 2.43 2.9 2.9 0 01-2.89-3.22l10.71-96.06z"
          style={{
            transformOrigin: "367.186px 377.05px"
          }}
          id="elf1mt84e1hso"
          className="animable"
          fill="#455a64"
        />
        <path
          d="M325 299.83l-15.26 93.11a2.9 2.9 0 01-2.86 2.44 2.91 2.91 0 01-2.89-3.23l10.71-96z"
          style={{
            transformOrigin: "314.486px 345.765px"
          }}
          id="elsjpmcv1ao3f"
          className="animable"
          fill="#455a64"
        />
        <path
          d="M402.16 309.38l15 80.23a2.45 2.45 0 004.83-.8l-11.12-74.37z"
          style={{
            transformOrigin: "412.084px 350.47px"
          }}
          id="el5sj34dalv3o"
          className="animable"
          fill="#263238"
        />
        <path
          d="M352.2 273.38A21.39 21.39 0 00364.81 256c2.43-24.43 11-74.77 40.19-58 36 20.68 23.1 80.75 17.87 100a26 26 0 01-6.61 11.51c-6.51 6.55-20 17.83-42.86 27.43a13.45 13.45 0 01-8.87.53c-11.37-3.24-41.08-13.19-55.9-32.14a5.18 5.18 0 01.85-7.24c6.01-4.81 19.57-14.39 42.72-24.71z"
          style={{
            transformOrigin: "368.492px 266.315px"
          }}
          id="el20w4qexdlne"
          className="animable"
          fill="#7519ff"
        />
        <g id="elgp363airup5">
          <path
            d="M352.2 273.38A21.39 21.39 0 00364.81 256c2.43-24.43 11-74.77 40.19-58 36 20.68 23.1 80.75 17.87 100a26 26 0 01-6.61 11.51c-6.51 6.55-20 17.83-42.86 27.43a13.45 13.45 0 01-8.87.53c-11.37-3.24-41.08-13.19-55.9-32.14a5.18 5.18 0 01.85-7.24c6.01-4.81 19.57-14.39 42.72-24.71z"
            style={{
              transformOrigin: "368.492px 266.315px"
            }}
            className="animable"
            id="el9xd4ic4ould"
            opacity={0.3}
          />
        </g>
        <path
          d="M307.56 301.84a5.12 5.12 0 001.07 3.45c.79 1 1.63 2 2.5 3 .48.52 1 1 1.46 1.55.5.5 1 1 1.51 1.49h.05c.51.49 1 1 1.56 1.45.53.48 1.08 1 1.63 1.41 8.4 7.06 18.6 12.43 27.71 16.3l1.69.7.17.07c.53.22 1 .43 1.56.63l.27.11 1.45.56.33.13 1.35.51.39.15 1.25.45.45.16 1.15.41.48.17 1.06.36.51.18 1 .32.55.18.87.28.58.18.75.24.63.19.64.2.77.23.39.11 1.09.32a13.45 13.45 0 008.87-.53c22.89-9.6 36.35-20.88 42.86-27.43a26 26 0 006.71-11.37C428.1 278.7 441 218.63 405 198c-.64-.37-1.26-.69-1.88-1l-.41-.19q-.91-.44-1.8-.78l-.17-.06c-.57-.22-1.12-.4-1.67-.56l-.25-.07c-.6-.17-1.18-.3-1.75-.41h-.14a15.83 15.83 0 00-3.45-.24h-.07a14.68 14.68 0 00-3.27.51c5.75-2.54 12.77-2.43 19.73 1.57 36 20.69 23.1 80.76 17.87 100l-.89 3.31a31.3 31.3 0 01-11.23 16.79l-9.34 7.13a147.17 147.17 0 01-32.84 18.71 13.48 13.48 0 01-8.9.54c-11.36-3.25-41.09-13.25-55.91-32.18a5.15 5.15 0 01-1.07-2.82v-6.42z"
          style={{
            transformOrigin: "370.937px 268.627px"
          }}
          id="el9ep74s4zse"
          className="animable"
          fill="#7519ff"
        />
        <g id="elp2p4ejcckmj">
          <path
            d="M307.56 301.84a5.12 5.12 0 001.07 3.45c.79 1 1.63 2 2.5 3 .48.52 1 1 1.46 1.55.5.5 1 1 1.51 1.49h.05c.51.49 1 1 1.56 1.45.53.48 1.08 1 1.63 1.41 8.4 7.06 18.6 12.43 27.71 16.3l1.69.7.17.07c.53.22 1 .43 1.56.63l.27.11 1.45.56.33.13 1.35.51.39.15 1.25.45.45.16 1.15.41.48.17 1.06.36.51.18 1 .32.55.18.87.28.58.18.75.24.63.19.64.2.77.23.39.11 1.09.32a13.45 13.45 0 008.87-.53c22.89-9.6 36.35-20.88 42.86-27.43a26 26 0 006.71-11.37C428.1 278.7 441 218.63 405 198c-.64-.37-1.26-.69-1.88-1l-.41-.19q-.91-.44-1.8-.78l-.17-.06c-.57-.22-1.12-.4-1.67-.56l-.25-.07c-.6-.17-1.18-.3-1.75-.41h-.14a15.83 15.83 0 00-3.45-.24h-.07a14.68 14.68 0 00-3.27.51c5.75-2.54 12.77-2.43 19.73 1.57 36 20.69 23.1 80.76 17.87 100l-.89 3.31a31.3 31.3 0 01-11.23 16.79l-9.34 7.13a147.17 147.17 0 01-32.84 18.71 13.48 13.48 0 01-8.9.54c-11.36-3.25-41.09-13.25-55.91-32.18a5.15 5.15 0 01-1.07-2.82v-6.42z"
            style={{
              transformOrigin: "370.937px 268.627px"
            }}
            className="animable"
            id="elgbycnbw98h"
            opacity={0.5}
          />
        </g>
        <path
          d="M417.11 158.29s8-8.27 17.13-3 4.8 22 2.17 26.75-7.5 21.83 6.77 18.37c0 0-4.88 7-15 5.09s-12.08-14.09-9.38-24.17-1.65-14.66-1.65-14.66z"
          style={{
            transformOrigin: "430.145px 179.682px"
          }}
          id="elqqzbvmqvdd"
          className="animable"
          fill="#263238"
        />
        <path
          d="M375.32 198.86s-10.59-1.1-14.8.79-30.91 39.76-30.91 39.76 10 3.16 26.19-10.25 19.52-30.3 19.52-30.3z"
          style={{
            transformOrigin: "352.465px 219.138px"
          }}
          id="eljy916sy9mea"
          className="animable"
          fill="#945959"
        />
        <path
          d="M410.58 237.67l1.26-29-13.37-6.67-15.93-3.1h-7.22s-14.08 12-18.43 23.57 5.49 20.2 5.49 20.2c0 9.75-5.49 20.43-5.49 20.43s9.43 13.78 30.18 12.26a52.93 52.93 0 0019.15-5.6 113.75 113.75 0 00-3.67-15z"
          style={{
            transformOrigin: "383.81px 237.189px"
          }}
          id="elbi8ckk76bym"
          className="animable"
          fill="#7519ff"
        />
        <g id="el3lw4f6g5aqs">
          <path
            d="M410.58 237.67l1.26-29-13.37-6.67-15.93-3.1h-7.22s-14.08 12-18.43 23.57 5.49 20.2 5.49 20.2c0 9.75-5.49 20.43-5.49 20.43s9.43 13.78 30.18 12.26a52.93 52.93 0 0019.15-5.6 113.75 113.75 0 00-3.67-15z"
            style={{
              transformOrigin: "383.81px 237.189px"
            }}
            className="animable"
            id="el5wizh8b9jyd"
            fill="#fff"
            opacity={0.5}
          />
        </g>
        <path
          d="M384.08 140.29s-10.85-5.78-15.48 5.53 2.21 24.05-11.36 25.92c0 0 6.62 7.53 15.18-3.26-1.21 1.53.21 6.53.75 8.17a18.19 18.19 0 005 7.76 25.32 25.32 0 009 4.94 21.68 21.68 0 007.46 1.36c3.86-.09 8.72-1.85 12.07-3.78a22.41 22.41 0 008.5-8.93 33 33 0 002.8-22 22.41 22.41 0 00-5.94-11c-7.65-6.8-18.49-7.11-27.98-4.71z"
          style={{
            transformOrigin: "388.004px 164.762px"
          }}
          id="el034etkda8fb"
          className="animable"
          fill="#263238"
        />
        <path
          d="M384.09 189l-1.55 9.91s-12 9.81-2.18 12.28 18.11-9.19 18.11-9.19l3.08-19.65z"
          style={{
            transformOrigin: "389.023px 196.942px"
          }}
          id="eldp0rcump8yw"
          className="animable"
          fill="#945959"
        />
        <path
          d="M401.89 179l-.09.57c-5.8 13.53-15.53 15.14-18.64 15.43l3.3-21.82 15.54 2.31z"
          style={{
            transformOrigin: "392.58px 184.09px"
          }}
          id="el1xml26vbqeb"
          className="animable"
          fill="#703838"
        />
        <path
          d="M392 145.31c-6-.44-19 2-21.6 17.53s-2.1 27.83 5.9 29.59 27.22-2.71 31.23-22.82-9.72-23.87-15.53-24.3z"
          style={{
            transformOrigin: "388.624px 169.022px"
          }}
          id="el0at5or1mgt3v"
          className="animable"
          fill="#945959"
        />
        <path
          style={{
            transformOrigin: "377.09px 175.4px"
          }}
          id="el8umtlemvn2"
          className="animable"
          fill="#703838"
          d="M380.31 170.81L379.02 179.99 373.87 177.58 380.31 170.81z"
        />
        <path
          d="M388.39 171.82a1.88 1.88 0 11-1.6-2.13 1.88 1.88 0 011.6 2.13z"
          style={{
            transformOrigin: "386.529px 171.552px"
          }}
          id="ellyljd5yipl"
          className="animable"
          fill="#263238"
        />
        <path
          d="M376.28 168.77a1.89 1.89 0 11-1.6-2.13 1.89 1.89 0 011.6 2.13z"
          style={{
            transformOrigin: "374.408px 168.51px"
          }}
          id="elzxueisqudkh"
          className="animable"
          fill="#263238"
        />
        <path
          d="M372.69 164.29l4-1.65s-1.19-1.66-2.72-1-1.28 2.65-1.28 2.65z"
          style={{
            transformOrigin: "374.682px 162.887px"
          }}
          id="elsp6e5uaxjq"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M380.2 182.27a57.15 57.15 0 005.94-2.28s.35 1.24-2.28 2.14-3.66.14-3.66.14z"
          style={{
            transformOrigin: "383.178px 181.28px"
          }}
          id="elpqvvrb4qiz"
          className="animable"
          fill="#703838"
        />
        <path
          d="M372.69 164.29l4-1.65s-1.19-1.66-2.72-1-1.28 2.65-1.28 2.65z"
          style={{
            transformOrigin: "374.682px 162.887px"
          }}
          id="elhhddz5iyri6"
          className="animable"
          fill="#263238"
        />
        <path
          d="M397.72 172.42s-1.4 6.51 2.38 7.1l7.41 1.16 2-12.52c2.19-14-6.16-30.36-30.63-22.32-.04 0-4.07 18.57 18.84 26.58z"
          style={{
            transformOrigin: "394.214px 162.197px"
          }}
          id="elziw7n21az3"
          className="animable"
          fill="#263238"
        />
        <path
          d="M400.18 178.75a9.62 9.62 0 011.47-4 4.46 4.46 0 014.31-2.35c4.43.61 4.31 4.95 4 6.45-.82 3.93-4.74 7.68-10.74 6.85z"
          style={{
            transformOrigin: "404.655px 179.1px"
          }}
          id="elh0h69onpmy4"
          className="animable"
          fill="#945959"
        />
        <path
          d="M282.58 398.3s-12.22 9.55-13.48 12.25 1.94 10.32 19.25 7.55c4.84-.77 11.8-5.71 15.29-10.45s10.54-8 11.6-9.7c1.53-2.39.67-14.94-2-15.17-4.33-.36-30.66 15.52-30.66 15.52z"
          style={{
            transformOrigin: "292.425px 400.735px"
          }}
          id="elhsa847bs68n"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M306.34 306.14s-7.68 63.23-8.95 72.54c-.59 4.36-12.66 17.38-16.83 21.77a1.74 1.74 0 00.27 2.62l.36.25a13.61 13.61 0 0016.44-.74c3.4-2.81 6.93-6.2 8.71-9.34a32.61 32.61 0 015.84-8 3.12 3.12 0 001-2.07c.22-3.09.93-10.86 2.7-15.21 2.25-5.52 13.71-27.76 13.71-47.88z"
          style={{
            transformOrigin: "304.838px 355.935px"
          }}
          id="el6g7hgiksv57"
          className="animable"
          fill="#945959"
        />
        <path
          d="M329.61 320.11l-.08-.05-5.17-.58c2 13.89-1.9 33.08-1.91 33.13 3.55-9.29 7.16-21.23 7.16-32.5z"
          style={{
            transformOrigin: "326.03px 336.045px"
          }}
          id="elslk520bigh"
          className="animable"
          fill="#703838"
        />
        <path
          d="M305.36 411.64s-12.22 9.55-13.48 12.25 1.95 10.32 19.25 7.55c4.84-.77 11.8-5.71 15.29-10.45s10.54-8 11.61-9.7c1.52-2.39.66-14.94-2-15.17-4.34-.36-30.67 15.52-30.67 15.52z"
          style={{
            transformOrigin: "315.207px 414.075px"
          }}
          id="elwguu5fxergg"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M329.13 319.48s-7.69 63.24-9 72.54c-.59 4.36-12.66 17.38-16.83 21.77a1.74 1.74 0 00.27 2.62l.36.25a13.61 13.61 0 0016.44-.74c3.4-2.81 6.93-6.2 8.72-9.34a32.68 32.68 0 015.83-8 3.12 3.12 0 001-2.07c.22-3.09.93-10.86 2.7-15.21 2.25-5.52 13.71-27.75 13.71-47.88z"
          style={{
            transformOrigin: "327.578px 369.275px"
          }}
          id="elq45p66ldyus"
          className="animable"
          fill="#945959"
        />
        <path
          d="M356.89 263.06s-36.71 20-44.23 28.91-10.72 29.43-10.72 29.43c12.82 16.54 52 18.35 52 18.35 3.3-10.79 11.39-12.69 24-18.79s26.7-18.43 29-31.73c1-6.08.39-13.27-.69-19.51a52.93 52.93 0 01-19.15 5.6c-20.78 1.52-30.21-12.26-30.21-12.26z"
          style={{
            transformOrigin: "354.694px 301.405px"
          }}
          id="eljqsbqq0q2va"
          className="animable"
          fill="#263238"
        />
        <path
          d="M377.06 244.34s16.11 4.34 23.07-7.19c0 0-5.65 15.03-23.07 7.19z"
          style={{
            transformOrigin: "388.595px 241.877px"
          }}
          id="eljl4ny0hpzrf"
          className="animable"
          fill="#7519ff"
        />
        <g id="elp0fef2gro9">
          <path
            d="M400.13 237.15s-5.65 15-23.07 7.19c0 0 16.11 4.34 23.07-7.19"
            style={{
              transformOrigin: "388.595px 241.872px"
            }}
            className="animable"
            id="elyv4ne2m7rd"
            fill="#fff"
            opacity={0.2}
          />
        </g>
        <path
          d="M410.09 207.77s-12.21 5.34-.62 32.26c7.79 18.1 9.09 23 9.09 23s-4.06 2-27.28 19.3l7 11.61s41.25-14.71 40.34-26.15-12.92-40.89-14.8-45.1-2.82-10.13-13.73-14.92z"
          style={{
            transformOrigin: "414.957px 250.855px"
          }}
          id="elgcw8v7u3yze"
          className="animable"
          fill="#945959"
        />
        <path
          d="M329.61 239.41c4 1.72 12.48-5.71 19.54-15.48A164.48 164.48 0 00360.47 206a16.4 16.4 0 0014.85-7.17c.92-1.34-6.45-10.34-6.45-10.34l3.89-1.74s6.19-1.69 5.81-4.44c-.16-1.18-10.38.95-14.23 1.8a4.4 4.4 0 00-2.42 1.43l-11.41 13s-11.7 10-18 15.91c-9.67 9.09-11.22 21.38-2.9 24.96z"
          style={{
            transformOrigin: "351.399px 210.81px"
          }}
          id="elajprcooc9jk"
          className="animable"
          fill="#945959"
        />
        <path
          d="M368.87 188.89a.36.36 0 01-.33-.22.37.37 0 01.18-.48l3.89-1.74c1.67-.46 5.78-2 5.61-4-.24-.12-1.57-.39-8.77 1a.36.36 0 01-.14-.71c8.32-1.61 9.08-1.06 9.36-.86a.58.58 0 01.26.41c.41 3-5.81 4.77-6.08 4.84l-3.85 1.72a.28.28 0 01-.13.04z"
          style={{
            transformOrigin: "373.73px 185.258px"
          }}
          id="elic9mogrcdie"
          className="animable"
          fill="#703838"
        />
        <path
          d="M344.73 229.89a.38.38 0 01-.24-.09.35.35 0 010-.51c1.5-1.73 3-3.61 4.4-5.57a166.41 166.41 0 0011.29-17.85l.12-.23h.25a16.22 16.22 0 008.19-1.53.36.36 0 11.32.65 17 17 0 01-8.35 1.63 169 169 0 01-11.23 17.72c-1.44 2-2.93 3.88-4.45 5.63a.36.36 0 01-.3.15z"
          style={{
            transformOrigin: "356.822px 216.981px"
          }}
          id="eltfspcrtergr"
          className="animable"
          fill="#703838"
        />
        <path
          d="M392.49 282.46s-5.8-1.07-10.35-.1a18.49 18.49 0 01-6.34.15c-1.95-.26-4.83-.74-3.56 2.27 1.1 2.62 4.84 3.24 7.29 3.48 0 0-.87 3.1-7.55 2.91s-8.51.09-8 2.22 7 9.8 13.75 9.91c5.06.08 14.11-3.43 20.55-9.33z"
          style={{
            transformOrigin: "381.09px 292.627px"
          }}
          id="el7mjn6udwh7y"
          className="animable"
          fill="#945959"
        />
      </g>
      <g
        id="freepik--speech-bubble--inject-2"
        className="animable"
        style={{
          transformOrigin: "342.009px 75.7737px",
          animation: "1.5s Infinite linear floating",
          animationDelay: "0s"
        }}
      >
        <path
          d="M394.34 73.25A20.89 20.89 0 00376.12 50c-.31 0-.62-.05-.94-.07a11.05 11.05 0 00.2-1.15 14.38 14.38 0 00-12.54-16 14.63 14.63 0 00-2.38-.07 22.75 22.75 0 00-39 .34 20.86 20.86 0 00-21.33 29.81 14.38 14.38 0 006.75 27.94 19.93 19.93 0 0030.78 14.44 21.84 21.84 0 0038.77-11 22.82 22.82 0 00.15-2.84 20.9 20.9 0 0017.76-18.15z"
          style={{
            transformOrigin: "342.043px 67.6613px"
          }}
          id="el6pao2ea3sm7"
          className="animable"
          fill="#fff"
        />
        <path
          d="M354.76 114.44a22.19 22.19 0 01-2.81-.17 22.69 22.69 0 01-14.52-7.74A20.94 20.94 0 01306 91.94a14.79 14.79 0 01-4 0 15.38 15.38 0 01-3.23-29.78A21.88 21.88 0 01321 32a23.75 23.75 0 0140-.3 15.3 15.3 0 0115.38 17.23v.12a21.92 21.92 0 0119 24.36 21.92 21.92 0 01-17.78 18.87 20.54 20.54 0 01-.15 2.11 22.86 22.86 0 01-22.64 20.09zm-17.13-10.23a1 1 0 01.79.38 20.85 20.85 0 0037-10.48 21.75 21.75 0 00.14-2.71 1 1 0 01.86-1A19.88 19.88 0 00376 51h-.89a1 1 0 01-.72-.4 1 1 0 01-.18-.8c.07-.35.14-.71.18-1.07a13.39 13.39 0 00-11.67-14.9 12.27 12.27 0 00-2.22-.07 1 1 0 01-.89-.48 21.76 21.76 0 00-37.27.33 1 1 0 01-1 .48A19.88 19.88 0 00301 62.4a1 1 0 010 .85 1 1 0 01-.65.55 13.38 13.38 0 001.86 26.2 13.05 13.05 0 004.44-.22 1 1 0 01.79.17 1 1 0 01.41.7 18.94 18.94 0 0029.24 13.72 1 1 0 01.54-.16zm56.71-31z"
          style={{
            transformOrigin: "342.009px 67.6937px"
          }}
          id="el2m50m4sei0s"
          className="animable"
          fill="#e0e0e0"
        />
        <g id="elii94fhxc4p">
          <path
            d="M336.68 86.28h10v5.55a4.47 4.47 0 01-4.47 4.47h-1.08a4.47 4.47 0 01-4.47-4.47v-5.55h.02z"
            style={{
              transformOrigin: "341.67px 91.29px"
            }}
            className="animable"
            id="elaq4pxlcntd8"
            fill="#263238"
            transform="rotate(-.49)"
          />
        </g>
        <g id="elbfbil69734w">
          <ellipse
            cx={341.66}
            cy={87.93}
            rx={7.82}
            ry={4.27}
            style={{
              transformOrigin: "341.66px 87.93px"
            }}
            className="animable"
            id="elk46kzzqbvl"
            fill="#37474f"
            transform="rotate(-.49)"
          />
        </g>
        <g id="el4ugnekstwgn">
          <ellipse
            cx={341.64}
            cy={85.44}
            rx={7.82}
            ry={4.27}
            style={{
              transformOrigin: "341.64px 85.44px"
            }}
            className="animable"
            id="el6ps5sz0cpf"
            fill="#455a64"
            transform="rotate(-.49)"
          />
        </g>
        <path
          d="M358.11 63.46a16.65 16.65 0 10-25.64 14.15 6.35 6.35 0 013 5.26 4.11 4.11 0 004.12 4.13h4.14a4.11 4.11 0 004.08-4.14v-.18a6 6 0 012.77-5 16.64 16.64 0 007.53-14.22z"
          style={{
            transformOrigin: "341.462px 66.973px"
          }}
          id="el2kbmtshjs6b"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M342.5 87c0-.06-.12-1.55-.12-14.75 0-.9 0-1.76.05-2.57-.41 0-.85.06-1.31.06h-1a30.11 30.11 0 01.33 4.26l.11 13h-.72l-.11-13a26.92 26.92 0 00-.35-4.33c-3.9-.44-5.72-2.47-5.73-4.39a2.06 2.06 0 012-2.27c2.06 0 3.62 2.24 4.34 6h1.13c.39 0 .93 0 1.36-.07.29-5 1.34-8 3.11-8.7a2.64 2.64 0 012.84.76 4.52 4.52 0 01.46 4.65c-.54 1.29-2 3.34-5.74 3.92 0 .81-.05 1.7-.05 2.66 0 13.07.12 14.63.12 14.69zm-6.86-23.29a1.36 1.36 0 00-1.27 1.55c0 1.57 1.56 3.24 4.88 3.67-.69-3.27-1.99-5.27-3.61-5.27zm10.77-2.9a1.58 1.58 0 00-.56.1c-.89.37-2.29 1.92-2.67 8 3.32-.55 4.57-2.33 5-3.46a3.85 3.85 0 00-.3-3.88 2.24 2.24 0 00-1.47-.81z"
          style={{
            transformOrigin: "341.484px 73.5492px"
          }}
          id="el5299mk7u6e8"
          className="animable"
          fill="#263238"
        />
        <g id="elh411j1tuc4h">
          <path
            d="M351.56 55.63c-.61 1.08-2.5 1.17-4.21.19s-2.62-2.63-2-3.71 2.5-1.16 4.21-.18 2.61 2.63 2 3.7z"
            style={{
              transformOrigin: "348.453px 53.8727px"
            }}
            className="animable"
            id="el0rbigorwp0h"
            fill="#fff"
            opacity={0.4}
          />
        </g>
        <g id="el529qiwdfnoc">
          <path
            d="M355.26 60c.26 1.16-.19 2.24-1 2.42s-1.68-.62-1.93-1.77.2-2.24 1-2.42 1.67.63 1.93 1.77z"
            style={{
              transformOrigin: "353.797px 60.3251px"
            }}
            className="animable"
            id="el03dg5wp00day"
            fill="#fff"
            opacity={0.4}
          />
        </g>
        <path
          d="M340.73 43.91a.71.71 0 01-.71-.68l-.28-7.79a.71.71 0 01.68-.74.73.73 0 01.74.69l.28 7.79a.7.7 0 01-.68.73z"
          style={{
            transformOrigin: "340.59px 39.305px"
          }}
          id="el7iuekz2djc6"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M324.11 52.16a.71.71 0 01-.34-.08l-6.87-3.66a.71.71 0 01.67-1.26l6.87 3.67a.7.7 0 01-.33 1.33z"
          style={{
            transformOrigin: "320.679px 49.6189px"
          }}
          id="eldlzcibk6yh"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M356.69 51a.68.68 0 01-.6-.33.71.71 0 01.23-1l6.62-4.11a.71.71 0 011 .23.72.72 0 01-.23 1l-6.62 4.11a.75.75 0 01-.4.1z"
          style={{
            transformOrigin: "360.013px 48.2219px"
          }}
          id="el9fqp0zdgqf9"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M383.28 106.74A10.69 10.69 0 11374 94.83a10.69 10.69 0 019.28 11.91z"
          style={{
            transformOrigin: "372.67px 105.437px"
          }}
          id="el5i5i1s3klfu"
          className="animable"
          fill="#fff"
        />
        <path
          d="M372.69 117.14A11.7 11.7 0 01361.06 104a11.7 11.7 0 0123.22 2.83 11.7 11.7 0 01-11.59 10.28zm0-21.39a9.69 9.69 0 00-9.61 8.52 9.69 9.69 0 1019.24 2.35 9.7 9.7 0 00-8.45-10.79 9.45 9.45 0 00-1.21-.08z"
          style={{
            transformOrigin: "372.652px 105.581px"
          }}
          id="eldw70jym2vsl"
          className="animable"
          fill="#e0e0e0"
        />
        <circle
          cx={388.41}
          cy={125.42}
          r={4.22}
          style={{
            transformOrigin: "388.41px 125.42px"
          }}
          id="elcoqv9i3coa"
          className="animable"
          fill="#fff"
        />
        <path
          d="M388.41 130.64a5.22 5.22 0 115.22-5.22 5.22 5.22 0 01-5.22 5.22zm0-8.43a3.22 3.22 0 103.22 3.21 3.21 3.21 0 00-3.22-3.21z"
          style={{
            transformOrigin: "388.41px 125.42px"
          }}
          id="elxqqes13k16j"
          className="animable"
          fill="#e0e0e0"
        />
      </g>
      <defs>
        <filter id="active" height="200%">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius={2}
          />
          <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
          <feMerge>
            <feMergeNode in="OUTLINE" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="hover" height="200%">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius={2}
          />
          <feFlood floodColor="red" floodOpacity={0.5} result="PINK" />
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
          <feMerge>
            <feMergeNode in="OUTLINE" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
          <feColorMatrix values="0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 1 0" />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgComponent
