
import RutesLandings from './router/routerLanding';

// Trasnlation 
import './i18n'
import { useTranslation } from 'react-i18next'

/** 
 * Componentes Globales 
 */
import HeaderLanding from './components/landings/Header_landing';
import Footer from './components/Footer';
import CopyR from './components/Copy'

/**
 * AppLAnding Componente es el comtenedor principal de las landing de aterrizaje
 * @alias App
 */
function AppLanding() {

  const { i18n } = useTranslation()
  /**
   * Inicializa la pagina en el idiona establecido en localstorage si no lo tiene lo define como español
   */
  const initLang = () => {
    if (localStorage.getItem('lng')) {
      i18n.changeLanguage(localStorage.getItem('lng'))
    } else {
      window.localStorage.setItem('lng', 'es')
      i18n.changeLanguage('es')
    }
  }

  return (
    <div className="App AppLanding" onLoad={initLang}>
      <HeaderLanding />
      <RutesLandings />
      <Footer />
      <CopyR />
    </div>
  );
}

export default AppLanding;
