import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

/**
 * Views
 */
import View from '../views/View'
import Blog from '../views/Blog'
import Article from '../views/Article'
import Nosotros from '../views/Nosotros'
import Portafolio from '../views/Portafolio'
import NotFound from '../views/NotFound'
import Prices from '../views/Prices'
import PoliticasTrataDatos from '../components/PoliticasTrataDatos'
import PoliticasHabeasData from '../components/PoliticasHabeasData'
import PoliticasPrivacidad from '../components/PoliticasPrivacidad'

/**
 * Router redirigido a las principales vistas de la web
 */
export default function Rutes() {
  return (

    <Switch>
      <Route exact path="/" component={View} />
      <Route exact path="/blog" component={Blog} />
      <Route exact path="/blog/:slug" component={Article} />
      <Route exact path="/nosotros" component={Nosotros} />
      <Route exact path="/portafolio" component={Portafolio} />
      <Route exact path="/prices" component={Prices} />
      <Route exact path="/politica-datos" component={PoliticasTrataDatos} />
      <Route exact path="/habeas-data" component={PoliticasHabeasData} />
      <Route exact path="/politica-privacidad" component={PoliticasPrivacidad} />
      <Route component={NotFound} />
    </Switch>

  )
}