import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      className="animated"
      id="freepik_stories-marketing"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      {...props}
    >
      <style>
        {
          "@keyframes wind{0%{transform:rotate(0deg)}25%{transform:rotate(1deg)}75%{transform:rotate(-1deg)}}@keyframes floating{0%,to{opacity:1;transform:translateY(0)}50%{transform:translateY(-10px)}}svg#freepik_stories-marketing:not(.animated) .animable{opacity:0}"
        }
      </style>
      <g
        id="freepik--Floor--inject-2"
        className="animable"
        style={{
          transformOrigin: "250.004px 346.658px"
        }}
      >
        <path
          d="M414.07 251.3c-91.21-52.66-238.6-52.66-329.21 0s-90.14 138 1.06 190.7 238.61 52.66 329.23 0 90.13-138-1.08-190.7z"
          style={{
            transformOrigin: "250.004px 346.658px"
          }}
          id="el2yspfmvrtfh"
          className="animable"
          fill="#f5f5f5"
        />
      </g>
      <g
        id="freepik--Shadows--inject-2"
        className="animable"
        style={{
          transformOrigin: "249.942px 342.843px"
        }}
      >
        <path
          d="M206.62 417l56.71 32.74a20 20 0 0020.06 0l45.28-26.34a5 5 0 000-8.66l-59.07-34.11z"
          style={{
            transformOrigin: "268.895px 416.533px"
          }}
          id="els6udvfhel2"
          className="animable"
          fill="#e0e0e0"
        />
        <path
          d="M59.07 354.85l119.47 68.94a16.33 16.33 0 0016.35 0l190.84-110.24a5.3 5.3 0 000-9.19l-123-71.11L59.07 350.83a2.32 2.32 0 000 4.02z"
          style={{
            transformOrigin: "223.149px 329.617px"
          }}
          id="elbj3k9s7yntt"
          className="animable"
          fill="#e0e0e0"
        />
        <path
          d="M425.53 366.59c-21.84-12.61-57.13-12.61-78.82 0s-21.58 33 .25 45.65 57.13 12.61 78.82 0 21.58-33.05-.25-45.65z"
          style={{
            transformOrigin: "386.245px 389.423px"
          }}
          id="eleocc2ys7w"
          className="animable"
          fill="#e0e0e0"
        />
        <path
          d="M140.4 386.07l9 5.2L108.32 415a4 4 0 01-4 0l-4-2.31a2 2 0 010-3.47z"
          style={{
            transformOrigin: "124.357px 400.803px"
          }}
          id="elojryrcovtf"
          className="animable"
          fill="#e0e0e0"
        />
      </g>
      <g
        id="freepik--device-2--inject-2"
        className="animable"
        style={{
          transformOrigin: "215.921px 241.254px"
        }}
      >
        <path
          d="M58 337.05c-.14-1.43.73-2.89 2.63-4l187.74-108.4a14 14 0 0112.69 0l119.19 69.26a4.54 4.54 0 012.59 3.52v11.38c.06 1.38-.81 2.77-2.64 3.82L192.43 421a14 14 0 01-12.69 0L60.56 351.78a4.52 4.52 0 01-2.6-3.51v-11.21z"
          style={{
            transformOrigin: "220.401px 322.825px"
          }}
          id="ela1ga1nxbjqe"
          className="animable"
          fill="#455a64"
        />
        <g id="elqfabsc7abvg">
          <path
            d="M380.17 301.28l-187.74 108.4a14 14 0 01-12.69 0L60.56 340.42a4.63 4.63 0 01-2.56-3.37c-.14-1.43.73-2.89 2.63-4l187.74-108.4a14 14 0 0112.69 0l119.19 69.26a4.54 4.54 0 012.59 3.52v.25c-.07 1.32-.95 2.61-2.67 3.6z"
            style={{
              transformOrigin: "220.413px 317.165px"
            }}
            className="animable"
            id="el2olsvs24p9r"
            fill="#fff"
            opacity={0.3}
          />
        </g>
        <path
          d="M252.83 258.31a.83.83 0 000 1.56l7.78 4.52a3 3 0 002.68 0l23.49-13.56c.74-.43.75-1.12 0-1.55l-7.78-4.53a2.92 2.92 0 00-2.67 0z"
          style={{
            transformOrigin: "269.811px 254.566px"
          }}
          id="elrfm2gzajc8d"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M255.81 242.6a.81.81 0 000 1.54l7.78 4.52a2.92 2.92 0 002.67 0l8.44-4.86a.82.82 0 000-1.55l-7.78-4.52a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "265.252px 243.199px"
          }}
          id="el7u40mro8p5n"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M242.72 250.15c-.74.43-.75 1.12 0 1.55l7.78 4.52a2.92 2.92 0 002.67 0l8.44-4.87a.82.82 0 000-1.55l-7.78-4.52a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "252.162px 250.754px"
          }}
          id="elktd4fy202d"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M190.36 280.38a.82.82 0 000 1.55l7.77 4.52a3 3 0 002.68 0l8.43-4.87c.74-.43.75-1.12 0-1.55l-7.78-4.52a2.92 2.92 0 00-2.67 0z"
          style={{
            transformOrigin: "199.803px 280.976px"
          }}
          id="elhemo4qjrxg8"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M177.28 287.94a.81.81 0 000 1.54l7.78 4.52a2.92 2.92 0 002.67 0l8.44-4.86a.82.82 0 000-1.55l-7.77-4.52a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "186.722px 288.539px"
          }}
          id="elr33b5mxq2yp"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M168.47 282.82a.81.81 0 000 1.54L173 287a3 3 0 002.68 0l8.43-4.87a.82.82 0 000-1.55l-4.5-2.61a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "176.287px 282.485px"
          }}
          id="el8n5fitnlykx"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M181.55 275.26a.82.82 0 000 1.55l4.5 2.62a3 3 0 002.67 0l8.43-4.87a.82.82 0 000-1.55l-4.5-2.62a2.92 2.92 0 00-2.67 0z"
          style={{
            transformOrigin: "189.35px 274.905px"
          }}
          id="el3dovnpz1783"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M194.65 267.7a.82.82 0 000 1.55l4.5 2.62a2.92 2.92 0 002.67 0l8.43-4.87a.82.82 0 000-1.55l-4.5-2.62a3 3 0 00-2.67 0z"
          style={{
            transformOrigin: "202.45px 267.355px"
          }}
          id="elrfz7xp03sb"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M219.44 291.59a.82.82 0 000 1.55l7.78 4.52a2.92 2.92 0 002.67 0l8.44-4.87a.81.81 0 000-1.54l-7.77-4.52a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "228.888px 292.199px"
          }}
          id="el62l8wni0rny"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M201.11 288.18a.82.82 0 000 1.55l7.78 4.52a2.92 2.92 0 002.67 0l8.44-4.87a.82.82 0 000-1.55l-7.78-4.52a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "210.555px 288.784px"
          }}
          id="el7a8uaxec6m5"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M76.85 335.71c-.74.43-.75 1.12 0 1.55l4.5 2.62a2.92 2.92 0 002.67 0l8.43-4.88a.82.82 0 000-1.55L88 330.84a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "84.6467px 335.364px"
          }}
          id="eluqylttru96s"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M266.06 294c-.74.43-.75 1.12 0 1.55l7.78 4.52a2.92 2.92 0 002.67 0l8.43-4.87c.74-.43.75-1.12 0-1.55l-7.77-4.52a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "275.5px 294.604px"
          }}
          id="el4chqccizksu"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M187.53 339.38a.82.82 0 000 1.55l7.78 4.52a2.92 2.92 0 002.67 0l8.43-4.87c.74-.43.75-1.12 0-1.55l-7.78-4.52a2.92 2.92 0 00-2.67 0z"
          style={{
            transformOrigin: "196.973px 339.98px"
          }}
          id="elzf2l9b4gab"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M155.38 290.37c-.74.43-.75 1.12 0 1.55l4.5 2.62a3 3 0 002.68 0l8.43-4.87c.74-.43.75-1.12 0-1.55l-4.5-2.62a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "163.185px 290.02px"
          }}
          id="el8gynxxi67ri"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M89.94 328.16a.81.81 0 000 1.54l4.5 2.62a3 3 0 002.68 0l8.43-4.87a.82.82 0 000-1.55l-4.5-2.61a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "97.7417px 327.805px"
          }}
          id="elks3ldubm77i"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M227 273.26a.82.82 0 000 1.55l7.78 4.52a3 3 0 002.68 0l8.43-4.87c.74-.43.75-1.12 0-1.55l-7.78-4.52a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "236.448px 273.86px"
          }}
          id="ely1c9dzidn8a"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M214 280.72a.82.82 0 000 1.55l7.78 4.52a3 3 0 002.68 0l8.43-4.87a.82.82 0 000-1.55l-7.78-4.52a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "223.445px 281.32px"
          }}
          id="el1fak2787lbn"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M232.14 284.26a.82.82 0 000 1.56l7.78 4.52a3 3 0 002.67 0l8.43-4.87c.74-.43.75-1.12 0-1.55l-7.78-4.52a3 3 0 00-2.69 0z"
          style={{
            transformOrigin: "241.576px 284.868px"
          }}
          id="el7zf6he5biao"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M305.32 271.37a.82.82 0 000 1.55l7.77 4.52a3 3 0 002.68 0l8.43-4.87c.74-.43.75-1.12 0-1.55l-7.78-4.52a3 3 0 00-2.67 0z"
          style={{
            transformOrigin: "314.763px 271.971px"
          }}
          id="elbng2zq65ypq"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M292.23 278.93a.82.82 0 000 1.55L300 285a3 3 0 002.68 0l8.43-4.87c.74-.43.75-1.12 0-1.55l-7.78-4.52a2.92 2.92 0 00-2.67 0z"
          style={{
            transformOrigin: "301.673px 279.526px"
          }}
          id="el23alxt44o85"
          className="animable"
          fill="#37474f"
        />
        <g
          style={{
            isolation: "isolate",
            transformOrigin: "278.183px 276.545px"
          }}
          id="elm0f29154mab"
          className="animable"
        >
          <path
            d="M257.51 269.61a.82.82 0 000 1.55l7.78 4.52a2.92 2.92 0 002.67 0l8.44-4.87c.74-.42.74-1.11 0-1.54l-7.77-4.52a3 3 0 00-2.68 0z"
            style={{
              transformOrigin: "266.956px 270.219px"
            }}
            id="eloq6en9vfz8d"
            className="animable"
            fill="#37474f"
          />
          <path
            d="M276.92 273.09a.82.82 0 000 1.55l7.78 4.52a3 3 0 002.68 0l24.16-14c.74-.43.75-1.12 0-1.55l-7.78-4.52a3 3 0 00-2.68 0z"
            style={{
              transformOrigin: "294.233px 269.125px"
            }}
            id="el0oln7c0ztwg"
            className="animable"
            fill="#37474f"
          />
          <path
            d="M244.82 276.94a.82.82 0 000 1.55l7.78 4.51a2.92 2.92 0 002.67 0l8.43-4.87a.82.82 0 000-1.55l-7.78-4.52a3 3 0 00-2.68 0z"
            style={{
              transformOrigin: "254.26px 277.534px"
            }}
            id="elyt64o07lt"
            className="animable"
            fill="#37474f"
          />
          <path
            d="M263.89 280.61a.82.82 0 000 1.55l7.77 4.52a3 3 0 002.68 0l8.43-4.87c.74-.43.75-1.12 0-1.55l-7.77-4.52a3 3 0 00-2.68 0z"
            style={{
              transformOrigin: "273.333px 281.21px"
            }}
            id="eluxsfp132hoe"
            className="animable"
            fill="#37474f"
          />
          <path
            d="M251.2 287.93a.83.83 0 000 1.56L259 294a3 3 0 002.68 0l8.43-4.86c.74-.43.75-1.12 0-1.55l-7.77-4.52a3 3 0 00-2.69 0z"
            style={{
              transformOrigin: "260.661px 288.534px"
            }}
            id="elep3pno1xw0g"
            className="animable"
            fill="#37474f"
          />
        </g>
        <path
          d="M270.62 262a.83.83 0 000 1.56l7.78 4.52a3 3 0 002.67 0l17.79-10.28a.81.81 0 000-1.54l-7.78-4.52a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "284.746px 259.909px"
          }}
          id="elubhvcjy2v6m"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M239.87 265.8a.82.82 0 000 1.55l7.77 4.52a3 3 0 002.68 0l8.43-4.87c.74-.43.75-1.12 0-1.55l-7.75-4.52a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "249.313px 266.4px"
          }}
          id="eletrx7ah71km"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M174.44 346.94a.82.82 0 000 1.55l7.78 4.52a3 3 0 002.67 0l8.43-4.87a.82.82 0 000-1.55l-7.78-4.52a2.92 2.92 0 00-2.67 0z"
          style={{
            transformOrigin: "183.88px 347.535px"
          }}
          id="eleo4f515kvls"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M166.48 358.13c-.74.43-.75 1.12 0 1.55l2.08 1.21a3 3 0 002.68 0l9.16-5.29a.82.82 0 000-1.55l-2.08-1.21a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "173.437px 356.865px"
          }}
          id="elwq5nbsuk0bg"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M149.68 346.55a.82.82 0 000 1.55l7.78 4.52a2.92 2.92 0 002.67 0l8.43-4.87a.82.82 0 000-1.55l-7.78-4.52a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "159.12px 347.154px"
          }}
          id="elh9h3mfk23g4"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M109.83 354.88a.82.82 0 000 1.55l7.77 4.57a3 3 0 002.68 0l16.52-9.54a.82.82 0 000-1.55l-7.8-4.57a2.92 2.92 0 00-2.67 0z"
          style={{
            transformOrigin: "123.315px 353.166px"
          }}
          id="el8ckah30hisb"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M122.5 362.24a.82.82 0 000 1.55l7.78 4.52a3 3 0 002.68 0l22.92-13.23c.74-.43.75-1.12 0-1.55L148.1 349a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "139.193px 358.655px"
          }}
          id="elknsd6isswf"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M130.61 342.88a.82.82 0 000 1.55l7.78 4.52a2.92 2.92 0 002.67 0l8.44-4.87a.82.82 0 000-1.55l-7.77-4.53a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "140.055px 343.479px"
          }}
          id="eljgig6qfalk"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M162.37 339.22a.82.82 0 000 1.55l7.78 4.52a2.92 2.92 0 002.67 0l8.43-4.87a.82.82 0 000-1.55l-7.78-4.52a2.92 2.92 0 00-2.67 0z"
          style={{
            transformOrigin: "171.81px 339.82px"
          }}
          id="el15yqjys0n81"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M143.31 335.55a.82.82 0 000 1.55l7.78 4.52a2.92 2.92 0 002.67 0l8.44-4.87a.82.82 0 000-1.55l-7.78-4.52a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "152.755px 336.154px"
          }}
          id="elci7dujwo7z"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M175.06 331.89a.83.83 0 000 1.56l7.77 4.52a3 3 0 002.68 0l8.43-4.87c.74-.43.75-1.13 0-1.55l-7.76-4.55a3 3 0 00-2.69 0z"
          style={{
            transformOrigin: "184.506px 332.484px"
          }}
          id="elwt9z1upazm"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M156 328.23a.82.82 0 000 1.55l7.78 4.52a3 3 0 002.67 0l8.43-4.87c.75-.43.76-1.12 0-1.55l-7.78-4.52a3 3 0 00-2.69 0z"
          style={{
            transformOrigin: "165.447px 328.828px"
          }}
          id="elkl6unaekfh"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M238.51 295.26a.82.82 0 000 1.55l7.78 4.52a2.92 2.92 0 002.67 0l8.43-4.87a.82.82 0 000-1.55l-7.78-4.52a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "247.95px 295.864px"
          }}
          id="elojtlh3dl7m"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M203.46 272.82a.82.82 0 000 1.55l7.77 4.52a3 3 0 002.68 0l8.43-4.87c.74-.43.75-1.12 0-1.55l-7.78-4.47a3 3 0 00-2.67 0z"
          style={{
            transformOrigin: "212.903px 273.446px"
          }}
          id="el13xael7dsz9"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M279.14 286.49a.82.82 0 000 1.54l7.78 4.52a3 3 0 002.68 0l8.43-4.87a.81.81 0 000-1.54l-7.78-4.52a2.92 2.92 0 00-2.67 0z"
          style={{
            transformOrigin: "288.595px 287.081px"
          }}
          id="el0836yea04obc"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M153.67 365.52a.82.82 0 000 1.55l2.08 1.21a2.92 2.92 0 002.67 0l9.17-5.29a.82.82 0 000-1.55l-2.08-1.21a2.92 2.92 0 00-2.67 0z"
          style={{
            transformOrigin: "160.63px 364.255px"
          }}
          id="elssilgphbp1"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M140.87 372.92a.82.82 0 000 1.55l2.08 1.21a3 3 0 002.67 0l9.17-5.3a.82.82 0 000-1.55l-2.08-1.21a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "147.83px 371.649px"
          }}
          id="elroyjhp5l77"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M148 362.21c-.74.43-.75 1.12 0 1.55l2.08 1.21a3 3 0 002.68 0l9.16-5.29c.74-.43.75-1.12 0-1.55l-2.08-1.21a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "154.96px 360.945px"
          }}
          id="elqdx89q0umma"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M273.87 310.87l33.32 19.36a1.69 1.69 0 010 2.92l-55.87 32.25a4.21 4.21 0 01-4.22 0L213.78 346a1.68 1.68 0 010-2.91l55.86-32.26a4.21 4.21 0 014.23.04z"
          style={{
            transformOrigin: "260.484px 338.123px"
          }}
          id="el3trv2eckm74"
          className="animable"
          fill="#37474f"
        />
        <g id="elbslfu05ca5k">
          <path
            d="M192.43 409.68l187.74-108.4c1.72-1 2.6-2.28 2.65-3.58v11.13c.06 1.38-.81 2.77-2.64 3.82L192.43 421a12.73 12.73 0 01-5.84 1.5v-11.33a12.73 12.73 0 005.84-1.49z"
            style={{
              transformOrigin: "284.706px 360.1px"
            }}
            className="animable"
            id="elxaqxl1v1dnf"
            fill="#fff"
            opacity={0.1}
          />
        </g>
        <g id="elcgcx6wnt00w">
          <path
            d="M58 337.05a4.63 4.63 0 002.59 3.37l119.18 69.26a13.13 13.13 0 006.85 1.49v11.37a13.14 13.14 0 01-6.85-1.5L60.56 351.78a4.52 4.52 0 01-2.6-3.51v-11.21z"
            style={{
              transformOrigin: "122.29px 379.805px"
            }}
            className="animable"
            id="elrwi9rfwa38c"
            opacity={0.2}
          />
        </g>
        <path
          d="M267.66 366.54V368c0 2 1.42 2.81 3.17 1.8l29.22-16.8a6.9 6.9 0 003.14-5.44V346z"
          style={{
            transformOrigin: "285.425px 358.117px"
          }}
          id="elwpe783em3fa"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M56.33 169.35L244.41 60.72a5.61 5.61 0 015.76.05l4 2.62a5.61 5.61 0 012.57 4.74v143.65a14.77 14.77 0 01-7.39 12.79L61.24 333.2a5.62 5.62 0 01-5.87-.12l-3.94-2.6a5.61 5.61 0 01-2.43-4.69V182.14a14.78 14.78 0 017.33-12.79z"
          style={{
            transformOrigin: "152.87px 196.959px"
          }}
          id="elo7z72i6hd6"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M244.2 215.72a4.56 4.56 0 002.27-3.94V76L65.21 180.7a4.56 4.56 0 00-2.27 3.93v135.78z"
          style={{
            transformOrigin: "154.705px 198.205px"
          }}
          id="elrhnxq6q56mf"
          className="animable"
          fill="#7519ff"
        />
        <g id="elw18mfkvwa">
          <path
            d="M244.2 215.72a4.56 4.56 0 002.27-3.94V76L65.21 180.7a4.56 4.56 0 00-2.27 3.93v135.78z"
            style={{
              transformOrigin: "154.705px 198.205px"
            }}
            className="animable"
            id="el6d8dok6bkc8"
            opacity={0.3}
          />
        </g>
        <g id="el86vyv7jjzej">
          <path
            d="M244.2 215.72a4.56 4.56 0 002.27-3.94V76L65.21 180.7a4.56 4.56 0 00-2.27 3.93v135.78z"
            style={{
              transformOrigin: "154.705px 198.205px"
            }}
            className="animable"
            id="el0kd85pd6wxrq"
            fill="#fff"
            opacity={0.1}
          />
        </g>
        <g id="el70akkdqjb3l">
          <path
            d="M56.33 169.35L244.41 60.72a5.61 5.61 0 015.76.05l4 2.62a5.62 5.62 0 00-6-.18L60.1 171.84a14.76 14.76 0 00-6.16 6.92l-3.88-2.24a14.81 14.81 0 016.27-7.17z"
            style={{
              transformOrigin: "152.115px 119.355px"
            }}
            className="animable"
            id="el0fa1x8uw2rn"
            fill="#fff"
            opacity={0.3}
          />
        </g>
        <g id="elzakq83nm4x">
          <path
            d="M53.94 178.76a14.76 14.76 0 00-1.22 5.87v143.65a5.6 5.6 0 002.65 4.8l-3.94-2.6a5.61 5.61 0 01-2.43-4.69V182.14a14.78 14.78 0 011.11-5.62z"
            style={{
              transformOrigin: "52.1848px 254.8px"
            }}
            className="animable"
            id="elsmy4snatru"
            fill="#fff"
            opacity={0.1}
          />
        </g>
        <path
          d="M220.82 252.59c-.74.43-.75 1.12 0 1.55l4.5 2.62a3 3 0 002.68 0l8.43-4.87c.74-.43.75-1.12 0-1.55l-4.5-2.62a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "228.625px 252.24px"
          }}
          id="el0oglpuokqk1h"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M229.63 257.71c-.74.43-.75 1.12 0 1.55l7.78 4.52a2.92 2.92 0 002.67 0l8.44-4.87a.82.82 0 000-1.55l-7.77-4.52a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "239.072px 258.314px"
          }}
          id="elzrqhx57vvrs"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M207.74 260.15a.81.81 0 000 1.54l4.5 2.62a2.92 2.92 0 002.67 0l8.43-4.87a.82.82 0 000-1.55l-4.5-2.61a2.92 2.92 0 00-2.67 0z"
          style={{
            transformOrigin: "215.537px 259.795px"
          }}
          id="el9xcwb2r4nxr"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M216.55 265.27a.81.81 0 000 1.54l7.78 4.52a2.92 2.92 0 002.67 0l8.43-4.86c.74-.43.75-1.12 0-1.55l-7.78-4.52a2.92 2.92 0 00-2.67 0z"
          style={{
            transformOrigin: "225.99px 265.865px"
          }}
          id="elbwwpqdq3b97"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M247 237.48a.81.81 0 000 1.54l4.5 2.62a3 3 0 002.68 0l8.43-4.87a.82.82 0 000-1.55l-4.5-2.61a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "254.802px 237.125px"
          }}
          id="el3j1w4i4w13z"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M233.91 245c-.74.43-.75 1.12 0 1.55l4.5 2.62a3 3 0 002.68 0l8.43-4.87c.74-.43.75-1.12 0-1.55l-4.5-2.62a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "241.715px 244.65px"
          }}
          id="elyu7ov55dxa"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M164.19 295.49c-.74.43-.75 1.12 0 1.55l7.78 4.52a2.92 2.92 0 002.67 0l8.44-4.87a.82.82 0 000-1.55l-7.77-4.52a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "173.632px 296.094px"
          }}
          id="elmlbb3sgjrof"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M188.18 295.64a.82.82 0 000 1.55l7.78 4.52a3 3 0 002.67 0l8.44-4.87a.82.82 0 000-1.55l-7.77-4.52a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "197.625px 296.239px"
          }}
          id="elxu9rigg48a"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M142.29 297.93c-.74.43-.75 1.12 0 1.55l4.5 2.62a3 3 0 002.68 0l8.43-4.87c.74-.43.75-1.12 0-1.55l-4.5-2.62a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "150.095px 297.58px"
          }}
          id="el44b61sc8xvo"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M206.75 298.92a.82.82 0 000 1.55l7.78 4.52a2.92 2.92 0 002.67 0l8.43-4.87a.82.82 0 000-1.55l-7.78-4.52a2.92 2.92 0 00-2.67 0z"
          style={{
            transformOrigin: "216.19px 299.52px"
          }}
          id="el6rmhaavffwr"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M225.82 302.59a.82.82 0 000 1.55l7.77 4.52a3 3 0 002.68 0l8.43-4.87c.74-.43.75-1.12 0-1.55l-7.78-4.52a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "235.263px 303.19px"
          }}
          id="elswawtd5l1g"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M151.1 303.05c-.74.43-.75 1.12 0 1.55l7.78 4.52a2.92 2.92 0 002.67 0l8.44-4.87a.82.82 0 000-1.55l-7.77-4.52a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "160.542px 303.654px"
          }}
          id="elsu53pw01lw"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M175.26 303.1a.82.82 0 000 1.55l7.78 4.52a3 3 0 002.67 0l8.43-4.87a.82.82 0 000-1.55l-7.78-4.52a2.92 2.92 0 00-2.67 0z"
          style={{
            transformOrigin: "184.7px 303.695px"
          }}
          id="elfvspi0q90j5"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M129.2 305.49c-.74.42-.74 1.11 0 1.54l4.5 2.62a2.92 2.92 0 002.67 0l8.43-4.87a.82.82 0 000-1.55l-4.5-2.61a2.92 2.92 0 00-2.67 0z"
          style={{
            transformOrigin: "136.999px 305.135px"
          }}
          id="elg0ktrdpyg9"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M194.07 306.24a.82.82 0 000 1.56l7.78 4.52a3 3 0 002.68 0l8.42-4.86c.75-.43.76-1.12 0-1.55l-7.78-4.52a3 3 0 00-2.69 0z"
          style={{
            transformOrigin: "203.51px 306.854px"
          }}
          id="elp5hoblrlx4k"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M213.13 309.91a.83.83 0 000 1.56l7.77 4.53a3 3 0 002.68 0l8.43-4.86c.74-.43.75-1.12 0-1.55l-7.77-4.52a3 3 0 00-2.69 0z"
          style={{
            transformOrigin: "222.576px 310.534px"
          }}
          id="el9uici16j66e"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M138 310.61a.81.81 0 000 1.54l7.77 4.52a3 3 0 002.68 0l8.43-4.86c.74-.43.75-1.12 0-1.55l-7.78-4.52a2.92 2.92 0 00-2.67 0z"
          style={{
            transformOrigin: "147.44px 311.201px"
          }}
          id="el92nn82vg0qc"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M162.35 310.55a.82.82 0 000 1.56l7.78 4.52a3 3 0 002.68 0l8.42-4.87c.75-.43.76-1.12 0-1.55l-7.78-4.52a3 3 0 00-2.69 0z"
          style={{
            transformOrigin: "171.79px 311.159px"
          }}
          id="elrefdn42rcy"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M181.38 313.57a.82.82 0 000 1.55l7.78 4.52a2.92 2.92 0 002.67 0l8.44-4.87a.82.82 0 000-1.55l-7.78-4.52a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "190.825px 314.174px"
          }}
          id="elny3gzcvkiqn"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M200.44 317.24a.82.82 0 000 1.55l7.78 4.52a2.92 2.92 0 002.67 0l8.43-4.87a.82.82 0 000-1.55l-7.78-4.52a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "209.88px 317.844px"
          }}
          id="elrxvknkzrnn"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M149.43 318a.82.82 0 000 1.56l7.77 4.52a3 3 0 002.68 0l8.43-4.87c.74-.43.75-1.12 0-1.55l-7.78-4.52a3 3 0 00-2.69 0z"
          style={{
            transformOrigin: "158.866px 318.609px"
          }}
          id="el2hanf9rxbwa"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M124.92 318.16a.82.82 0 000 1.55l7.77 4.52a3 3 0 002.68 0l8.43-4.87c.74-.43.75-1.12 0-1.55l-7.8-4.52a3 3 0 00-2.67 0z"
          style={{
            transformOrigin: "134.363px 318.761px"
          }}
          id="el4gd45z1bdyj"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M168.68 320.9a.82.82 0 000 1.55l7.78 4.52a2.92 2.92 0 002.67 0l8.44-4.87a.82.82 0 000-1.55L179.8 316a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "178.125px 321.489px"
          }}
          id="elsipoxhltxqd"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M187.75 324.57a.82.82 0 000 1.55l7.78 4.52a2.92 2.92 0 002.67 0l8.43-4.87c.74-.43.75-1.12 0-1.55l-7.78-4.52a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "197.193px 325.174px"
          }}
          id="eluwec8hmkhf"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M136.5 325.47a.83.83 0 000 1.56l7.78 4.52a3 3 0 002.67 0l8.44-4.87a.82.82 0 000-1.55l-7.77-4.52a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "145.948px 326.079px"
          }}
          id="el9j1mwjg4v9k"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M111.83 325.72a.82.82 0 000 1.55l7.77 4.52a3 3 0 002.68 0l8.43-4.87c.74-.43.75-1.12 0-1.55l-7.78-4.52a2.92 2.92 0 00-2.67 0z"
          style={{
            transformOrigin: "121.273px 326.316px"
          }}
          id="eloqbmuqzpnmn"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M200.61 331.82a.82.82 0 000 1.55l7.78 4.52a3 3 0 002.68 0l60.78-35.09a.82.82 0 000-1.55l-7.78-4.52a2.92 2.92 0 00-2.67 0z"
          style={{
            transformOrigin: "236.23px 317.306px"
          }}
          id="elk3qds0gzvgp"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M123.58 332.93a.83.83 0 000 1.56l7.78 4.52a3 3 0 002.67 0l8.43-4.87c.74-.43.75-1.12 0-1.55l-7.77-4.52a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "133.026px 333.539px"
          }}
          id="elj9it2o1zy3i"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M110.66 340.39a.83.83 0 000 1.56l7.78 4.52a3 3 0 002.67 0l8.43-4.87a.82.82 0 000-1.55l-7.78-4.52a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "120.103px 340.999px"
          }}
          id="elgdhgr8kqypc"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M85.66 340.83c-.74.43-.75 1.12 0 1.55l7.78 4.52a2.92 2.92 0 002.67 0l21.53-12.42a.82.82 0 000-1.55l-7.77-4.52a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "101.647px 337.659px"
          }}
          id="eletvqwo2jmv"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M97.74 347.85a.83.83 0 000 1.56l7.78 4.52a3 3 0 002.67 0l8.43-4.87a.82.82 0 000-1.55l-7.77-4.51a3 3 0 00-2.68 0z"
          style={{
            transformOrigin: "107.183px 348.464px"
          }}
          id="eliayvyhuce9r"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M116.11 313a.82.82 0 000 1.55l4.5 2.62a2.92 2.92 0 002.67 0l8.43-4.87a.82.82 0 000-1.55l-4.5-2.62a3 3 0 00-2.67 0z"
          style={{
            transformOrigin: "123.91px 312.655px"
          }}
          id="elvczdb1g6qta"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M103 320.6a.82.82 0 000 1.55l4.5 2.62a3 3 0 002.67 0l8.43-4.87a.82.82 0 000-1.55l-4.5-2.62a2.92 2.92 0 00-2.67 0z"
          style={{
            transformOrigin: "110.8px 320.245px"
          }}
          id="eln2r6j6nna"
          className="animable"
          fill="#37474f"
        />
      </g>
      <g
        id="freepik--Screen--inject-2"
        className="animable"
        style={{
          transformOrigin: "188.38px 199.695px"
        }}
      >
        <path
          d="M287.25 76.79l.39 137.39a8.57 8.57 0 01-3.89 6.73L90.58 332.44v4.42l-4.73-3-.14-.08a2.8 2.8 0 01-1-2.4l-.4-143.27 4.87 3.08z"
          style={{
            transformOrigin: "185.975px 206.825px"
          }}
          id="elrr6y3i7zn8l"
          className="animable"
          fill="#f5f5f5"
        />
        <path
          d="M88.15 175.39L283.3 62.72a2.59 2.59 0 013-.09l4.62 2.9-3.7 2.11v9.17L89.16 191.16l-4.87-3.08v-6a8.54 8.54 0 013.86-6.69z"
          style={{
            transformOrigin: "187.605px 126.677px"
          }}
          id="el5iy4gne9jqj"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M89.16 191.16l.4 143.27c0 2.48 1.76 3.47 3.91 2.23L288.62 224a8.57 8.57 0 003.89-6.73L292.11 74z"
          style={{
            transformOrigin: "190.835px 205.597px"
          }}
          id="eldsn9nu2kn1u"
          className="animable"
          fill="#f5f5f5"
        />
        <path
          d="M286.5 70a3.75 3.75 0 00-1.7 3c0 1.08.77 1.52 1.71 1a3.74 3.74 0 001.7-3c0-1.08-.77-1.51-1.71-1z"
          style={{
            transformOrigin: "286.505px 72.0022px"
          }}
          id="elal176eylt59"
          className="animable"
          fill="#f5f5f5"
        />
        <path
          d="M281.54 72.89a3.77 3.77 0 00-1.7 3c0 1.07.77 1.52 1.71 1a3.71 3.71 0 001.7-2.94c0-1.17-.76-1.6-1.71-1.06z"
          style={{
            transformOrigin: "281.545px 74.8828px"
          }}
          id="elab2tagj8096"
          className="animable"
          fill="#f5f5f5"
        />
        <path
          d="M276.58 75.75a3.77 3.77 0 00-1.7 3c0 1.08.77 1.52 1.71 1a3.7 3.7 0 001.7-2.94c0-1.17-.76-1.6-1.71-1.06z"
          style={{
            transformOrigin: "276.585px 77.7437px"
          }}
          id="elsim4l6uydkq"
          className="animable"
          fill="#f5f5f5"
        />
        <path
          d="M288.17 65.8c2.16-1.25 3.91-.25 3.92 2.22v6l-203 117.17v-6a8.54 8.54 0 013.91-6.72zm-6.62 11a3.71 3.71 0 001.7-2.94c0-1.09-.76-1.52-1.71-1a3.77 3.77 0 00-1.7 3c0 1.07.77 1.52 1.71 1m-5 2.86a3.7 3.7 0 001.7-2.94c0-1.09-.76-1.52-1.71-1a3.77 3.77 0 00-1.7 3c0 1.08.77 1.52 1.71 1m9.96-5.78a3.74 3.74 0 001.7-3c0-1.08-.77-1.51-1.71-1a3.75 3.75 0 00-1.7 3c0 1.08.77 1.52 1.71 1"
          style={{
            transformOrigin: "190.59px 128.226px"
          }}
          id="eli232g8mcus"
          className="animable"
          fill="#7519ff"
        />
        <g id="elmquewuizgl">
          <g
            style={{
              transformOrigin: "190.59px 128.226px"
            }}
            className="animable"
            id="elmavs9iatv3"
            opacity={0.2}
          >
            <path
              d="M288.17 65.8c2.16-1.25 3.91-.25 3.92 2.22v6l-203 117.17v-6a8.54 8.54 0 013.91-6.72zm-6.62 11a3.71 3.71 0 001.7-2.94c0-1.09-.76-1.52-1.71-1a3.77 3.77 0 00-1.7 3c0 1.07.77 1.52 1.71 1m-5 2.86a3.7 3.7 0 001.7-2.94c0-1.09-.76-1.52-1.71-1a3.77 3.77 0 00-1.7 3c0 1.08.77 1.52 1.71 1m9.96-5.78a3.74 3.74 0 001.7-3c0-1.08-.77-1.51-1.71-1a3.75 3.75 0 00-1.7 3c0 1.08.77 1.52 1.71 1"
              style={{
                transformOrigin: "190.59px 128.226px"
              }}
              id="el1xrht5urbxe"
              className="animable"
              fill="#fff"
            />
          </g>
        </g>
        <g id="eljpttsv46r9r">
          <path
            d="M89.56 334.43a2.77 2.77 0 001 2.4l-4.73-3-.14-.08a2.8 2.8 0 01-1-2.4l-.4-143.27 4.87 3.08z"
            style={{
              transformOrigin: "87.425px 262.455px"
            }}
            className="animable"
            id="elsi8be8rqoh"
            opacity={0.1}
          />
        </g>
        <g id="elhzgi08qj78">
          <path
            d="M88.15 175.39L283.3 62.72a2.59 2.59 0 013-.09l4.6 2.9a2.76 2.76 0 00-2.74.29L93 178.47a8.54 8.54 0 00-3.88 6.72v6l-4.87-3.08v-6a8.54 8.54 0 013.9-6.72z"
            style={{
              transformOrigin: "187.575px 126.692px"
            }}
            className="animable"
            id="elrgsxoig438"
            fill="#fff"
            opacity={0.5}
          />
        </g>
        <path
          d="M243 138.63l7.24 14.74-5.73 3.3-1.14-2.67-6.24 3.61-1.1 3.92-5.63 3.25 7.13-23zm-4.34 13.53l3.2-1.85-1.61-3.83-1.59 5.68"
          style={{
            transformOrigin: "240.32px 151.705px"
          }}
          id="el96a54e2z3"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M223.57 149.4a12.18 12.18 0 016.21-1.94l-1.66 5.54a9.12 9.12 0 00-4.58 1.25c-1.63.94-2.14 1.78-2.13 2.41 0 2.26 9.09-4.87 9.11 2.1 0 3.46-2.54 7.83-7.65 10.78-2.73 1.58-5.51 2.42-7.16 2.21l1.77-5.69a9.61 9.61 0 005.43-1.39c1.57-.9 2.13-1.61 2.13-2.26 0-2.37-9.08 4.77-9.1-2.28-.01-3.46 2.54-7.79 7.63-10.73z"
          style={{
            transformOrigin: "223.115px 159.621px"
          }}
          id="el38mv4dio913"
          className="animable"
          fill="#7519ff"
        />
        <path
          style={{
            transformOrigin: "257.575px 141.8px"
          }}
          id="eluxh9prixa3"
          className="animable"
          fill="#7519ff"
          d="M256.61 130.75L256.65 144.7 264.07 140.42 264.09 145.36 251.12 152.85 251.06 133.96 256.61 130.75z"
        />
        <path
          style={{
            transformOrigin: "272.425px 131.17px"
          }}
          id="el7xifj5kw42s"
          className="animable"
          fill="#7519ff"
          d="M278.92 117.87L278.94 122.67 271.02 127.24 271.03 129.47 277.99 125.45 278 130.02 271.04 134.04 271.05 136.53 279.28 131.78 279.29 136.58 265.62 144.47 265.56 125.58 278.92 117.87z"
        />
        <path
          d="M229.4 195.5c1.9-.29 3 .67 3 2.9 0 3.25-2.38 6.54-6.89 9.14l-9.17 5.3-.05-18.73 8.72-5c4.49-2.59 6.59-1.73 6.6 1.1a8.3 8.3 0 01-2.21 5.29zm-4.32 7.89a3 3 0 001.81-2.52c0-1-.62-1.11-1.82-.42l-3.3 1.91v2.93l3.3-1.9m-3.33-8v2.8l2.55-1.48a2.88 2.88 0 001.79-2.44c0-.91-.56-1.06-1.8-.35l-2.55 1.47"
          style={{
            transformOrigin: "224.345px 200.201px"
          }}
          id="elncxxomkzxy"
          className="animable"
          fill="#7519ff"
        />
        <path
          style={{
            transformOrigin: "241.135px 189.25px"
          }}
          id="elnkqi9m9y0ip"
          className="animable"
          fill="#7519ff"
          d="M247.57 176.06L247.59 180.82 239.74 185.35 239.75 187.56 246.65 183.57 246.66 188.11 239.76 192.09 239.77 194.56 247.93 189.85 247.94 194.61 234.38 202.44 234.33 183.71 247.57 176.06z"
        />
        <path
          d="M256.83 170.29a12.08 12.08 0 016.16-1.92l-1.65 5.53a8.81 8.81 0 00-4.54 1.24c-1.61.93-2.12 1.76-2.12 2.38 0 2.25 9-4.82 9 2.09 0 3.41-2.53 7.77-7.58 10.69-2.73 1.57-5.47 2.39-7.1 2.17l1.75-5.62a9.55 9.55 0 005.39-1.38c1.55-.9 2.11-1.6 2.11-2.24 0-2.35-9 4.74-9-2.25-.01-3.47 2.53-7.77 7.58-10.69z"
          style={{
            transformOrigin: "256.34px 180.438px"
          }}
          id="elblocslwoubj"
          className="animable"
          fill="#7519ff"
        />
        <path
          style={{
            transformOrigin: "271.37px 170.305px"
          }}
          id="elpoz0iexw3o"
          className="animable"
          fill="#7519ff"
          d="M278.88 157.98L278.9 162.88 274.13 165.63 274.17 179.47 268.68 182.63 268.64 168.8 263.86 171.57 263.84 166.67 278.88 157.98z"
        />
        <path
          d="M221 215.3c2.28-1.32 3.75-.79 3.75 1.4a6.91 6.91 0 01-3.73 5.72l-1.19.69v2.32L217 227v-9.44zm-.17 4.77a2 2 0 001.12-1.75c0-.69-.36-.9-1.12-.46l-1 .58v2.21l1-.58"
          style={{
            transformOrigin: "220.875px 220.792px"
          }}
          id="elt2h93nq2v7f"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M231.78 215.59l1.73 1.92-3 1.71-1.4-1.56-.75.43v2.37l-2.76 1.6v-9.45l4-2.28c2.29-1.33 3.75-.79 3.75 1.4a6 6 0 01-1.57 3.86zm-2.38-.49a2 2 0 001.13-1.76c0-.69-.37-.89-1.13-.45l-1 .58v2.21l1-.58"
          style={{
            transformOrigin: "229.555px 215.835px"
          }}
          id="elfl960pk0doi"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M238.67 204.86c2.68-1.55 4.65-.61 4.66 2.26a9.49 9.49 0 01-4.63 7.62c-2.68 1.55-4.64.59-4.65-2.26a9.41 9.41 0 014.62-7.62zm0 7.3a4 4 0 001.83-3.42c0-1.47-.82-1.89-1.84-1.3a4 4 0 00-1.84 3.42c0 1.47.83 1.89 1.85 1.3"
          style={{
            transformOrigin: "238.69px 209.798px"
          }}
          id="elll9c6qtpas"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M248.37 199.47c2.75-1.59 4.65-.88 4.66 2.05a8.91 8.91 0 01-4.63 7.4l-4.05 2.33v-9.45zm-.09 7a3.9 3.9 0 002-3.37c0-1.45-.81-1.78-2-1.1l-1.16.67v4.47l1.17-.67"
          style={{
            transformOrigin: "248.69px 204.946px"
          }}
          id="el7xwb2dule2x"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M262 191.62v5.19a7.72 7.72 0 01-4 6.79c-2.52 1.46-4 .67-4-2.16v-5.2l2.77-1.6v5.1c0 1.45.48 1.71 1.26 1.27a2.83 2.83 0 001.25-2.72v-5.1z"
          style={{
            transformOrigin: "258px 197.969px"
          }}
          id="elra25zafuh3"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M267.49 188.22c1.61-.93 2.88-1 3.69-.24l-1.74 2.8a1.41 1.41 0 00-1.8-.06 4 4 0 00-1.92 3.47c0 1.44.78 1.91 1.93 1.25a4.83 4.83 0 001.8-2.15l1.75.79a9.29 9.29 0 01-3.68 4c-2.64 1.53-4.59.6-4.6-2.3a9.34 9.34 0 014.57-7.56z"
          style={{
            transformOrigin: "267.06px 193.135px"
          }}
          id="elf9fvax3f5ah"
          className="animable"
          fill="#7519ff"
        />
        <path
          style={{
            transformOrigin: "275.095px 188.07px"
          }}
          id="elrxacxrdkzf"
          className="animable"
          fill="#7519ff"
          d="M278.89 181.85L278.89 184.32 276.49 185.71 276.51 192.69 273.73 194.29 273.71 187.31 271.31 188.7 271.3 186.23 278.89 181.85z"
        />
        <g id="el1rdgrki3ldd">
          <g
            style={{
              transformOrigin: "247.5px 172.435px"
            }}
            className="animable"
            id="elgs0vf286kp7"
            opacity={0.2}
          >
            <path
              d="M243 138.63l7.24 14.74-5.73 3.3-1.14-2.67-6.24 3.61-1.1 3.92-5.63 3.25 7.13-23zm-4.34 13.53l3.2-1.85-1.61-3.83-1.59 5.68"
              style={{
                transformOrigin: "240.32px 151.705px"
              }}
              id="el1pgie2bi56m"
              className="animable"
              fill="#fff"
            />
            <path
              d="M223.57 149.4a12.18 12.18 0 016.21-1.94l-1.66 5.54a9.12 9.12 0 00-4.58 1.25c-1.63.94-2.14 1.78-2.13 2.41 0 2.26 9.09-4.87 9.11 2.1 0 3.46-2.54 7.83-7.65 10.78-2.73 1.58-5.51 2.42-7.16 2.21l1.77-5.69a9.61 9.61 0 005.43-1.39c1.57-.9 2.13-1.61 2.13-2.26 0-2.37-9.08 4.77-9.1-2.28-.01-3.46 2.54-7.79 7.63-10.73z"
              style={{
                transformOrigin: "223.115px 159.621px"
              }}
              id="el5qawl962ha6"
              className="animable"
              fill="#fff"
            />
            <path
              style={{
                transformOrigin: "257.575px 141.8px"
              }}
              id="el3riexgr2yo2"
              className="animable"
              fill="#fff"
              d="M256.61 130.75L256.65 144.7 264.07 140.42 264.09 145.36 251.12 152.85 251.06 133.96 256.61 130.75z"
            />
            <path
              style={{
                transformOrigin: "272.425px 131.17px"
              }}
              id="elnibqm5kj82"
              className="animable"
              fill="#fff"
              d="M278.92 117.87L278.94 122.67 271.02 127.24 271.03 129.47 277.99 125.45 278 130.02 271.04 134.04 271.05 136.53 279.28 131.78 279.29 136.58 265.62 144.47 265.56 125.58 278.92 117.87z"
            />
            <path
              d="M229.4 195.5c1.9-.29 3 .67 3 2.9 0 3.25-2.38 6.54-6.89 9.14l-9.17 5.3-.05-18.73 8.72-5c4.49-2.59 6.59-1.73 6.6 1.1a8.3 8.3 0 01-2.21 5.29zm-4.32 7.89a3 3 0 001.81-2.52c0-1-.62-1.11-1.82-.42l-3.3 1.91v2.93l3.3-1.9m-3.33-8v2.8l2.55-1.48a2.88 2.88 0 001.79-2.44c0-.91-.56-1.06-1.8-.35l-2.55 1.47"
              style={{
                transformOrigin: "224.345px 200.201px"
              }}
              id="el4dbfhdozgmh"
              className="animable"
              fill="#fff"
            />
            <path
              style={{
                transformOrigin: "241.135px 189.25px"
              }}
              id="eleaz4xevkhsb"
              className="animable"
              fill="#fff"
              d="M247.57 176.06L247.59 180.82 239.74 185.35 239.75 187.56 246.65 183.57 246.66 188.11 239.76 192.09 239.77 194.56 247.93 189.85 247.94 194.61 234.38 202.44 234.33 183.71 247.57 176.06z"
            />
            <path
              d="M256.83 170.29a12.08 12.08 0 016.16-1.92l-1.65 5.53a8.81 8.81 0 00-4.54 1.24c-1.61.93-2.12 1.76-2.12 2.38 0 2.25 9-4.82 9 2.09 0 3.41-2.53 7.77-7.58 10.69-2.73 1.57-5.47 2.39-7.1 2.17l1.75-5.62a9.55 9.55 0 005.39-1.38c1.55-.9 2.11-1.6 2.11-2.24 0-2.35-9 4.74-9-2.25-.01-3.47 2.53-7.77 7.58-10.69z"
              style={{
                transformOrigin: "256.34px 180.438px"
              }}
              id="elfm3gg5ex8nm"
              className="animable"
              fill="#fff"
            />
            <path
              style={{
                transformOrigin: "271.37px 170.305px"
              }}
              id="elfijapg0p7d"
              className="animable"
              fill="#fff"
              d="M278.88 157.98L278.9 162.88 274.13 165.63 274.17 179.47 268.68 182.63 268.64 168.8 263.86 171.57 263.84 166.67 278.88 157.98z"
            />
            <path
              d="M221 215.3c2.28-1.32 3.75-.79 3.75 1.4a6.91 6.91 0 01-3.73 5.72l-1.19.69v2.32L217 227v-9.44zm-.17 4.77a2 2 0 001.12-1.75c0-.69-.36-.9-1.12-.46l-1 .58v2.21l1-.58"
              style={{
                transformOrigin: "220.875px 220.792px"
              }}
              id="elltspjjvniwj"
              className="animable"
              fill="#fff"
            />
            <path
              d="M231.78 215.59l1.73 1.92-3 1.71-1.4-1.56-.75.43v2.37l-2.76 1.6v-9.45l4-2.28c2.29-1.33 3.75-.79 3.75 1.4a6 6 0 01-1.57 3.86zm-2.38-.49a2 2 0 001.13-1.76c0-.69-.37-.89-1.13-.45l-1 .58v2.21l1-.58"
              style={{
                transformOrigin: "229.555px 215.835px"
              }}
              id="el6oll9g7k2zf"
              className="animable"
              fill="#fff"
            />
            <path
              d="M238.67 204.86c2.68-1.55 4.65-.61 4.66 2.26a9.49 9.49 0 01-4.63 7.62c-2.68 1.55-4.64.59-4.65-2.26a9.41 9.41 0 014.62-7.62zm0 7.3a4 4 0 001.83-3.42c0-1.47-.82-1.89-1.84-1.3a4 4 0 00-1.84 3.42c0 1.47.83 1.89 1.85 1.3"
              style={{
                transformOrigin: "238.69px 209.798px"
              }}
              id="ela9vx2la7y4c"
              className="animable"
              fill="#fff"
            />
            <path
              d="M248.37 199.47c2.75-1.59 4.65-.88 4.66 2.05a8.91 8.91 0 01-4.63 7.4l-4.05 2.33v-9.45zm-.09 7a3.9 3.9 0 002-3.37c0-1.45-.81-1.78-2-1.1l-1.16.67v4.47l1.17-.67"
              style={{
                transformOrigin: "248.69px 204.946px"
              }}
              id="elv2c5g90a6n"
              className="animable"
              fill="#fff"
            />
            <path
              d="M262 191.62v5.19a7.72 7.72 0 01-4 6.79c-2.52 1.46-4 .67-4-2.16v-5.2l2.77-1.6v5.1c0 1.45.48 1.71 1.26 1.27a2.83 2.83 0 001.25-2.72v-5.1z"
              style={{
                transformOrigin: "258px 197.969px"
              }}
              id="els5ivrqz82gn"
              className="animable"
              fill="#fff"
            />
            <path
              d="M267.49 188.22c1.61-.93 2.88-1 3.69-.24l-1.74 2.8a1.41 1.41 0 00-1.8-.06 4 4 0 00-1.92 3.47c0 1.44.78 1.91 1.93 1.25a4.83 4.83 0 001.8-2.15l1.75.79a9.29 9.29 0 01-3.68 4c-2.64 1.53-4.59.6-4.6-2.3a9.34 9.34 0 014.57-7.56z"
              style={{
                transformOrigin: "267.06px 193.135px"
              }}
              id="ell49x7vki1b"
              className="animable"
              fill="#fff"
            />
            <path
              style={{
                transformOrigin: "275.095px 188.07px"
              }}
              id="el1d8q8wvryie"
              className="animable"
              fill="#fff"
              d="M278.89 181.85L278.89 184.32 276.49 185.71 276.51 192.69 273.73 194.29 273.71 187.31 271.31 188.7 271.3 186.23 278.89 181.85z"
            />
          </g>
        </g>
      </g>
      <g
        id="freepik--Target--inject-2"
        className="animable"
        style={{
          transformOrigin: "110.105px 72.1523px",
          animation: "1.5s Infinite linear wind",
          animationDelay: "0s"
        }}
      >
        <path
          d="M123.53 37.42l1.08-1.85-6.89-4.08c-5.78-3.38-13.79-2.94-22.62 2.16-17.5 10.11-31.69 34.7-31.64 54.81 0 9.72 3.39 16.63 8.82 20.05l.43.26.19.11 6.72 4 1.13-1.95c4.37.08 9.32-1.3 14.56-4.32C112.81 96.5 127 71.91 127 51.81c-.06-5.88-1.3-10.72-3.47-14.39z"
          style={{
            transformOrigin: "95.2299px 71.0974px"
          }}
          id="elyvdqb3h8gi"
          className="animable"
          fill="#7519ff"
        />
        <g id="elaw80gnx4j8">
          <path
            d="M123.53 37.42l1.08-1.85-6.89-4.08c-5.78-3.38-13.79-2.94-22.62 2.16-17.5 10.11-31.69 34.7-31.64 54.81 0 9.72 3.39 16.63 8.82 20.05l.43.26.19.11 6.72 4 1.13-1.95c4.37.08 9.32-1.3 14.56-4.32C112.81 96.5 127 71.91 127 51.81c-.06-5.88-1.3-10.72-3.47-14.39z"
            style={{
              transformOrigin: "95.2299px 71.0974px"
            }}
            className="animable"
            id="el2je7bnjguui"
            opacity={0.3}
          />
        </g>
        <g id="elw1mbjrh662">
          <ellipse
            cx={102}
            cy={74.16}
            rx={9.58}
            ry={5.55}
            style={{
              transformOrigin: "102px 74.16px"
            }}
            className="animable"
            id="elt3n23llqhr"
            fill="#7519ff"
            transform="rotate(-59.827)"
          />
        </g>
        <path
          d="M101.92 44.85c14.08-8.13 25.54-1.6 25.59 14.59s-11.34 35.9-25.43 44-25.54 1.64-25.58-14.55S87.83 53 101.92 44.85zm.14 51.45c10.63-6.13 19.25-21.06 19.21-33.26s-8.7-17.16-19.33-11-19.24 21-19.21 33.27 8.71 17.15 19.33 11"
          style={{
            transformOrigin: "102.005px 74.1425px"
          }}
          id="elk89cjktb3ck"
          className="animable"
          fill="#fff"
        />
        <path
          d="M101.9 37.68c17.5-10.1 31.79-2 31.85 18.15s-14.14 44.7-31.65 54.81-31.78 2-31.84-18.15 14.14-44.7 31.64-54.81zm.18 65.79c14.09-8.13 25.48-27.85 25.43-44S116 36.72 101.92 44.85 76.45 72.7 76.5 88.89s11.5 22.71 25.58 14.58"
          style={{
            transformOrigin: "102.005px 74.1622px"
          }}
          id="eljnib6007ggd"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M102 59.18c7.15-4.18 13-.81 13 7.46s-5.77 18.36-13 22.5-13 .8-13-7.45 5.77-18.36 13-22.51zM102 82a15 15 0 006.78-11.74c0-4.31-3.08-6-6.82-3.89a15 15 0 00-6.76 11.72c0 4.3 3.07 6 6.82 3.89"
          style={{
            transformOrigin: "102px 74.1504px"
          }}
          id="ely5aa2of618"
          className="animable"
          fill="#fff"
        />
        <path
          d="M101.94 52c10.63-6.14 19.3-1.2 19.33 11s-8.58 27.13-19.21 33.26-19.29 1.19-19.33-11S91.31 58.15 101.94 52zm.1 37.12c7.19-4.16 13-14.25 13-22.5S109.15 55 102 59.18 89 73.42 89 81.69s5.86 11.59 13 7.45"
          style={{
            transformOrigin: "102px 74.1269px"
          }}
          id="ela1xmapxmnsw"
          className="animable"
          fill="#7519ff"
        />
        <path
          style={{
            transformOrigin: "146.58px 95.505px"
          }}
          id="elcli5vuz5v7t"
          className="animable"
          fill="#7519ff"
          d="M136.41 92.42L145.93 97.95 156.75 98.59 147.23 93.06 136.41 92.42z"
        />
        <path
          style={{
            transformOrigin: "141.17px 95.805px"
          }}
          id="elw5u88qm8qp"
          className="animable"
          fill="#7519ff"
          d="M136.41 92.42L136.41 93.66 145.93 99.19 145.93 97.95 136.41 92.42z"
        />
        <path
          style={{
            transformOrigin: "151.34px 98.885px"
          }}
          id="eltqspp9erti"
          className="animable"
          fill="#7519ff"
          d="M156.75 98.59L156.75 99.82 145.93 99.19 145.93 97.95 156.75 98.59z"
        />
        <g id="elsam3xomoay">
          <g
            style={{
              transformOrigin: "146.58px 95.505px"
            }}
            className="animable"
            id="elwtalrusbtzl"
            opacity={0.2}
          >
            <path
              id="elhh26lgq8i4u"
              className="animable"
              style={{
                transformOrigin: "146.58px 95.505px"
              }}
              d="M136.41 92.42L145.93 97.95 156.75 98.59 147.23 93.06 136.41 92.42z"
            />
          </g>
        </g>
        <g id="el91306b8vbn">
          <path
            style={{
              transformOrigin: "141.17px 95.805px"
            }}
            className="animable"
            id="eloqjhmwezyvr"
            opacity={0.4}
            d="M136.41 92.42L136.41 93.66 145.93 99.19 145.93 97.95 136.41 92.42z"
          />
        </g>
        <g id="elzicwrhxqp8">
          <path
            style={{
              transformOrigin: "151.34px 98.885px"
            }}
            className="animable"
            id="elqpprrhrgs7b"
            opacity={0.3}
            d="M156.75 98.59L156.75 99.82 145.93 99.19 145.93 97.95 156.75 98.59z"
          />
        </g>
        <path
          d="M149.71 103.89l1.62-2.79-48.27-28a.74.74 0 00-.84 0 2.52 2.52 0 00-1.14 2 .8.8 0 00.33.73z"
          style={{
            transformOrigin: "126.203px 88.4296px"
          }}
          id="elikvlrfoa3wg"
          className="animable"
          fill="#455a64"
        />
        <path
          d="M150.53 101.19c.62-.36 1.14-.07 1.14.65a2.52 2.52 0 01-1.14 2c-.63.37-1.14.07-1.14-.65a2.49 2.49 0 011.14-2z"
          style={{
            transformOrigin: "150.53px 102.517px"
          }}
          id="el8w0xui1fg2a"
          className="animable"
          fill="#455a64"
        />
        <g id="elrwsr1gmvook">
          <g
            style={{
              transformOrigin: "150.53px 102.517px"
            }}
            className="animable"
            id="el1udd8og0ot4"
            opacity={0.3}
          >
            <path
              d="M150.53 101.19c.62-.36 1.14-.07 1.14.65a2.52 2.52 0 01-1.14 2c-.63.37-1.14.07-1.14-.65a2.49 2.49 0 011.14-2z"
              style={{
                transformOrigin: "150.53px 102.517px"
              }}
              id="elg263mvv4g1m"
              className="animable"
              fill="#fff"
            />
          </g>
        </g>
        <path
          style={{
            transformOrigin: "139.71px 99.47px"
          }}
          id="eli1te3g6o1nl"
          className="animable"
          fill="#7519ff"
          d="M134.42 93.57L135.48 99.84 145 105.37 143.94 99.1 134.42 93.57z"
        />
        <g id="elf3ht5t4ed8p">
          <g
            style={{
              transformOrigin: "139.71px 99.47px"
            }}
            className="animable"
            id="elj6iqzxiwa"
            opacity={0.2}
          >
            <path
              id="eltus2vw4uvu"
              className="animable"
              style={{
                transformOrigin: "139.71px 99.47px"
              }}
              d="M134.42 93.57L135.48 99.84 145 105.37 143.94 99.1 134.42 93.57z"
            />
          </g>
        </g>
        <path
          style={{
            transformOrigin: "134.95px 97.325px"
          }}
          id="elj9qzikhn9gg"
          className="animable"
          fill="#7519ff"
          d="M134.42 93.57L135.48 99.84 135.48 101.08 134.42 94.81 134.42 93.57z"
        />
        <path
          style={{
            transformOrigin: "140.24px 103.225px"
          }}
          id="el8qxftgc9o6a"
          className="animable"
          fill="#7519ff"
          d="M145 105.37L145 106.61 135.48 101.08 135.48 99.84 145 105.37z"
        />
        <g id="elk87rp4ufs7e">
          <path
            style={{
              transformOrigin: "134.95px 97.325px"
            }}
            className="animable"
            id="ely1no2krn33"
            opacity={0.4}
            d="M134.42 93.57L135.48 99.84 135.48 101.08 134.42 94.81 134.42 93.57z"
          />
        </g>
        <g id="els2o37s7v9kb">
          <path
            style={{
              transformOrigin: "140.24px 103.225px"
            }}
            className="animable"
            id="el5jtfy9xymbk"
            opacity={0.3}
            d="M145 105.37L145 106.61 135.48 101.08 135.48 99.84 145 105.37z"
          />
        </g>
      </g>
      <g
        id="freepik--device-1--inject-2"
        className="animable"
        style={{
          transformOrigin: "145.37px 288.178px"
        }}
      >
        <path
          d="M186.38 191.49c-.51-1.78-4.13-5.18-7.74-5.11a3.26 3.26 0 00-1 .2l-70 39.85a6.81 6.81 0 00-3.39 5.82v151.5c0 3.86 4.45 7.54 8.63 5.77a1.53 1.53 0 00.27-.11l70-39.91a6.69 6.69 0 003.38-5.82V192.22a1.64 1.64 0 00-.15-.73z"
          style={{
            transformOrigin: "145.39px 288.178px"
          }}
          id="elf1ldaffb0a5"
          className="animable"
          fill="#7519ff"
        />
        <g id="eleol1782tn0b">
          <path
            d="M186.38 191.49c-.51-1.78-4.13-5.18-7.74-5.11a3.26 3.26 0 00-1 .2l-70 39.85a6.81 6.81 0 00-3.39 5.82v151.5c0 3.86 4.45 7.54 8.63 5.77a1.53 1.53 0 00.27-.11l70-39.91a6.69 6.69 0 003.38-5.82V192.22a1.64 1.64 0 00-.15-.73z"
            style={{
              transformOrigin: "145.39px 288.178px"
            }}
            className="animable"
            id="elalf927kad3"
            fill="#fff"
            opacity={0.3}
          />
        </g>
        <path
          d="M114 248.27a3.36 3.36 0 011.7-2.92l65-37a1.67 1.67 0 012.5 1.45V332a3.37 3.37 0 01-1.69 2.92l-65 37.05a1.68 1.68 0 01-2.51-1.45z"
          style={{
            transformOrigin: "148.6px 290.159px"
          }}
          id="el85ekxan3gkx"
          className="animable"
          fill="#455a64"
        />
        <g id="elytj31t5vb5s">
          <path
            d="M104.21 232.25v151.5c0 3.86 4.45 7.54 8.63 5.77a1.67 1.67 0 01-2.24-1.56V236.44a6.68 6.68 0 011.14-3.7l-6.86-3.43a6.73 6.73 0 00-.67 2.94z"
            style={{
              transformOrigin: "108.525px 309.643px"
            }}
            className="animable"
            id="el1agckymcbxv"
            opacity={0.3}
          />
        </g>
        <g id="elrqew0ntsnv8">
          <path
            d="M178.65 186.38a3.26 3.26 0 00-1 .2l-70 39.85a6.67 6.67 0 00-2.69 2.88l6.86 3.43a6.54 6.54 0 012.25-2.12l70-39.85a1.66 1.66 0 012.32.72c-.51-1.78-4.13-5.18-7.74-5.11z"
            style={{
              transformOrigin: "145.675px 209.559px"
            }}
            className="animable"
            id="el6eepvrx3v4p"
            fill="#fff"
            opacity={0.1}
          />
        </g>
        <path
          d="M157.23 215.79c.49-.29.9-.07.9.5a2 2 0 01-.89 1.55l-13.74 7.93c-.5.29-.89 0-.89-.52a1.92 1.92 0 01.88-1.53z"
          style={{
            transformOrigin: "150.37px 220.774px"
          }}
          id="el59adpfxqnev"
          className="animable"
          fill="#263238"
        />
        <path
          d="M140 225.76c.49-.29.89-.06.89.5a2 2 0 01-.88 1.55c-.5.28-.9.05-.9-.51a1.93 1.93 0 01.89-1.54z"
          style={{
            transformOrigin: "140px 226.782px"
          }}
          id="el3efsysv1td7"
          className="animable"
          fill="#263238"
        />
        <path
          d="M148.76 214.63c1-.56 1.77-.11 1.78 1a3.94 3.94 0 01-1.77 3.08c-1 .57-1.8.11-1.8-1a3.91 3.91 0 011.79-3.08z"
          style={{
            transformOrigin: "148.755px 216.672px"
          }}
          id="el57lsg37knne"
          className="animable"
          fill="#263238"
        />
        <path
          d="M148.76 357.26c2-1.16 3.62-.24 3.63 2.06a8 8 0 01-3.6 6.29c-2 1.15-3.67.21-3.67-2.09a8 8 0 013.64-6.26z"
          style={{
            transformOrigin: "148.755px 361.43px"
          }}
          id="el41l1ty1kooe"
          className="animable"
          fill="#263238"
        />
      </g>
      <g
        id="freepik--Icons--inject-2"
        className="animable animator-active"
        style={{
          transformOrigin: "158.325px 267.869px",
          animation: "1.5s Infinite linear wind",
          animationDelay: "0s"
        }}
      >
        <path
          d="M197.89 180.06l-2.14-1.14a1 1 0 00-1-.07l-36.87 21a1.91 1.91 0 00-1 1.65v43.77a1 1 0 00.61.88l2.16 1.16a1 1 0 00.91 0l36.87-21a1.91 1.91 0 001-1.65v-43.75a.94.94 0 00-.54-.85z"
          style={{
            transformOrigin: "177.655px 213.084px"
          }}
          id="elij2dm4ppvqr"
          className="animable"
          fill="#7519ff"
        />
        <g id="el04j75g5gt9qb">
          <path
            d="M197.89 180.06l-2.14-1.14a1 1 0 00-1-.07l-36.87 21a1.91 1.91 0 00-1 1.65v43.77a1 1 0 00.61.88l2.16 1.16a1 1 0 00.91 0l36.87-21a1.91 1.91 0 001-1.65v-43.75a.94.94 0 00-.54-.85z"
            style={{
              transformOrigin: "177.655px 213.084px"
            }}
            className="animable"
            id="el7cvizmn86pw"
            fill="#fff"
            opacity={0.6}
          />
        </g>
        <g id="ely8yjjfsxjt">
          <path
            d="M156.88 201.49v43.77a1 1 0 00.61.88l2.16 1.16a.92.92 0 01-.47-.81v-43.78a1.85 1.85 0 01.15-.74l-2.26-1.3a1.9 1.9 0 00-.19.82z"
            style={{
              transformOrigin: "158.265px 223.985px"
            }}
            className="animable"
            id="el8nn1rfbbdts"
            fill="#7519ff"
            opacity={0.3}
          />
        </g>
        <g id="elj2d2kfvsyql">
          <path
            d="M195.75 178.92a1 1 0 00-1-.07l-36.87 21a1.93 1.93 0 00-.77.83l2.26 1.3a1.93 1.93 0 01.81-.91l36.87-21a.92.92 0 01.88 0z"
            style={{
              transformOrigin: "177.52px 190.364px"
            }}
            className="animable"
            id="elqiu99bpvdoo"
            fill="#fff"
            opacity={0.4}
          />
        </g>
        <path
          d="M182.28 195.38c.52-.3 1.09-.61 1.68-.9s1.18-.57 1.73-.79v5.16a2.22 2.22 0 00-.53.19l-.67.36a4 4 0 00-1.19 1.1 2.72 2.72 0 00-.51 1.63v1.5l2.9-1.67v5.53l-2.9 1.67v19.2l-7.15 4.13-.06-19.19-2.46 1.42v-5.53l2.47-1.43V206a15.55 15.55 0 01.33-3.36 8.76 8.76 0 011.11-2.72 10.26 10.26 0 012-2.34 20.7 20.7 0 013.25-2.2z"
          style={{
            transformOrigin: "179.405px 213.09px"
          }}
          id="elhh8ddg5v7l4"
          className="animable"
          fill="#fff"
        />
        <path
          d="M147 230l-2.15-1.14a.94.94 0 00-1-.07l-36.87 21a1.92 1.92 0 00-1 1.66v43.77a1 1 0 00.61.88l2.16 1.16a.93.93 0 00.9 0l36.87-21a1.89 1.89 0 001-1.65v-43.74a.94.94 0 00-.52-.87z"
          style={{
            transformOrigin: "126.75px 263.027px"
          }}
          id="elwbngrl7a6rr"
          className="animable"
          fill="#7519ff"
        />
        <g id="el71d22xoypv4">
          <path
            d="M147 230l-2.15-1.14a.94.94 0 00-1-.07l-36.87 21a1.92 1.92 0 00-1 1.66v43.77a1 1 0 00.61.88l2.16 1.16a.93.93 0 00.9 0l36.87-21a1.89 1.89 0 001-1.65v-43.74a.94.94 0 00-.52-.87z"
            style={{
              transformOrigin: "126.75px 263.027px"
            }}
            className="animable"
            id="eldrgply75a6"
            fill="#fff"
            opacity={0.6}
          />
        </g>
        <g id="el44nx9k0cco5">
          <path
            d="M106 251.45v43.77a1 1 0 00.61.88l2.16 1.16a.94.94 0 01-.47-.82v-43.77a1.86 1.86 0 01.16-.74l-2.26-1.3a1.9 1.9 0 00-.2.82z"
            style={{
              transformOrigin: "107.385px 273.945px"
            }}
            className="animable"
            id="el46xjkkx888r"
            fill="#7519ff"
            opacity={0.3}
          />
        </g>
        <g id="el43ladlqbsv7">
          <path
            d="M144.82 228.88a.94.94 0 00-1-.07l-36.87 21a1.89 1.89 0 00-.77.84l2.26 1.3a1.91 1.91 0 01.8-.91l36.87-21a1 1 0 01.88 0z"
            style={{
              transformOrigin: "126.585px 240.324px"
            }}
            className="animable"
            id="elgxt7kygk8vp"
            fill="#fff"
            opacity={0.4}
          />
        </g>
        <path
          style={{
            transformOrigin: "130.325px 269.415px"
          }}
          id="eloz0z9tbfg78"
          className="animable"
          fill="#fff"
          d="M120.72 256.7L139.93 258.35 120.8 282.13 120.72 256.7z"
        />
        <path
          d="M210.14 238.85l-2.14-1.14a.94.94 0 00-1-.07l-36.87 21a1.91 1.91 0 00-1 1.65v43.77a.94.94 0 00.61.88l2.16 1.16a.9.9 0 00.9 0l36.87-21a1.89 1.89 0 001-1.65V239.7a.94.94 0 00-.53-.85z"
          style={{
            transformOrigin: "189.9px 271.874px"
          }}
          id="el7bn9aqgnb99"
          className="animable"
          fill="#7519ff"
        />
        <g id="el55zwlqaduaw">
          <path
            d="M210.14 238.85l-2.14-1.14a.94.94 0 00-1-.07l-36.87 21a1.91 1.91 0 00-1 1.65v43.77a.94.94 0 00.61.88l2.16 1.16a.9.9 0 00.9 0l36.87-21a1.89 1.89 0 001-1.65V239.7a.94.94 0 00-.53-.85z"
            style={{
              transformOrigin: "189.9px 271.874px"
            }}
            className="animable"
            id="elq5t0ft5smf"
            fill="#fff"
            opacity={0.6}
          />
        </g>
        <g id="elhzj16foxqc7">
          <path
            d="M169.14 260.28v43.77a.94.94 0 00.61.88l2.16 1.16a.94.94 0 01-.47-.81V261.5a1.89 1.89 0 01.16-.74l-2.26-1.3a1.9 1.9 0 00-.2.82z"
            style={{
              transformOrigin: "170.525px 282.775px"
            }}
            className="animable"
            id="elfbighxnes66"
            fill="#7519ff"
            opacity={0.3}
          />
        </g>
        <g id="el6ffq5y7p1tq">
          <path
            d="M208 237.71a.94.94 0 00-1-.07l-36.87 21a1.87 1.87 0 00-.77.83l2.26 1.3a2 2 0 01.8-.91l36.87-21a1 1 0 01.88 0z"
            style={{
              transformOrigin: "189.765px 249.149px"
            }}
            className="animable"
            id="elccaij9rheo"
            fill="#fff"
            opacity={0.4}
          />
        </g>
        <path
          d="M199.19 259.22c.92-.5 1.66-.06 1.65 1a3.73 3.73 0 01-1.67 2.87 1 1 0 01-1.64-1 3.71 3.71 0 011.66-2.87z"
          style={{
            transformOrigin: "199.168px 261.183px"
          }}
          id="elzaebiapzbsp"
          className="animable"
          fill="#fff"
        />
        <path
          d="M204.93 257.1a9.06 9.06 0 00.23 1.06v14.93a8.16 8.16 0 00-.2 1.13 16.31 16.31 0 01-5.12 9c-.36.32-.73.53-1.09.88l-12.94 7.47a4.19 4.19 0 00-.89.29c-2.7.7-4.44-.26-5.23-2.9a5 5 0 00-.23-1v-15c.09-.1.06-.18.07-.26a12.85 12.85 0 01.81-3.29 15.13 15.13 0 016.29-7.85c1.58-.93 3.15-1.84 4.73-2.75l6.58-3.79a7.59 7.59 0 011.77-.79c2.69-.73 4.43.23 5.22 2.87zm-2 16.26v-12.89a5.17 5.17 0 00-.26-1.78c-.73-1.93-2.5-2.34-4.7-1.07l-4.75 2.74-6.49 3.75a8 8 0 00-1.68 1.27 10.58 10.58 0 00-3.26 7.23v13c0 1.93.68 3.09 2 3.45a4 4 0 002.93-.66l1.88-1.09 3.73-2.15q2.79-1.61 5.57-3.22a8.36 8.36 0 001.73-1.29 10.62 10.62 0 003.26-7.29"
          style={{
            transformOrigin: "192.31px 273.037px"
          }}
          id="eldcddvpcdu6"
          className="animable"
          fill="#fff"
        />
        <path
          d="M192.31 265.4c3.64-2.09 6.63-.38 6.64 3.79a14.62 14.62 0 01-6.6 11.43c-3.64 2.1-6.63.39-6.64-3.79a14.64 14.64 0 016.6-11.43zm4.36 5.14c0-2.74-1.9-3.87-4.32-2.53l-.08.05a9.57 9.57 0 00-4.27 7.44c0 2.75 1.93 3.86 4.34 2.51a9.58 9.58 0 004.29-7.44"
          style={{
            transformOrigin: "192.33px 273.013px"
          }}
          id="elsoo7z34cg4n"
          className="animable"
          fill="#fff"
        />
        <path
          d="M160.19 289.63l-2.15-1.14a.94.94 0 00-1-.07l-36.87 21a1.89 1.89 0 00-1 1.65v43.78a.92.92 0 00.6.87l2.16 1.16a1 1 0 00.91 0l36.87-21a1.91 1.91 0 001-1.65v-43.75a.94.94 0 00-.52-.85z"
          style={{
            transformOrigin: "139.94px 322.649px"
          }}
          id="eleedzbzuyzg5"
          className="animable"
          fill="#7519ff"
        />
        <g id="el4s280mbb27r">
          <path
            d="M160.19 289.63l-2.15-1.14a.94.94 0 00-1-.07l-36.87 21a1.89 1.89 0 00-1 1.65v43.78a.92.92 0 00.6.87l2.16 1.16a1 1 0 00.91 0l36.87-21a1.91 1.91 0 001-1.65v-43.75a.94.94 0 00-.52-.85z"
            style={{
              transformOrigin: "139.94px 322.649px"
            }}
            className="animable"
            id="elofrub2rm2r"
            fill="#fff"
            opacity={0.6}
          />
        </g>
        <g id="eldlpo235avih">
          <path
            d="M119.19 311.05v43.78a.92.92 0 00.6.87l2.16 1.16a1 1 0 01-.47-.82v-43.76a1.85 1.85 0 01.15-.74l-2.25-1.3a1.89 1.89 0 00-.19.81z"
            style={{
              transformOrigin: "120.57px 333.55px"
            }}
            className="animable"
            id="elrzrvxuyldol"
            fill="#7519ff"
            opacity={0.3}
          />
        </g>
        <g id="elyku3yc5758">
          <path
            d="M158.05 288.49a.94.94 0 00-1-.07l-36.87 21a1.84 1.84 0 00-.77.84l2.25 1.3a1.88 1.88 0 01.81-.91l36.87-21a1 1 0 01.88 0z"
            style={{
              transformOrigin: "139.815px 299.934px"
            }}
            className="animable"
            id="el8l1qevcnep8"
            fill="#fff"
            opacity={0.4}
          />
        </g>
        <path
          d="M147.89 307.53a4.11 4.11 0 013.9-.49.43.43 0 00.47 0l.21-.16a24.76 24.76 0 003.23-3.36 14.54 14.54 0 01-2.33 5c.34-.27.67-.52 1-.8s.7-.61 1-.93.66-.66 1-1h.06c-.45.88-.89 1.77-1.37 2.63-.39.69-.84 1.34-1.25 2a1.06 1.06 0 00-.17.51 32.46 32.46 0 01-4 15.33 33.59 33.59 0 01-8.21 10.51 28.68 28.68 0 01-4 2.85 13.72 13.72 0 01-8.55 2.11l-.14-.05A14.23 14.23 0 00130 341a17.73 17.73 0 003.14-2.3 25.28 25.28 0 003.94-4.56c-2.61 1.25-4.36.76-5.33-1.5a9.54 9.54 0 001.11-.55 8.54 8.54 0 001.35-1 8.28 8.28 0 01-1.38.14 3.08 3.08 0 01-1.26-.31 2.81 2.81 0 01-1-.83 4 4 0 01-.61-1.27 13.78 13.78 0 01-.28-1.65c.48-.07.88-.09 1.29-.19a6.65 6.65 0 001.25-.5c-1.37-.41-2.21-1.48-2.41-3.32a9.34 9.34 0 01.73-4.43c3.09 2.38 7 2.42 11.77.05 0-.37-.09-.69-.11-1a11.76 11.76 0 012.8-7.69 10.21 10.21 0 012.89-2.56z"
          style={{
            transformOrigin: "142.585px 322.65px"
          }}
          id="el88btae3jaoi"
          className="animable"
          fill="#fff"
        />
      </g>
      <g
        id="freepik--Megaphone--inject-2"
        className="animable"
        style={{
          transformOrigin: "270.605px 371.983px",
          animation: "1.5s Infinite linear wind",
          animationDelay: "0s"
        }}
      >
        <path
          d="M233.11 407.58l3.43-2a4.3 4.3 0 002.15-3.72V360.7l-7.69 4.6-7.73-4.46v41a4.3 4.3 0 002.15 3.72l3.43 2a4.29 4.29 0 004.26.02z"
          style={{
            transformOrigin: "230.98px 384.418px"
          }}
          id="elvvkivdk5mm8"
          className="animable"
          fill="#7519ff"
        />
        <g id="elajx41yod1wg">
          <path
            d="M238.69 360.7v41.18a4.3 4.3 0 01-2.15 3.72l-3.43 2a4.35 4.35 0 01-2.15.57V365.3z"
            style={{
              transformOrigin: "234.825px 384.435px"
            }}
            className="animable"
            id="elnldo8hr678"
            fill="#fff"
            opacity={0.1}
          />
        </g>
        <g id="elu01btgeouw">
          <path
            d="M231 365.3v42.85a4.35 4.35 0 01-2.15-.57l-3.43-2a4.3 4.3 0 01-2.15-3.72v-41z"
            style={{
              transformOrigin: "227.135px 384.505px"
            }}
            className="animable"
            id="el1f3nly7wq5u"
            fill="#fff"
            opacity={0.3}
          />
        </g>
        <path
          d="M224.72 313.92l-.16-.1c-2.63-1.49-6.26-1.26-10.26 1-8 4.63-14.49 15.85-14.47 25.06 0 4.52 1.6 7.72 4.15 9.25L224 360.54 243.53 325zm-23.31 25a21 21 0 01.14-2.34 21 21 0 00-.14 2.34 14.85 14.85 0 00.16 2.17 14.85 14.85 0 01-.16-2.09z"
          style={{
            transformOrigin: "221.68px 336.708px"
          }}
          id="elw2w38e96uyb"
          className="animable"
          fill="#7519ff"
        />
        <g id="eltyihh0337oh">
          <path
            d="M224.72 313.92l-.16-.1c-2.63-1.49-6.26-1.26-10.26 1-8 4.63-14.49 15.85-14.47 25.06 0 4.52 1.6 7.72 4.15 9.25L224 360.54 243.53 325zm-23.31 25a21 21 0 01.14-2.34 21 21 0 00-.14 2.34 14.85 14.85 0 00.16 2.17 14.85 14.85 0 01-.16-2.09z"
            style={{
              transformOrigin: "221.68px 336.708px"
            }}
            className="animable"
            id="elx9th4x6c4x"
            fill="#fff"
            opacity={0.3}
          />
        </g>
        <path
          d="M245.47 320.34l-.2-.13h-.05c-3.33-1.9-7.93-1.6-13 1.32-10.15 5.87-18.36 20.08-18.33 31.75 0 5.73 2 9.78 5.26 11.72 32.41 23.29 52 58.77 52 58.77l46.49-89c-16.87 3.75-48.64-.36-72.17-14.43z"
          style={{
            transformOrigin: "265.765px 371.391px"
          }}
          id="elc2j21jguxx9"
          className="animable"
          fill="#7519ff"
        />
        <g id="eliiygpo7kwf9">
          <path
            d="M245.47 320.34l-.2-.13h-.05c-3.33-1.9-7.93-1.6-13 1.32-10.15 5.87-18.36 20.08-18.33 31.75 0 5.73 2 9.78 5.26 11.72 32.41 23.29 52 58.77 52 58.77l46.49-89c-16.87 3.75-48.64-.36-72.17-14.43z"
            style={{
              transformOrigin: "265.765px 371.391px"
            }}
            className="animable"
            id="elkebj2iyxcz"
            fill="#fff"
            opacity={0.5}
          />
        </g>
        <path
          d="M273.34 397.25a48.26 48.26 0 00-.36 5.83 38.45 38.45 0 00.39 5.41 38.45 38.45 0 01-.39-5.41 48.26 48.26 0 01.36-5.83z"
          style={{
            transformOrigin: "273.175px 402.87px"
          }}
          id="elagerbx7i7lo"
          className="animable"
          fill="#455a64"
        />
        <path
          d="M332.59 349.49a18.05 18.05 0 00-1.74-1.95l-.12-.1a14.89 14.89 0 00-1.86-1.5c-.34-.24-.7-.46-1.07-.67a15.78 15.78 0 00-4.77-1.76 18.46 18.46 0 00-4-.34 22.32 22.32 0 00-2.88.26 25 25 0 00-3 .66c-.51.15-1 .31-1.55.49a33.17 33.17 0 00-3.19 1.28c-1.08.5-2.18 1.06-3.29 1.7a51 51 0 00-8 5.8c-1 .9-2 1.84-3 2.83s-1.95 2-2.88 3.07c-.47.53-.93 1.07-1.39 1.61a77.08 77.08 0 00-6.28 8.78c-.76 1.24-1.48 2.5-2.17 3.77s-1.35 2.57-2 3.88-1.19 2.62-1.73 3.95a68 68 0 00-3.19 10c-.31 1.34-.57 2.67-.78 4-.11.66-.2 1.32-.28 2a48.26 48.26 0 00-.36 5.83 38.45 38.45 0 00.39 5.41c.17 1.13.39 2.2.65 3.23a27.17 27.17 0 001 2.93 24.15 24.15 0 001.23 2.61 20.41 20.41 0 001.5 2.28 15.71 15.71 0 0010.87 6.19 19 19 0 002.74.13 22.47 22.47 0 002.89-.26 25.28 25.28 0 003-.66c.51-.15 1-.31 1.55-.49a36.29 36.29 0 006.48-3 58.28 58.28 0 0015.31-13.31c.45-.55.9-1.11 1.34-1.67.88-1.13 1.74-2.29 2.56-3.48s1.62-2.4 2.38-3.63c6.46-10.51 10.5-22.61 10.47-33.44a33.16 33.16 0 00-1.48-10.14 21 21 0 00-3.35-6.29z"
          style={{
            transformOrigin: "305.241px 384.515px"
          }}
          id="el2vg0dq0ttd2"
          className="animable"
          fill="#7519ff"
        />
        <g id="elxbt04o296c">
          <path
            d="M332.59 349.49a18.05 18.05 0 00-1.74-1.95l-.12-.1a14.89 14.89 0 00-1.86-1.5c-.34-.24-.7-.46-1.07-.67a15.78 15.78 0 00-4.77-1.76 18.46 18.46 0 00-4-.34 22.32 22.32 0 00-2.88.26 25 25 0 00-3 .66c-.51.15-1 .31-1.55.49a33.17 33.17 0 00-3.19 1.28c-1.08.5-2.18 1.06-3.29 1.7a51 51 0 00-8 5.8c-1 .9-2 1.84-3 2.83s-1.95 2-2.88 3.07c-.47.53-.93 1.07-1.39 1.61a77.08 77.08 0 00-6.28 8.78c-.76 1.24-1.48 2.5-2.17 3.77s-1.35 2.57-2 3.88-1.19 2.62-1.73 3.95a68 68 0 00-3.19 10c-.31 1.34-.57 2.67-.78 4-.11.66-.2 1.32-.28 2a48.26 48.26 0 00-.36 5.83 38.45 38.45 0 00.39 5.41c.17 1.13.39 2.2.65 3.23a27.17 27.17 0 001 2.93 24.15 24.15 0 001.23 2.61 20.41 20.41 0 001.5 2.28 15.71 15.71 0 0010.87 6.19 19 19 0 002.74.13 22.47 22.47 0 002.89-.26 25.28 25.28 0 003-.66c.51-.15 1-.31 1.55-.49a36.29 36.29 0 006.48-3 58.28 58.28 0 0015.31-13.31c.45-.55.9-1.11 1.34-1.67.88-1.13 1.74-2.29 2.56-3.48s1.62-2.4 2.38-3.63c6.46-10.51 10.5-22.61 10.47-33.44a33.16 33.16 0 00-1.48-10.14 21 21 0 00-3.35-6.29z"
            style={{
              transformOrigin: "305.241px 384.515px"
            }}
            className="animable"
            id="el18xlkle91u1"
            opacity={0.5}
          />
        </g>
        <path
          style={{
            transformOrigin: "285.91px 374.12px"
          }}
          id="el79306whwu8"
          className="animable"
          fill="#7519ff"
          d="M286.09 355.96L302.88 365.97 287.41 392.28 268.94 382.33 286.09 355.96z"
        />
        <g id="elpo504fbc4b">
          <path
            style={{
              transformOrigin: "285.91px 374.12px"
            }}
            className="animable"
            id="el245c84z283q"
            opacity={0.3}
            d="M286.09 355.96L302.88 365.97 287.41 392.28 268.94 382.33 286.09 355.96z"
          />
        </g>
        <path
          d="M304.13 367.17a5.34 5.34 0 00-.59-.66 4.31 4.31 0 00-.62-.51l-.36-.23a5.27 5.27 0 00-1.61-.59 6.13 6.13 0 00-1.37-.12 8.33 8.33 0 00-1 .09 8.22 8.22 0 00-1 .23l-.52.16a10.54 10.54 0 00-1.08.43c-.36.17-.73.36-1.11.58a17.54 17.54 0 00-2.7 2c-.35.3-.69.62-1 .95s-.65.68-1 1l-.47.54a27.29 27.29 0 00-2.11 3c-.26.42-.5.84-.74 1.27s-.45.87-.66 1.31-.4.88-.58 1.33a22.78 22.78 0 00-1.07 3.38c-.11.45-.2.9-.27 1.34l-.09.67a16.53 16.53 0 00-.12 2 11.72 11.72 0 00.13 1.82c.06.38.13.74.22 1.09a8.84 8.84 0 00.32 1 7.89 7.89 0 00.41.88 8.56 8.56 0 00.51.77 5.33 5.33 0 003.66 2.08 7 7 0 00.92.05 8.3 8.3 0 001-.09 10.41 10.41 0 001-.22l.52-.17a12 12 0 002.18-1 19.53 19.53 0 005.16-4.49l.46-.56c.29-.38.58-.77.86-1.17s.54-.81.8-1.23a22.27 22.27 0 003.53-11.27 11.1 11.1 0 00-.5-3.42 7 7 0 00-1.11-2.24z"
          style={{
            transformOrigin: "294.9px 379.044px"
          }}
          id="elyynzbqugwif"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M331.12 340.59l-.4-.23c.14.07.26.16.4.24z"
          style={{
            transformOrigin: "330.92px 340.48px"
          }}
          id="elb73f0ljj6wq"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M268.94 405.41c-.06-23 16.1-51 36.09-62.5 10-5.76 19-6.33 25.6-2.61l-7.63-4.5c-6.58-3.77-15.66-3.21-25.67 2.57-20 11.54-36.15 39.53-36.08 62.5 0 11.09 3.84 19 10 22.88l.43.25.39.23 7.26 4.27c-6.41-3.83-10.33-11.8-10.39-23.09z"
          style={{
            transformOrigin: "295.94px 380.954px"
          }}
          id="el51hrerszm2x"
          className="animable"
          fill="#7519ff"
        />
        <g id="elpzdrn2uwqj">
          <path
            d="M268.94 405.41c-.06-23 16.1-51 36.09-62.5 10-5.76 19-6.33 25.6-2.61l-7.63-4.5c-6.58-3.77-15.66-3.21-25.67 2.57-20 11.54-36.15 39.53-36.08 62.5 0 11.09 3.84 19 10 22.88l.43.25.39.23 7.26 4.27c-6.41-3.83-10.33-11.8-10.39-23.09z"
            style={{
              transformOrigin: "295.94px 380.954px"
            }}
            className="animable"
            id="ele19imy8kiz5"
            fill="#fff"
            opacity={0.3}
          />
        </g>
        <path
          d="M331.12 340.6c-.14-.08-.26-.17-.4-.24l-.09-.06c-6.57-3.72-15.62-3.15-25.6 2.61-20 11.55-36.15 39.53-36.09 62.5 0 11.29 4 19.26 10.35 23.09 6.59 4 15.8 3.49 26-2.38 20-11.54 36.15-39.53 36.09-62.51-.06-11.21-3.96-19.15-10.26-23.01zm-4.28 58.78c-.76 1.23-1.55 2.45-2.38 3.63s-1.68 2.35-2.56 3.48c-.44.56-.89 1.12-1.34 1.67a58.28 58.28 0 01-15.31 13.31 36.29 36.29 0 01-6.48 3c-.52.18-1 .34-1.55.49a25.28 25.28 0 01-3 .66 22.47 22.47 0 01-2.89.26 19 19 0 01-2.74-.13 15.71 15.71 0 01-10.87-6.19 20.41 20.41 0 01-1.5-2.28 24.15 24.15 0 01-1.23-2.61 27.17 27.17 0 01-1-2.93c-.26-1-.48-2.1-.65-3.23a38.45 38.45 0 01-.39-5.41 48.26 48.26 0 01.36-5.83 57.542 57.542 0 011.06-6 68 68 0 013.19-10c.54-1.33 1.11-2.65 1.73-3.95s1.27-2.6 2-3.88 1.41-2.53 2.17-3.77a77.08 77.08 0 016.28-8.78c.46-.54.92-1.08 1.39-1.61.93-1.06 1.9-2.09 2.88-3.07s2-1.93 3-2.83a51 51 0 018-5.8c1.11-.64 2.21-1.2 3.29-1.7a33.17 33.17 0 013.19-1.28c.52-.18 1-.34 1.55-.49a25 25 0 013-.66 22.32 22.32 0 012.88-.26 18.46 18.46 0 014 .34 15.78 15.78 0 014.77 1.76c.37.21.73.43 1.07.67a14.89 14.89 0 011.86 1.5l.12.1a18.05 18.05 0 011.74 1.95 21 21 0 013.24 6.31 33.16 33.16 0 011.48 10.14c.14 10.81-3.9 22.91-10.36 33.42z"
          style={{
            transformOrigin: "305.16px 384.52px"
          }}
          id="elb06bo1m74d"
          className="animable"
          fill="#7519ff"
        />
      </g>
      <g
        id="freepik--Character--inject-2"
        className="animable"
        style={{
          transformOrigin: "378.822px 271.584px"
        }}
      >
        <path
          d="M393.37 188.64l1.42.22a18.66 18.66 0 0114.91 12.41l9.92 28.92-11.72 5.38-9.85-21.74z"
          style={{
            transformOrigin: "406.495px 212.105px"
          }}
          id="elb69tcwsi4rf"
          className="animable"
          fill="#455a64"
        />
        <g id="el0vhm18c1tlc">
          <path
            d="M393.37 188.64l1.42.22a18.66 18.66 0 0114.91 12.41l9.92 28.92-11.72 5.38-9.85-21.74z"
            style={{
              transformOrigin: "406.495px 212.105px"
            }}
            className="animable"
            id="elcgtri4xd87l"
            fill="#fff"
            opacity={0.1}
          />
        </g>
        <path
          d="M413.24 384.4a9.72 9.72 0 01-7.28 2.67 22.06 22.06 0 01-7.7-1.94c-2.46-1-5.55-3.85-8.21-3.79a65.19 65.19 0 01-7.79-.53 40.36 40.36 0 011.11-10.55l12.42-.33c3.36 4.6 9.88 8.42 14.82 9.85a4.35 4.35 0 013.07 3.27v.08a5.54 5.54 0 01-.44 1.27z"
          style={{
            transformOrigin: "397.963px 378.508px"
          }}
          id="elg89yhywsfip"
          className="animable"
          fill="#455a64"
        />
        <path
          d="M390.05 381.34c2.66-.06 5.75 2.78 8.21 3.79a22.06 22.06 0 007.7 1.94 9.72 9.72 0 007.28-2.67 5.54 5.54 0 00.43-1.27v-.08a4 4 0 010 1.66 5.56 5.56 0 01-.43 1.26 9.69 9.69 0 01-7.28 2.68 22.06 22.06 0 01-7.7-1.94c-2.46-1-5.55-3.85-8.21-3.79a57.93 57.93 0 01-7.67-.45c-.06-.55-.1-1.11-.12-1.66a65.19 65.19 0 007.79.53z"
          style={{
            transformOrigin: "398.009px 384.738px"
          }}
          id="eldiw84iccio8"
          className="animable"
          fill="#263238"
        />
        <path
          d="M355 385.56a18.33 18.33 0 00.37 3.77 8.75 8.75 0 003.45 5.5 11.1 11.1 0 005.47 1.46 12.08 12.08 0 005-.52 5.68 5.68 0 002.94-2.4.2.2 0 000 .07 5.25 5.25 0 01-3.38 3.51 12.08 12.08 0 01-5 .52 11.14 11.14 0 01-5.48-1.46 8.74 8.74 0 01-3.44-5.5 17.6 17.6 0 01-.38-3.77 6.34 6.34 0 00-2-4.6c-.31-.3-.61-.6-.88-.91a2.29 2.29 0 01-.54-1.65c0-.23.05-.46.08-.68.45.53 1.53 1.8 1.8 2.05a6.4 6.4 0 011.99 4.61z"
          style={{
            transformOrigin: "361.677px 388.219px"
          }}
          id="elmez1vw0ozm"
          className="animable"
          fill="#263238"
        />
        <path
          d="M364.47 372.43a13.74 13.74 0 001 7.64 29.23 29.23 0 004.05 6.65 19.67 19.67 0 012.12 3.09 5.61 5.61 0 01.6 3.56 5.68 5.68 0 01-2.94 2.4 12.08 12.08 0 01-5 .52 11.1 11.1 0 01-5.47-1.46 8.75 8.75 0 01-3.45-5.5 18.33 18.33 0 01-.37-3.77 6.4 6.4 0 00-2-4.61c-.27-.25-1.35-1.52-1.8-2.05a13.78 13.78 0 011.86-5.34z"
          style={{
            transformOrigin: "361.761px 384.394px"
          }}
          id="elsiyuznw0fgc"
          className="animable"
          fill="#455a64"
        />
        <path
          d="M401.18 249.67l-1 70.89-3.34 49.62c-8.47 5.35-14.6 1-14.6 1-3.21-23.5-3.65-38-1.9-47.74l-1.61-38.26-4.82 39.72-8.1 48.68c-8.69 5.63-14.82 0-14.82 0 0-18-1.6-38.17 3.36-51.16a298.8 298.8 0 012.34-72z"
          style={{
            transformOrigin: "375.962px 312.876px"
          }}
          id="el9d3wdn20h5g"
          className="animable"
          fill="#7519ff"
        />
        <g id="elop4i39cq1">
          <path
            d="M401.18 249.67l-1 70.89-3.34 49.62c-8.47 5.35-14.6 1-14.6 1-3.21-23.5-3.65-38-1.9-47.74l-1.61-38.26-4.82 39.72-8.1 48.68c-8.69 5.63-14.82 0-14.82 0 0-18-1.6-38.17 3.36-51.16a298.8 298.8 0 012.34-72z"
            style={{
              transformOrigin: "375.962px 312.876px"
            }}
            className="animable"
            id="el6oil6ox63sc"
            opacity={0.3}
          />
        </g>
        <g id="elg3sp8b6tflp">
          <path
            d="M401.18 249.67l-1 70.89-3.34 49.62c-8.47 5.35-14.6 1-14.6 1-3.21-23.5-3.65-38-1.9-47.74l-1.61-38.26-4.82 39.72-8.1 48.68c-8.69 5.63-14.82 0-14.82 0 0-18-1.6-38.17 3.36-51.16a298.8 298.8 0 012.34-72z"
            style={{
              transformOrigin: "375.962px 312.876px"
            }}
            className="animable"
            id="elk4lkym1talc"
            fill="#fff"
            opacity={0.1}
          />
        </g>
        <g id="el8ifchku5qe3">
          <path
            d="M380.47 270.55l-1.78 14.6.76 17.92 3.26-29.45c7.55-3 8.54-7.12 8.54-7.12a23.2 23.2 0 01-10.78 4.05z"
            style={{
              transformOrigin: "384.97px 284.785px"
            }}
            className="animable"
            id="el44ujivcls8u"
            opacity={0.3}
          />
        </g>
        <path
          d="M385.08 187.77l8.29.87c6.79 6 10.29 18.55 8.83 32.42a245.75 245.75 0 00-1 28.61c-18.4 13.44-41.61 3.65-44.53.73l1.36-17a11.68 11.68 0 00-.93-5.6c-1.74-4-4.84-12-5.25-18.13a32.06 32.06 0 013.36-15.84l12-5.26z"
          style={{
            transformOrigin: "377.172px 222.176px"
          }}
          id="elekfazzitbus"
          className="animable"
          fill="#455a64"
        />
        <g id="elwicyamlflpm">
          <g
            style={{
              transformOrigin: "377.172px 222.176px"
            }}
            className="animable"
            id="elwstc4ks9zu"
            opacity={0.1}
          >
            <path
              d="M385.08 187.77l8.29.87c6.79 6 10.29 18.55 8.83 32.42a245.75 245.75 0 00-1 28.61c-18.4 13.44-41.61 3.65-44.53.73l1.36-17a11.68 11.68 0 00-.93-5.6c-1.74-4-4.84-12-5.25-18.13a32.06 32.06 0 013.36-15.84l12-5.26z"
              id="elotuhsrwc3ia"
              className="animable"
              style={{
                transformOrigin: "377.172px 222.176px"
              }}
            />
          </g>
        </g>
        <path
          d="M387.38 199.85a89.66 89.66 0 016.83 32.38l-3.42 7.33-5.34-7a277.42 277.42 0 00-.65-32.23z"
          style={{
            transformOrigin: "389.505px 219.705px"
          }}
          id="el3jy230vpboj"
          className="animable"
          fill="#455a64"
        />
        <path
          d="M387.67 193.83a24.72 24.72 0 01-.29 6 4.8 4.8 0 01-2.58.47 21.1 21.1 0 01-4.14-5l4.14-1.49z"
          style={{
            transformOrigin: "384.198px 197.066px"
          }}
          id="elqzowken4ia8"
          className="animable"
          fill="#455a64"
        />
        <g id="elc8isifzplrv">
          <g
            style={{
              transformOrigin: "387.435px 216.685px"
            }}
            className="animable"
            id="elsaxvgnlj3ab"
            opacity={0.6}
          >
            <path
              d="M387.38 199.85a89.66 89.66 0 016.83 32.38l-3.42 7.33-5.34-7a277.42 277.42 0 00-.65-32.23z"
              style={{
                transformOrigin: "389.505px 219.705px"
              }}
              id="elg8nx548hgsb"
              className="animable"
              fill="#fff"
            />
            <path
              d="M387.67 193.83a24.72 24.72 0 01-.29 6 4.8 4.8 0 01-2.58.47 21.1 21.1 0 01-4.14-5l4.14-1.49z"
              style={{
                transformOrigin: "384.198px 197.066px"
              }}
              id="elkhhzysusk1"
              className="animable"
              fill="#fff"
            />
          </g>
        </g>
        <path
          d="M384.8 193.83c-3.56 1.31-6.74 7.66-6.74 7.66-9.37-8-10.83-12.92-10.83-12.92a18.86 18.86 0 014.54-4.8z"
          style={{
            transformOrigin: "376.015px 192.63px"
          }}
          id="elspdsd12nyg9"
          className="animable"
          fill="#455a64"
        />
        <path
          d="M384.8 193.83c2.73.07 5.14 3.87 5.14 3.87.43-6.65-5.14-11.76-5.14-11.76z"
          style={{
            transformOrigin: "387.382px 191.82px"
          }}
          id="elj01xvdu7qhs"
          className="animable"
          fill="#455a64"
        />
        <g id="ell5nr4209v3n">
          <g
            style={{
              transformOrigin: "378.597px 192.63px"
            }}
            className="animable"
            id="el0v6kt6jwcpze"
            opacity={0.35}
          >
            <g id="elj00ire5b0cs">
              <path
                d="M384.8 193.83c-3.56 1.31-6.74 7.66-6.74 7.66-9.37-8-10.83-12.92-10.83-12.92a18.86 18.86 0 014.54-4.8z"
                style={{
                  transformOrigin: "376.015px 192.63px"
                }}
                className="animable"
                id="el6ij9ehmlhja"
                fill="#fff"
                opacity={0.1}
              />
            </g>
            <g id="elxvxnl8842u">
              <path
                d="M384.8 193.83c2.73.07 5.14 3.87 5.14 3.87.43-6.65-5.14-11.76-5.14-11.76z"
                style={{
                  transformOrigin: "387.382px 191.82px"
                }}
                className="animable"
                id="elpbkx7fcw9yk"
                fill="#fff"
                opacity={0.1}
              />
            </g>
          </g>
        </g>
        <path
          d="M367.23 157a7.37 7.37 0 014.72-2.55l24.66 2.33c2.85 6.11.58 10.16.58 10.16.38 9.32.7 15.25-3.34 17.22a18.65 18.65 0 01-9 1.27 21.28 21.28 0 01-8.26-2.23l-.13-.07a7.06 7.06 0 01-3.54-4.71 39.58 39.58 0 01-.85-5.52 22 22 0 00-.34-3.15 5.62 5.62 0 00-1.38-2.79 3.18 3.18 0 00-2.85-1 2.18 2.18 0 00-.5.2c-.72-4.16-.92-7.72.23-9.16z"
          style={{
            transformOrigin: "382.23px 169.992px"
          }}
          id="elbyrtasm8rra"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M376.26 157.7a8.73 8.73 0 01-.33 5.24 38.75 38.75 0 00-1.56 5.09 8.32 8.32 0 00.68 5.14 6 6 0 003.93 3.19c2.64.57 5.23-1 7.89-1.52a10.92 10.92 0 017.43 1.33 1.28 1.28 0 001.94-1c.31-5.37.31-13.78-.48-17.74z"
          style={{
            transformOrigin: "385.33px 166.956px"
          }}
          id="ellf1ehx0sxm"
          className="animable"
          fill="#ffa8a7"
        />
        <path
          style={{
            transformOrigin: "373.745px 181.685px"
          }}
          id="el7eifd2rv47w"
          className="animable"
          fill="#ffa8a7"
          d="M373.74 181.69L373.74 181.68 373.75 181.69 373.74 181.69z"
        />
        <path
          d="M371.59 175.57l.11 4.78a7.48 7.48 0 01-2.3-3.61 3.32 3.32 0 002.19-1.17z"
          style={{
            transformOrigin: "370.55px 177.96px"
          }}
          id="el9dlv9yih7j"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M395.35 152.84l3.06-2.16a6.66 6.66 0 01-1.17 6.12 9.71 9.71 0 01-5.41 3.23 20.18 20.18 0 01-6.35.29 45.42 45.42 0 01-9.18-1.7 7.22 7.22 0 01-3.6-1.92 4.88 4.88 0 01-.19-5.7 7.11 7.11 0 015.07-3 19.08 19.08 0 016.1.49 18.23 18.23 0 006.1.44 6.34 6.34 0 004.92-3.3c1.73 2.86.65 7.21.65 7.21z"
          style={{
            transformOrigin: "385.193px 153.039px"
          }}
          id="elgjkbctkvwra"
          className="animable"
          fill="#37474f"
        />
        <path
          style={{
            transformOrigin: "389.86px 170.725px"
          }}
          id="el23m9s1w2onu"
          className="animable"
          fill="#f28f8f"
          d="M387.61 167.22L392.11 172.75 387.98 174.23 387.61 167.22z"
        />
        <path
          d="M384.2 177l3.48 1.09a2.12 2.12 0 01-2.34 1.09 2 2 0 01-1.14-2.18z"
          style={{
            transformOrigin: "385.924px 178.115px"
          }}
          id="elas2u3xufowr"
          className="animable"
          fill="#f28f8f"
        />
        <g id="elsekpu4hjwt">
          <path
            d="M384.2 177l3.48 1.09a2.12 2.12 0 01-2.34 1.09 2 2 0 01-1.14-2.18z"
            style={{
              transformOrigin: "385.924px 178.115px"
            }}
            className="animable"
            id="el7pc3xb3si6c"
            opacity={0.3}
          />
        </g>
        <path
          d="M386.47 179.08a1.91 1.91 0 01-1.13.07 1.76 1.76 0 01-1.13-1.59 2 2 0 011.34.25 2.06 2.06 0 01.92 1.27z"
          style={{
            transformOrigin: "385.34px 178.369px"
          }}
          id="ela6gs8fswcx"
          className="animable"
          fill="#f28f8f"
        />
        <path
          d="M381.49 168.43a1.24 1.24 0 101.17-1.32 1.26 1.26 0 00-1.17 1.32z"
          style={{
            transformOrigin: "382.728px 168.348px"
          }}
          id="elc330jg58v4j"
          className="animable"
          fill="#263238"
        />
        <path
          d="M391.83 167.46a1.23 1.23 0 101.16-1.32 1.24 1.24 0 00-1.16 1.32z"
          style={{
            transformOrigin: "393.057px 167.368px"
          }}
          id="el5zsvabubslt"
          className="animable"
          fill="#263238"
        />
        <path
          d="M392.76 162.41l2.84 1.77a1.8 1.8 0 00-.64-1.93 2.07 2.07 0 00-2.2.16z"
          style={{
            transformOrigin: "394.215px 163.094px"
          }}
          id="el15xmsf8e8v5"
          className="animable"
          fill="#263238"
        />
        <path
          d="M382.19 162.84l-2.63 2.07a1.81 1.81 0 01.43-2 2.05 2.05 0 012.2-.07z"
          style={{
            transformOrigin: "380.807px 163.732px"
          }}
          id="elh44226sup99"
          className="animable"
          fill="#263238"
        />
        <path
          d="M365.28 168a3.4 3.4 0 011.71-1.81 2.18 2.18 0 01.5-.2 3.18 3.18 0 012.85 1 5.62 5.62 0 011.38 2.79 22 22 0 01.34 3.15 40 40 0 00.88 5.65 7 7 0 003.52 4.58l.12.07a21.28 21.28 0 008.26 2.23v8.41l-11.66-6.9a2.83 2.83 0 01-1.38-2.38c0-1.35-.06-2.83-.09-4.2l-.11-4.78a3.32 3.32 0 01-2.19 1.17 2.93 2.93 0 01-1.15-.06 4.35 4.35 0 01-2.57-2.23 8.33 8.33 0 01-.82-3.39 7 7 0 01.41-3.1z"
          style={{
            transformOrigin: "374.84px 179.91px"
          }}
          id="elsroxg615m5"
          className="animable"
          fill="#ffa8a7"
        />
        <g id="el3uue4effxfq">
          <path
            d="M374.92 183a7.85 7.85 0 01-1.44-3 7 7 0 003 3.13l.12.07a21.28 21.28 0 008.26 2.23v2.14a20.81 20.81 0 01-4.88-1 10.93 10.93 0 01-5.06-3.57z"
            style={{
              transformOrigin: "379.17px 183.785px"
            }}
            className="animable"
            id="el0lldo3qjhtgg"
            opacity={0.2}
          />
        </g>
        <path
          d="M327 223.76l-4.4-2.18a7.75 7.75 0 01-3.28-3.06l-1.32-2.36a9.66 9.66 0 00-4-3.75 33.09 33.09 0 01-7-4.64 3.64 3.64 0 00.57 4.57 25 25 0 002.86 2.29l-2.11.54a7.51 7.51 0 01-4.59-.28l-6-2.35a1.05 1.05 0 00-1.41 1.16 24 24 0 003 7.56 9 9 0 006.12 4.3c3.08.58 6.06 1 7.75 1.28a9.41 9.41 0 012.79.86l6.46 3.21z"
          style={{
            transformOrigin: "311.652px 219.34px"
          }}
          id="eln2h5x0hef3"
          className="animable"
          fill="#ffa8a7"
        />
        <path
          d="M331.2 230.53a11 11 0 00.77-5.73l8.25 3.92a170.19 170.19 0 003.5-18.28c1-8.32.77-13.91 11.47-16.61 0 0 7.21 11.89 2.08 22.41 0 7.75-2.27 26.17-9.93 29.13-6.35 2.45-10.83-3.59-20.51-9.84a11 11 0 004.37-5z"
          style={{
            transformOrigin: "342.971px 219.879px"
          }}
          id="el6jydtdyui7q"
          className="animable"
          fill="#455a64"
        />
        <g id="el3jorcytu4c6">
          <path
            d="M331.2 230.53a11 11 0 00.77-5.73l8.25 3.92a170.19 170.19 0 003.5-18.28c1-8.32.77-13.91 11.47-16.61 0 0 7.21 11.89 2.08 22.41 0 7.75-2.27 26.17-9.93 29.13-6.35 2.45-10.83-3.59-20.51-9.84a11 11 0 004.37-5z"
            style={{
              transformOrigin: "342.971px 219.879px"
            }}
            className="animable"
            id="elnh97g7mwxed"
            fill="#fff"
            opacity={0.1}
          />
        </g>
        <g id="elw9njl57lvx">
          <path
            d="M345.77 234.2c-.29-1.24-2.34-4.09-5.55-5.48l.61-2.54c1.66.82 5.76 3.34 4.94 8.02z"
            style={{
              transformOrigin: "343.049px 230.19px"
            }}
            className="animable"
            id="el8277bcwhnft"
            opacity={0.2}
          />
        </g>
        <path
          d="M324.37 222.11l7.6 2.69a10.78 10.78 0 01-5.14 10.73 56.24 56.24 0 01-6.3-5s3.29-1.19 3.84-8.42z"
          style={{
            transformOrigin: "326.301px 228.82px"
          }}
          id="el5gsifdnpuds"
          className="animable"
          fill="#455a64"
        />
        <g id="eliiz8sipxdtq">
          <path
            d="M413.9 213.52l-5.73 15.55.41-4.16a19 19 0 015.32-11.39z"
            style={{
              transformOrigin: "411.035px 221.295px"
            }}
            className="animable"
            id="elsafj4yju7n"
            opacity={0.2}
          />
        </g>
        <path
          d="M421.8 220.7l-.19-6-3-4.52a2 2 0 00-3.63.42l-1.38 3.73a7.3 7.3 0 002.34 4.61 7.43 7.43 0 005.86 1.76z"
          style={{
            transformOrigin: "417.7px 214.982px"
          }}
          id="elcqcvled4fde"
          className="animable"
          fill="#455a64"
        />
        <path
          d="M415.94 219a7.3 7.3 0 01-2.34-4.61L407.79 230a7.07 7.07 0 00.11 5.58 7.06 7.06 0 009.66 3.35l.77-.4a7.05 7.05 0 003.82-6.49l-.35-11.33a7.43 7.43 0 01-5.86-1.71z"
          style={{
            transformOrigin: "414.712px 227.052px"
          }}
          id="el4ip30gyn8us"
          className="animable"
          fill="#455a64"
        />
        <g id="elf8d169v3ma">
          <path
            d="M415.94 219a7.3 7.3 0 01-2.34-4.61L407.79 230a7.07 7.07 0 00.11 5.58 7.06 7.06 0 009.66 3.35l.77-.4a7.05 7.05 0 003.82-6.49l-.35-11.33a7.43 7.43 0 01-5.86-1.71z"
            style={{
              transformOrigin: "414.712px 227.052px"
            }}
            className="animable"
            id="el6s7miry0loi"
            fill="#fff"
            opacity={0.1}
          />
        </g>
        <path
          d="M422.66 224.86l-1.06-.61a1.36 1.36 0 01-.66-1.15v-4.78l2.39 1.42 2.38-1.38v4.74a1.33 1.33 0 01-.66 1.15l-1.06.61a1.32 1.32 0 01-1.33 0z"
          style={{
            transformOrigin: "423.325px 221.68px"
          }}
          id="elzry8dg4hnvb"
          className="animable"
          fill="#7519ff"
        />
        <g id="elltss8deno5d">
          <path
            d="M422.66 224.86l-1.06-.61a1.36 1.36 0 01-.66-1.15v-4.78l2.39 1.42 2.38-1.38v4.74a1.33 1.33 0 01-.66 1.15l-1.06.61a1.32 1.32 0 01-1.33 0z"
            style={{
              transformOrigin: "423.325px 221.68px"
            }}
            className="animable"
            id="elporqtqckft"
            fill="#fff"
            opacity={0.3}
          />
        </g>
        <path
          d="M421.62 203.9l2.6-.46 4.79 3.42a1.81 1.81 0 01.77 1.49v10a1.83 1.83 0 01-1.09 1.67l-3.35 1.48a1.86 1.86 0 01-2-.34l-2.83-2.65a1.85 1.85 0 01-.6-1.36v-.91a7.36 7.36 0 01-2.62-2.86 6.35 6.35 0 01-.89-3.48 6.37 6.37 0 015.22-6z"
          style={{
            transformOrigin: "423.088px 212.546px"
          }}
          id="el807wddsf655"
          className="animable"
          fill="#ffa8a7"
        />
        <path
          d="M421.78 208.25v5.55a1.67 1.67 0 001.67 1.67 1.66 1.66 0 001.66-1.67v-5.93l.69 1.66v4.55a2.35 2.35 0 01-2.35 2.35 2.36 2.36 0 01-2.36-2.35v-2.29a3.62 3.62 0 01-2.5 2.23c2.5-1.92 3.19-5.77 3.19-5.77z"
          style={{
            transformOrigin: "422.195px 212.15px"
          }}
          id="elgvebm6wyr0g"
          className="animable"
          fill="#f28f8f"
        />
        <path
          d="M418.2 184.86h-.06a3.79 3.79 0 00-3.79.39 11.76 11.76 0 00-5.35 9.26 3.8 3.8 0 001.53 3.42l7.47 4.17 7.2-13.14zm-8.61 9.26a8.43 8.43 0 01.05-.86 8.43 8.43 0 00-.05.86 5.1 5.1 0 00.06.8 5.1 5.1 0 01-.06-.8z"
          style={{
            transformOrigin: "417.091px 193.312px"
          }}
          id="el3oej7dg52m"
          className="animable"
          fill="#7519ff"
        />
        <g id="elq5hc5mcdtyl">
          <path
            d="M418.2 184.86h-.06a3.79 3.79 0 00-3.79.39 11.76 11.76 0 00-5.35 9.26 3.8 3.8 0 001.53 3.42l7.47 4.17 7.2-13.14zm-8.61 9.26a8.43 8.43 0 01.05-.86 8.43 8.43 0 00-.05.86 5.1 5.1 0 00.06.8 5.1 5.1 0 01-.06-.8z"
            style={{
              transformOrigin: "417.091px 193.312px"
            }}
            className="animable"
            id="eldz30y9ilnwp"
            fill="#fff"
            opacity={0.3}
          />
        </g>
        <path
          d="M425.87 187.24l-.07-.05c-1.23-.7-2.93-.59-4.81.49a14.94 14.94 0 00-6.77 11.73c0 2.12.75 3.62 1.94 4.33 12 8.61 19.24 21.72 19.24 21.72l17.18-32.87c-6.27 1.41-18.02-.15-26.71-5.35z"
          style={{
            transformOrigin: "433.4px 206.104px"
          }}
          id="elhhyalebuce"
          className="animable"
          fill="#7519ff"
        />
        <g id="eld3s9xw4219f">
          <path
            d="M425.87 187.24l-.07-.05c-1.23-.7-2.93-.59-4.81.49a14.94 14.94 0 00-6.77 11.73c0 2.12.75 3.62 1.94 4.33 12 8.61 19.24 21.72 19.24 21.72l17.18-32.87c-6.27 1.41-18.02-.15-26.71-5.35z"
            style={{
              transformOrigin: "433.4px 206.104px"
            }}
            className="animable"
            id="elyjbgthuvox"
            fill="#fff"
            opacity={0.5}
          />
        </g>
        <path
          d="M436.17 215.66a17.58 17.58 0 00-.13 2.15 14 14 0 00.14 2 14 14 0 01-.14-2 17.58 17.58 0 01.13-2.15z"
          style={{
            transformOrigin: "436.11px 217.735px"
          }}
          id="eliwnknvdp9r"
          className="animable"
          fill="#455a64"
        />
        <path
          d="M458.07 198a6.4 6.4 0 00-.65-.72 5.94 5.94 0 00-.69-.55l-.39-.25a5.86 5.86 0 00-1.77-.65 6.56 6.56 0 00-1.49-.13 7.89 7.89 0 00-1.07.1 9.88 9.88 0 00-1.12.25l-.57.17a12.72 12.72 0 00-1.18.48 11.66 11.66 0 00-1.21.63 18.54 18.54 0 00-3 2.14c-.38.33-.75.68-1.12 1.05s-.71.74-1.06 1.13c-.17.2-.34.39-.51.6a28 28 0 00-2.32 3.24c-.28.46-.55.92-.81 1.39s-.49 1-.72 1.44-.44 1-.64 1.45a26.13 26.13 0 00-1.18 3.71c-.11.49-.21 1-.29 1.48 0 .24-.07.49-.1.73a17.58 17.58 0 00-.13 2.15 14 14 0 00.14 2 10.17 10.17 0 00.59 2.28 8.53 8.53 0 00.46 1 7.29 7.29 0 00.55.85 5.85 5.85 0 004 2.29 6.72 6.72 0 001 0 8 8 0 001.07-.09 9.73 9.73 0 001.11-.25c.19 0 .39-.11.58-.18a13.6 13.6 0 002.39-1.1 21.35 21.35 0 005.66-4.92c.17-.2.33-.41.5-.62.32-.41.64-.84.94-1.28s.6-.89.88-1.34a24.47 24.47 0 003.87-12.36 12.19 12.19 0 00-.55-3.75 7.63 7.63 0 00-1.17-2.37z"
          style={{
            transformOrigin: "447.92px 210.988px"
          }}
          id="el0qkalp7oow5"
          className="animable"
          fill="#7519ff"
        />
        <g id="elc83enyt6r8">
          <path
            d="M458.07 198a6.4 6.4 0 00-.65-.72 5.94 5.94 0 00-.69-.55l-.39-.25a5.86 5.86 0 00-1.77-.65 6.56 6.56 0 00-1.49-.13 7.89 7.89 0 00-1.07.1 9.88 9.88 0 00-1.12.25l-.57.17a12.72 12.72 0 00-1.18.48 11.66 11.66 0 00-1.21.63 18.54 18.54 0 00-3 2.14c-.38.33-.75.68-1.12 1.05s-.71.74-1.06 1.13c-.17.2-.34.39-.51.6a28 28 0 00-2.32 3.24c-.28.46-.55.92-.81 1.39s-.49 1-.72 1.44-.44 1-.64 1.45a26.13 26.13 0 00-1.18 3.71c-.11.49-.21 1-.29 1.48 0 .24-.07.49-.1.73a17.58 17.58 0 00-.13 2.15 14 14 0 00.14 2 10.17 10.17 0 00.59 2.28 8.53 8.53 0 00.46 1 7.29 7.29 0 00.55.85 5.85 5.85 0 004 2.29 6.72 6.72 0 001 0 8 8 0 001.07-.09 9.73 9.73 0 001.11-.25c.19 0 .39-.11.58-.18a13.6 13.6 0 002.39-1.1 21.35 21.35 0 005.66-4.92c.17-.2.33-.41.5-.62.32-.41.64-.84.94-1.28s.6-.89.88-1.34a24.47 24.47 0 003.87-12.36 12.19 12.19 0 00-.55-3.75 7.63 7.63 0 00-1.17-2.37z"
            style={{
              transformOrigin: "447.92px 210.988px"
            }}
            className="animable"
            id="el9rmvk6ytkkk"
            opacity={0.5}
          />
        </g>
        <path
          style={{
            transformOrigin: "440.815px 207.11px"
          }}
          id="el8qqjqvlelao"
          className="animable"
          fill="#7519ff"
          d="M440.88 200.4L447.08 204.1 441.37 213.82 434.55 210.15 440.88 200.4z"
        />
        <g id="elhawfbrz301q">
          <path
            style={{
              transformOrigin: "440.815px 207.11px"
            }}
            className="animable"
            id="el9w5el5faerl"
            opacity={0.3}
            d="M440.88 200.4L447.08 204.1 441.37 213.82 434.55 210.15 440.88 200.4z"
          />
        </g>
        <path
          d="M447.55 204.54a1.7 1.7 0 00-.22-.24l-.23-.19-.1-.11a1.75 1.75 0 00-.59-.22 2.1 2.1 0 00-.51-.05h-.36a2.74 2.74 0 00-.37.08l-.2.06a3.2 3.2 0 00-.39.16 3.17 3.17 0 00-.41.21 6.15 6.15 0 00-1 .72c-.13.12-.26.23-.38.36l-.36.38c-.06.06-.11.13-.17.2a9.38 9.38 0 00-.78 1.09c-.1.16-.19.31-.27.47s-.17.32-.25.49-.15.32-.21.49a8.25 8.25 0 00-.4 1.25c0 .16-.07.33-.1.49v.25a6.11 6.11 0 00-.05.73 4.8 4.8 0 00.05.67c0 .14.05.27.08.4l.12.37.15.32a2.9 2.9 0 00.19.29 2 2 0 001.36.77 1.9 1.9 0 00.34 0h.36a2.74 2.74 0 00.37-.08l.2-.06a4.37 4.37 0 00.8-.37 7.41 7.41 0 001.91-1.66l.17-.21.32-.43c.1-.15.2-.3.29-.45a8.25 8.25 0 001.31-4.17 4.33 4.33 0 00-.19-1.26 2.55 2.55 0 00-.48-.75z"
          style={{
            transformOrigin: "444.21px 208.858px"
          }}
          id="elq3pweu1d6x"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M457.52 194.72l-.14-.09.14.1z"
          style={{
            transformOrigin: "457.45px 194.68px"
          }}
          id="elpz44z9fmjg"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M434.55 218.68c0-8.49 5.94-18.84 13.33-23.1 3.69-2.13 7-2.34 9.46-1L454.5 193c-2.43-1.39-5.78-1.19-9.48 1-7.39 4.27-13.36 14.61-13.34 23.1 0 4.09 1.42 7 3.7 8.45l.16.09.14.09 2.69 1.57c-2.37-1.51-3.81-4.45-3.82-8.62z"
          style={{
            transformOrigin: "444.51px 209.71px"
          }}
          id="el0fu35dbzoual"
          className="animable"
          fill="#7519ff"
        />
        <g id="el6lspwull4ci">
          <path
            d="M434.55 218.68c0-8.49 5.94-18.84 13.33-23.1 3.69-2.13 7-2.34 9.46-1L454.5 193c-2.43-1.39-5.78-1.19-9.48 1-7.39 4.27-13.36 14.61-13.34 23.1 0 4.09 1.42 7 3.7 8.45l.16.09.14.09 2.69 1.57c-2.37-1.51-3.81-4.45-3.82-8.62z"
            style={{
              transformOrigin: "444.51px 209.71px"
            }}
            className="animable"
            id="elxanmp3zyb6"
            fill="#fff"
            opacity={0.3}
          />
        </g>
        <path
          d="M457.52 194.73l-.14-.1c-2.43-1.37-5.77-1.16-9.46 1-7.39 4.26-13.36 14.61-13.33 23.1 0 4.17 1.46 7.11 3.82 8.53s5.84 1.29 9.6-.88c7.39-4.27 13.36-14.61 13.33-23.1-.05-4.2-1.49-7.13-3.82-8.55zm-1.58 21.72c-.28.45-.57.9-.88 1.34s-.62.87-.94 1.28c-.17.21-.33.42-.5.62a21.35 21.35 0 01-5.66 4.92 13.6 13.6 0 01-2.39 1.1c-.19.07-.39.13-.58.18a9.73 9.73 0 01-1.11.25 8 8 0 01-1.07.09 6.72 6.72 0 01-1 0 5.85 5.85 0 01-4-2.29 7.29 7.29 0 01-.55-.85 8.53 8.53 0 01-.46-1 10.17 10.17 0 01-.59-2.28 14 14 0 01-.14-2 17.58 17.58 0 01.13-2.15c0-.24.06-.49.1-.73.08-.49.18-1 .29-1.48a26.13 26.13 0 011.18-3.71c.2-.49.41-1 .64-1.45s.47-1 .72-1.44.53-.93.81-1.39a28 28 0 012.32-3.24c.17-.21.34-.4.51-.6.35-.39.7-.77 1.06-1.13s.74-.72 1.12-1.05a18.54 18.54 0 013-2.14 11.66 11.66 0 011.21-.63 12.72 12.72 0 011.18-.48l.57-.17a9.88 9.88 0 011.12-.25 7.89 7.89 0 011.07-.1 6.56 6.56 0 011.49.13 5.86 5.86 0 011.77.65l.39.25a5.94 5.94 0 01.69.55 6.4 6.4 0 01.65.72 7.63 7.63 0 011.19 2.33 12.19 12.19 0 01.55 3.75 24.47 24.47 0 01-3.89 12.4z"
          style={{
            transformOrigin: "447.965px 210.98px"
          }}
          id="els2xswg4c4ib"
          className="animable"
          fill="#7519ff"
        />
      </g>
      <g
        id="freepik--speech-bubbles--inject-2"
        className="animable"
        style={{
          transformOrigin: "266.8px 198.478px",
          animation: "1.5s Infinite linear floating",
          animationDelay: "0s"
        }}
      >
        <path
          d="M304.86 270.92l-26.74 15.34a5.29 5.29 0 00-1.95 2 5.19 5.19 0 00-.67 2.55v19.46a5.15 5.15 0 007.88 4.24l4.64-2.66.42.69.07.06h.05c.77.5 2.11 1.23 2.42 1.38a.28.28 0 00.35-.17L293 309l17.07-9.76a5.2 5.2 0 002.63-4.53v-19.54c.04-3.77-5.5-5.59-7.84-4.25z"
          style={{
            transformOrigin: "294.1px 292.888px"
          }}
          id="el9edo5ircleo"
          className="animable"
          fill="#7519ff"
        />
        <g id="elxj53h62nyzm">
          <path
            d="M288.44 312.52l.07.06h.05c.77.5 2.11 1.23 2.42 1.38a.26.26 0 01-.17-.12l-1.66-2.68.33-.19-1.46.83z"
            style={{
              transformOrigin: "289.5px 312.465px"
            }}
            className="animable"
            id="elcverkgb1aqe"
            fill="#fff"
            opacity={0.2}
          />
        </g>
        <g id="elsjtcnm4jjnh">
          <path
            d="M281.36 291.18a5.39 5.39 0 011.4-1.75 4.77 4.77 0 01.64-.43l26.74-15.34a1.73 1.73 0 012.6 1.51c0-3.77-5.54-5.59-7.88-4.25l-26.74 15.34a5.29 5.29 0 00-1.95 2z"
            style={{
              transformOrigin: "294.455px 280.831px"
            }}
            className="animable"
            id="elbckses1kjn"
            fill="#fff"
            opacity={0.4}
          />
        </g>
        <g id="el5r62muya8w3">
          <path
            d="M280.78 313v-19.48a4.17 4.17 0 010-.61 5.45 5.45 0 01.54-1.73l-5.19-2.94a5.19 5.19 0 00-.67 2.55v19.46a5.15 5.15 0 007.88 4.24 1.74 1.74 0 01-2.56-1.49z"
            style={{
              transformOrigin: "279.4px 301.757px"
            }}
            className="animable"
            id="el8olze7oj2v5"
            fill="#fff"
            opacity={0.2}
          />
        </g>
        <path
          d="M294.5 293.55c.4-.24.73-.05.73.42v7.21a1.63 1.63 0 01-.72 1.27l-3.64 2.09c-.4.24-.73.05-.73-.42v-7.26a1.59 1.59 0 01.74-1.26zm-1.36 8.75a1.69 1.69 0 00.76-1.32c0-.49-.34-.69-.77-.44a1.66 1.66 0 00-.76 1.32c0 .49.34.69.77.44"
          style={{
            transformOrigin: "292.685px 299.045px"
          }}
          id="elrs6nmmu1yn"
          className="animable"
          fill="#7519ff"
        />
        <g id="elt86t8pkp73a">
          <g
            style={{
              transformOrigin: "292.685px 299.045px"
            }}
            className="animable"
            id="els1tw8axpg5"
            opacity={0.5}
          >
            <path
              d="M294.5 293.55c.4-.24.73-.05.73.42v7.21a1.63 1.63 0 01-.72 1.27l-3.64 2.09c-.4.24-.73.05-.73-.42v-7.26a1.59 1.59 0 01.74-1.26zm-1.36 8.75a1.69 1.69 0 00.76-1.32c0-.49-.34-.69-.77-.44a1.66 1.66 0 00-.76 1.32c0 .49.34.69.77.44"
              style={{
                transformOrigin: "292.685px 299.045px"
              }}
              id="elfka01wh5a9"
              className="animable"
              fill="#fff"
            />
          </g>
        </g>
        <path
          d="M298.2 283.39c1.59-.92 2.86-.1 2.76 1.78l-.15 3 4.19-2.47c1.45-.83 1.76 1.1.78 2.46 1 .28.7 2-.19 3 .82.37.09 2.26-.91 3.05.66.27.09 2.31-1.06 3l-4.16 2.4-2.62 1.52c-.9.51-1.63.1-1.63-.93v-6l1.66-4a16 16 0 001.31-6.09z"
          style={{
            transformOrigin: "300.808px 292.18px"
          }}
          id="elfmkch8gjfvc"
          className="animable"
          fill="#fff"
        />
        <path
          d="M396.67 82.1l-26.74 15.34a5.28 5.28 0 00-2.62 4.52v19.46a5.15 5.15 0 007.88 4.24l4.65-2.65.42.68a.2.2 0 00.07.06h.05c.76.51 2.11 1.23 2.41 1.39a.29.29 0 00.36-.18l1.71-4.85 17.06-9.75a5.21 5.21 0 002.63-4.53V86.34c0-3.76-5.55-5.58-7.88-4.24z"
          style={{
            transformOrigin: "385.93px 104.053px"
          }}
          id="elr5td1uiiaj"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M374.58 100.6z"
          style={{
            transformOrigin: "374.58px 100.6px"
          }}
          id="elktwteuixxt"
          className="animable"
          fill="none"
        />
        <g id="ele6al5er8oll">
          <path
            d="M380.26 123.69a.2.2 0 00.07.06h.05c.76.51 2.11 1.23 2.41 1.39a.38.38 0 01-.17-.12l-1.62-2.66.33-.18-1.46.83z"
            style={{
              transformOrigin: "381.33px 123.66px"
            }}
            className="animable"
            id="elkac2mvmwvg"
            fill="#fff"
            opacity={0.2}
          />
        </g>
        <g id="elz6i0acwu3s">
          <path
            d="M373.18 102.36a5.36 5.36 0 011.39-1.75 7 7 0 01.63-.43L402 84.83a1.74 1.74 0 012.6 1.51c0-3.76-5.54-5.58-7.88-4.24l-26.79 15.34a5.1 5.1 0 00-1.94 2z"
            style={{
              transformOrigin: "386.295px 92.0107px"
            }}
            className="animable"
            id="elbjniry59qpm"
            fill="#fff"
            opacity={0.4}
          />
        </g>
        <g id="ell3xttyu4q5r">
          <path
            d="M372.59 124.15V104.7a4.21 4.21 0 010-.62 5.49 5.49 0 01.55-1.72l-5.19-3a5.12 5.12 0 00-.68 2.55v19.46a5.15 5.15 0 007.88 4.24 1.73 1.73 0 01-2.56-1.46z"
            style={{
              transformOrigin: "371.21px 112.877px"
            }}
            className="animable"
            id="elj4hqcyo8w"
            fill="#fff"
            opacity={0.2}
          />
        </g>
        <path
          d="M386.31 104.72c.41-.23.73 0 .74.42v7.27a1.62 1.62 0 01-.73 1.26l-3.64 2.1c-.4.23-.73 0-.73-.42v-7.27a1.62 1.62 0 01.73-1.26zm-1.31 8.76a1.68 1.68 0 00.76-1.33c0-.48-.34-.68-.76-.44a1.69 1.69 0 00-.77 1.33c0 .49.35.68.77.44"
          style={{
            transformOrigin: "384.5px 110.245px"
          }}
          id="el0ifftpfnagt"
          className="animable"
          fill="#7519ff"
        />
        <g id="elrgj68feih9d">
          <g
            style={{
              transformOrigin: "384.5px 110.245px"
            }}
            className="animable"
            id="el6mkfin14hpm"
            opacity={0.5}
          >
            <path
              d="M386.31 104.72c.41-.23.73 0 .74.42v7.27a1.62 1.62 0 01-.73 1.26l-3.64 2.1c-.4.23-.73 0-.73-.42v-7.27a1.62 1.62 0 01.73-1.26zm-1.31 8.76a1.68 1.68 0 00.76-1.33c0-.48-.34-.68-.76-.44a1.69 1.69 0 00-.77 1.33c0 .49.35.68.77.44"
              style={{
                transformOrigin: "384.5px 110.245px"
              }}
              id="elj0zmbokik0k"
              className="animable"
              fill="#fff"
            />
          </g>
        </g>
        <path
          d="M390 94.57c1.59-.92 2.85-.11 2.76 1.77l-.15 3 4.22-2.44c1.45-.83 1.76 1.09.79 2.45 1 .29.69 2-.2 3 .82.37.1 2.27-.91 3.06.66.27.1 2.3-1.06 3l-4.15 2.4-2.63 1.51c-.89.52-1.62.1-1.63-.92v-6l1.66-4a16.31 16.31 0 001.3-6.17z"
          style={{
            transformOrigin: "392.641px 103.367px"
          }}
          id="el5ax1595xhmo"
          className="animable"
          fill="#fff"
        />
        <path
          d="M158.39 158.18l-26.74 15.34A5.23 5.23 0 00129 178v19.5a5.15 5.15 0 007.88 4.24l4.65-2.65.42.68.06.06h.05c.77.51 2.11 1.23 2.42 1.38a.3.3 0 00.36-.18l1.71-4.85 17.06-9.75a5.21 5.21 0 002.63-4.53v-19.48c.03-3.76-5.51-5.59-7.85-4.24z"
          style={{
            transformOrigin: "147.62px 180.131px"
          }}
          id="elvnin49rzlkn"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M136.3 176.68z"
          style={{
            transformOrigin: "136.3px 176.68px"
          }}
          id="eljdg3qls8jgf"
          className="animable"
          fill="none"
        />
        <g id="el7p4urph1hhd">
          <path
            d="M142 199.77l.06.06h.05c.77.51 2.11 1.23 2.42 1.38a.3.3 0 01-.17-.12l-1.66-2.69.33-.18-1.45.83z"
            style={{
              transformOrigin: "143.055px 199.715px"
            }}
            className="animable"
            id="elo9fzca95zd"
            fill="#fff"
            opacity={0.2}
          />
        </g>
        <g id="elixh5ku5ouy">
          <path
            d="M134.89 178.44a5.29 5.29 0 011.4-1.75 4.11 4.11 0 01.63-.43l26.74-15.34a1.74 1.74 0 012.6 1.51c0-3.76-5.54-5.59-7.88-4.24l-26.74 15.34a5.19 5.19 0 00-2 2z"
            style={{
              transformOrigin: "147.95px 168.094px"
            }}
            className="animable"
            id="elkvc82mrcgr"
            fill="#fff"
            opacity={0.4}
          />
        </g>
        <g id="eltrze8515yui">
          <path
            d="M134.31 200.23v-19.45a4.21 4.21 0 010-.62 5.39 5.39 0 01.54-1.72l-5.19-2.95A5.22 5.22 0 00129 178v19.5a5.15 5.15 0 007.88 4.24 1.73 1.73 0 01-2.57-1.51z"
            style={{
              transformOrigin: "132.94px 189.007px"
            }}
            className="animable"
            id="elzoxvl75xzg"
            fill="#fff"
            opacity={0.2}
          />
        </g>
        <path
          d="M148 180.8c.41-.23.73-.05.73.42v7.21a1.58 1.58 0 01-.72 1.27l-3.64 2.1c-.4.23-.73 0-.73-.42v-7.27a1.57 1.57 0 01.73-1.26zm-1.36 8.76a1.71 1.71 0 00.76-1.33c0-.49-.34-.68-.77-.44a1.71 1.71 0 00-.76 1.33c0 .48.34.68.77.44"
          style={{
            transformOrigin: "146.185px 186.296px"
          }}
          id="eljrov2gsb1b"
          className="animable"
          fill="#7519ff"
        />
        <g id="elkcntpqb8eqd">
          <g
            style={{
              transformOrigin: "146.185px 186.296px"
            }}
            className="animable"
            id="elqsne82lm2ab"
            opacity={0.5}
          >
            <path
              d="M148 180.8c.41-.23.73-.05.73.42v7.21a1.58 1.58 0 01-.72 1.27l-3.64 2.1c-.4.23-.73 0-.73-.42v-7.27a1.57 1.57 0 01.73-1.26zm-1.36 8.76a1.71 1.71 0 00.76-1.33c0-.49-.34-.68-.77-.44a1.71 1.71 0 00-.76 1.33c0 .48.34.68.77.44"
              style={{
                transformOrigin: "146.185px 186.296px"
              }}
              id="el049mns2akyx"
              className="animable"
              fill="#fff"
            />
          </g>
        </g>
        <path
          d="M151.73 170.65c1.59-.92 2.86-.11 2.76 1.77l-.14 3 4.22-2.44c1.45-.84 1.76 1.09.78 2.45 1 .29.7 2-.19 3 .82.37.09 2.27-.91 3.06.66.27.09 2.3-1.06 3l-4.16 2.4-2.62 1.51c-.9.52-1.63.1-1.63-.93v-6l1.66-4a16.22 16.22 0 001.32-6.09z"
          style={{
            transformOrigin: "154.378px 179.446px"
          }}
          id="el2a1omozcthe"
          className="animable"
          fill="#fff"
        />
      </g>
      <defs>
        <filter id="active" height="200%">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius={2}
          />
          <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
          <feMerge>
            <feMergeNode in="OUTLINE" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="hover" height="200%">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius={2}
          />
          <feFlood floodColor="red" floodOpacity={0.5} result="PINK" />
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
          <feMerge>
            <feMergeNode in="OUTLINE" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
          <feColorMatrix values="0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 1 0" />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgComponent
