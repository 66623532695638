export function modalActive(name, setOpenModal) {
  console.log('name out ======>> ', name);

  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
  if (name === 'luciagentes') setOpenModal(
    {
      luci: true,
      emi: false,
      siri: false,
      anay: false,
      bbt: false,
      vet: false,
      clean: false,
    }
  )
  if (name === 'emilan') setOpenModal(
    {
      luci: false,
      emi: true,
      siri: false,
      anay: false,
      bbt: false,
      vet: false,
      clean: false,
    }
  )
  if (name === 'sirimiri') setOpenModal(
    {
      luci: false,
      emi: false,
      siri: true,
      anay: false,
      bbt: false,
      vet: false,
      clean: false,
    }
  )
  if (name === 'anayiri') setOpenModal(
    {
      luci: false,
      emi: false,
      siri: false,
      anay: true,
      bbt: false,
      vet: false,
      clean: false,
    }
  )
  if (name === 'BBT todo') setOpenModal(
    {
      luci: false,
      emi: false,
      siri: false,
      anay: false,
      bbt: true,
      vet: false,
      clean: false,
    }
  )
  if (name === 'vending') setOpenModal(
    {
      luci: false,
      emi: false,
      siri: false,
      anay: false,
      bbt: false,
      vet: true,
      clean: false,
    }
  )
  if (name === 'cleandaz') setOpenModal(
    {
      luci: false,
      emi: false,
      siri: false,
      anay: false,
      bbt: false,
      vet: false,
      clean: true,
    }
  )
  if (name === '') setOpenModal(
    {
      luci: false,
      emi: false,
      siri: false,
      anay: false,
      bbt: false,
      vet: false,
      clean: false,
    }
  )
}