export function SubmitSheet(inputs = {}) {

  const url = "https://script.google.com/macros/s/AKfycbxHFUvnP8xncErQ78DqRDuHHEr_ZkxJusv_nDHtz_t18mDMcLxTNK6FJbzhUiXXeapEVA/exec"
  const formDatab = new FormData();
  formDatab.append('Name', inputs.Name);
  formDatab.append('Ext_', inputs.Ext_);
  formDatab.append('Phone', inputs.Phone);
  formDatab.append('Email', inputs.Email);
  formDatab.append('Message', inputs.Message);
  fetch(url, {
    method: "POST",
    body: formDatab,
  }
  ).then((res) => res.json())
    .then((data) => {
      console.log(data)
    })
    .catch((error) => {
      console.log(error)
    })
}
//sheet form
// https://docs.google.com/spreadsheets/d/17Jq-Cc1ki3VOOfXs0_J9eoktJwJ79HTFDAZp_1TE1-k/edit#gid=0

//App Script to sheet form

// const sheets = SpreadsheetApp.openByUrl("https://docs.google.com/spreadsheets/d/17Jq-Cc1ki3VOOfXs0_J9eoktJwJ79HTFDAZp_1TE1-k/edit#gid=0");
// const sheet = sheets.getSheetByName("FormularioWeb");
// function doPost(e) {
//   let data = e.parameter;
//   sheet.appendRow([data.Name, data.Phone, data.Email, data.Ext_, data.Message]);
//   return ContentService.createTextOutput("Your message was successfully sent to the Googlesheet database!");
// }