import React, { useEffect } from 'react'

// Trasnlation 
import '../i18n'
import { useTranslation } from 'react-i18next'

/**    IMAGENES    */
import img_card1 from '../assets/images/landings/shooting_darts.png'
import img_card2 from '../assets/images/landings/rocket_pc.png'
import img_card3 from '../assets/images/landings/Money2.png'
import image_form from '../assets/images/landings/jefe.png'
import image_testimony from '../assets/images/landings/agente_positivo.png'

// Components
import Carousel from '../components/Carousel'
import FormContact from '../components/FormcontactLandings'



const PagePauta = () => {

  const { t, i18n } = useTranslation();
  /**
   * inicializa el idioma de la vista HOme
   */
  const initLang = () => {
    if (localStorage.getItem('lng')) {
      i18n.changeLanguage(localStorage.getItem('lng'))
    } else {
      window.localStorage.setItem('lng', 'es')
      i18n.changeLanguage('es')
    }
  }
  useEffect(() => {
    initLang()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const benefit_cards = [
    { id: 1, image: img_card1, text: t('Land_pauta.benef_card1') },
    { id: 2, image: img_card2, text: t('Land_pauta.benef_card2') },
    { id: 3, image: img_card3, text: t('Land_pauta.benef_card3') },
  ]

  return (
    <section className='LandingPauta' >

      <Carousel>
        <div className="bannerLandingPauta">
          <div className="text_banner">
            <p>{t('Land_pauta.banner_text1')}<b> {t('Land_pauta.banner_text2')}</b> </p>
          </div>
        </div>
      </Carousel>
      <div className="contenedor">
        <div className="testimon">
          <figure>
            <img src={image_testimony} alt="nuestros agentes siempre positivos" />
          </figure>
          <div className="section_text">
            <h3 className="">{t('Land_pauta.testimo_title')}</h3>
            <p className=''>{t('Land_pauta.testimo_text')}</p>
            <div className="div_btn">
              <a href={t('Land_pauta.testimo_button_link')} className="boton_gradient" target='_blank' rel='noreferrer noopener'>{t('Land_pauta.testimo_button')}</a>
            </div>
          </div>
        </div>
        <div className="beneficios">
          <h1>{t('Land_pauta.benef_title')}</h1>
          <div className="benefits_list">
            {benefit_cards && benefit_cards.map((card, i) => (
              <div className={"benefit_card cardN" + card.id} key={card.id + 'jk' + i}>
                <figure>
                  <img src={card.image} alt="animacion de marketing" />
                </figure>
                <p>{card.text}</p>
              </div>
            ))}
          </div>
          <div className="div_btn">
            <a href={t('Land_pauta.benef_button_link')} className="boton_gradient" target='_blank' rel='noreferrer noopener'>{t('Land_pauta.benef_button')}</a>
          </div>
        </div>
        <div className="contact_sec">
          <h3 className="text_form mobile">{t('Land_pauta.form_title')}</h3>
          <figure>
            <img src={image_form} alt="nuestros agentes quieren contactarte" />
          </figure>
          <div className="section_form">
            <h3 className="text_form desktop">{t('Land_pauta.form_title')}</h3>
            <FormContact />
          </div>
        </div>
      </div>
    </section>
  )
}

export default PagePauta
