/** COMPONENTES SVG ANIMADOS PARA LOS SERVICIOS*/
import SVG1 from '../components/svg_animated/developFocus_svg'
import SVG2 from '../components/svg_animated/mobileMarketing_svg'
import SVG3 from '../components/svg_animated/webDesing_svg'
import SVG4 from '../components/svg_animated/desingTeam_svg'
import SVG5 from '../components/svg_animated/marketing_svg'
import SVG6 from '../components/svg_animated/desingThinking_svg'
import SVG7 from '../components/svg_animated/ecommerceWeb_svg'

/**
 * Servicios individuales ofrecidos por la compañia
 */
export const SERVICES = [
  {
    title: 'digitalChanel',
    svg: <SVG1 />,
    linkES: 'https://wa.link/yx7hmr',
    linkEN: 'https://wa.link/zqkt81',
    dn: false,
  },
  {
    title: 'admSocialMedia',
    svg: <SVG2 />,
    linkES: 'https://wa.link/0q2aa8',
    linkEN: 'https://wa.link/usjbea',
    dn: false,
  },
  {
    title: 'website',
    svg: <SVG3 />,
    linkES: 'https://wa.link/jn886o',
    linkEN: 'https://wa.link/irx5pp',
    dn: false,
  },
  {
    title: 'devLandingPage',
    svg: <SVG4 />,
    linkES: 'https://wa.link/p74k8l',
    linkEN: 'https://wa.link/a3ihid',
    dn: true,
  },
  {
    title: 'digitalAdversi',
    svg: <SVG5 />,
    linkES: 'https://wa.link/ws46gr',
    linkEN: 'https://wa.link/jpitfp',
    dn: true,
  },
  {
    title: 'branding',
    svg: <SVG6 />,
    linkES: 'https://wa.link/boiptj',
    linkEN: 'https://wa.link/fsguov',
    dn: true,
  },
  {
    title: 'ecommerseSetup',
    svg: <SVG7 />,
    linkES: 'https://wa.link/jc3kdl',
    linkEN: 'https://wa.link/trb6hb',
    dn: true,
  }
]

/**
 * planes ofrecidos en nuestro servicio de kreative
 */
export const PLANS = {
  basic:
  {
    price: 441,
    pieces: 23,
    linkES: 'https://wa.link/fhj207',
    linkEN: 'https://wa.link/iyi8wm'
  },
  silver:
  {
    price: 808,
    pieces: 35,
    linkES: 'https://wa.link/3lk3pe',
    linkEN: 'https://wa.link/4yekcb'
  },
  gold:
  {
    price: 1921,
    pieces: 46,
    linkES: 'https://wa.link/g3pfbh',
    linkEN: 'https://wa.link/260yg6'
  },
  custom:
  {
    price: 0,
    pieces: 0,
    linkES: 'https://wa.link/swf5jz',
    linkEN: 'https://wa.link/wo3r6h'
  }
}

/**
 * arreglo de prefijos telefonicos para el select del formulario
 */
export const País_Prefijo_telefónico = [
  { pais: 'Estados Unidos', prefijo: '+1' },
  { pais: 'Alemania', prefijo: '+49' },
  { pais: 'Argentina', prefijo: '+54' },
  { pais: 'Australia', prefijo: '+61' },
  { pais: 'Austria', prefijo: '+43' },
  { pais: 'Bélgica', prefijo: '+32' },
  { pais: 'Bulgaria', prefijo: '+359' },
  { pais: 'Canadá', prefijo: '+1' },
  { pais: 'Colombia', prefijo: '+57' },
  { pais: 'Croacia', prefijo: '+385' },
  { pais: 'Eslovaquia', prefijo: '+421' },
  { pais: 'Eslovenia', prefijo: '+386' },
  { pais: 'España', prefijo: '+34' },
  { pais: 'Estonia', prefijo: '+372' },
  { pais: 'Finlandia', prefijo: '+358' },
  { pais: 'Francia', prefijo: '+33' },
  { pais: 'Grecia', prefijo: '+30' },
  { pais: 'Holanda', prefijo: '+31' },
  { pais: 'Irlanda', prefijo: '+353' },
  { pais: 'Islandia', prefijo: '+354' },
  { pais: 'Italia', prefijo: '+39' },
  { pais: 'Letonia', prefijo: '+371' },
  { pais: 'Lituania', prefijo: '+370' },
  { pais: 'Luxemburgo', prefijo: '+352' },
  { pais: 'Malta', prefijo: '+356' },
  { pais: 'Marruecos', prefijo: '+212' },
  { pais: 'México', prefijo: '+52' },
  { pais: 'Noruega', prefijo: '+47' },
  { pais: 'Polonia', prefijo: '+48' },
  { pais: 'Portugal', prefijo: '+351' },
  { pais: 'Reino Unido', prefijo: '+44' },
  { pais: 'Rusia', prefijo: '+7' },
  { pais: 'Suecia', prefijo: '+46' },
  { pais: 'Suiza', prefijo: '+41' },
  { pais: 'Venezuela', prefijo: '+58' }
]