import React from "react";
import $ from 'jquery'
// import env from '../environtments'

// Router
import { useHistory } from 'react-router-dom';

// Trasnlation 
import '../i18n'
import { useTranslation } from 'react-i18next'

//imagen Logo
import logo_footer from '../assets/images/logo_horizontal_color.svg'
import TerminosPLanes from "./subComponents/terminosPLanes";
import SocialContact from "./subComponents/SocialContact";

/**
 * Componente que muestra el pie de la pagina mostrando info de contacto, redes sociales, tambien,  terminos y condiciones 
* @alias  Footer 
*/
const Footer = () => {
  const { t } = useTranslation();

  let history = useHistory();
  const Linkto = (link) => {
    history.push('/' + link);
  };

  const Open = () => {
    $('.prf_term_footer').toggleClass('block')
  }

  return (
    <div className="footer">
      <div className="contact_info">
        <h5 className="subtitle">{t('contactUs.label')}</h5>
        <div className="flex">
          <p className="info_Contct inf1">
            <i className="icon icon-location"></i><span>Miami, Florida.</span>
          </p>
          <p className="info_Contct inf2">
            <i className="icon icon-email"></i><span>info@kreativetreestudio.com</span>
          </p>
          <p className="info_Contct inf3">
            <i className="icon icon-phone"></i><span>+1 (305) 833 81 93{/**748-5738*/}</span>
          </p>
        </div>
      </div>
      {/* <div className="social_info">
        <div className="social1">
          <h5 className="subtitle">{t('folowUs.label')}</h5>
          <div className="social_networks">
            <a href="https://www.facebook.com/KreativeTree.studio " target='_blank' rel='noreferrer nonopener '>
              <i className="icon icon-faceBUB"></i>
            </a>
            <a href="https://www.Instagram.com/kreativetree.studio " target='_blank' rel='noreferrer nonopener '>
              <i className="icon icon-instaBUB"></i>
            </a>
            <a href="https://www.tiktok.com/@kreativetreestudio" target='_blank' rel='noreferrer nonopener '>
              <i className="icon icon-tiktokBUB"></i>
            </a>
            <a href="">
              <i className="icon icon-youtuBUB"></i>
            </a>
          </div>
        </div>
        <div className="social2">
          <h5 className="subtitle">{t('writingUs.label')}</h5>
          <label htmlFor="" className="label_contac label_footer">
            <i className="icon icon-whatsapp"></i>
            <input type="text" />
            <a className="" href={(localStorage.getItem('lng') === 'es') ? env.LINK_WHATS : env.LINK_WHATS_EN} target='_blank' rel='noreferrer nonopener '><i className="icon icon-send"></i></a>
          </label>
        </div>
      </div> */}
      <SocialContact t={t} />
      <TerminosPLanes func={Open} class1={"terminos_info"} class2={"see_termin_footer subtitle"} class3={"prf_term_footer"} />
      <div className="logo_footer">
        {/* <a href="/" target='_self' rel="noreferrer noopener" >
        </a> */}
        <img src={logo_footer} alt="logo de la empresa a color" onClick={() => Linkto('')} />
      </div>
    </div>
  )
}

export default Footer