import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/scss/utilities/slider.scss";

/** Import de la raduccion */
import '../i18n'
import { useTranslation } from 'react-i18next'

/** Import de los titulos  */
import tit_div4 from '../assets/images/asi_te_podemos.svg'
import tit_div4ING from '../assets/images/wecanhelp.svg'

/**  
  * Componente Card 
  * {@link CardServicios}  
*/
import CardServicios from "../components/subComponents/cardServicios";

import { SERVICES } from '../helpers/listServices'

/**
 * CarruselServices Componente del carrusel  que mestra los servicos que presta kreative tree como empresa en la version desktop de la web --> {@link DivServices} <-- Vista contenedora
 * @alias CarruselServices
 * @namespace
 */
const CarruselServices = () => {
  const { t } = useTranslation();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className='contenedor '>
      <div className="div4 carrServicios" id="carrServ">
        <div className="titulo">
          {(localStorage.getItem('lng') === 'es') ?
            <img src={tit_div4} alt="titulo - Asi podemos ayudarte" /> : <img src={tit_div4ING} alt=" title - We can hekp you like this" />
          }
        </div>
        <div className="carrusell">
          <Slider {...settings}>
            {SERVICES && SERVICES.map((serv, i) => (
              <CardServicios titulo={serv.title} linkes={serv.linkES} linken={serv.linkEN} key={serv.title + i}>
                {serv.svg}
              </CardServicios>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default CarruselServices