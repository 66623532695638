import React from 'react'
import $ from 'jquery'

// Trasnlation 
import '../i18n'
import { useTranslation } from 'react-i18next'

//images
import tit_div5 from '../assets/images/aun_no_sabes.svg'
import tit_div5ING from '../assets/images/dontnow.svg'
//component
import CarrusellPrices from '../components/Carrusell_prices'
import CardPlanBasic from '../components/subComponents/cardPlanBasic'
import CardPlanSilver from '../components/subComponents/cardPlanSilver'
import CardPlanGold from '../components/subComponents/cardPlanGold'
import CardPlanCustom from '../components/subComponents/cardPlanCustom'
import TerminosPLanes from '../components/subComponents/terminosPLanes'

//list plans Object
import { PLANS } from '../helpers/listServices'

/**
 * Prices Componente es el div de desktop que muestra las card de precios y planes de la empresa--> {@link View} <-- Vista contenedora
 * 
 * y esta misma es contedora del componente de carrusel: {@link CarrusellPrices}
 * 
 * @alias Prices
 */
const Prices = () => {

  const { t } = useTranslation();

  const vewMore = () => {
    $('.prf_term').toggleClass('block')
  }

  return (
    <div className="contenedor" >
      <div className="div5 divPrices" id="Dprices">
        <div className="titulo">
          {(localStorage.getItem('lng') === 'es') ?
            <img src={tit_div5} alt="aun no sabes que necesitas?" /> : <img src={tit_div5ING} alt="don't know what you need yet?" />
          }
        </div>
        <p className="subtitle">
          <b>{t('canStart.label')}</b>
        </p>
        <div className="planes">
          <CardPlanBasic plan={PLANS.basic} />
          <CardPlanSilver plan={PLANS.silver} />
          <CardPlanGold plan={PLANS.gold} />
          <CardPlanCustom plan={PLANS.custom} />
        </div>
        <TerminosPLanes func={vewMore} class1={"terminos_planes"} class2={"sub see_termin"} class3={"prf_term dn"} />
      </div>
      <CarrusellPrices plans={PLANS} />
    </div>
  )
}

export default Prices