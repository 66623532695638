const env = {
  API_URL: 'https://apinubidev.emilan.co/', // Dev

  API_URL_BLOG: "https://blog.kreativetreestudio.com/wp-json/wp/v2/posts", // Prod
  API_URL_BLOG_EN: "https://blog.kreativetreestudio.com/en/wp-json/wp/v2/posts", // Prod
  API_URL_BLOG_CAT: "https://blog.kreativetreestudio.com/wp-json/wp/v2/posts?categories=", // Prod
  API_URL_BLOG_MEDIA: "https://blog.kreativetreestudio.com/wp-json/wp/v2/media/", // Prod
  API_URL_BLOG_TOKEN: "https://blog.kreativetreestudio.com/wp-json/jwt-auth/v1/token", // Prod

  // LINK_CONTACT: 'https://m.me/KreativeTree.studio',
  // LINK_CONTACT_EN: 'https://m.me/KreativeTree.studio',
  LINK_CONTACT: 'https://wa.link/reh6ng',
  LINK_CONTACT_EN: 'https://wa.link/2rf36e',

  //WhatsApp Links
  LINK_WHATS: 'https://wa.link/reh6ng',
  // ¡Hola Kreative Tree! Vengo desde el sitio web y quiero una asesoría sobre sus servicios.
  LINK_WHATS_EN: 'https://wa.link/2rf36e',
  //

}

export default env