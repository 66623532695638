import React from "react";

//librerias
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";

/**
 * CarruselCompaneros Componente funciona con la libreria swiper y maneja el carrusel de los integrantes del Equipo de marketing de kreative tree en a version mobile de la web --> {@link Nosotros } <-- Vista contenedora del carrusel
 * @alias CarruselCompaneros
 * @namespace
 */
const CarruselCompaneros= ({changeCompa, equipo}) => {

  return (
      <div className="carrusell carrCompaneros">
        <Swiper
          slidesPerView={3}
          loop={true}
          cssMode={true}
          navigation={true}
          pagination={true}
          mousewheel={true}
          keyboard={true}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          className="mySwiper"
        >
        {equipo && equipo.map((suj,i) =>(
          <SwiperSlide key={suj.id}>
            <div className="mini_perfil mini_carrusell" key ={i} >
              <div className={suj.nombre == 'Érika Serna' ? "div_foto active":"div_foto"} onClick={(e)=>changeCompa(e,suj)}>
                <img src={suj.imagen} alt={suj.nombre} className="foto" />
              </div>
              <div className="nombre">{suj.arroba}</div>
              {localStorage.getItem('lng') === 'es' ? <p className="cargo">{suj.cargo}</p>: <p className="cargo">{suj.position}</p>}
            </div>
          </SwiperSlide>

        ))}
        </Swiper>
      </div>
  )
}

export default CarruselCompaneros