/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useState, Fragment } from 'react'
import $ from 'jquery';

// Trasnlation 
import '../i18n'
import { useTranslation } from 'react-i18next'

// Services 
import { getinApiBlog } from '../services/wp-api'
import Html2React from 'html2react'
import { useParams } from 'react-router-dom';
import moment from 'moment'
import { Helmet } from "react-helmet";

import {
  FacebookShareButton,
  LinkedinShareButton,
  FacebookMessengerShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import SocialContact from '../components/subComponents/SocialContact';

/**
 * Article componente determina cada articulo recibido por el blog de kreative tre con su imagen respectiva 
 * @date 24/7/2023 - 19:55:58
 * @alias Article
 * @todo ajustar para recibir paramretros de uun API o base de datos
 */
const Article = () => {

  const { slug } = useParams();
  const { t } = useTranslation();
  let languege = window.localStorage.getItem('lng')

  /**
   * Efectos de clase de css de los botones de like y share
   */
  const likefn = () => {
    $('.likes .div_icon').toggleClass('lk')
  }
  const sharefn = () => {
    $('.btns_share').toggleClass('show')
  }


  /**
   * Constantes que manejan los estados de la API y el renderizado
   */
  let [post, setPost] = useState({})
  let [ready, setReady] = useState(false)
  const [handLike, setHandLike] = useState(false)
  const [handShare, setHandShare] = useState(false)

  /**
  * @function beLikeShare actualiza  los likes,shares de los post y la apide wordpress con estas propiedades
  * @param {number} data - valor traido y enviado de las veces que ha sido compartido y likeado el post
  * @param {string} type - Diferencia el typo de accion si es un like o un share
  */
  const beLikeShare = async (data, type) => {

    if (type === 'likes' && handLike === false && post.likes >= 0) {
      setPost({ ...post, [type]: Number(data) + 1 })
      await getinApiBlog('postBlog', '/' + post.id + `?meta_box={"${type}":${data + 1}}`).then()
      setHandLike(true)
    }
    if (type === 'likes' && handLike === true && post.likes > 0) {
      setPost({ ...post, [type]: Number(data) - 1 })
      await getinApiBlog('postBlog', '/' + post.id + `?meta_box={"${type}":${data - 1}}`).then()
      setHandLike(false)
    }
    if (type === 'shares' && handShare === false && post.shares >= 0) {
      setPost({ ...post, shares: Number(data) + 1 })
      await getinApiBlog('postBlog', '/' + post.id + `?meta_box={"${type}":${data + 1}}`).then()
      setHandShare(true)
    }
    if (type === 'shares' && handShare === true && post.shares > 0) {
      setPost({ ...post, shares: Number(data) - 1 })
      await getinApiBlog('postBlog', '/' + post.id + `?meta_box={"${type}":${data - 1}}`).then()
      setHandShare(false)
    }

  }

  const gotoPost = (slugi) => {
    window.location.href = window.location.origin + '/blog/' + slugi;
  }
  const Cont = Html2React(post.content)

  /**
  * @function getPost trae el post de la api corespondiente evaluando el idiona por el slug
  * @param {string} sluga - parametro de titulo de el post que utilizamos para traer el post correspondiente
  * @returns {Object} Post - EL articulo completo 
  */
  const getPost = async (sluga) => {
    var pst;
    var Base = 'getAll'
    if (window.localStorage.getItem('lng') === 'en') {
      Base = 'getAllEn'
    }
    await getinApiBlog(Base, '?slug=' + sluga).then(async (res) => {
      var dateString = res[0].date;
      var dateObj = new Date(dateString);
      var momentObj = moment(dateObj);
      var momentString = momentObj.format('DD/MM/YYYY');
      pst = {
        id: res[0].id,
        date: momentString,
        likes: Number(res[0].meta_box.likes),
        shares: Number(res[0].meta_box.shares),
        title: res[0].title.rendered,
        slug: res[0].slug,
        content: ((res[0].content.rendered).replace(/\&nbsp;/g, '')).replace(/<strong> <\/strong>/g, ''),
        excerpt: res[0].excerpt.rendered,
        featured_media: res[0].featured_media,
        media: [],
        liked: false,
        autor: res[0].acf.autor
      }
      // console.log(res[0].acf.slug_ingles, res[0].slug);
      $('body').on('click', '.bt', function () {
        if (!$(this).hasClass("" + languege)) {
          if (res[0].acf.slug_ingles == undefined) {
            gotoPost('')
          } else {
            gotoPost(res[0].acf.slug_ingles)
          }
        }
      });
      if (res[0].featured_media !== 0) {
        await getinApiBlog('getMedia', res[0].featured_media).then(async (res) => {
          pst.media = (res.source_url);
          setReady(true)
          return false
        }).catch((err) => console.log(err));
      } else {
        setReady(true)
        return false
      }
    }).catch((err) => console.warn(err))
    setPost(pst);
  }

  /** Effecto de inicializacion del componente */
  useEffect(() => {
    getPost(slug);
    moment.locale('es')
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      {ready &&
        <Fragment>
          <Helmet>
            <title>{post.title}</title>
            <meta name="keywords" content="kreative, blog, Article, articulo" />
            <meta name="description" content={post.excerpt} />
          </Helmet>
          <div className='contenedor pageArticle' >
            <div className="Socialfloat">
              <SocialContact t={t} />
            </div>
            <section className="div_article">
              <article className="article">
                <div className="head_article">
                  <figure>
                    {post && post.media ?
                      <div className="figure-bkp" style={{ backgroundImage: `url(${post.media})` }}></div>
                      :
                      <div className="figure-bkp" style={{ backgroundImage: `url(assets/Images/blog_card1@2x.png)` }}></div>
                    }
                  </figure>
                </div>
                <div className="body_article">
                  {localStorage.getItem('lng') === 'es' ? <h3 className="titulo_articulo">{post.title}</h3> : <h3 className="titulo_articulo">{post.title}</h3>}
                  <div className="flex">
                    <div className="info_art">
                      <p><span>{t('author.label')} : </span> {post.autor}</p>
                      <p><span>{post.date}</span></p>
                    </div>
                    <div className="social_art">
                      <div className=" bt_blue likes" onClick={() => { beLikeShare(post.likes, 'likes'); likefn() }}>
                        <div className={handLike == true ? "div_icon lk" : "div_icon"}><i className="icon icon-heart"></i></div>
                        <span>{t('like.label')}</span>
                      </div>
                      <div className="social_share" onClick={() => { beLikeShare(post.shares, 'shares'); sharefn() }}>
                        <div className=" bt_blue share">
                          <div className="div_icon"><i className="icon icon-share"></i></div>
                          <span>{t('share.label')}</span>
                        </div>
                        <div className="btns_share">
                          <FacebookShareButton url={`https://${location.href}`} >
                            <i className="icon icon-facebook"></i>
                          </FacebookShareButton>
                          {/* <i className="icon icon-instagram"></i> */}
                          <TwitterShareButton url={`https://${location.href}`}>
                            <i className="icon icon-twiter"></i>
                          </TwitterShareButton>
                          <LinkedinShareButton url={`https://${location.href}`}>
                            <i className="icon icon-linkedin"></i>
                          </LinkedinShareButton>
                          <WhatsappShareButton url={`https://${location.href}`}>
                            <i className="icon icon-whatsapp_white"></i>
                          </WhatsappShareButton>
                          <FacebookMessengerShareButton url={`https://${location.href}`}>
                            <i className="icon icon-messenger"></i>
                          </FacebookMessengerShareButton>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text_art" dangerouslySetInnerHTML={{ __html: post.content }}>

                  </div>
                </div>
              </article>
            </section>
          </div>
        </Fragment>
      }
    </Fragment>
  )
}

export default Article