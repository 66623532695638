import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      className="animated"
      id="freepik_stories-design-team"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      {...props}
    >
      <style>
        {
          "@keyframes wind{0%{transform:rotate(0deg)}25%{transform:rotate(1deg)}75%{transform:rotate(-1deg)}}@keyframes heartbeat{0%,30%,40%,60%,to{transform:scale(1)}10%,50%{transform:scale(1.1)}}svg#freepik_stories-design-team:not(.animated) .animable{opacity:0}"
        }
      </style>
      <g
        id="freepik--Floor--inject-7"
        className="animable"
        style={{
          transformOrigin: "246.15px 347.532px"
        }}
      >
        <path
          d="M405.2 255.09c-88.41-51-231.29-51-319.12 0s-87.38 133.81 1 184.85 231.29 51.05 319.13 0 87.4-133.8-1.01-184.85z"
          style={{
            transformOrigin: "246.15px 347.532px"
          }}
          id="elcbkwdiwrfj6"
          className="animable"
          fill="#f5f5f5"
        />
      </g>
      <g
        id="freepik--Shadows--inject-7"
        className="animable animator-active"
        style={{
          transformOrigin: "255.885px 368.711px",
          animation: "1.5s Infinite linear wind",
          animationDelay: "0s"
        }}
      >
        <path
          d="M441.75 354.44c7.92-4.57 20.72-4.57 28.59 0s7.83 12-.09 16.56-20.72 4.57-28.59 0-7.83-12 .09-16.56z"
          style={{
            transformOrigin: "456px 362.718px"
          }}
          id="eld6n5qagrm9w"
          className="animable"
          fill="#e6e6e6"
        />
        <path
          d="M51.42 419.46c21-12.15 55.06-12.15 76 0s20.8 31.85-.24 44-55.06 12.15-76 0-20.81-31.85.24-44z"
          style={{
            transformOrigin: "89.2981px 441.46px"
          }}
          id="el4skmhi3t2io"
          className="animable"
          fill="#e6e6e6"
        />
        <path
          d="M143 316.51c-19.83-11.46-51.89-11.46-71.6 0s-19.6 30 .24 41.47 51.89 11.45 71.6 0 19.6-29.98-.24-41.47z"
          style={{
            transformOrigin: "107.32px 337.245px"
          }}
          id="elt3omd8l0mwl"
          className="animable"
          fill="#e6e6e6"
        />
        <path
          d="M180.08 402c22.27-12.85 58.26-12.85 80.38 0s22 33.71-.26 46.57-58.26 12.85-80.38 0-22.01-33.72.26-46.57z"
          style={{
            transformOrigin: "220.138px 425.287px"
          }}
          id="elro3zoao0de7"
          className="animable"
          fill="#e6e6e6"
        />
        <path
          style={{
            transformOrigin: "302.085px 339.225px"
          }}
          id="elzas3xakvmea"
          className="animable"
          fill="#e6e6e6"
          d="M172.44 272.19L419.08 413.6 431.73 406.23 185.03 264.85 172.44 272.19z"
        />
      </g>
      <g
        id="freepik--light-bulb--inject-7"
        className="animable"
        style={{
          transformOrigin: "79.5968px 62.6504px",
          animation: "1.5s Infinite linear heartbeat",
          animationDelay: "0s"
        }}
      >
        <g
          id="freepik--freepik--light-bulb--inject-77--inject-7"
          className="animable"
          style={{
            transformOrigin: "79.5968px 62.6504px"
          }}
        >
          <g
            id="freepik--freepik--Idea--inject-77--inject-7"
            className="animable"
            style={{
              transformOrigin: "79.5968px 62.6504px"
            }}
          >
            <g
              id="freepik--freepik--light--inject-77--inject-7"
              className="animable"
              style={{
                transformOrigin: "80.6828px 68.6043px"
              }}
            >
              <path
                d="M93.14 84.63A3 3 0 1189.2 83a3 3 0 013.94 1.59z"
                style={{
                  transformOrigin: "90.3631px 85.7624px"
                }}
                id="elztww95pt9ge"
                className="animable"
                fill="#37474f"
              />
              <path
                d="M87.11 87.42c-2.28-.28-3-2-1.62-3.84a7.94 7.94 0 016.63-2.8c2.28.28 3 2 1.62 3.82a8 8 0 01-6.63 2.82z"
                style={{
                  transformOrigin: "89.615px 84.0985px"
                }}
                id="ellubs4cj9ex"
                className="animable"
                fill="#455a64"
              />
              <path
                d="M86.59 86.47c-2.35-.3-3.1-2.06-1.68-4a8.24 8.24 0 016.84-2.91c2.35.3 3.1 2.06 1.66 4a8.14 8.14 0 01-6.82 2.91z"
                style={{
                  transformOrigin: "89.1672px 83.016px"
                }}
                id="el3drch6zhkr3"
                className="animable"
                fill="#37474f"
              />
              <path
                d="M86.06 85.49c-2.42-.3-3.19-2.11-1.69-4a8.42 8.42 0 017-3c2.42.29 3.2 2.11 1.74 4.06a8.45 8.45 0 01-7.05 2.94z"
                style={{
                  transformOrigin: "88.7243px 81.9928px"
                }}
                id="eltu6c0s8p9sj"
                className="animable"
                fill="#455a64"
              />
              <path
                d="M85.48 84.46c-2.49-.3-3.28-2.18-1.77-4.18A8.7 8.7 0 0191 77.19c2.49.31 3.29 2.18 1.77 4.19a8.7 8.7 0 01-7.29 3.08z"
                style={{
                  transformOrigin: "88.2422px 80.8253px"
                }}
                id="els7f7h47hin"
                className="animable"
                fill="#37474f"
              />
              <path
                d="M84.92 83.4c-2.59-.34-3.36-2.22-1.82-4.28A8.89 8.89 0 0190.52 76c2.54.3 3.36 2.22 1.81 4.27a8.86 8.86 0 01-7.41 3.13z"
                style={{
                  transformOrigin: "87.7128px 79.6999px"
                }}
                id="elr2od31u91jc"
                className="animable"
                fill="#455a64"
              />
              <path
                d="M75 49.59a14.28 14.28 0 00-7.61 18.7v.06C71.8 78.8 81.15 76.88 83 81.08a2.46 2.46 0 002.12 1.29 7.71 7.71 0 006.42-2.72 2.48 2.48 0 00.53-2.4c-1.8-4.21 6.09-9.61 1.65-20.07A14.28 14.28 0 0075 49.57z"
                style={{
                  transformOrigin: "80.6828px 65.4156px"
                }}
                id="elm01soedh73l"
                className="animable"
                fill="#7519ff"
              />
              <g id="els2jl28s6og7">
                <g
                  style={{
                    transformOrigin: "80.6828px 65.4156px"
                  }}
                  className="animable"
                  id="eliyffb4x3a8s"
                  opacity={0.6}
                >
                  <path
                    d="M75 49.59a14.28 14.28 0 00-7.61 18.7v.06C71.8 78.8 81.15 76.88 83 81.08a2.46 2.46 0 002.12 1.29 7.71 7.71 0 006.42-2.72 2.48 2.48 0 00.53-2.4c-1.8-4.21 6.09-9.61 1.65-20.07A14.28 14.28 0 0075 49.57z"
                    style={{
                      transformOrigin: "80.6828px 65.4156px"
                    }}
                    id="el03bb45xwwcq3"
                    className="animable"
                    fill="#fff"
                  />
                </g>
              </g>
              <path
                d="M85.66 81.49c-1.63-.23-2.2-1.44-1.22-2.75a5.79 5.79 0 014.84-2c1.63.23 2.18 1.45 1.18 2.78a5.76 5.76 0 01-4.8 1.97z"
                style={{
                  transformOrigin: "87.4597px 79.115px"
                }}
                id="elalszszu17s4"
                className="animable"
                fill="#7519ff"
              />
              <path
                d="M89.09 78a.48.48 0 01-.62-.24 34.7 34.7 0 01-2.26-7.91 17.15 17.15 0 01-.21-2.37 7.21 7.21 0 01-1.39-.08 8.34 8.34 0 01-2.39-.76 8.68 8.68 0 01-1.12 2.21 7.53 7.53 0 01-.91 1 20.55 20.55 0 011.55 1.81 34.47 34.47 0 014.11 7.14.48.48 0 01-.27.63.5.5 0 01-.63-.28 33.9 33.9 0 00-4-6.9 15.56 15.56 0 00-1.54-1.8 3.05 3.05 0 01-2.81.32 1.55 1.55 0 01-1-1.76 1.29 1.29 0 011-1 3.68 3.68 0 012.84 1.21 5.22 5.22 0 00.82-.94 7.46 7.46 0 001.07-2.15 3.55 3.55 0 01-1.72-2.23 1.37 1.37 0 012.4-1 3.51 3.51 0 01.41 2.79 7.69 7.69 0 002.28.73 5.79 5.79 0 001.25 0 3.71 3.71 0 011.11-2.88 1.3 1.3 0 011.44 0 1.54 1.54 0 01.59 1.92 3.06 3.06 0 01-2.18 1.8 17 17 0 00.22 2.36 34 34 0 002.2 7.67.5.5 0 01-.24.65zm-12.35-9a.34.34 0 00-.18.27c-.09.39.3.61.42.66a1.91 1.91 0 001.63-.14 2.74 2.74 0 00-1.8-.82zM87 66.29a2 2 0 001.23-1.09c0-.12.17-.55-.18-.76a.31.31 0 00-.38 0c-.3.16-.59.77-.67 1.85zm-6.13-2.85a.33.33 0 00-.24.39 2.34 2.34 0 001 1.32 2.39 2.39 0 00-.28-1.61.34.34 0 00-.45-.11z"
                style={{
                  transformOrigin: "82.4712px 70.944px"
                }}
                id="elkyra83s8hl"
                className="animable"
                fill="#fff"
              />
            </g>
            <path
              d="M72.92 44.56a.7.7 0 01-.21.14.94.94 0 01-1.23-.49l-1.93-4.48a.92.92 0 01.45-1.22.94.94 0 011.23.5l1.93 4.47a1 1 0 01-.24 1.08z"
              style={{
                transformOrigin: "71.3462px 41.6058px"
              }}
              id="elzu3x0iek4vs"
              className="animable"
              fill="#e0e0e0"
            />
            <path
              d="M64.48 51.25a.93.93 0 01-1.13 0l-3.91-2.9a.94.94 0 011.11-1.51l3.91 2.9a.94.94 0 01.19 1.31.58.58 0 01-.17.2z"
              style={{
                transformOrigin: "61.9747px 49.0702px"
              }}
              id="elkl9biif58df"
              className="animable"
              fill="#e0e0e0"
            />
            <path
              d="M60.53 61.25a.94.94 0 01-.7.2L55 60.89a.93.93 0 01-.82-1 1 1 0 011-.83l4.84.56a.93.93 0 01.82 1 .91.91 0 01-.31.63z"
              style={{
                transformOrigin: "57.51px 60.2583px"
              }}
              id="elxonwvz88dpj"
              className="animable"
              fill="#e0e0e0"
            />
            <path
              d="M62.09 71.89a.58.58 0 01-.18.17L57.44 74a.92.92 0 01-1.22-.46.93.93 0 01.46-1.23l4.45-2a.94.94 0 011 1.59z"
              style={{
                transformOrigin: "59.3366px 72.1344px"
              }}
              id="el59j3wtm8tia"
              className="animable"
              fill="#e0e0e0"
            />
            <path
              d="M104.72 53.47a1 1 0 01-.18.17L100 55.53a.93.93 0 11-.73-1.71l4.47-1.94a.93.93 0 011.21.53.94.94 0 01-.26 1.06z"
              style={{
                transformOrigin: "101.849px 53.7149px"
              }}
              id="el3f4njnt7kh"
              className="animable"
              fill="#e0e0e0"
            />
            <path
              d="M93.57 47a.94.94 0 01-1.33-1.3l2.91-3.9a.91.91 0 011.28-.2.93.93 0 01.2 1.29l-2.91 3.91a.73.73 0 01-.15.2z"
              style={{
                transformOrigin: "94.4332px 44.3066px"
              }}
              id="elcvop9hgfcqj"
              className="animable"
              fill="#e0e0e0"
            />
            <path
              d="M83.57 43a1 1 0 01-1.32-.15 1 1 0 01-.2-.69l.61-4.8a.93.93 0 011.85.21l-.57 4.84a.89.89 0 01-.37.59z"
              style={{
                transformOrigin: "83.2811px 39.8589px"
              }}
              id="elovk4gcbywa"
              className="animable"
              fill="#e0e0e0"
            />
          </g>
        </g>
      </g>
      <g
        id="freepik--Plant--inject-7"
        className="animable"
        style={{
          transformOrigin: "461.382px 314.491px"
        }}
      >
        <path
          d="M436.83 328.13l19.17.56 19.18-.56c.82 12.95-1.71 26.15-5.19 32.59a8.67 8.67 0 01-3.79 3.86c-5.66 3.27-14.8 3.27-20.42 0a8.5 8.5 0 01-3.8-4c-3.48-6.44-5.98-19.58-5.15-32.45z"
          style={{
            transformOrigin: "456.003px 347.581px"
          }}
          id="els2dgbrt6u6s"
          className="animable"
          fill="#fff"
        />
        <path
          d="M442.49 320.28c-7.52 4.34-7.56 11.37-.09 15.71s19.6 4.33 27.12 0 7.55-11.37.08-15.71-19.6-4.34-27.11 0z"
          style={{
            transformOrigin: "456.002px 328.133px"
          }}
          id="elwgkg1jd7cu9"
          className="animable"
          fill="#ebebeb"
        />
        <path
          d="M445.46 322c-5.86 3.39-5.9 8.87-.07 12.26s15.29 3.38 21.15 0 5.9-8.87.07-12.26-15.29-3.38-21.15 0z"
          style={{
            transformOrigin: "456px 328.13px"
          }}
          id="el8csqpeybqjm"
          className="animable"
          fill="#e0e0e0"
        />
        <g id="elerqym3clxw">
          <g
            style={{
              transformOrigin: "456px 328.13px"
            }}
            className="animable"
            id="el8jb8pkfap2s"
            opacity={0.15}
          >
            <path
              d="M445.46 322c-5.86 3.39-5.9 8.87-.07 12.26s15.29 3.38 21.15 0 5.9-8.87.07-12.26-15.29-3.38-21.15 0z"
              id="eltpnpg8ovae"
              className="animable"
              style={{
                transformOrigin: "456px 328.13px"
              }}
            />
          </g>
        </g>
        <path
          d="M465.78 288.89c3.12-3.88 6.74-7.35 9.6-11.42 1.95-2.78 3.58-6.18 2.76-9.47a8.46 8.46 0 00-6.71-5.91 12.84 12.84 0 00-9 2 27.58 27.58 0 00-6.81 6.48 67.67 67.67 0 00-14.25 48.32c.19 2 .52 4 1.78 5.55a5.53 5.53 0 005.73 1.75c5.22-1.55 4.53-8.6 5.38-12.75a58.47 58.47 0 016-16.29 57.3 57.3 0 015.52-8.26z"
          style={{
            transformOrigin: "459.712px 294.174px"
          }}
          id="el29ny3sohq35"
          className="animable"
          fill="#455a64"
        />
        <g id="el9h5rzf8z3e">
          <path
            d="M465.78 288.89c3.12-3.88 6.74-7.35 9.6-11.42 1.95-2.78 3.58-6.18 2.76-9.47a8.46 8.46 0 00-6.71-5.91 12.84 12.84 0 00-9 2 27.58 27.58 0 00-6.81 6.48 67.67 67.67 0 00-14.25 48.32c.19 2 .52 4 1.78 5.55a5.53 5.53 0 005.73 1.75c5.22-1.55 4.53-8.6 5.38-12.75a58.47 58.47 0 016-16.29 57.3 57.3 0 015.52-8.26z"
            style={{
              transformOrigin: "459.712px 294.174px"
            }}
            className="animable"
            id="eld6bhpg7nzfq"
            fill="#fff"
            opacity={0.2}
          />
        </g>
        <path
          d="M448.54 321a.46.46 0 00.37-.43c.37-8.39 3.09-37 22.82-53.25a.46.46 0 00.06-.64.45.45 0 00-.64-.06c-20 16.48-22.78 45.43-23.15 53.91a.45.45 0 00.44.47z"
          style={{
            transformOrigin: "459.946px 293.757px"
          }}
          id="el73lkaishpvn"
          className="animable"
          fill="#f0f0f0"
        />
        <path
          d="M445.66 321.85c.6 3.29 3.43 7.37 6.41 8.88a18 18 0 009.69 1.63 42.6 42.6 0 009.84-2.36c1.58-.53 3.34-1.25 3.5-3.21a3.48 3.48 0 00-1.11-2.75 11.07 11.07 0 00-1-.88 1 1 0 01.54-1.82 22.24 22.24 0 002.81-.35 12.21 12.21 0 005.88-2.66 9.75 9.75 0 002.72-4c.48-1.31.61-3-.39-3.95a4.31 4.31 0 00-2.18-.95q-1.05-.23-2.1-.39a.93.93 0 01-.22-1.79l3-1.23a5.63 5.63 0 002.26-1.41 4.89 4.89 0 00.57-4.09c-.36-1.87-1-3.66-1.44-5.51a11.8 11.8 0 01-.2-5.63 47.41 47.41 0 00-16.66 1.11 11.94 11.94 0 00-4.55 2c-1.85 1.49-2.34 3.71-2.14 6.13a.43.43 0 01-.81.23l-1.9-3.51a2.25 2.25 0 00-3-.94 13.67 13.67 0 00-4.34 3.41 7.52 7.52 0 00-1.86 5.57 49.1 49.1 0 001.38 8.17.75.75 0 01-1.18.79l-.09-.06a2.54 2.54 0 00-1.79-.52c-1.68.21-2.11 1.91-2.22 3.36a27.89 27.89 0 00.58 6.73z"
          style={{
            transformOrigin: "465.579px 310.78px"
          }}
          id="elof91srorgvg"
          className="animable"
          fill="#455a64"
        />
        <g id="elg62t0wlphp">
          <path
            d="M445.66 321.85c.6 3.29 3.43 7.37 6.41 8.88a18 18 0 009.69 1.63 42.6 42.6 0 009.84-2.36c1.58-.53 3.34-1.25 3.5-3.21a3.48 3.48 0 00-1.11-2.75 11.07 11.07 0 00-1-.88 1 1 0 01.54-1.82 22.24 22.24 0 002.81-.35 12.21 12.21 0 005.88-2.66 9.75 9.75 0 002.72-4c.48-1.31.61-3-.39-3.95a4.31 4.31 0 00-2.18-.95q-1.05-.23-2.1-.39a.93.93 0 01-.22-1.79l3-1.23a5.63 5.63 0 002.26-1.41 4.89 4.89 0 00.57-4.09c-.36-1.87-1-3.66-1.44-5.51a11.8 11.8 0 01-.2-5.63 47.41 47.41 0 00-16.66 1.11 11.94 11.94 0 00-4.55 2c-1.85 1.49-2.34 3.71-2.14 6.13a.43.43 0 01-.81.23l-1.9-3.51a2.25 2.25 0 00-3-.94 13.67 13.67 0 00-4.34 3.41 7.52 7.52 0 00-1.86 5.57 49.1 49.1 0 001.38 8.17.75.75 0 01-1.18.79l-.09-.06a2.54 2.54 0 00-1.79-.52c-1.68.21-2.11 1.91-2.22 3.36a27.89 27.89 0 00.58 6.73z"
            style={{
              transformOrigin: "465.579px 310.78px"
            }}
            className="animable"
            id="elvjbwg1ylm3s"
            opacity={0.2}
          />
        </g>
        <g id="el65zlofh9iau">
          <path
            d="M445.66 321.85c.6 3.29 3.43 7.37 6.41 8.88a18 18 0 009.69 1.63 42.6 42.6 0 009.84-2.36c1.58-.53 3.34-1.25 3.5-3.21a3.48 3.48 0 00-1.11-2.75 11.07 11.07 0 00-1-.88 1 1 0 01.54-1.82 22.24 22.24 0 002.81-.35 12.21 12.21 0 005.88-2.66 9.75 9.75 0 002.72-4c.48-1.31.61-3-.39-3.95a4.31 4.31 0 00-2.18-.95q-1.05-.23-2.1-.39a.93.93 0 01-.22-1.79l3-1.23a5.63 5.63 0 002.26-1.41 4.89 4.89 0 00.57-4.09c-.36-1.87-1-3.66-1.44-5.51a11.8 11.8 0 01-.2-5.63 47.41 47.41 0 00-16.66 1.11 11.94 11.94 0 00-4.55 2c-1.85 1.49-2.34 3.71-2.14 6.13a.43.43 0 01-.81.23l-1.9-3.51a2.25 2.25 0 00-3-.94 13.67 13.67 0 00-4.34 3.41 7.52 7.52 0 00-1.86 5.57 49.1 49.1 0 001.38 8.17.75.75 0 01-1.18.79l-.09-.06a2.54 2.54 0 00-1.79-.52c-1.68.21-2.11 1.91-2.22 3.36a27.89 27.89 0 00.58 6.73z"
            style={{
              transformOrigin: "465.579px 310.78px"
            }}
            className="animable"
            id="el3bt9v857m5q"
            fill="#fff"
            opacity={0.2}
          />
        </g>
        <path
          d="M454.5 330.28a66.72 66.72 0 014.48-13 .44.44 0 01.16-.34c.1-.23.2-.45.31-.68A41.77 41.77 0 00455 301.4a.47.47 0 01.17-.63.46.46 0 01.62.17 42.32 42.32 0 014.38 13.82c3.79-7.6 9.85-16.5 19.19-23a.45.45 0 01.64.11.46.46 0 01-.12.64c-9.64 6.7-15.72 16-19.41 23.71a28.1 28.1 0 0115.88-2.15.47.47 0 01.37.54.46.46 0 01-.36.36h-.18c-.09 0-8.81-1.6-16.35 2.62a66.34 66.34 0 00-4.44 12.86.46.46 0 01-.36.36.37.37 0 01-.18 0 .45.45 0 01-.35-.53z"
          style={{
            transformOrigin: "467.286px 311.247px"
          }}
          id="ell56z7y2ukgp"
          className="animable"
          fill="#f0f0f0"
        />
      </g>
      <g
        id="freepik--Screen--inject-7"
        className="animable"
        style={{
          transformOrigin: "308.685px 193.465px",
          animation: "1.5s Infinite linear wind",
          animationDelay: "0s"
        }}
      >
        <path
          d="M417 358.21l.33-185.21a14.25 14.25 0 00-6.47-11.22L181.12 29.44c-1.78-1-3.4-1.14-4.58-.48l6.8-3.93c1.18-.75 2.86-.69 4.72.38L417.79 157.7a14.27 14.27 0 016.48 11.22L424 354.19a4.69 4.69 0 01-1.73 4.05l-.36.21-6.71 3.91c1.09-.69 1.8-2.12 1.8-4.15z"
          style={{
            transformOrigin: "300.405px 193.444px"
          }}
          id="eliqjh8n9l4q"
          className="animable"
          fill="#7519ff"
        />
        <g id="el9t6jx9e1mys">
          <path
            d="M183.34 25c1.18-.75 2.86-.69 4.72.38L417.79 157.7a13.62 13.62 0 015 5.53l-6.95 4a13.55 13.55 0 00-4.95-5.52L181.12 29.44c-1.78-1-3.4-1.14-4.58-.48z"
            style={{
              transformOrigin: "299.665px 95.8637px"
            }}
            className="animable"
            id="elxr0pmv2tw8"
            fill="#fff"
            opacity={0.3}
          />
        </g>
        <g id="el8fsa8zr2ef">
          <path
            d="M424.27 168.92L424 354.19a4.69 4.69 0 01-1.73 4.05l-.36.21-6.71 3.91c1.15-.69 1.86-2.12 1.87-4.15l.26-185.21a12.57 12.57 0 00-1.52-5.7l6.95-4a12.68 12.68 0 011.51 5.62z"
            style={{
              transformOrigin: "419.735px 262.83px"
            }}
            className="animable"
            id="ell4405xphgei"
            fill="#fff"
            opacity={0.2}
          />
        </g>
        <path
          d="M417.32 181.36L417 358.21c0 2-.72 3.46-1.87 4.15l-.1.06h-.05c-1.17.61-2.75.5-4.5-.51L180.75 229.64a14.31 14.31 0 01-6.48-11.22l.31-177.08z"
          style={{
            transformOrigin: "295.795px 202.067px"
          }}
          id="elwb8t2i8jz9"
          className="animable"
          fill="#7519ff"
        />
        <g id="el569p9tybeti">
          <path
            d="M417.32 181.36L417 358.21c0 2-.72 3.46-1.87 4.15l-.1.06h-.05c-1.17.61-2.75.5-4.5-.51L180.75 229.64a14.31 14.31 0 01-6.48-11.22l.31-177.08z"
            style={{
              transformOrigin: "295.795px 202.067px"
            }}
            className="animable"
            id="elox1y0mirxr"
            opacity={0.2}
          />
        </g>
        <path
          style={{
            transformOrigin: "302.085px 207.34px"
          }}
          id="ely3up86wh6yq"
          className="animable"
          fill="#fff"
          d="M203.16 224.14L203.42 77.16 401.01 190.76 400.75 337.52 203.16 224.14z"
        />
        <path
          style={{
            transformOrigin: "328.83px 198.855px"
          }}
          id="elfig8uiub32"
          className="animable"
          fill="#455a64"
          d="M284.97 211.71L285.08 135.73 372.69 186.09 372.57 261.98 284.97 211.71z"
        />
        <path
          d="M174.6 33.15c0-2 .72-3.45 1.87-4.14 1.18-.66 2.8-.55 4.58.48l229.81 132.24a14.25 14.25 0 016.47 11.27v8.41l-242.74-140z"
          style={{
            transformOrigin: "295.96px 105.003px"
          }}
          id="eldq6ydgw27x"
          className="animable"
          fill="#7519ff"
        />
        <g id="elq5ebereux3">
          <path
            d="M174.6 33.15c0-2 .72-3.45 1.87-4.14 1.18-.66 2.8-.55 4.58.48l229.81 132.24a14.25 14.25 0 016.47 11.27v8.41l-242.74-140z"
            style={{
              transformOrigin: "295.96px 105.003px"
            }}
            className="animable"
            id="el5c9i8n855q"
            opacity={0.3}
          />
        </g>
        <g id="elg6ucw4jqe5a">
          <g
            style={{
              transformOrigin: "186.96px 41.3372px"
            }}
            className="animable"
            id="elo2jopf0tpd"
            opacity={0.39}
          >
            <path
              d="M191.8 42.1c-1-.56-1.77-.11-1.77 1a3.85 3.85 0 001.76 3.06c1 .57 1.78.11 1.78-1a3.9 3.9 0 00-1.77-3.06z"
              style={{
                transformOrigin: "191.8px 44.1322px"
              }}
              id="eli66v3wh93w8"
              className="animable"
              fill="#fff"
            />
            <path
              d="M187 39.31c-1-.57-1.77-.12-1.77 1a3.88 3.88 0 001.77 3.07c1 .56 1.78.11 1.78-1a3.89 3.89 0 00-1.78-3.07z"
              style={{
                transformOrigin: "187.005px 41.3419px"
              }}
              id="elwmvqktb2szb"
              className="animable"
              fill="#fff"
            />
            <path
              d="M182.12 36.51c-1-.56-1.77-.11-1.77 1a3.85 3.85 0 001.76 3.06c1 .57 1.78.12 1.78-1a3.92 3.92 0 00-1.77-3.06z"
              style={{
                transformOrigin: "182.12px 38.5431px"
              }}
              id="elli9b90sf4kk"
              className="animable"
              fill="#fff"
            />
          </g>
        </g>
        <path
          style={{
            transformOrigin: "191.065px 107.115px"
          }}
          id="elrk4hky67uk"
          className="animable"
          fill="#fff"
          d="M191.08 102.5L191.05 111.73 191.08 102.5 191.08 102.5z"
        />
        <path
          style={{
            transformOrigin: "187.025px 117.575px"
          }}
          id="elu6vatgzz4i7"
          className="animable"
          fill="#fff"
          d="M191.04 115.28L183.01 110.64 183.01 110.64 191.04 115.28 191.02 124.51 191.02 124.51 191.04 115.28z"
        />
        <g id="elmde5dgnw3fe">
          <rect
            x={186.99}
            y={121.09}
            height={9.27}
            style={{
              transformOrigin: "186.99px 125.725px"
            }}
            className="animable"
            id="elxytapynsxsr"
            fill="#fff"
            transform="rotate(-60)"
          />
        </g>
        <g id="elqg9py9y4w9">
          <rect
            x={186.86}
            y={167.54}
            height={9.27}
            style={{
              transformOrigin: "186.86px 172.175px"
            }}
            className="animable"
            id="el83ln4aiyu0e"
            fill="#fff"
            transform="rotate(-60)"
          />
        </g>
        <path
          style={{
            transformOrigin: "187.11px 83.87px"
          }}
          id="elxqikga6a1h"
          className="animable"
          fill="#fff"
          d="M183.09 81.55L191.13 86.19 183.09 81.55 183.09 81.55z"
        />
        <path
          style={{
            transformOrigin: "188.8px 93.005px"
          }}
          id="elo0tyjlm59ff"
          className="animable"
          fill="#fff"
          d="M186.48 87.05L191.12 89.73 191.09 98.96 191.12 89.73 186.48 87.05z"
        />
        <path
          style={{
            transformOrigin: "187.075px 96.64px"
          }}
          id="elio6t3xv0svc"
          className="animable"
          fill="#fff"
          d="M183.06 94.32L191.09 98.96 183.06 94.32 183.06 94.32z"
        />
        <path
          style={{
            transformOrigin: "187.035px 109.41px"
          }}
          id="el8lqlkhl34t6"
          className="animable"
          fill="#fff"
          d="M183.02 107.09L191.05 111.73 183.02 107.09 183.02 107.09z"
        />
        <path
          style={{
            transformOrigin: "188.52px 190.545px"
          }}
          id="el9y8kg20rqb"
          className="animable"
          fill="#fff"
          d="M186.2 184.59L190.84 187.27 190.81 196.5 190.81 196.5 190.84 187.27 186.2 184.59z"
        />
        <g id="el41uva8tgizv">
          <rect
            x={186.93}
            y={142}
            height={9.27}
            style={{
              transformOrigin: "186.93px 146.635px"
            }}
            className="animable"
            id="elubys6kruqwn"
            fill="#fff"
            transform="rotate(-60)"
          />
        </g>
        <g id="el20amji4ajxr">
          <rect
            x={186.9}
            y={154.77}
            height={9.27}
            style={{
              transformOrigin: "186.9px 159.405px"
            }}
            className="animable"
            id="elpiwenjqlz"
            fill="#fff"
            transform="rotate(-60)"
          />
        </g>
        <path
          style={{
            transformOrigin: "187.05px 104.8px"
          }}
          id="elklj70r1gn8s"
          className="animable"
          fill="#fff"
          d="M183.05 97.87L191.08 102.5 191.05 111.73 183.02 107.09 183.05 97.87z"
        />
        <path
          style={{
            transformOrigin: "187.015px 117.575px"
          }}
          id="el4nllxm8kzsk"
          className="animable"
          fill="#fff"
          d="M191.02 124.51L182.99 119.87 183.01 110.64 191.04 115.28 191.02 124.51z"
        />
        <path
          style={{
            transformOrigin: "186.88px 164.025px"
          }}
          id="elgnyqxva9xcw"
          className="animable"
          fill="#fff"
          d="M182.88 157.09L190.91 161.73 190.88 170.96 182.85 166.32 182.88 157.09z"
        />
        <path
          style={{
            transformOrigin: "186.85px 176.79px"
          }}
          id="el7g5xv18bg6"
          className="animable"
          fill="#fff"
          d="M190.85 183.72L182.82 179.09 182.84 169.86 190.88 174.5 190.85 183.72z"
        />
        <path
          style={{
            transformOrigin: "186.92px 151.25px"
          }}
          id="elnahff0nwip"
          className="animable"
          fill="#fff"
          d="M182.92 144.32L190.95 148.95 190.92 158.18 182.89 153.54 182.92 144.32z"
        />
        <path
          d="M180.15 206.12l13.21 7.62.19-66.26-13.21-7.63zm2.69-36.26l8 4.64v9.23l-8-4.64v-9.23zm8.08-11.68l-8-4.64v-9.22l8 4.63zm0 12.78l-8-4.64v-9.23l8 4.64zm-.07 25.54l-8-4.64v-9.22l3.39 2 4.64 2.68v9.23zm-8.06 8.13v-9.23l8 4.64v9.23z"
          style={{
            transformOrigin: "186.85px 176.795px"
          }}
          id="elwnjz1bl0kvb"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M180.36 134.12l13.2 7.63.19-66.27-13.21-7.63zm10.77-47.93l-8-4.64v-9.23l8 4.64zm0 12.77l-8-4.64v-9.23l3.4 2 4.64 2.68zm0 12.77l-8-4.63v-9.23l8 4.63zm0 12.78l-8-4.64v-9.23l8 4.64zm0 12.77l-8-4.64v-9.23l8 4.64z"
          style={{
            transformOrigin: "187.055px 104.8px"
          }}
          id="el4d9j554v3is"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M180.15 206.12l13.21 7.62.19-66.26-13.21-7.63zm2.69-36.26l8 4.64v9.23l-8-4.64v-9.23zm8.08-11.68l-8-4.64v-9.22l8 4.63zm0 12.78l-8-4.64v-9.23l8 4.64zm-.07 25.54l-8-4.64v-9.22l3.39 2 4.64 2.68v9.23zm-8.06 8.13v-9.23l8 4.64v9.23z"
          style={{
            transformOrigin: "186.85px 176.795px"
          }}
          id="elk7esyzi7spq"
          className="animable"
          fill="#455a64"
        />
        <path
          d="M180.36 134.12l13.2 7.63.19-66.27-13.21-7.63zm10.77-47.93l-8-4.64v-9.23l8 4.64zm0 12.77l-8-4.64v-9.23l3.4 2 4.64 2.68zm0 12.77l-8-4.63v-9.23l8 4.63zm0 12.78l-8-4.64v-9.23l8 4.64zm0 12.77l-8-4.64v-9.23l8 4.64z"
          style={{
            transformOrigin: "187.055px 104.8px"
          }}
          id="el6iec6buc156"
          className="animable"
          fill="#455a64"
        />
        <path
          style={{
            transformOrigin: "187.09px 92.025px"
          }}
          id="elm5mlynrso7j"
          className="animable"
          fill="#fff"
          d="M183.06 94.32L183.08 85.09 186.48 87.05 191.12 89.73 191.09 98.96 183.06 94.32 183.06 94.32z"
        />
        <path
          style={{
            transformOrigin: "187.155px 69.095px"
          }}
          id="eli17xmhcl4t"
          className="animable"
          fill="#fff"
          d="M180.54 67.85L180.56 62.71 193.77 70.33 193.75 75.48 180.54 67.85 180.54 67.85z"
        />
        <path
          style={{
            transformOrigin: "187.12px 79.255px"
          }}
          id="el0qczcn69c3zc"
          className="animable"
          fill="#fff"
          d="M183.09 81.55L183.12 72.32 191.15 76.96 191.13 86.19 183.09 81.55 183.09 81.55z"
        />
        <path
          style={{
            transformOrigin: "186.98px 130.345px"
          }}
          id="elolnk7xzp4sk"
          className="animable"
          fill="#fff"
          d="M182.97 123.41L182.97 123.41 191.01 128.05 191.01 128.05 190.98 137.28 182.95 132.64 182.97 123.41z"
        />
        <path
          style={{
            transformOrigin: "186.81px 189.565px"
          }}
          id="elpq1yv2pczhr"
          className="animable"
          fill="#fff"
          d="M182.78 191.86L182.81 182.63 186.2 184.59 190.84 187.27 190.81 196.5 190.81 196.5 182.78 191.86z"
        />
        <path
          style={{
            transformOrigin: "186.775px 202.335px"
          }}
          id="el5ha4mcno1do"
          className="animable"
          fill="#fff"
          d="M190.8 200.04L190.78 209.27 182.75 204.63 182.77 195.4 190.8 200.04z"
        />
        <path
          style={{
            transformOrigin: "318.54px 247.005px"
          }}
          id="elau2fquinkd4"
          className="animable"
          fill="#37474f"
          d="M352.37 264.71L352.35 268.92 284.71 229.3 284.73 225.09 352.37 264.71z"
        />
        <g id="el85mj9k13ayo">
          <ellipse
            cx={224.86}
            cy={214.42}
            rx={6.61}
            ry={11.41}
            style={{
              transformOrigin: "224.86px 214.42px"
            }}
            className="animable"
            id="el11jxz0wmi14k"
            fill="#e0e0e0"
            transform="rotate(-30.16)"
          />
        </g>
        <path
          d="M225 181.54c4.47 2.58 8.09 8.84 8.07 14s-3.65 7.21-8.12 4.63-8.09-8.84-8.07-14 3.6-7.17 8.12-4.63z"
          style={{
            transformOrigin: "224.975px 190.864px"
          }}
          id="elexslby6qyc"
          className="animable"
          fill="#e0e0e0"
        />
        <path
          d="M247 194.24c4.47 2.58 8.09 8.84 8.07 14s-3.65 7.21-8.12 4.63-8.09-8.84-8.07-14 3.6-7.21 8.12-4.63z"
          style={{
            transformOrigin: "246.975px 203.555px"
          }}
          id="elvbcxsyszw5m"
          className="animable"
          fill="#e0e0e0"
        />
        <path
          d="M268.88 230.52c4.47 2.58 8.08 8.84 8.07 14s-3.65 7.21-8.13 4.63-8.08-8.84-8.07-14 3.65-7.21 8.13-4.63z"
          style={{
            transformOrigin: "268.85px 239.835px"
          }}
          id="el31m1jry2hw3"
          className="animable"
          fill="#e0e0e0"
        />
        <g id="el2j1hukdjl9d">
          <ellipse
            cx={268.92}
            cy={216.24}
            rx={6.61}
            ry={11.41}
            style={{
              transformOrigin: "268.92px 216.24px"
            }}
            className="animable"
            id="elofxes2kr4u"
            fill="#e0e0e0"
            transform="rotate(-30.16)"
          />
        </g>
        <g id="eliurs2xrf96">
          <ellipse
            cx={246.86}
            cy={227.12}
            rx={6.61}
            ry={11.41}
            style={{
              transformOrigin: "246.86px 227.12px"
            }}
            className="animable"
            id="el4ubw6rw2wwh"
            fill="#e0e0e0"
            transform="rotate(-30.16)"
          />
        </g>
        <path
          d="M440.63 130.1L354 80.06a1.74 1.74 0 00-1.76-.17l-6.48 4.64a1.76 1.76 0 011.74.18l86.68 50.05a5.46 5.46 0 012.42 4.24l-.34 77.39a1.73 1.73 0 01-.69 1.56l6.56-4.7a1.8 1.8 0 00.63-1.52l.34-77.39a5.46 5.46 0 00-2.47-4.24z"
          style={{
            transformOrigin: "394.43px 148.838px"
          }}
          id="elx1y09uefwdo"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M436.26 216.43l.34-77.43a5.46 5.46 0 00-2.47-4.28l-86.68-50a1.76 1.76 0 00-1.74-.18 1.72 1.72 0 00-.74 1.61l-.34 77.39a5.46 5.46 0 002.47 4.28l86.68 50a1.69 1.69 0 001.79.14 1.73 1.73 0 00.69-1.53z"
          style={{
            transformOrigin: "390.615px 151.271px"
          }}
          id="elis3d1ofg7hr"
          className="animable"
          fill="#455a64"
        />
        <g id="el49ujrtv4w9n">
          <g
            style={{
              transformOrigin: "354.135px 143.695px"
            }}
            className="animable"
            id="el3fqke1adlyn"
            opacity={0.3}
          >
            <path
              id="el7aco0o49x74"
              className="animable"
              style={{
                transformOrigin: "354.135px 143.695px"
              }}
              d="M358.14 141.41L358.12 150.59 350.13 145.98 350.16 136.8 358.14 141.41z"
            />
          </g>
        </g>
        <g id="elx5xr614o8q">
          <g
            style={{
              transformOrigin: "354.135px 160.865px"
            }}
            className="animable"
            id="el4j6o5ib6vw"
            opacity={0.3}
          >
            <path
              id="el5ickj5fko1y"
              className="animable"
              style={{
                transformOrigin: "354.135px 160.865px"
              }}
              d="M358.14 158.59L358.12 167.76 350.13 163.15 350.16 153.97 358.14 158.59z"
            />
          </g>
        </g>
        <path
          style={{
            transformOrigin: "362.125px 165.48px"
          }}
          id="elagamy3bn6z7"
          className="animable"
          fill="#fff"
          d="M366.13 163.19L366.11 172.37 358.12 167.76 358.15 158.59 366.13 163.19z"
        />
        <g id="elax3pu3awer">
          <g
            style={{
              transformOrigin: "386.615px 162.445px"
            }}
            className="animable"
            id="eltt84cjl08b"
            opacity={0.5}
          >
            <path
              style={{
                transformOrigin: "386.615px 162.445px"
              }}
              id="eljkbznj56dxc"
              className="animable"
              fill="#fff"
              d="M410.38 174.37L410.37 177.95 362.85 150.52 362.86 146.94 410.38 174.37z"
            />
          </g>
        </g>
        <g id="elezgao1bxyei">
          <g
            style={{
              transformOrigin: "354.175px 129.62px"
            }}
            className="animable"
            id="elta68xyujznp"
            opacity={0.3}
          >
            <path
              id="elm3tvdu3be6k"
              className="animable"
              style={{
                transformOrigin: "354.175px 129.62px"
              }}
              d="M358.18 127.34L358.16 136.51 350.17 131.9 350.2 122.73 358.18 127.34z"
            />
          </g>
        </g>
        <g id="elawiz4325hm">
          <g
            style={{
              transformOrigin: "386.695px 134.29px"
            }}
            className="animable"
            id="el5l9nmlej5rc"
            opacity={0.5}
          >
            <path
              style={{
                transformOrigin: "386.695px 134.29px"
              }}
              id="elntyvm1uo6xs"
              className="animable"
              fill="#fff"
              d="M410.46 146.22L410.45 149.8 362.93 122.37 362.94 118.78 410.46 146.22z"
            />
          </g>
        </g>
        <g id="elbbjq9k3xksn">
          <g
            style={{
              transformOrigin: "386.655px 148.375px"
            }}
            className="animable"
            id="el82py46lkkvc"
            opacity={0.5}
          >
            <path
              style={{
                transformOrigin: "386.655px 148.375px"
              }}
              id="elemordzj4qqe"
              className="animable"
              fill="#fff"
              d="M410.42 160.3L410.41 163.88 362.89 136.45 362.9 132.87 410.42 160.3z"
            />
          </g>
        </g>
        <g id="el1ugnzqk45bt">
          <g
            style={{
              transformOrigin: "354.215px 115.545px"
            }}
            className="animable"
            id="elyal67uki5z"
            opacity={0.3}
          >
            <path
              id="el8sngj39m0v4"
              className="animable"
              style={{
                transformOrigin: "354.215px 115.545px"
              }}
              d="M358.22 113.26L358.2 122.44 350.21 117.83 350.24 108.65 358.22 113.26z"
            />
          </g>
        </g>
        <path
          style={{
            transformOrigin: "422.885px 155.185px"
          }}
          id="el257v4bj1wap"
          className="animable"
          fill="#fff"
          d="M431.1 155.33L431.07 164.51 414.67 155.04 414.7 145.86 431.1 155.33z"
        />
        <path
          style={{
            transformOrigin: "422.805px 183.34px"
          }}
          id="el61lp3i3uur3"
          className="animable"
          fill="#fff"
          d="M431.02 183.49L430.99 192.66 414.59 183.19 414.62 174.02 431.02 183.49z"
        />
        <path
          style={{
            transformOrigin: "422.845px 169.265px"
          }}
          id="eloc3uc4mn16h"
          className="animable"
          fill="#fff"
          d="M431.06 169.41L431.03 178.59 414.63 169.12 414.66 159.94 431.06 169.41z"
        />
        <g id="elzrg2lbe6vnq">
          <g
            style={{
              transformOrigin: "409.95px 125.895px"
            }}
            className="animable"
            id="elw81qb8v9cl"
            opacity={0.3}
          >
            <path
              d="M434.13 134.75a5.46 5.46 0 012.47 4.25v7.41l-53.3-30.78v-10.25z"
              id="elfjowd5mkwyw"
              className="animable"
              style={{
                transformOrigin: "409.95px 125.895px"
              }}
            />
          </g>
        </g>
        <path
          style={{
            transformOrigin: "363.625px 100.325px"
          }}
          id="elg6v6rqbjhmv"
          className="animable"
          fill="#fff"
          d="M378.49 107.21L348.77 90.05 348.76 93.44 378.49 110.6 378.49 107.21z"
        />
        <path
          style={{
            transformOrigin: "429.785px 135.29px"
          }}
          id="eltnvii7ho0p9"
          className="animable"
          fill="#fff"
          d="M426.79 133.08L426.79 134.04 432.78 137.5 432.78 136.54 426.79 133.08z"
        />
        <path
          style={{
            transformOrigin: "429.775px 140.2px"
          }}
          id="elxnndypf7boj"
          className="animable"
          fill="#fff"
          d="M426.78 138.95L432.76 142.4 432.77 141.46 426.78 138 426.78 138.95z"
        />
        <path
          style={{
            transformOrigin: "429.785px 136.925px"
          }}
          id="elm9mthyuo06f"
          className="animable"
          fill="#fff"
          d="M426.79 135.68L432.77 139.13 432.78 138.18 426.79 134.72 426.79 135.68z"
        />
        <path
          style={{
            transformOrigin: "429.775px 138.56px"
          }}
          id="elsm250gkt3h"
          className="animable"
          fill="#fff"
          d="M426.78 137.31L432.77 140.76 432.77 139.82 426.78 136.36 426.78 137.31z"
        />
        <path
          d="M440.63 130.1L354 80.06a1.74 1.74 0 00-1.76-.17l-6.48 4.64a1.76 1.76 0 011.74.18l86.68 50.05a5.15 5.15 0 011.89 2.12l6.81-3.91a5.48 5.48 0 00-2.25-2.87z"
          style={{
            transformOrigin: "394.32px 108.303px"
          }}
          id="elkbv9y4g0j8n"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M436 136.88a4.89 4.89 0 01.58 2.16l-.34 77.39a1.73 1.73 0 01-.69 1.56l6.56-4.7a1.8 1.8 0 00.63-1.52l.34-77.39a4.28 4.28 0 00-.27-1.41z"
          style={{
            transformOrigin: "439.315px 175.48px"
          }}
          id="ela0wxdqjdgiu"
          className="animable"
          fill="#263238"
        />
        <path
          d="M306.76 74.47l-86.68-50a1.77 1.77 0 00-1.76-.18l-6.48 4.65a1.76 1.76 0 011.74.18l86.68 50.05a5.46 5.46 0 012.47 4.28l-.34 35.39a1.75 1.75 0 01-.68 1.56l6.56-4.7a1.81 1.81 0 00.62-1.51l.34-35.4a5.46 5.46 0 00-2.47-4.32z"
          style={{
            transformOrigin: "260.535px 72.2676px"
          }}
          id="el3a0q9i5631m"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M302.39 118.8l.34-35.39a5.46 5.46 0 00-2.47-4.28l-86.68-50a1.76 1.76 0 00-1.74-.18 1.74 1.74 0 00-.74 1.6l-.34 35.4a5.46 5.46 0 002.47 4.28l86.68 50a1.71 1.71 0 001.8.14 1.75 1.75 0 00.68-1.57z"
          style={{
            transformOrigin: "256.745px 74.6806px"
          }}
          id="elyesam29nsli"
          className="animable"
          fill="#455a64"
        />
        <g id="elm9fr2boo0ac">
          <g
            style={{
              transformOrigin: "221.95px 60.65px"
            }}
            className="animable"
            id="elfdahghs3tde"
            opacity={0.5}
          >
            <path
              style={{
                transformOrigin: "221.95px 60.65px"
              }}
              id="el7uco70f0ez2"
              className="animable"
              fill="#fff"
              d="M227.47 57.5L227.43 70.15 216.43 63.8 216.46 51.15 227.47 57.5z"
            />
          </g>
        </g>
        <g id="elmp538uge4g8">
          <g
            style={{
              transformOrigin: "239.11px 70.155px"
            }}
            className="animable"
            id="elle5us4m46wh"
            opacity={0.5}
          >
            <path
              style={{
                transformOrigin: "239.11px 70.155px"
              }}
              id="el70ujxd97wq5"
              className="animable"
              fill="#fff"
              d="M244.63 67L244.59 79.66 233.59 73.31 233.62 60.65 244.63 67z"
            />
          </g>
        </g>
        <g id="el5cpqu8erroi">
          <g
            style={{
              transformOrigin: "256.26px 79.655px"
            }}
            className="animable"
            id="el6xxl357wloh"
            opacity={0.5}
          >
            <path
              style={{
                transformOrigin: "256.26px 79.655px"
              }}
              id="elt3ost0tf3nf"
              className="animable"
              fill="#fff"
              d="M261.78 76.51L261.75 89.16 250.74 82.81 250.78 70.15 261.78 76.51z"
            />
          </g>
        </g>
        <g id="elrift6q7mtvq">
          <g
            style={{
              transformOrigin: "273.42px 89.16px"
            }}
            className="animable"
            id="elnov421grwek"
            opacity={0.5}
          >
            <path
              style={{
                transformOrigin: "273.42px 89.16px"
              }}
              id="elzs0xc0lk3kl"
              className="animable"
              fill="#fff"
              d="M278.94 86.01L278.91 98.66 267.9 92.31 267.94 79.66 278.94 86.01z"
            />
          </g>
        </g>
        <g id="elufsdbnweycf">
          <g
            style={{
              transformOrigin: "290.58px 98.665px"
            }}
            className="animable"
            id="el2b8u6trqvwl"
            opacity={0.5}
          >
            <path
              style={{
                transformOrigin: "290.58px 98.665px"
              }}
              id="el3maoiahbusp"
              className="animable"
              fill="#fff"
              d="M296.1 95.51L296.06 108.17 285.06 101.81 285.1 89.16 296.1 95.51z"
            />
          </g>
        </g>
        <g id="elg4sqica1h8d">
          <g
            style={{
              transformOrigin: "276.07px 70.275px"
            }}
            className="animable"
            id="elk4s6a0v4kpa"
            opacity={0.3}
          >
            <path
              d="M300.26 79.12a5.46 5.46 0 012.47 4.28v7.4L249.41 60V49.75z"
              id="elj315n0ihwrj"
              className="animable"
              style={{
                transformOrigin: "276.07px 70.275px"
              }}
            />
          </g>
        </g>
        <path
          style={{
            transformOrigin: "229.765px 44.695px"
          }}
          id="el4a3o8m4ma24"
          className="animable"
          fill="#fff"
          d="M244.63 51.58L214.91 34.42 214.9 37.81 244.62 54.97 244.63 51.58z"
        />
        <path
          style={{
            transformOrigin: "295.915px 79.655px"
          }}
          id="el5mt0sb7gg0o"
          className="animable"
          fill="#fff"
          d="M292.93 77.45L292.92 78.41 298.91 81.86 298.91 80.91 292.93 77.45z"
        />
        <path
          style={{
            transformOrigin: "295.905px 84.57px"
          }}
          id="elg06v38yjea"
          className="animable"
          fill="#fff"
          d="M292.91 83.32L298.9 86.77 298.9 85.83 292.91 82.37 292.91 83.32z"
        />
        <path
          style={{
            transformOrigin: "295.915px 81.295px"
          }}
          id="elg7yjf8lomm"
          className="animable"
          fill="#fff"
          d="M292.92 80.05L298.91 83.5 298.91 82.55 292.92 79.09 292.92 80.05z"
        />
        <path
          style={{
            transformOrigin: "295.905px 82.93px"
          }}
          id="el05zby24z73to"
          className="animable"
          fill="#fff"
          d="M292.91 81.68L298.9 85.13 298.9 84.19 292.92 80.73 292.91 81.68z"
        />
        <path
          d="M306.76 74.47l-86.68-50a1.77 1.77 0 00-1.76-.18l-6.48 4.65a1.76 1.76 0 011.74.18l86.68 50.05a5.15 5.15 0 011.89 2.12l6.85-3.95a5.44 5.44 0 00-2.24-2.87z"
          style={{
            transformOrigin: "260.42px 52.7126px"
          }}
          id="el47kk1jgqj5"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M302.15 81.25a4.89 4.89 0 01.58 2.16l-.34 35.39a1.75 1.75 0 01-.68 1.56l6.56-4.7a1.81 1.81 0 00.62-1.51l.34-35.4a4.28 4.28 0 00-.27-1.41z"
          style={{
            transformOrigin: "305.47px 98.85px"
          }}
          id="elcostz2mb0oi"
          className="animable"
          fill="#263238"
        />
        <path
          d="M440.63 230.46l-86.68-50a1.77 1.77 0 00-1.76-.18l-6.48 4.65a1.76 1.76 0 011.74.18l86.68 50.05a5.46 5.46 0 012.47 4.28l-.34 118.42a1.73 1.73 0 01-.69 1.56l6.56-4.7a1.8 1.8 0 00.63-1.52l.34-118.42a5.46 5.46 0 00-2.47-4.32z"
          style={{
            transformOrigin: "394.405px 269.773px"
          }}
          id="el1q3cf6weid1"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M436.26 357.82l.34-118.42a5.46 5.46 0 00-2.47-4.28l-86.68-50.05a1.76 1.76 0 00-1.74-.18 1.72 1.72 0 00-.74 1.6l-.34 118.43a5.46 5.46 0 002.47 4.28l86.68 50a1.69 1.69 0 001.79.14 1.73 1.73 0 00.69-1.52z"
          style={{
            transformOrigin: "390.615px 272.136px"
          }}
          id="elh846vjie3wn"
          className="animable"
          fill="#7519ff"
        />
        <g id="elmy2l24k9tv">
          <g
            style={{
              transformOrigin: "409.94px 226.27px"
            }}
            className="animable"
            id="elv0wbgcgua8"
            opacity={0.3}
          >
            <path
              d="M434.13 235.11a5.46 5.46 0 012.47 4.28v7.4L383.28 216v-10.25z"
              id="eluh5c4tzenkf"
              className="animable"
              style={{
                transformOrigin: "409.94px 226.27px"
              }}
            />
          </g>
        </g>
        <path
          style={{
            transformOrigin: "429.32px 315.065px"
          }}
          id="elop94m2e97h"
          className="animable"
          fill="none"
          d="M430.16 315.57L430.16 315.57 429.57 314.56 428.48 315.19 429.57 314.56 430.16 315.57z"
        />
        <path
          style={{
            transformOrigin: "429.39px 289.19px"
          }}
          id="el81u8xfvcm5f"
          className="animable"
          fill="none"
          d="M430.23 289.7L430.23 289.7 429.65 288.68 428.55 289.31 429.65 288.68 430.23 289.7z"
        />
        <path
          style={{
            transformOrigin: "427.195px 277.13px"
          }}
          id="elwyacxekuc2"
          className="animable"
          fill="none"
          d="M427.49 276.96L426.9 277.3 427.49 276.96 427.49 276.96z"
        />
        <path
          style={{
            transformOrigin: "380.58px 277.275px"
          }}
          id="elr03ryuutiq"
          className="animable"
          fill="none"
          d="M381.42 278.89L379.74 275.99 380.33 275.66 379.74 275.99 381.42 278.89 381.42 278.89z"
        />
        <path
          style={{
            transformOrigin: "427.205px 344.615px"
          }}
          id="elglezbymxjn7"
          className="animable"
          fill="none"
          d="M427.7 344.96L427.3 344.27 426.71 344.61 427.3 344.27 427.7 344.96z"
        />
        <path
          style={{
            transformOrigin: "427.64px 314.245px"
          }}
          id="elf3f3wkq09sj"
          className="animable"
          fill="none"
          d="M428.48 315.19L427.39 313.3 426.8 313.63 427.39 313.3 428.48 315.19z"
        />
        <path
          style={{
            transformOrigin: "381.39px 287.25px"
          }}
          id="eldh2zb7enuxj"
          className="animable"
          fill="none"
          d="M380.3 286.11L381.39 288.01 382.49 287.37 383.07 288.39 383.07 288.39 382.49 287.37 381.39 288.01 380.3 286.11 379.71 286.45 379.71 286.45 380.3 286.11z"
        />
        <rect
          x={380.08}
          y={260.06}
          height={0.68}
          style={{
            transformOrigin: "380.08px 260.4px"
          }}
          id="elirrix8oe5zd"
          className="animable"
          fill="none"
        />
        <path
          style={{
            transformOrigin: "382.14px 318.855px"
          }}
          id="eloo64r7m2x9"
          className="animable"
          fill="none"
          d="M382.98 319.36L382.98 319.36 382.4 318.35 381.3 318.98 382.4 318.35 382.98 319.36z"
        />
        <path
          style={{
            transformOrigin: "380.155px 318.175px"
          }}
          id="el1u3e1ip87f3"
          className="animable"
          fill="none"
          d="M379.62 317.42L380.21 317.09 380.21 317.09 379.62 317.42 380.69 319.26 379.62 317.42z"
        />
        <path
          style={{
            transformOrigin: "429.23px 346.04px"
          }}
          id="elyhibf08nz59"
          className="animable"
          fill="none"
          d="M429.49 345.54L430.07 346.55 429.49 345.53 428.39 346.17 428.39 346.17 429.49 345.54z"
        />
        <path
          style={{
            transformOrigin: "427.17px 287.59px"
          }}
          id="el0p76fk3mc6ge"
          className="animable"
          fill="none"
          d="M426.88 287.76L426.88 287.76 427.46 287.42 426.88 287.76z"
        />
        <path
          style={{
            transformOrigin: "429.17px 264.99px"
          }}
          id="elxtpmyr91sw8"
          className="animable"
          fill="none"
          d="M429.72 264.68L428.62 265.3 429.72 264.68 429.72 264.68z"
        />
        <path
          style={{
            transformOrigin: "351.6px 270.68px"
          }}
          id="elz7bswelyog"
          className="animable"
          fill="#fff"
          d="M354.65 268.74L348.57 265.23 348.55 272.62 354.63 276.13 354.65 268.74z"
        />
        <path
          style={{
            transformOrigin: "351.63px 260.22px"
          }}
          id="elqntbqbyvc4j"
          className="animable"
          fill="#fff"
          d="M354.68 258.28L348.6 254.77 348.58 262.16 354.66 265.67 354.68 258.28z"
        />
        <path
          style={{
            transformOrigin: "363.625px 200.685px"
          }}
          id="elnf9b6vordv"
          className="animable"
          fill="#fff"
          d="M378.49 207.56L348.77 190.41 348.76 193.8 378.49 210.96 378.49 207.56z"
        />
        <path
          style={{
            transformOrigin: "428.62px 265.03px"
          }}
          id="elyz800odnabs"
          className="animable"
          fill="#fff"
          d="M430.3 265.68L429.72 264.68 428.62 265.3 427.53 263.42 426.94 263.74 428.62 266.64 430.3 265.68z"
        />
        <path
          style={{
            transformOrigin: "351.675px 244.805px"
          }}
          id="el5qaoucjumfi"
          className="animable"
          fill="#fff"
          d="M354.73 242.86L348.64 239.35 348.62 246.74 354.7 250.26 354.73 242.86z"
        />
        <path
          style={{
            transformOrigin: "351.705px 234.345px"
          }}
          id="el122s0zaa5ha"
          className="animable"
          fill="#fff"
          d="M354.76 232.4L348.67 228.89 348.65 236.28 354.74 239.8 354.76 232.4z"
        />
        <path
          style={{
            transformOrigin: "386.62px 240.92px"
          }}
          id="elg1m06m3aqh9"
          className="animable"
          fill="#7519ff"
          d="M348.71 215.33L348.69 222.73 424.53 266.51 424.55 259.12 348.71 215.33z"
        />
        <path
          d="M424.53 266.51l8.16 4.71v-7.39l-8.16-4.71zm3-3.09l1.09 1.88 1.1-.62.58 1-1.68 1-1.68-2.9z"
          style={{
            transformOrigin: "428.61px 265.17px"
          }}
          id="elf8thuf7n8o"
          className="animable"
          fill="#7519ff"
        />
        <path
          style={{
            transformOrigin: "386.65px 229.91px"
          }}
          id="el304y9yazh59"
          className="animable"
          fill="#7519ff"
          d="M348.74 204.32L348.72 211.71 424.56 255.5 424.58 248.11 348.74 204.32z"
        />
        <path
          style={{
            transformOrigin: "428.65px 254.005px"
          }}
          id="elxw15294womm"
          className="animable"
          fill="#fff"
          d="M430.33 254.67L429.75 253.66 428.65 254.28 427.56 252.39 426.97 252.73 428.65 255.62 430.33 254.67z"
        />
        <path
          d="M424.56 255.5l8.16 4.71v-7.39l-8.16-4.71zm3-3.11l1.09 1.89 1.1-.62.58 1-1.68 1-1.65-2.93z"
          style={{
            transformOrigin: "428.64px 254.16px"
          }}
          id="el2c7mhi35221"
          className="animable"
          fill="#7519ff"
        />
        <path
          style={{
            transformOrigin: "381.49px 251.395px"
          }}
          id="elpanilcodi3q"
          className="animable"
          fill="#fff"
          d="M383.17 252.05L382.59 251.04 381.49 251.67 380.4 249.78 379.81 250.11 381.49 253.01 383.17 252.05z"
        />
        <path
          d="M385.56 257.59v-7.39l-8.16-4.71v7.39zm-5.16-7.81l1.09 1.89 1.1-.63.58 1-1.68 1-1.68-2.9z"
          style={{
            transformOrigin: "381.48px 251.54px"
          }}
          id="elv21tsaykp9"
          className="animable"
          fill="#7519ff"
        />
        <path
          style={{
            transformOrigin: "367.595px 253.995px"
          }}
          id="elh7yyugv2vvl"
          className="animable"
          fill="#7519ff"
          d="M357.8 252.04L377.37 263.34 377.37 263.34 377.37 263.34 377.39 255.95 357.82 244.65 357.8 252.04z"
        />
        <path
          style={{
            transformOrigin: "367.625px 243.535px"
          }}
          id="elejlepd55955"
          className="animable"
          fill="#7519ff"
          d="M377.42 245.49L357.85 234.19 357.83 241.59 377.4 252.88 377.42 245.49z"
        />
        <path
          style={{
            transformOrigin: "381.465px 261.855px"
          }}
          id="elluadn7qn82r"
          className="animable"
          fill="#fff"
          d="M381.46 263.47L383.14 262.51 382.56 261.5 381.46 262.12 380.37 260.24 379.79 260.57 381.46 263.47z"
        />
        <path
          d="M377.37 263.34l8.16 4.71v-7.39l-8.14-4.66v7.39zm3-3.1l1.09 1.88 1.1-.62.58 1-1.68 1-1.67-2.9z"
          style={{
            transformOrigin: "381.45px 262.025px"
          }}
          id="eldpeyrgdvggw"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M424.46 290.53l8.16 4.71v-7.39l-8.16-4.71zm2.42-2.77l.58-.34 1.09 1.89 1.1-.63.58 1-1.68 1-1.67-2.9z"
          style={{
            transformOrigin: "428.54px 289.19px"
          }}
          id="elwabntreujxo"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M424.42 306l8.16 4.71v-7.39l-8.16-4.71zm3-3.1l1.09 1.89 1.09-.63.59 1-1.68 1-1.68-2.9z"
          style={{
            transformOrigin: "428.5px 304.66px"
          }}
          id="ellx58qo4x7u8"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M424.39 316.41l8.16 4.71v-7.4l-8.14-4.72zm3-3.11l1.09 1.89 1.1-.63.58 1-1.68 1-1.68-2.89z"
          style={{
            transformOrigin: "428.47px 315.06px"
          }}
          id="elnrfodqyfrp"
          className="animable"
          fill="#7519ff"
        />
        <path
          style={{
            transformOrigin: "414.615px 307.06px"
          }}
          id="elcjme8ce9z8"
          className="animable"
          fill="#7519ff"
          d="M404.84 297.71L404.82 305.11 424.39 316.41 424.39 316.41 424.41 309.01 404.84 297.71z"
        />
        <path
          style={{
            transformOrigin: "414.645px 296.605px"
          }}
          id="elgp131r0zj7c"
          className="animable"
          fill="#7519ff"
          d="M404.87 287.26L404.85 294.65 424.42 305.95 424.44 298.56 404.87 287.26z"
        />
        <path
          style={{
            transformOrigin: "414.69px 281.185px"
          }}
          id="elh5vlmwouvj7"
          className="animable"
          fill="#7519ff"
          d="M404.91 271.84L404.89 279.23 424.46 290.53 424.49 283.14 404.91 271.84z"
        />
        <path
          style={{
            transformOrigin: "414.715px 270.725px"
          }}
          id="elarshw9d24np"
          className="animable"
          fill="#7519ff"
          d="M404.94 261.38L404.92 268.77 424.49 280.07 424.51 272.68 404.94 261.38z"
        />
        <path
          style={{
            transformOrigin: "428.58px 278.58px"
          }}
          id="eluyidhtcz838"
          className="animable"
          fill="#fff"
          d="M430.26 279.24L429.68 278.23 428.58 278.86 427.49 276.96 426.9 277.3 428.58 280.2 430.26 279.24z"
        />
        <path
          d="M424.51 272.68v7.39l8.16 4.71v-7.39l-8.16-4.71zm3 4.28l1.09 1.9 1.1-.63.58 1-1.68 1-1.68-2.9z"
          style={{
            transformOrigin: "428.59px 278.73px"
          }}
          id="eleui6w9kavk"
          className="animable"
          fill="#7519ff"
        />
        <path
          style={{
            transformOrigin: "398.765px 271.99px"
          }}
          id="elj6dbs5lx1fj"
          className="animable"
          fill="#fff"
          d="M395.71 273.93L401.8 277.44 401.82 270.05 395.73 266.54 395.71 273.93z"
        />
        <path
          style={{
            transformOrigin: "398.795px 261.535px"
          }}
          id="elwmumlv5svf"
          className="animable"
          fill="#fff"
          d="M401.85 259.59L395.76 256.08 395.74 263.47 401.82 266.99 401.85 259.59z"
        />
        <path
          style={{
            transformOrigin: "428.555px 289.04px"
          }}
          id="elycweq3c0fb"
          className="animable"
          fill="#fff"
          d="M428.55 290.66L430.23 289.7 430.23 289.7 429.65 288.68 428.55 289.31 427.46 287.42 426.88 287.76 426.88 287.76 428.55 290.66z"
        />
        <path
          style={{
            transformOrigin: "367.555px 269.415px"
          }}
          id="elq3yhaj7v2ns"
          className="animable"
          fill="#7519ff"
          d="M357.76 267.46L377.33 278.76 377.33 278.76 377.33 278.76 377.35 271.37 357.78 260.07 357.76 267.46z"
        />
        <path
          d="M377.33 278.76l8.16 4.71v-7.39l-8.16-4.71v7.39zm3-3.1l1.09 1.89 1.09-.63.59 1-1.68 1-1.68-2.9z"
          style={{
            transformOrigin: "381.41px 277.42px"
          }}
          id="elq4r1b9xulyo"
          className="animable"
          fill="#7519ff"
        />
        <path
          style={{
            transformOrigin: "381.42px 277.275px"
          }}
          id="elc2v4iqzhfy"
          className="animable"
          fill="#fff"
          d="M381.42 278.89L381.42 278.89 383.1 277.93 382.51 276.92 381.42 277.55 380.33 275.66 379.74 275.99 381.42 278.89z"
        />
        <path
          style={{
            transformOrigin: "381.39px 287.73px"
          }}
          id="elhy3abpo8d8s"
          className="animable"
          fill="#fff"
          d="M381.39 288.01L380.3 286.11 379.71 286.45 381.39 289.35 383.07 288.39 382.49 287.37 381.39 288.01z"
        />
        <path
          d="M377.3 289.22l8.16 4.71v-7.39l-8.16-4.71zm3-3.11l1.09 1.9 1.1-.64.58 1-1.68 1-1.68-2.9z"
          style={{
            transformOrigin: "381.38px 287.88px"
          }}
          id="el2opd785ny8z"
          className="animable"
          fill="#7519ff"
        />
        <path
          style={{
            transformOrigin: "362.19px 291.52px"
          }}
          id="eln9e3lhm65hd"
          className="animable"
          fill="#fff"
          d="M365.22 296.97L365.24 289.58 359.16 286.07 359.14 293.46 365.22 296.97z"
        />
        <path
          style={{
            transformOrigin: "367.525px 279.875px"
          }}
          id="elw3hn9z4vw8g"
          className="animable"
          fill="#7519ff"
          d="M357.73 277.92L377.3 289.22 377.3 289.22 377.32 281.83 377.32 281.83 357.75 270.53 357.73 277.92z"
        />
        <path
          style={{
            transformOrigin: "362.845px 308.195px"
          }}
          id="el4qogyo7xdao"
          className="animable"
          fill="#7519ff"
          d="M377.23 312.8L377.23 312.8 348.48 296.2 348.46 303.59 377.21 320.19 377.23 312.8 377.23 312.8z"
        />
        <path
          style={{
            transformOrigin: "381.3px 318.705px"
          }}
          id="eli4ib8t4igfp"
          className="animable"
          fill="#fff"
          d="M380.69 319.26L381.3 320.32 382.98 319.36 382.4 318.35 381.3 318.98 380.21 317.09 379.62 317.42 380.69 319.26z"
        />
        <path
          d="M377.23 312.8v7.39l8.16 4.71v-7.39l-8.16-4.71zm3 4.29l1.07 1.91 1.1-.63.58 1-1.68 1-.61-1.06-1.07-1.84z"
          style={{
            transformOrigin: "381.31px 318.85px"
          }}
          id="elqbb8c01o85"
          className="animable"
          fill="#7519ff"
        />
        <path
          style={{
            transformOrigin: "409.935px 335.385px"
          }}
          id="elg7wwkwb8jmt"
          className="animable"
          fill="#7519ff"
          d="M424.32 339.99L395.57 323.39 395.55 330.78 424.3 347.38 424.3 347.38 424.3 347.38 424.32 339.99z"
        />
        <g id="el9ijhm0skqov">
          <path
            style={{
              transformOrigin: "386.62px 240.92px"
            }}
            className="animable"
            id="el3sffvnjeqnj"
            opacity={0.3}
            d="M348.71 215.33L348.69 222.73 424.53 266.51 424.55 259.12 348.71 215.33z"
          />
        </g>
        <g id="elarhhp2gf5sq">
          <path
            style={{
              transformOrigin: "386.65px 229.91px"
            }}
            className="animable"
            id="elr6l0tv3jpu9"
            opacity={0.3}
            d="M348.74 204.32L348.72 211.71 424.56 255.5 424.58 248.11 348.74 204.32z"
          />
        </g>
        <g id="el9y4oy33blxm">
          <path
            style={{
              transformOrigin: "367.595px 253.995px"
            }}
            className="animable"
            id="ela89et9ovs0g"
            opacity={0.3}
            d="M357.8 252.04L377.37 263.34 377.37 263.34 377.37 263.34 377.39 255.95 357.82 244.65 357.8 252.04z"
          />
        </g>
        <g id="el2chl4bif4l">
          <path
            style={{
              transformOrigin: "367.625px 243.535px"
            }}
            className="animable"
            id="elvmne2u3r7un"
            opacity={0.3}
            d="M377.42 245.49L357.85 234.19 357.83 241.59 377.4 252.88 377.42 245.49z"
          />
        </g>
        <g id="elamvoou83nya">
          <path
            style={{
              transformOrigin: "414.615px 307.06px"
            }}
            className="animable"
            id="el4obyyoqysm5"
            opacity={0.3}
            d="M404.84 297.71L404.82 305.11 424.39 316.41 424.39 316.41 424.41 309.01 404.84 297.71z"
          />
        </g>
        <g id="elbr9sbcfrqkh">
          <path
            style={{
              transformOrigin: "414.645px 296.605px"
            }}
            className="animable"
            id="elkjo62axmq2h"
            opacity={0.3}
            d="M404.87 287.26L404.85 294.65 424.42 305.95 424.44 298.56 404.87 287.26z"
          />
        </g>
        <g id="elyz41gedcqac">
          <path
            style={{
              transformOrigin: "414.69px 281.185px"
            }}
            className="animable"
            id="elirywbr5qty"
            opacity={0.3}
            d="M404.91 271.84L404.89 279.23 424.46 290.53 424.49 283.14 404.91 271.84z"
          />
        </g>
        <g id="el6ap5of2kffx">
          <path
            style={{
              transformOrigin: "414.715px 270.725px"
            }}
            className="animable"
            id="elhqbty1h8u8p"
            opacity={0.3}
            d="M404.94 261.38L404.92 268.77 424.49 280.07 424.51 272.68 404.94 261.38z"
          />
        </g>
        <g id="elq48engr1vp9">
          <path
            style={{
              transformOrigin: "367.555px 269.415px"
            }}
            className="animable"
            id="elq37uj73ndda"
            opacity={0.3}
            d="M357.76 267.46L377.33 278.76 377.33 278.76 377.33 278.76 377.35 271.37 357.78 260.07 357.76 267.46z"
          />
        </g>
        <g id="el2rs5rcpywax">
          <path
            style={{
              transformOrigin: "367.525px 279.875px"
            }}
            className="animable"
            id="elqgwqlae4b3"
            opacity={0.3}
            d="M357.73 277.92L377.3 289.22 377.3 289.22 377.32 281.83 377.32 281.83 357.75 270.53 357.73 277.92z"
          />
        </g>
        <g id="el3nlr3yw485v">
          <path
            style={{
              transformOrigin: "362.845px 308.195px"
            }}
            className="animable"
            id="eld82ykon4uef"
            opacity={0.3}
            d="M377.23 312.8L377.23 312.8 348.48 296.2 348.46 303.59 377.21 320.19 377.23 312.8 377.23 312.8z"
          />
        </g>
        <g id="elcb4x7vkwn94">
          <path
            style={{
              transformOrigin: "409.935px 335.385px"
            }}
            className="animable"
            id="elo19iu6wehom"
            opacity={0.3}
            d="M424.32 339.99L395.57 323.39 395.55 330.78 424.3 347.38 424.3 347.38 424.3 347.38 424.32 339.99z"
          />
        </g>
        <path
          d="M424.32 340v7.39l8.16 4.71v-7.39l-8.16-4.71zm3 4.28l.4.69.69 1.21 1.1-.64.58 1-1.68 1-1.68-2.9z"
          style={{
            transformOrigin: "428.4px 346.05px"
          }}
          id="elnfq8a82ibx"
          className="animable"
          fill="#7519ff"
        />
        <g id="el9vryzqg2kg">
          <path
            d="M424.53 266.51l8.16 4.71v-7.39l-8.16-4.71zm3-3.09l1.09 1.88 1.1-.62.58 1-1.68 1-1.68-2.9z"
            style={{
              transformOrigin: "428.61px 265.17px"
            }}
            className="animable"
            id="elga8ufr4y3dk"
            opacity={0.4}
          />
        </g>
        <g id="el852uddea8tv">
          <path
            d="M424.56 255.5l8.16 4.71v-7.39l-8.16-4.71zm3-3.11l1.09 1.89 1.1-.62.58 1-1.68 1-1.65-2.93z"
            style={{
              transformOrigin: "428.64px 254.16px"
            }}
            className="animable"
            id="eleo5ykmx33se"
            opacity={0.4}
          />
        </g>
        <g id="elbhqxmfzz8v7">
          <path
            d="M385.56 257.59v-7.39l-8.16-4.71v7.39zm-5.16-7.81l1.09 1.89 1.1-.63.58 1-1.68 1-1.68-2.9z"
            style={{
              transformOrigin: "381.48px 251.54px"
            }}
            className="animable"
            id="ell1drsqwn0n"
            opacity={0.4}
          />
        </g>
        <g id="el7r932d50oq7">
          <path
            d="M377.37 263.34l8.16 4.71v-7.39l-8.14-4.66v7.39zm3-3.1l1.09 1.88 1.1-.62.58 1-1.68 1-1.67-2.9z"
            style={{
              transformOrigin: "381.45px 262.025px"
            }}
            className="animable"
            id="elqvsi3mt466"
            opacity={0.4}
          />
        </g>
        <g id="el6v6vzs8ah5y">
          <path
            d="M424.46 290.53l8.16 4.71v-7.39l-8.16-4.71zm2.42-2.77l.58-.34 1.09 1.89 1.1-.63.58 1-1.68 1-1.67-2.9z"
            style={{
              transformOrigin: "428.54px 289.19px"
            }}
            className="animable"
            id="elzc82erildur"
            opacity={0.4}
          />
        </g>
        <g id="eljl3vzibigvm">
          <path
            d="M424.42 306l8.16 4.71v-7.39l-8.16-4.71zm3-3.1l1.09 1.89 1.09-.63.59 1-1.68 1-1.68-2.9z"
            style={{
              transformOrigin: "428.5px 304.66px"
            }}
            className="animable"
            id="el5kqghxagl6j"
            opacity={0.4}
          />
        </g>
        <g id="elm9542xs2rqp">
          <path
            d="M424.39 316.41l8.16 4.71v-7.4l-8.14-4.72zm3-3.11l1.09 1.89 1.1-.63.58 1-1.68 1-1.68-2.89z"
            style={{
              transformOrigin: "428.47px 315.06px"
            }}
            className="animable"
            id="elwmpucy9r09s"
            opacity={0.4}
          />
        </g>
        <g id="elemtrz4sip4d">
          <path
            d="M424.51 272.68v7.39l8.16 4.71v-7.39l-8.16-4.71zm3 4.28l1.09 1.9 1.1-.63.58 1-1.68 1-1.68-2.9z"
            style={{
              transformOrigin: "428.59px 278.73px"
            }}
            className="animable"
            id="el76qxf02qoob"
            opacity={0.4}
          />
        </g>
        <g id="elf3pw3c1krd8">
          <path
            d="M377.33 278.76l8.16 4.71v-7.39l-8.16-4.71v7.39zm3-3.1l1.09 1.89 1.09-.63.59 1-1.68 1-1.68-2.9z"
            style={{
              transformOrigin: "381.41px 277.42px"
            }}
            className="animable"
            id="elpgobm7b5zbh"
            opacity={0.4}
          />
        </g>
        <g id="el1hgxd7892hn">
          <path
            d="M377.3 289.22l8.16 4.71v-7.39l-8.16-4.71zm3-3.11l1.09 1.9 1.1-.64.58 1-1.68 1-1.68-2.9z"
            style={{
              transformOrigin: "381.38px 287.88px"
            }}
            className="animable"
            id="ell0scmsdr5z"
            opacity={0.4}
          />
        </g>
        <g id="el3vypa956966">
          <path
            d="M377.23 312.8v7.39l8.16 4.71v-7.39l-8.16-4.71zm3 4.29l1.07 1.91 1.1-.63.58 1-1.68 1-.61-1.06-1.07-1.84z"
            style={{
              transformOrigin: "381.31px 318.85px"
            }}
            className="animable"
            id="elni9gb80eegm"
            opacity={0.4}
          />
        </g>
        <g id="elzijd9e87oas">
          <path
            d="M424.32 340v7.39l8.16 4.71v-7.39l-8.16-4.71zm3 4.28l.4.69.69 1.21 1.1-.64.58 1-1.68 1-1.68-2.9z"
            style={{
              transformOrigin: "428.4px 346.05px"
            }}
            className="animable"
            id="el68mtz74o11"
            opacity={0.4}
          />
        </g>
        <path
          style={{
            transformOrigin: "428.39px 345.89px"
          }}
          id="elu4npbbqthbr"
          className="animable"
          fill="#fff"
          d="M430.07 346.55L429.49 345.54 428.39 346.17 428.39 346.17 427.7 344.96 427.3 344.27 426.71 344.61 428.39 347.51 430.07 346.55z"
        />
        <path
          style={{
            transformOrigin: "398.69px 297.865px"
          }}
          id="eluydhb03hmo"
          className="animable"
          fill="#fff"
          d="M395.64 299.81L401.72 303.32 401.74 295.93 395.66 292.41 395.64 299.81z"
        />
        <path
          style={{
            transformOrigin: "398.72px 287.405px"
          }}
          id="elgo7amph1tnt"
          className="animable"
          fill="#fff"
          d="M395.67 289.35L401.75 292.86 401.77 285.47 395.69 281.95 395.67 289.35z"
        />
        <path
          style={{
            transformOrigin: "428.51px 304.465px"
          }}
          id="elu425p5fn9t"
          className="animable"
          fill="#fff"
          d="M430.19 305.12L429.61 304.11 428.51 304.74 427.42 302.85 426.83 303.18 428.5 306.08 430.19 305.12z"
        />
        <path
          style={{
            transformOrigin: "428.48px 314.915px"
          }}
          id="elqkk8gf4r6xs"
          className="animable"
          fill="#fff"
          d="M430.16 315.57L430.16 315.57 429.57 314.56 428.48 315.19 427.39 313.3 426.8 313.63 428.48 316.53 430.16 315.57z"
        />
        <path
          style={{
            transformOrigin: "351.555px 285.38px"
          }}
          id="elhbx3exa8md6"
          className="animable"
          fill="#fff"
          d="M354.61 283.44L348.53 279.93 348.5 287.32 354.59 290.83 354.61 283.44z"
        />
        <path
          style={{
            transformOrigin: "386.13px 305.34px"
          }}
          id="el1ba9ogr8roqh"
          className="animable"
          fill="#fff"
          d="M383.08 307.28L389.16 310.79 389.18 303.4 383.1 299.89 383.08 307.28z"
        />
        <path
          style={{
            transformOrigin: "396.755px 311.475px"
          }}
          id="el0hb4e0hbvih"
          className="animable"
          fill="#fff"
          d="M393.7 313.41L399.79 316.93 399.81 309.54 393.72 306.02 393.7 313.41z"
        />
        <path
          style={{
            transformOrigin: "418.84px 324.225px"
          }}
          id="eledq0gd2h0c6"
          className="animable"
          fill="#fff"
          d="M415.81 318.77L415.79 326.17 421.87 329.68 421.89 322.29 415.81 318.77z"
        />
        <path
          style={{
            transformOrigin: "429.47px 330.365px"
          }}
          id="eliemxryjxbah"
          className="animable"
          fill="#fff"
          d="M426.41 332.3L432.51 335.82 432.53 328.43 426.44 324.91 426.41 332.3z"
        />
        <path
          style={{
            transformOrigin: "429.785px 235.645px"
          }}
          id="elyoqfq30xhbs"
          className="animable"
          fill="#fff"
          d="M426.79 233.44L426.79 234.4 432.78 237.85 432.78 236.9 426.79 233.44z"
        />
        <path
          style={{
            transformOrigin: "429.775px 240.56px"
          }}
          id="elj8t9i230uf"
          className="animable"
          fill="#fff"
          d="M426.78 239.31L432.76 242.76 432.77 241.81 426.78 238.36 426.78 239.31z"
        />
        <path
          style={{
            transformOrigin: "429.785px 237.285px"
          }}
          id="el3p860pkbnek"
          className="animable"
          fill="#fff"
          d="M426.79 236.04L432.77 239.49 432.78 238.54 426.79 235.08 426.79 236.04z"
        />
        <path
          style={{
            transformOrigin: "429.775px 238.92px"
          }}
          id="elxk8x0yxrmw"
          className="animable"
          fill="#fff"
          d="M426.78 237.67L432.77 241.12 432.77 240.18 426.78 236.72 426.78 237.67z"
        />
        <g id="elj9i7ewewqph">
          <path
            d="M440.63 230.46l-86.68-50a1.77 1.77 0 00-1.76-.18l-6.48 4.65a1.76 1.76 0 011.74.18l86.68 50.05a5.09 5.09 0 011.89 2.12l6.81-3.91a5.44 5.44 0 00-2.2-2.91z"
            style={{
              transformOrigin: "394.27px 208.703px"
            }}
            className="animable"
            id="elx4nmm5gg0b"
            fill="#fff"
            opacity={0.3}
          />
        </g>
        <g id="eluicetwkdx5l">
          <path
            d="M436 237.24a4.89 4.89 0 01.58 2.16l-.34 118.42a1.73 1.73 0 01-.69 1.56l6.56-4.7a1.8 1.8 0 00.63-1.52l.34-118.42a4.28 4.28 0 00-.27-1.41z"
            style={{
              transformOrigin: "439.315px 296.355px"
            }}
            className="animable"
            id="el0xqr9vun6ncj"
            fill="#fff"
            opacity={0.2}
          />
        </g>
        <path
          style={{
            transformOrigin: "308.295px 268.01px"
          }}
          id="eltdc88sclj1"
          className="animable"
          fill="#37474f"
          d="M331.95 279.84L331.94 284.06 284.64 256.17 284.65 251.96 331.95 279.84z"
        />
        <path
          style={{
            transformOrigin: "302.365px 250.54px"
          }}
          id="elkxeqswfywg"
          className="animable"
          fill="#37474f"
          d="M320.05 258.93L320.04 263.14 284.68 242.16 284.69 237.94 320.05 258.93z"
        />
        <path
          style={{
            transformOrigin: "318.485px 267.45px"
          }}
          id="elxhn4us4e71j"
          className="animable"
          fill="#37474f"
          d="M352.31 285.16L352.3 289.37 284.66 249.75 284.67 245.53 352.31 285.16z"
        />
        <path
          style={{
            transformOrigin: "318.525px 253.435px"
          }}
          id="eldss2z649m9a"
          className="animable"
          fill="#37474f"
          d="M352.35 271.14L352.33 275.35 284.7 235.72 284.71 231.52 352.35 271.14z"
        />
      </g>
      <g
        id="freepik--character-1--inject-7"
        className="animable"
        style={{
          transformOrigin: "137.049px 216.123px"
        }}
      >
        <path
          d="M181.16 134s.71-3-1.18-3.42c0 0 1.63-3.44-.43-4.12s-4.66 6.2-7.68 9c-.55.62-4.08 3.81-4.55 2.78l-4.85 1.87a9.3 9.3 0 01-1.37 3.88c-5.2 7.92-11.13 11.74-15.56 17.38l-4.3-4.17-8.18 11.37 8 6a9 9 0 0012.81-2.18l14.78-22a7.73 7.73 0 012.45-2.31c2.35-1.39 6.88-4.21 8.55-6.27 2.35-2.76 2.63-5.81 1.51-7.81z"
          style={{
            transformOrigin: "157.427px 151.377px"
          }}
          id="elsrpfklanghl"
          className="animable"
          fill="#ffc3c2"
        />
        <path
          d="M116.21 137.85c8.19-.36 19.3 12.92 25.56 19.18-.48 7.66-8.72 12.69-8.72 12.69a177.79 177.79 0 01-16.84-13.17z"
          style={{
            transformOrigin: "128.99px 153.781px"
          }}
          id="el06tii80m1y14"
          className="animable"
          fill="#7519ff"
        />
        <g id="el8qfkt07k0lm">
          <path
            d="M116.21 137.85c8.19-.36 19.3 12.92 25.56 19.18-.48 7.66-8.72 12.69-8.72 12.69a177.79 177.79 0 01-16.84-13.17z"
            style={{
              transformOrigin: "128.99px 153.781px"
            }}
            className="animable"
            id="elvfn2la8l15"
            fill="#fff"
            opacity={0.3}
          />
        </g>
        <path
          style={{
            transformOrigin: "180.89px 113.6px"
          }}
          id="el3b67tvp1wxn"
          className="animable"
          fill="#7519ff"
          d="M190.46 80.29L215.92 134.83 171.33 146.91 145.86 92.37 190.46 80.29z"
        />
        <g id="el99wvklcpxzh">
          <g
            style={{
              transformOrigin: "180.89px 113.6px"
            }}
            className="animable"
            id="elkmav3oxrttd"
            opacity={0.3}
          >
            <path
              style={{
                transformOrigin: "180.89px 113.6px"
              }}
              id="eljyed9xnq5zb"
              className="animable"
              fill="#fff"
              d="M190.46 80.29L215.92 134.83 171.33 146.91 145.86 92.37 190.46 80.29z"
            />
          </g>
        </g>
        <path
          d="M203.88 121l.64 1.38a2 2 0 01-.61 1.51 3.08 3.08 0 01-3.84.56 5.46 5.46 0 01-1.19-1 4.55 4.55 0 01-.87 1.86 3.21 3.21 0 01-1.77 1.13 4.69 4.69 0 01-3.69-.58l-.47-.3a10.13 10.13 0 01-3.4-4.23 7.88 7.88 0 01-.93-4.89 3.45 3.45 0 012.77-2.64 6.75 6.75 0 011.8-.2 5.29 5.29 0 011.73.31l-2.67-5.71a5.54 5.54 0 00-1.7-2.33l-.15-.1a2.17 2.17 0 00-1.67-.25 1.52 1.52 0 00-1.16 1.09 1.87 1.87 0 01.63.24 2.47 2.47 0 01.56.43 4.54 4.54 0 01.91 1.33 3.87 3.87 0 01.42 2.41 1.57 1.57 0 01-1.19 1.28 2.33 2.33 0 01-1.78-.28c-.11-.06-.22-.14-.33-.21a5.81 5.81 0 01-1.77-2.28 6.75 6.75 0 01-.67-3 4.3 4.3 0 011-2.7 5.6 5.6 0 013-1.71 6 6 0 014.75.52l.16.1a10.56 10.56 0 013.77 4.72l5.32 11.39a4.08 4.08 0 001.51 1.88 2.09 2.09 0 00.89.27zm-6.78 1.08a1.76 1.76 0 00.45-.67l-2-4.17a3.81 3.81 0 00-1-.32 2.31 2.31 0 00-.91 0 1.22 1.22 0 00-.9 1.1 4.12 4.12 0 00.52 2.3 4.45 4.45 0 001.38 1.78 2.11 2.11 0 00.27.18 1.82 1.82 0 001.4.22 1.48 1.48 0 00.71-.42"
          style={{
            transformOrigin: "194px 114.221px"
          }}
          id="elabm5li65l8d"
          className="animable"
          fill="#fff"
        />
        <path
          d="M191 126.14a4.3 4.3 0 01.12 1.38l-7.41 2a5.67 5.67 0 01-1-1.12 8 8 0 01-.87-1.45c1-.29 1.24-1.11.59-2.49a16.09 16.09 0 00-1.13-2l-.59-.86-6.12 1.67.26.94a12.69 12.69 0 00.64 2 3.35 3.35 0 001.25 1.56 1.91 1.91 0 001.51.16 7.26 7.26 0 01.54 1.54 4.3 4.3 0 01.12 1.38l-6.09 1.65a5.42 5.42 0 01-1.05-1.12 8.66 8.66 0 01-.87-1.46 1.52 1.52 0 001.06-1.09 4.39 4.39 0 000-2.33l-5.23-22a6.48 6.48 0 00-3 .19 7.1 7.1 0 01-.79-3.28l7.49-2 16.45 23a6.36 6.36 0 001.93 1.89 2.34 2.34 0 001.76.4 7.21 7.21 0 01.43 1.44zm-17.42-6.37l4.93-1.33-6.92-10.13a15.34 15.34 0 00-1.66-2l3.65 13.49"
          style={{
            transformOrigin: "177.036px 115.955px"
          }}
          id="elber0moho72"
          className="animable"
          fill="#fff"
        />
        <path
          d="M120.47 312.13c-.15 3.48 0 7.57 0 7.57l-2 .11a4.9 4.9 0 00-4.34 3.23v.13a1.91 1.91 0 01-2.36 1.17 1.9 1.9 0 01-1.15-1l-1-8.74z"
          style={{
            transformOrigin: "115.045px 318.278px"
          }}
          id="elr9nrw2ofwz"
          className="animable"
          fill="#ffc3c2"
        />
        <path
          d="M123.44 342.44c5.3 2.27 13.41 2.71 16.78-2.7v1.46c-3.37 5.41-11.48 5-16.78 2.71s-4.14-6-8.89-8a12.83 12.83 0 01-4.64-2.93c0-.43-.09-1-.16-1.59a16 16 0 004.8 3.05c4.75 2.04 3.59 5.74 8.89 8z"
          style={{
            transformOrigin: "124.985px 338.449px"
          }}
          id="el9ti7an8udt"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M109.75 326.63a7.76 7.76 0 000-3.13 1.66 1.66 0 01.24-1.38l.4-.39.19 1.61a1.9 1.9 0 003.51-.17v-.13a4.9 4.9 0 014.34-3.23l2-.11a1.49 1.49 0 011.45.91c2.12 5.1 7.43 10.77 11.53 13.36 3-.07 6.74 2.43 6.74 5.47v.3c-3.37 5.41-11.48 5-16.78 2.7s-4.14-6-8.89-7.95a16 16 0 01-4.8-3.05 17 17 0 01.07-4.81z"
          style={{
            transformOrigin: "124.846px 331.872px"
          }}
          id="elrkjcvcfn6"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M120 330.81a.67.67 0 01-1-.81 7.7 7.7 0 016.92-3c.28.35.57.7.87 1a7.4 7.4 0 00-6.79 2.81z"
          style={{
            transformOrigin: "122.869px 328.945px"
          }}
          id="el13l6de3lcc4"
          className="animable"
          fill="#fff"
        />
        <path
          d="M117.91 328.47a.65.65 0 01-.93.09.67.67 0 01-.11-.93 8.07 8.07 0 017.22-3.12c.25.38.52.76.79 1.13a7.48 7.48 0 00-6.97 2.83z"
          style={{
            transformOrigin: "120.805px 326.592px"
          }}
          id="eltuv1rwxo0f8"
          className="animable"
          fill="#fff"
        />
        <path
          d="M122.18 333.1a.68.68 0 01-.94.09.67.67 0 01-.11-.93 7.66 7.66 0 016.74-3.05c.34.36.69.7 1 1a7.4 7.4 0 00-6.69 2.89z"
          style={{
            transformOrigin: "124.93px 331.262px"
          }}
          id="elicb2urklygo"
          className="animable"
          fill="#fff"
        />
        <path
          d="M133.48 334c3-.07 6.74 2.43 6.74 5.47v.3c-3.32 5.33-11.24 5-16.54 2.8.41-7.31 7.4-8.63 9.8-8.57z"
          style={{
            transformOrigin: "131.95px 339.035px"
          }}
          id="elxlc89qqfhvo"
          className="animable"
          fill="#fff"
        />
        <path
          d="M124.41 335.28a.67.67 0 01-.94.09.68.68 0 01-.11-.93 7.71 7.71 0 016.76-3.05c.42.38.84.73 1.26 1.06a7.48 7.48 0 00-6.97 2.83z"
          style={{
            transformOrigin: "127.302px 333.444px"
          }}
          id="elghysfrb243p"
          className="animable"
          fill="#fff"
        />
        <path
          d="M92.3 318.57c-.15 3.48 0 7.58 0 7.58l-2 .11a4.9 4.9 0 00-4.34 3.22v.13a1.9 1.9 0 01-2.36 1.17 1.89 1.89 0 01-1.15-1l-1-8.74z"
          style={{
            transformOrigin: "86.875px 324.719px"
          }}
          id="elm1mx34t5jxm"
          className="animable"
          fill="#ffc3c2"
        />
        <path
          d="M95.27 348.89c5.29 2.26 13.41 2.7 16.78-2.71v1.47c-3.37 5.41-11.49 5-16.78 2.7s-4.14-6-8.89-8a12.9 12.9 0 01-4.64-2.92c0-.43-.09-1-.16-1.6a16.15 16.15 0 004.8 3.06c4.75 2.04 3.62 5.73 8.89 8z"
          style={{
            transformOrigin: "96.815px 344.893px"
          }}
          id="elkkqgg757ci"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M81.58 333.07a7.69 7.69 0 000-3.12 1.67 1.67 0 01.24-1.39l.39-.39.2 1.62a1.91 1.91 0 003.51-.18v-.13a4.9 4.9 0 014.34-3.22l2-.11a1.46 1.46 0 011.45.91c2.12 5.1 7.43 10.76 11.53 13.36 3-.07 6.74 2.42 6.74 5.46v.3c-3.37 5.41-11.49 5-16.78 2.71s-4.14-6-8.89-7.95a16.15 16.15 0 01-4.8-3.06 17 17 0 01.07-4.81z"
          style={{
            transformOrigin: "96.6761px 338.318px"
          }}
          id="elzpj7ah06efk"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M91.84 337.26a.68.68 0 01-.94.08.65.65 0 01-.11-.92 7.65 7.65 0 016.92-3c.28.35.57.69.87 1a7.41 7.41 0 00-6.74 2.84z"
          style={{
            transformOrigin: "94.6138px 335.434px"
          }}
          id="elm1acylnnb3n"
          className="animable"
          fill="#fff"
        />
        <path
          d="M89.74 334.92a.67.67 0 01-1-.84 8.07 8.07 0 017.18-3.08c.25.38.52.75.79 1.13a7.48 7.48 0 00-6.97 2.79z"
          style={{
            transformOrigin: "92.6882px 333.007px"
          }}
          id="elyleyksijax"
          className="animable"
          fill="#fff"
        />
        <path
          d="M94 339.55a.68.68 0 01-.94.08.67.67 0 01-.11-.93 7.66 7.66 0 016.74-3.05c.34.36.69.71 1 1.05a7.36 7.36 0 00-6.69 2.85z"
          style={{
            transformOrigin: "96.7504px 337.704px"
          }}
          id="elmgnnff2ioi"
          className="animable"
          fill="#fff"
        />
        <path
          d="M105.31 340.42c3-.07 6.74 2.42 6.74 5.46v.3c-3.32 5.33-11.24 5-16.54 2.8.41-7.28 7.4-8.59 9.8-8.56z"
          style={{
            transformOrigin: "103.78px 345.45px"
          }}
          id="elbmpk33wcygj"
          className="animable"
          fill="#fff"
        />
        <path
          d="M96.24 341.73a.67.67 0 01-1-.84 7.69 7.69 0 016.76-3.06c.42.38.84.73 1.26 1.07a7.48 7.48 0 00-7.02 2.83z"
          style={{
            transformOrigin: "99.2132px 339.836px"
          }}
          id="elau1s8visgyb"
          className="animable"
          fill="#fff"
        />
        <path
          d="M127.32 202.18l-1 67.63-3.19 47.33c-8.08 5.11-16.43.27-16.43.27-3.06-22.43-3.18-36.29-1.51-45.62l-1-36.11-1.57 37.32L95 320.83c-8.29 5.38-17.3-.16-17.3-.16 0-17.19-1.76-37.62 3-50-3.35-38.32 2.3-67.76 2.3-67.76z"
          style={{
            transformOrigin: "102.351px 262.68px"
          }}
          id="elxrjylimiuzi"
          className="animable"
          fill="#37474f"
        />
        <g id="elp2kawyd4pc">
          <path
            d="M107.56 222.09l-3.35 13.59.48 17.44 5-28.1c7.21-2.82 8.15-6.79 8.15-6.79a22.15 22.15 0 01-10.28 3.86z"
            style={{
              transformOrigin: "111.025px 235.675px"
            }}
            className="animable"
            id="ela9yystwxdv9"
            opacity={0.3}
          />
        </g>
        <path
          d="M86.16 144.41c11.72-5.5 20.51-6.14 30.09-6.55 14.34 9.23 9 16.92 8.73 29.25a170.53 170.53 0 004.42 40.28c-18.79 15-43.66 11.48-49.34 7.88 1.34-36.72-.27-68.27 6.1-70.86z"
          style={{
            transformOrigin: "104.73px 178.132px"
          }}
          id="el1h4zbw07q8k"
          className="animable"
          fill="#7519ff"
        />
        <g id="elm0j3oyesbx8">
          <path
            d="M86.16 144.41c11.72-5.5 20.51-6.14 30.09-6.55 14.34 9.23 9 16.92 8.73 29.25a170.53 170.53 0 004.42 40.28c-18.79 15-43.66 11.48-49.34 7.88 1.34-36.72-.27-68.27 6.1-70.86z"
            style={{
              transformOrigin: "104.73px 178.132px"
            }}
            className="animable"
            id="elmgp8jyjt27c"
            fill="#fff"
            opacity={0.6}
          />
        </g>
        <path
          d="M121.74 119.72a9.77 9.77 0 002.71-12.88 5.26 5.26 0 002.57-2.42 2.83 2.83 0 00-3.68-3.78 4 4 0 00-1.59-4.64 3.75 3.75 0 00-4.68.86 2.54 2.54 0 00-1.74-2.5 5.23 5.23 0 00-3.24.2c-1 .36-2 .88-3.07 1.21a24.36 24.36 0 01-5.53.71 11.14 11.14 0 00-5.33 1.42c-1.56 1-2.94 3.37-2.1 5.41a3.08 3.08 0 00-2.22-1.18 3 3 0 00-2.27 1c1.16.72 2.29 1.49 3.45 2.21a3.15 3.15 0 00-3.55 1.42 7.08 7.08 0 00-.62 3.74 50 50 0 002.57 14.33c.79 2.39 3 6.45 5.26 7.55z"
          style={{
            transformOrigin: "109.043px 113.286px"
          }}
          id="el5wejtr3vhlm"
          className="animable"
          fill="#263238"
        />
        <path
          d="M121.75 107.11a12.36 12.36 0 01-8.21 3.45c-2.89 0-5.69-.92-8.48-1.68a6.44 6.44 0 01-1.91 3.53 7.36 7.36 0 01-4 1.72 25.33 25.33 0 010 7.87 1.47 1.47 0 01-1 1.21c-.94.28-1.56-.68-2-1.46a6.28 6.28 0 00-2-2.27 3.15 3.15 0 00-2.94-.3 3.06 3.06 0 00-1.55 1.89 5.65 5.65 0 00-.12 2.49 7.48 7.48 0 001.62 3.81 5.14 5.14 0 003.62 1.87 3.73 3.73 0 003.49-1.72l.06.26a10 10 0 00.38 1.21 10.42 10.42 0 007.61 6.52c1.77.37 3.73.72 5.73 1 1 .15 2 .29 3 .41a7.12 7.12 0 007.87-5.83c1.27-7.58 1.72-16.45-1.17-23.98z"
          style={{
            transformOrigin: "106.681px 122.041px"
          }}
          id="el29ejpx1046j"
          className="animable"
          fill="#ffc3c2"
        />
        <path
          d="M98.68 129v13.26a14.64 14.64 0 0010.67 5.91 5.23 5.23 0 003.83-1 3.1 3.1 0 00-.39-4.92 1.7 1.7 0 01-.77-1.42v-4.31c-2-.3-4-.65-5.73-1a10.42 10.42 0 01-7.61-6.52z"
          style={{
            transformOrigin: "106.447px 138.608px"
          }}
          id="eln2c0n92sdpr"
          className="animable"
          fill="#ffc3c2"
        />
        <path
          d="M107.54 138.43a29.16 29.16 0 004.48.79v-2.69c-2-.3-4-.65-5.73-1a10.39 10.39 0 01-7.35-5.94 10.65 10.65 0 002.68 5.54 12.78 12.78 0 005.92 3.3z"
          style={{
            transformOrigin: "105.48px 134.405px"
          }}
          id="elu0mpnxtep5"
          className="animable"
          fill="#ffa8a7"
        />
        <path
          style={{
            transformOrigin: "117.555px 122.175px"
          }}
          id="el64w7cx0zpw9"
          className="animable"
          fill="#ffa8a7"
          d="M115.12 118.44L119.99 124.33 115.52 125.91 115.12 118.44z"
        />
        <path
          d="M108.25 119.18a1.34 1.34 0 101.34-1.33 1.33 1.33 0 00-1.34 1.33z"
          style={{
            transformOrigin: "109.59px 119.19px"
          }}
          id="elteqbexfbxx"
          className="animable"
          fill="#263238"
        />
        <path
          d="M119.47 118.82a1.34 1.34 0 102.67 0 1.34 1.34 0 10-2.67 0z"
          style={{
            transformOrigin: "120.805px 118.82px"
          }}
          id="elfdnfelsud3o"
          className="animable"
          fill="#263238"
        />
        <path
          d="M118.76 112.88l3.58.41a1.9 1.9 0 00-1.5-1.58 2.28 2.28 0 00-2.08 1.17z"
          style={{
            transformOrigin: "120.55px 112.499px"
          }}
          id="el20dcq4qcxo3"
          className="animable"
          fill="#263238"
        />
        <path
          d="M110.78 112.88l-3.46 1a1.89 1.89 0 011.2-1.81 2.26 2.26 0 012.26.81z"
          style={{
            transformOrigin: "109.05px 112.949px"
          }}
          id="elwyby02psoma"
          className="animable"
          fill="#263238"
        />
        <path
          d="M110 127.85l3.73 1.81a2.44 2.44 0 01-2.83.81 2.22 2.22 0 01-.9-2.62z"
          style={{
            transformOrigin: "111.8px 129.242px"
          }}
          id="el2o0h4z1yu7t"
          className="animable"
          fill="#ffa8a7"
        />
        <g id="eli1nh3xs2oy">
          <path
            d="M110 127.85l3.73 1.81a2.44 2.44 0 01-2.83.81 2.22 2.22 0 01-.9-2.62z"
            style={{
              transformOrigin: "111.8px 129.242px"
            }}
            className="animable"
            id="elpeufj5ioz3m"
            opacity={0.3}
          />
        </g>
        <path
          d="M112.19 130.59a2.28 2.28 0 01-1.3-.12 2 2 0 01-1-2 2.31 2.31 0 011.47.51 2.36 2.36 0 01.83 1.61z"
          style={{
            transformOrigin: "111.032px 129.552px"
          }}
          id="el86kyj408ok2"
          className="animable"
          fill="#ffa8a7"
        />
        <path
          d="M75.94 220.84l-16.54-29a11.67 11.67 0 01-.29-9.77l9.2-21.64a28.2 28.2 0 0116-15.35l2.32-.86c1.32 13-2.6 19.23-14.48 41.78 0 0 6.26 21 11.15 30.38a35.31 35.31 0 0110.46 7.58 1.9 1.9 0 01.11 2.5 1.76 1.76 0 01-2.19.47c-1.59-.82-3.18-1.67-4.78-2.48a51.53 51.53 0 006.21 10.28 1.94 1.94 0 01-.12 2.52 1.91 1.91 0 01-1.7.59l-5.18-.76a9.36 9.36 0 01-7-5.14 31.9 31.9 0 01-3.17-11.1z"
          style={{
            transformOrigin: "76.2321px 191.042px"
          }}
          id="elr6lfxqol0f"
          className="animable"
          fill="#ffc3c2"
        />
        <path
          d="M86.66 144.18c-10.07 3.45-15.77 5.74-23.76 27.42 4 6.19 15 5.35 15 5.35 14.33-21.61 8.76-32.77 8.76-32.77z"
          style={{
            transformOrigin: "75.3128px 160.584px"
          }}
          id="el36wh53cnneo"
          className="animable"
          fill="#7519ff"
        />
        <g id="el89jsq0ebo9p">
          <path
            d="M86.66 144.18c-10.07 3.45-15.77 5.74-23.76 27.42 4 6.19 15 5.35 15 5.35 14.33-21.61 8.76-32.77 8.76-32.77z"
            style={{
              transformOrigin: "75.3128px 160.584px"
            }}
            className="animable"
            id="eluljojuyh6mk"
            fill="#fff"
            opacity={0.3}
          />
        </g>
        <path
          d="M167.32 138.27a1.4 1.4 0 01.06-.93c.77-2.7 1.55-5.26.07-6.33a.83.83 0 00-1.23.37c-.59 1.27-2 3.54-2.78 5.05a14.62 14.62 0 00-1 3.72l4.85-1.87z"
          style={{
            transformOrigin: "165.364px 135.509px"
          }}
          id="elhhjfa0xs2m"
          className="animable"
          fill="#ffc3c2"
        />
      </g>
      <g
        id="freepik--character-3--inject-7"
        className="animable"
        style={{
          transformOrigin: "101.629px 335.909px"
        }}
      >
        <path
          d="M146.35 259.69c-.23-.84.17-4.7-.76-5.36s.81-2.05 2.5-.66-1.74 6.02-1.74 6.02z"
          style={{
            transformOrigin: "146.944px 256.382px"
          }}
          id="el29d3edhtzn4"
          className="animable"
          fill="#ffa8a7"
        />
        <path
          style={{
            transformOrigin: "153.255px 243.015px"
          }}
          id="elkt1spgq0oy9"
          className="animable"
          fill="#f0f0f0"
          d="M147.47 231.19L147.56 261.47 159.04 254.84 158.96 224.56 147.47 231.19z"
        />
        <path
          style={{
            transformOrigin: "153.225px 232.165px"
          }}
          id="eluvzvi7l3mjb"
          className="animable"
          fill="#7519ff"
          d="M148.66 231.87L148.68 237.73 157.79 232.47 157.78 226.6 148.66 231.87z"
        />
        <path
          style={{
            transformOrigin: "153.245px 239.395px"
          }}
          id="elbsrp4add4g"
          className="animable"
          fill="#7519ff"
          d="M148.68 239.09L148.7 244.96 157.81 239.7 157.8 233.83 148.68 239.09z"
        />
        <path
          style={{
            transformOrigin: "153.265px 246.625px"
          }}
          id="el78wuj2o6brf"
          className="animable"
          fill="#7519ff"
          d="M148.7 246.32L148.72 252.19 157.83 246.93 157.82 241.06 148.7 246.32z"
        />
        <path
          style={{
            transformOrigin: "153.285px 253.855px"
          }}
          id="eld1apmsik9pi"
          className="animable"
          fill="#7519ff"
          d="M148.72 253.56L148.74 259.42 157.85 254.15 157.84 248.29 148.72 253.56z"
        />
        <g id="eli3fqt7ws2hj">
          <g
            style={{
              transformOrigin: "153.225px 232.165px"
            }}
            className="animable"
            id="ell4ojwncpm7g"
            opacity={0.6}
          >
            <path
              style={{
                transformOrigin: "153.225px 232.165px"
              }}
              id="el2rw5z9alhdz"
              className="animable"
              fill="#fff"
              d="M148.66 231.87L148.68 237.73 157.79 232.47 157.78 226.6 148.66 231.87z"
            />
          </g>
        </g>
        <g id="elc0sh6emrsfb">
          <g
            style={{
              transformOrigin: "153.245px 239.395px"
            }}
            className="animable"
            id="eled5a5sfun5o"
            opacity={0.4}
          >
            <path
              style={{
                transformOrigin: "153.245px 239.395px"
              }}
              id="el8wexnk68x96"
              className="animable"
              fill="#fff"
              d="M148.68 239.09L148.7 244.96 157.81 239.7 157.8 233.83 148.68 239.09z"
            />
          </g>
        </g>
        <g id="eliogvr4n3g4r">
          <g
            style={{
              transformOrigin: "153.265px 246.625px"
            }}
            className="animable"
            id="elfk5n4cueiep"
            opacity={0.2}
          >
            <path
              style={{
                transformOrigin: "153.265px 246.625px"
              }}
              id="elovkmw6bp41"
              className="animable"
              fill="#fff"
              d="M148.7 246.32L148.72 252.19 157.83 246.93 157.82 241.06 148.7 246.32z"
            />
          </g>
        </g>
        <path
          d="M74.66 256.56L58 294.32l8.49 9.3-3 12.7-16.79-14.57A9.05 9.05 0 0144.48 291L58 263a11.53 11.53 0 0110.59-6.52z"
          style={{
            transformOrigin: "59.1236px 286.399px"
          }}
          id="ellv2gtfb40ve"
          className="animable"
          fill="#e0e0e0"
        />
        <g id="el2hd52mg8vsp">
          <path
            d="M54.61 299s2.66-2.21 3.4-4.64l1.47 1.62a7.58 7.58 0 01-4.87 3.02z"
            style={{
              transformOrigin: "57.045px 296.68px"
            }}
            className="animable"
            id="elnvpf6l3am5"
            opacity={0.3}
          />
        </g>
        <path
          d="M86.52 457.35c.7 1.47 2.91 2.82 5.34 3.22s4.89-.12 7.32-.46a48.17 48.17 0 0110.93-.28 57.58 57.58 0 006.81.48 10.77 10.77 0 006.39-2 6.5 6.5 0 002.4-4.08 6.14 6.14 0 01-2.4 5.5 10.85 10.85 0 01-6.39 2 57.6 57.6 0 01-6.81-.49 48.17 48.17 0 00-10.93.28c-2.43.34-5.07 1-7.5.64S87 460.41 86.35 459z"
          style={{
            transformOrigin: "106.045px 458.247px"
          }}
          id="elpdo8la4ddt"
          className="animable"
          fill="#263238"
        />
        <path
          d="M113.06 453c-2 0-1.29-3-3.24-5 .79 0 1.59.05 2.38.05 1.8 2.51 2.23 4.95.86 4.95z"
          style={{
            transformOrigin: "111.844px 450.5px"
          }}
          id="elairkftij4pg"
          className="animable"
          fill="#fff"
        />
        <path
          d="M108.82 452.87c-2 0-1.51-3.6-3.53-5.7a11.2 11.2 0 001.4.4c.4.09.8.16 1.21.22 1.8 2.55 2.31 5.08.92 5.08z"
          style={{
            transformOrigin: "107.453px 450.02px"
          }}
          id="elmlrue0ha297"
          className="animable"
          fill="#fff"
        />
        <path
          d="M88.63 446a2.71 2.71 0 011.06-1.66l11-.28a9.42 9.42 0 004.58 3.14c2 2.1 1.48 5.7 3.53 5.7 1.39 0 .88-2.53-.92-5.08.64.1 1.28.16 1.92.2 2 2 1.2 5 3.24 5 1.37 0 .89-2.47-.86-5h1.54c.47 0 .94 0 1.41-.08 4.06 3.21 4.4 6.68 3.88 12.14a13.73 13.73 0 01-2.11.22 57.58 57.58 0 01-6.81-.48 48.17 48.17 0 00-10.93.28c-2.43.34-4.9.86-7.32.46s-4.64-1.75-5.34-3.22z"
          style={{
            transformOrigin: "102.849px 452.382px"
          }}
          id="elrymwv3t2vva"
          className="animable"
          fill="#455a64"
        />
        <path
          d="M115.15 448a17.32 17.32 0 015.52 0 6.59 6.59 0 015 4.32 4.88 4.88 0 01.08 1.94 6.5 6.5 0 01-2.4 4.08 9.55 9.55 0 01-4.28 1.8c.48-5.51.14-8.98-3.92-12.14z"
          style={{
            transformOrigin: "120.481px 453.959px"
          }}
          id="elad1vc62uq6e"
          className="animable"
          fill="#fff"
        />
        <path
          d="M101 440.89v5.31a2.19 2.19 0 01-2.19 2.19h-5.52a3.58 3.58 0 01-3.58-3.47l-.13-4z"
          style={{
            transformOrigin: "95.29px 444.64px"
          }}
          id="el7jdxu99z0rw"
          className="animable"
          fill="#ffa8a7"
        />
        <path
          d="M64.29 438.53a4.08 4.08 0 003.34 2.34 7 7 0 004.24-1c1.91-1.21 2.65-3.12 4-4.85a29.74 29.74 0 014.61-4.5 15.17 15.17 0 004.4-5.11 6.59 6.59 0 00.53-1.87 6.66 6.66 0 01-.53 3.6 15.14 15.14 0 01-4.4 5.1 29.36 29.36 0 00-4.61 4.51c-1.31 1.72-2 3.63-4 4.84a7 7 0 01-4.24 1 4.08 4.08 0 01-3.34-2.34 9.06 9.06 0 01-.2-3 3.67 3.67 0 00.2 1.28z"
          style={{
            transformOrigin: "74.7556px 433.078px"
          }}
          id="elp1rupj3zqds"
          className="animable"
          fill="#263238"
        />
        <path
          d="M76.82 426.87a1.4 1.4 0 01.26.91 1 1 0 01-.6.69 1.36 1.36 0 01-1.24-.32 7.12 7.12 0 01-.86-1 6.48 6.48 0 00-2.83-2.07 6.63 6.63 0 00-1.7-.37l1.38-1.26a6.84 6.84 0 015.59 3.45z"
          style={{
            transformOrigin: "73.4666px 425.979px"
          }}
          id="elzyl0f8sskpo"
          className="animable"
          fill="#fff"
        />
        <path
          d="M76.52 422.49a7 7 0 013.08 2.84 1.36 1.36 0 01.26.9.92.92 0 01-.6.69 1.31 1.31 0 01-1.26-.32 7 7 0 01-.86-1 6.39 6.39 0 00-5.62-2.44l1.36-1.24a6.91 6.91 0 013.64.57z"
          style={{
            transformOrigin: "75.6917px 424.421px"
          }}
          id="elujpdzihk6rs"
          className="animable"
          fill="#fff"
        />
        <path
          d="M73.77 421.11a14.34 14.34 0 014.33-2.89 5.76 5.76 0 016 .82 5.17 5.17 0 011.32 4.46 6.59 6.59 0 01-.53 1.87 9.55 9.55 0 01-1.36 2.17c-.53-4.75-4.7-6.97-9.76-6.43z"
          style={{
            transformOrigin: "79.6362px 422.632px"
          }}
          id="elplz7leie15"
          className="animable"
          fill="#fff"
        />
        <path
          d="M64.18 433.87a9.81 9.81 0 011.08-4.64l2.68-2.81 1.91-1.74a6.63 6.63 0 011.7.37 6.48 6.48 0 012.83 2.07 7.12 7.12 0 00.86 1 1.36 1.36 0 001.24.32 1 1 0 00.6-.69 1.4 1.4 0 00-.26-.91 6.84 6.84 0 00-5.59-3.45l.31-.28a6.39 6.39 0 015.62 2.44 7 7 0 00.86 1 1.31 1.31 0 001.24.32.92.92 0 00.6-.69 1.36 1.36 0 00-.26-.9 7 7 0 00-3.08-2.84 6.91 6.91 0 00-3.62-.59l.82-.74c5.06-.54 9.18 1.68 9.71 6.43a22.24 22.24 0 01-3 2.94 29.74 29.74 0 00-4.61 4.5c-1.31 1.73-2 3.64-4 4.85a7 7 0 01-4.24 1 4.08 4.08 0 01-3.34-2.34 3.67 3.67 0 01-.2-1.24 31.74 31.74 0 01.14-3.38z"
          style={{
            transformOrigin: "73.7339px 430.943px"
          }}
          id="el44vki9073au"
          className="animable"
          fill="#455a64"
        />
        <path
          d="M73.85 425.48l-.31 5.7c-3.6 2.07-7.38.94-8.64-.37l-.24-5.33z"
          style={{
            transformOrigin: "69.255px 428.901px"
          }}
          id="eljqkhhmh728h"
          className="animable"
          fill="#ffa8a7"
        />
        <path
          d="M66.22 303.17a5.56 5.56 0 00.55-.9 51.8 51.8 0 0037.09 4.22c2.63 19.56 4.89 52.67 3.5 74.52-1.15 25.43-5.83 61.44-5.83 61.44-6.11 1.83-10.69.71-12.41-.61-2-25.23-6.52-42.52-3.06-59.21-2.3-11.66-3.79-26.31-3.79-26.31l-1.77 25.77-6 45.3a10.68 10.68 0 01-10.71-.54c-4.34-25.9-6-43.46-2.17-56.32-5.77-42.53-6.06-53.38 4.6-67.36z"
          style={{
            transformOrigin: "82.7172px 372.84px"
          }}
          id="elm14rluzxzcl"
          className="animable"
          fill="#37474f"
        />
        <g id="elw44hz1xzfto">
          <path
            d="M79.7 351.23l-3.2-13.42c-3.73-.61-9.56-3.06-12.63-8.17a25.73 25.73 0 0015.48 5.45l2.92 21.23-.63 9.07-.25-3.3a68.71 68.71 0 00-1.69-10.86z"
            style={{
              transformOrigin: "73.07px 347.515px"
            }}
            className="animable"
            id="el6g8ogfxjlnu"
            opacity={0.3}
          />
        </g>
        <path
          d="M89.35 257.1l9.33 1.83c2.06 3.78 4.16 7.32 6.22 11.09 1.55 2.84 3.15 5.87 3 9.1a9.26 9.26 0 01-5.58 8l4.85 32.12c-12.6 11.44-45 3.26-48.94-.13 1.69-8.58 5.4-12 7.34-21 1.55-7.21-1.48-15.11-2-17.48A56.08 56.08 0 0162 270.19 18.48 18.48 0 0164.49 260l.05-.09A11.41 11.41 0 0176 255z"
          style={{
            transformOrigin: "83.0699px 289.976px"
          }}
          id="elhrxutoado8"
          className="animable"
          fill="#fff"
        />
        <path
          d="M74.66 218.13a15.7 15.7 0 00-6.82 13.8c.2 3.09 1.34 6.13 1 9.2-.21 1.81-.92 3.64-.45 5.39a5.64 5.64 0 002.94 3.33c1.35.7 4.5.52 6 .82 4.55 4.14 10.26 2.59 13.89-1.1s9.49-17.57 9.49-17.57a19 19 0 002.87-9.05 7.59 7.59 0 00-1.31-5.15c-1.14-1.41-3.4-2-4.82-.89 1.35-1 .38-3.45-1-4.42a5.84 5.84 0 00-4.93-.37 46.68 46.68 0 01-4.86 1.58c-2.21.4-4.49-.1-6.72.14s-5.06 2.06-5.28 4.29z"
          style={{
            transformOrigin: "85.7212px 232.44px"
          }}
          id="elzaurpxn9un"
          className="animable"
          fill="#37474f"
        />
        <path
          style={{
            transformOrigin: "102.945px 239.175px"
          }}
          id="elb5llzl5199d"
          className="animable"
          fill="#ff9192"
          d="M101.66 236.13L104.5 241.07 101.39 242.22 101.66 236.13z"
        />
        <path
          d="M102 233.93c-.46-4.33-1.73-8.41-4.55-10.48a23.35 23.35 0 01-3.24 15.49 6.87 6.87 0 00-2.1-3.71 3.92 3.92 0 00-4-.86 4 4 0 00-2 2.21 5.26 5.26 0 006.57 6.95 10.11 10.11 0 01-7.77 7.86 13 13 0 01-7.53-.72l-1.17 5.84c4.75 5.07 12.87 1.66 12.87 1.66l1.07-5.74c9.34.56 10.22.4 11.24-4.75a50.4 50.4 0 00.61-13.75z"
          style={{
            transformOrigin: "89.2131px 241.371px"
          }}
          id="el0v2j5lz0kbhk"
          className="animable"
          fill="#ffa8a7"
        />
        <path
          d="M92.6 243.53c-.06 1.76 0 3.44-.08 5.2a13.88 13.88 0 00.54 5.49 4.8 4.8 0 004.07 3.3 12.48 12.48 0 01-11.24 2 12.19 12.19 0 01-7.93-8 9.29 9.29 0 002.65 7.59 11.07 11.07 0 01-9.92-2.18A10.79 10.79 0 0167 247.6c.27-2.43 1.37-4.67 2.13-7s1.16-5 .09-7.14a64.41 64.41 0 007.24 7.44 20 20 0 008.7 4.75 8.89 8.89 0 003.7.14 7 7 0 003.74-2.26z"
          style={{
            transformOrigin: "82.0342px 246.767px"
          }}
          id="el9qs1ybvbfcf"
          className="animable"
          fill="#37474f"
        />
        <g id="elwnouo98sd9">
          <path
            d="M79.05 223.45A9 9 0 0170 232.5a8.73 8.73 0 01-2.12-.26v-.31a15.7 15.7 0 016.82-13.8 3.62 3.62 0 01.84-1.86 9 9 0 013.51 7.18z"
            style={{
              transformOrigin: "73.4539px 224.385px"
            }}
            className="animable"
            id="eli9njwzsnjaq"
            opacity={0.2}
          />
        </g>
        <g id="elazz9zqhbmj">
          <circle
            cx={71.15}
            cy={220.4}
            r={9.06}
            style={{
              transformOrigin: "71.15px 220.4px"
            }}
            className="animable"
            id="elk0l3xf7um1"
            fill="#37474f"
            transform="rotate(-12.84)"
          />
        </g>
        <path
          d="M151.1 273.14a182.53 182.53 0 01-20 25.7 4.91 4.91 0 01-6.21.66c-10.16-6.7-18.56-18.06-18.56-18.06l11-7.57 9.53 12.39 18-17.74 1.19-9.85a2.4 2.4 0 011.44-1.93l5.08-2.21a2.43 2.43 0 011.89 0l3 1.24a1.3 1.3 0 01.55 2 1.29 1.29 0 01-1.42.48l-1.46-.43 3.44 2.56a2.71 2.71 0 011.1 2.18v1.77a2.7 2.7 0 01-1.21 2.26l-.42.28a1.4 1.4 0 01-2-.49c-.64 3.67-4.94 6.76-4.94 6.76z"
          style={{
            transformOrigin: "133px 277.325px"
          }}
          id="elkzyz9jw2l"
          className="animable"
          fill="#ffa8a7"
        />
        <path
          d="M155.13 257.77l-.92-.61a1.48 1.48 0 00-1.12-.22l-4.2.83 3.93-1.35a1.51 1.51 0 011.68.51z"
          style={{
            transformOrigin: "152.01px 257.055px"
          }}
          id="elqf45qxmtaa"
          className="animable"
          fill="#f28f8f"
        />
        <path
          d="M156 266.33c.39-1.38.61-3.23 0-3.91l1.72.94-.19 1.76z"
          style={{
            transformOrigin: "156.86px 264.375px"
          }}
          id="el1di1zl3aifz"
          className="animable"
          fill="#f28f8f"
        />
        <path
          d="M135 276.29a2.21 2.21 0 00-2.08.55l-2.53 2.41a2.24 2.24 0 00-.66 1.93v.23l.73-.69c9 2.86 8 11.85 8 11.85l-1.27 1.61a2.23 2.23 0 002.12-.8l3.14-4a2.2 2.2 0 00.48-1.48c-.12-2.5-1.09-9.9-7.93-11.61z"
          style={{
            transformOrigin: "136.32px 285.217px"
          }}
          id="elyvae58whrcn"
          className="animable"
          fill="#fff"
        />
        <path
          d="M135 276.29a2.21 2.21 0 00-2.08.55l-2.53 2.41a2.24 2.24 0 00-.66 1.93v.23l.73-.69c9 2.86 8 11.85 8 11.85l-1.27 1.61a2.23 2.23 0 002.12-.8l3.14-4a2.2 2.2 0 00.48-1.48c-.12-2.5-1.09-9.9-7.93-11.61z"
          style={{
            transformOrigin: "136.32px 285.217px"
          }}
          id="elgdclz7nzpnl"
          className="animable"
          fill="#f0f0f0"
        />
        <path
          d="M130.45 280.72l-.73.69-3 2.87-1.07-1.65-12.29-16a20.4 20.4 0 00-13.7-7.81l-1.64-.2s-3.29 13.82 3.77 21.51l13.5 14.29a53.28 53.28 0 009 6.95 6.29 6.29 0 008.37-1.4l5.82-7.39s.97-9-8.03-11.86z"
          style={{
            transformOrigin: "117.882px 280.5px"
          }}
          id="elpuh6jnixtd8"
          className="animable"
          fill="#e0e0e0"
        />
      </g>
      <g
        id="freepik--character-2--inject-7"
        className="animable"
        style={{
          transformOrigin: "228.259px 270.66px"
        }}
      >
        <path
          style={{
            transformOrigin: "192.325px 176.32px"
          }}
          id="el6w7dliozsif"
          className="animable"
          fill="#9c5756"
          d="M200.35 146.25L203.96 149.6 197.74 184.22 193.9 206.79 180.69 208.33 186.09 184.22 196.32 144.31 200.35 146.25z"
        />
        <path
          d="M195.1 204c-9.1-3.38-15.1 1.4-15.1 1.4l-1.49 38.66 16.26-9.3z"
          style={{
            transformOrigin: "186.805px 223.425px"
          }}
          id="elxawykl2lbg"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M195.1 204c-9.1-3.38-15.1 1.4-15.1 1.4l-1.49 38.66 16.26-9.3z"
          style={{
            transformOrigin: "186.805px 223.425px"
          }}
          id="eluyxzy3tcfus"
          className="animable"
          fill="#263238"
        />
        <g id="el0epz5osqqj3">
          <path
            d="M195.1 204c-9.1-3.38-15.1 1.4-15.1 1.4l-1.49 38.66 16.26-9.3z"
            style={{
              transformOrigin: "186.805px 223.425px"
            }}
            className="animable"
            id="elkkhuxe572q"
            fill="#fff"
            opacity={0.1}
          />
        </g>
        <path
          d="M203.21 92.86c-1.49-.86-2.71-.16-2.72 1.56L200 150a6 6 0 002.71 4.69l66.87 38.6c1.49.87 2.71.17 2.72-1.55l.48-55.56a6 6 0 00-2.71-4.69z"
          style={{
            transformOrigin: "236.39px 143.078px"
          }}
          id="elhmh8d2qwbxf"
          className="animable"
          fill="#7519ff"
        />
        <path
          d="M242.77 225.9l14.36-4.79 9.45-35.69a5.4 5.4 0 00-.27-3.13l-1-2.49a10.21 10.21 0 01-.73-4.13l.18-5.45a1.68 1.68 0 011.67-1.62 1.66 1.66 0 011.63 1.37l.86 4.74 2 2.3 1.62-6.49v-8.46l4.28 6.16a7.5 7.5 0 011.22 5.46l-1.71 10.37-4.6 40.13a16 16 0 01-10.15 13.12l-15.08 5.82z"
          style={{
            transformOrigin: "260.454px 202.59px"
          }}
          id="el1y9dla249lz"
          className="animable"
          fill="#9c5756"
        />
        <path
          d="M212 443.08c.78 2.45 3.46 3.85 6 4.06s5.1-.52 7.63-1c5.66-1 11.12 0 16.77-.14a23.68 23.68 0 005.51-.6 9.16 9.16 0 004.72-2.78 6.14 6.14 0 001.35-2.95 5.83 5.83 0 01-1.35 4.61 9.16 9.16 0 01-4.72 2.78 24.23 24.23 0 01-5.51.6c-5.65.13-11.11-.89-16.77.14-2.53.46-5.06 1.18-7.63 1s-5.24-1.6-6-4.05a6.38 6.38 0 01-.2-2.61 5.18 5.18 0 00.2.94z"
          style={{
            transformOrigin: "232.891px 444.249px"
          }}
          id="el7e0m3j02zy7"
          className="animable"
          fill="#263238"
        />
        <path
          d="M212.15 438.39l1-5.14a7.44 7.44 0 01.87-2.62 2.58 2.58 0 012.29-1.31c3.1-.41 7.46-1.54 10 .24a23.15 23.15 0 0013.87 4c2.56-.09 5.13-.62 7.67-.3s5.2 1.72 5.95 4.17a4.85 4.85 0 01.13 2.24 6.14 6.14 0 01-1.35 2.95 9.16 9.16 0 01-4.72 2.78 23.68 23.68 0 01-5.51.6c-5.65.13-11.11-.88-16.77.14-2.53.46-5.06 1.18-7.63 1s-5.24-1.61-6-4.06a5.18 5.18 0 01-.2-.95 12.49 12.49 0 01.4-3.74z"
          style={{
            transformOrigin: "232.871px 437.878px"
          }}
          id="elo9zz2ebgai"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M227.15 424.17l.22 6.7a2.66 2.66 0 01-2.46 2.74 43.48 43.48 0 01-6.76-.06 3.82 3.82 0 01-3.45-3.55l-.27-4.95z"
          style={{
            transformOrigin: "220.901px 428.942px"
          }}
          id="el0lzro5g5lpb"
          className="animable"
          fill="#9c5756"
        />
        <path
          d="M183.39 420.6a5.23 5.23 0 00.89 2.77 5 5 0 003.17 1.78 8 8 0 007.85-3.23c.84-1.22 1.32-2.66 2.12-3.91a17.91 17.91 0 014.4-4.28 18.61 18.61 0 004.43-4.24 6.26 6.26 0 00.85-5.67 4.15 4.15 0 01.42.71 6.44 6.44 0 01-.8 6 19 19 0 01-4.52 4.47 18.14 18.14 0 00-4.5 4.41c-.81 1.29-1.3 2.77-2.16 4a8.12 8.12 0 01-8 3.34 5.2 5.2 0 01-3.23-1.84 6.24 6.24 0 01-.93-3.85c-.01-.17 0-.31.01-.46z"
          style={{
            transformOrigin: "195.641px 415.342px"
          }}
          id="el22m7ll4o3zy"
          className="animable"
          fill="#263238"
        />
        <path
          d="M185.42 412.19c2-3.57 6.24-6.17 9.48-8.7a12.19 12.19 0 016.28-2.85 5.89 5.89 0 015.85 3 1.07 1.07 0 01.07.19 6.26 6.26 0 01-.85 5.67 18.61 18.61 0 01-4.43 4.24 17.91 17.91 0 00-4.4 4.28c-.8 1.25-1.28 2.69-2.12 3.91a8 8 0 01-7.85 3.23 5 5 0 01-3.17-1.78 5.23 5.23 0 01-.89-2.77 7.69 7.69 0 010-1 15.72 15.72 0 012.03-7.42z"
          style={{
            transformOrigin: "195.403px 412.936px"
          }}
          id="elgmfkuddijit"
          className="animable"
          fill="#37474f"
        />
        <path
          d="M194.26 409.09v6.09a10.2 10.2 0 01-9.48-.41l-.57-5.49z"
          style={{
            transformOrigin: "189.235px 412.622px"
          }}
          id="elqm00aglmwdc"
          className="animable"
          fill="#9c5756"
        />
        <path
          d="M182.61 298.58c-1.83 5.48.42 31.52 1.48 56.5-4 9.44-2.89 36.24-.61 55.27 0 0 8 2.6 15.11.17l5-52.7 1.25-29.7s2.25 26.35 4.84 43.25c-2.43 12.94-3.4 14.76.43 55.47 0 0 9.93 3.31 17.24-.28 3.4-55.93 2.15-106.91 1.5-125.24z"
          style={{
            transformOrigin: "205.523px 363.406px"
          }}
          id="elp7c7ks8tl8i"
          className="animable"
          fill="#7519ff"
        />
        <g id="elcagj0w99bo">
          <path
            d="M182.61 298.58c-1.83 5.48.42 31.52 1.48 56.5-4 9.44-2.89 36.24-.61 55.27 0 0 8 2.6 15.11.17l5-52.7 1.25-29.7s2.25 26.35 4.84 43.25c-2.43 12.94-3.4 14.76.43 55.47 0 0 9.93 3.31 17.24-.28 3.4-55.93 2.15-106.91 1.5-125.24z"
            style={{
              transformOrigin: "205.523px 363.406px"
            }}
            className="animable"
            id="el8f7unj85mgi"
            opacity={0.3}
          />
        </g>
        <g id="el8jz75d2lunb">
          <path
            d="M204.07 346.2l1-24.21c-6.26 0-12.07-4.68-16.49-9.11 4.25 7.55 12.47 10.9 12.47 10.9z"
            style={{
              transformOrigin: "196.825px 329.54px"
            }}
            className="animable"
            id="elb53jh681m6v"
            opacity={0.3}
          />
        </g>
        <path
          d="M229.8 302.81c-27.93 11.7-46 1.13-48.48-4.37 1.29-19.31-3.28-41.89-2.92-51.48s1.52-20.4 16.4-18.24l15.82 1.55 8 1.95 9.63 20z"
          style={{
            transformOrigin: "204.09px 268.271px"
          }}
          id="elof699ngjmos"
          className="animable"
          fill="#263238"
        />
        <path
          d="M194.8 221.14a4.19 4.19 0 01-3.09-1 3.06 3.06 0 01-.74-2.65 2.9 2.9 0 01-2.12-1.72 3.29 3.29 0 01.49-2.89 4.88 4.88 0 01-3.14-2.57 5.1 5.1 0 01-.14-4.17 4.5 4.5 0 01-3.16-3.14 4.88 4.88 0 01.89-4.51 6.09 6.09 0 01-.71-6.06 6.46 6.46 0 015.21-3.51 6.24 6.24 0 012-5.6 6 6 0 015.75-1.13 5.52 5.52 0 019.21-1.58 4.2 4.2 0 013.73-1.37 4.34 4.34 0 013.37 2.22 5.65 5.65 0 018 5.41 3.35 3.35 0 012.39 3.29 3.55 3.55 0 01-2.76 3.18 78.92 78.92 0 01-7.42 21.73c-1.57 3-3.49 6.14-6.53 7.73-3.26 1.73-8.42.75-11.23-1.66z"
          style={{
            transformOrigin: "202.66px 201.226px"
          }}
          id="el3t1c7w8qwyf"
          className="animable"
          fill="#263238"
        />
        <path
          d="M194.8 221.14a4.19 4.19 0 01-3.09-1 3.06 3.06 0 01-.74-2.65 2.9 2.9 0 01-2.12-1.72 3.29 3.29 0 01.49-2.89 4.88 4.88 0 01-3.14-2.57 5.1 5.1 0 01-.14-4.17 4.5 4.5 0 01-3.16-3.14 4.88 4.88 0 01.89-4.51 6.09 6.09 0 01-.71-6.06 6.46 6.46 0 015.21-3.51 6.24 6.24 0 012-5.6 6 6 0 015.75-1.13 5.52 5.52 0 019.21-1.58 4.2 4.2 0 013.73-1.37 4.34 4.34 0 013.37 2.22 5.65 5.65 0 018 5.41 3.35 3.35 0 012.39 3.29 3.55 3.55 0 01-2.76 3.18 78.92 78.92 0 01-7.42 21.73c-1.57 3-3.49 6.14-6.53 7.73-3.26 1.73-8.42.75-11.23-1.66z"
          style={{
            transformOrigin: "202.66px 201.226px"
          }}
          id="elkgpdoyi3ppq"
          className="animable"
          fill="#263238"
        />
        <path
          style={{
            transformOrigin: "222.625px 206.79px"
          }}
          id="el2dq68ahwj8v"
          className="animable"
          fill="#f28f8f"
          d="M221.14 203.28L224.42 208.97 220.83 210.3 221.14 203.28z"
        />
        <path
          style={{
            transformOrigin: "222.625px 206.79px"
          }}
          id="elsd8d7yu8xk"
          className="animable"
          fill="#803837"
          d="M221.14 203.28L224.42 208.97 220.83 210.3 221.14 203.28z"
        />
        <path
          d="M220 193.36a53.08 53.08 0 01.94 24.2 8.23 8.23 0 01-1.5 3.83 6 6 0 01-4.16 1.92 18.37 18.37 0 01-4.67-.45v7.41c-5.63 1.32-11.41 1.85-15.82-1.55v-7.58a20.09 20.09 0 009.82.61c3.88-2.09 4.66-9.19 4.12-11.06a2.92 2.92 0 01-3 1.06 4 4 0 01-2.52-2 8.22 8.22 0 01-.94-3.15 5.76 5.76 0 01.57-3.91 4.08 4.08 0 014.4-1.48 6.3 6.3 0 013.71 3.09 10.69 10.69 0 01.65 1.42.88.88 0 001.7-.22 11.76 11.76 0 00-1.11-6.2 4.28 4.28 0 003.17-1.94 4 4 0 00.62-3.56 12.11 12.11 0 004.02-.44z"
          style={{
            transformOrigin: "208.349px 212.323px"
          }}
          id="elo4av3zyfylm"
          className="animable"
          fill="#9c5756"
        />
        <path
          d="M200 147.54l2.9-7.49 1.31-5.88a1.5 1.5 0 00-1-1.75 1.49 1.49 0 00-1.9.94l-1.1 3.19v-9l-3.76 6.73a5.91 5.91 0 00-.72 3.32l-.73 11.86z"
          style={{
            transformOrigin: "199.623px 138.505px"
          }}
          id="elnp8ryvwru2"
          className="animable"
          fill="#9c5756"
        />
        <path
          d="M218.61 232.2c8.9-2.95 24.74-7.73 24.74-7.73 7.11 7.17 6.51 18.75 6.51 18.75l-21.59 9c-20.6 6.51-23.6-15.41-9.66-20.02z"
          style={{
            transformOrigin: "229.962px 238.944px"
          }}
          id="elniosr9m63eq"
          className="animable"
          fill="#263238"
        />
        <g id="eljnz1yvjcukk">
          <path
            d="M218.61 232.2c8.9-2.95 24.74-7.73 24.74-7.73 7.11 7.17 6.51 18.75 6.51 18.75l-21.59 9c-20.6 6.51-23.6-15.41-9.66-20.02z"
            style={{
              transformOrigin: "229.962px 238.944px"
            }}
            className="animable"
            id="elthh4inweex"
            fill="#fff"
            opacity={0.1}
          />
        </g>
        <path
          style={{
            transformOrigin: "236.4px 152.73px"
          }}
          id="el86dcia7ri0p"
          className="animable"
          fill="#fff"
          d="M224.38 121.15L206.21 135.45 206.17 149.43 266.59 184.31 266.63 170.34 255.12 147.47 244.78 155.5 248.43 166.85 243.13 156.77 224.38 121.15z"
        />
        <path
          d="M244.22 128.19c-2.84-1.64-5.15-.32-5.16 2.95a11.31 11.31 0 005.13 8.89c2.84 1.64 5.16.32 5.17-2.95a11.34 11.34 0 00-5.14-8.89z"
          style={{
            transformOrigin: "244.21px 134.11px"
          }}
          id="ellsq0vve96wf"
          className="animable"
          fill="#fff"
        />
      </g>
      <defs>
        <filter id="active" height="200%">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius={2}
          />
          <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
          <feMerge>
            <feMergeNode in="OUTLINE" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="hover" height="200%">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius={2}
          />
          <feFlood floodColor="red" floodOpacity={0.5} result="PINK" />
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
          <feMerge>
            <feMergeNode in="OUTLINE" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
          <feColorMatrix values="0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 1 0" />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgComponent
