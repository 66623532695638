import React, { useEffect } from 'react'

// Trasnlation 
import '../i18n'
import { useTranslation } from 'react-i18next'

/**
 * Componente que muestra los terminos habeas data de kreative tree
  * @alias PoliticasHabeasData 
  */
const PoliticasHabeasData = () => {

  const { t, i18n } = useTranslation();
  /**
   * Funcion inicializadora de lenguaje de la vista 
   * @alias initLang
   */
  const initLang = () => {
    if (localStorage.getItem('lng')) {
      i18n.changeLanguage(localStorage.getItem('lng'))
    } else {
      window.localStorage.setItem('lng', 'es')
      i18n.changeLanguage('es')
    }
  }

  useEffect(() => {
    initLang()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='terminos contenedor'>
      <div className={(localStorage.getItem('lng') === 'es') ? "img_term terminos_habeas" : "img_term terminos_habeas_ing"}>
        {/* <img src={terminos} alt="" /> */}
      </div>
      <p>{t('Habeas_par1.label')}</p>
      <p>{t('Habeas_par2.label')}</p>
      <p>{t('Habeas_par3.label')}</p>
      <p>{t('Habeas_par4.label')}</p>
      <p>{t('Habeas_par5.label')}</p>
      <p className='fecha'>{t('Habeas_par6_fcreaHabeas.label')}</p>
      <p className='fecha'>{t('Habeas_par7_fUpdaHabeas.label')}</p>
    </div>
  )
}

export default PoliticasHabeasData