import React from 'react'
import CarrusellPasos from './Carrusell_pasos'
import env from '../environtments'

// Trasnlation 
import '../i18n'
import { useTranslation } from 'react-i18next'

/**    IMAGENES    */
import chica1 from '../assets/images/chica1.png'
import chica2 from '../assets/images/chica2.png'
import chica3 from '../assets/images/chica3.png'

/**
 * Pasos Componente degie los pasos que damos para ayudarte con tu proyecto--> {@link View} <-- Vista contenedora
 * @alias Pasos
 * @namespace
 */
const Pasos = () => {

  const { t } = useTranslation();

  return (
    <section className="div2" id="2a">
      <h5 className="subtitle"><b>{t('weCreate1.label')}</b>{t('weCreate2.label')}</h5>
      <div className="pasos div_pasos">
        <div className="paso">
          <div className="div_img">
            <img src={chica1} alt="" />
          </div>
          <div className="description">
            <div className="num"><span>1</span></div>
            <p className="prf_paso">{t('weGet1.label')}<b>{t('weGet2.label')}</b> </p>
          </div>
        </div>
        <div className="paso">
          <div className="div_img">
            <img src={chica2} alt="" />
          </div>
          <div className="description">
            <div className="num"><span>2</span></div>
            <p className="prf_paso">{t('weDesing1.label')}<b>{t('weDesing2.label')}</b> {t('weDesing3.label')}</p>
          </div>
        </div>
        <div className="paso">
          <div className="div_img">
            <img src={chica3} alt="" />
          </div>
          <div className="description">
            <div className="num"><span>3</span></div>
            <p className="prf_paso">{t('weMeasure1.label')}<b>{t('weMeasure2.label')}</b></p>
          </div>
        </div>
      </div>
      <CarrusellPasos />
      <a className="boton" href={(localStorage.getItem('lng') === 'es') ? env.LINK_WHATS : env.LINK_WHATS_EN} target='_blanck' >{t('iWant.label')}</a>
    </section>
  )
}

export default Pasos