import React, { useEffect } from "react";
import env from '../environtments'

// Trasnlation 
import '../i18n'
import { useTranslation } from 'react-i18next'

/**    IMAGENES    */
import jet from '../assets/images/rocket.png'
import m10 from '../assets/images/+10.svg'

/**     COMPONENTES      */
import Prices from "./Prices";
import CarruselBlog from "../components/Carrusel_blog";
import Contact from "./contact";
import CarruselServices from "../components/Carrusel_services";
import DivServices from "../components/div_services";
import Pasos from "../components/Pasos";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";


/**
 * View Componente Principal de la vista Home y contemedora de la mayoria de los componentes inforativos y de contacto
 * contentedora de:
 * {@link Prices}
 * {@link CarruselBlog}
 * {@link Contact}
 * {@link CarruselServices}
 * {@link DivServices}
 * {@link Pasos}
 * 
 * @alias View
 */
const View = () => {
  const { t, i18n } = useTranslation();
  /**
   * inicializa el idioma de la vista HOme
   */
  const initLang = () => {
    if (localStorage.getItem('lng')) {
      i18n.changeLanguage(localStorage.getItem('lng'))
    } else {
      window.localStorage.setItem('lng', 'es')
      i18n.changeLanguage('es')
    }
  }

  /**
 * inicializa el efecto del biglogo n la vista home
 */
  useEffect(() => {
    var Bheader = document.querySelector(".big_logo ");
    var ima = document.querySelector(".big_logo img");

    window.addEventListener("scroll", function () {
      Bheader.classList.toggle("bye", window.scrollY > 0);
      ima.classList.toggle("bye", window.scrollY > 0);
    })
  }, [])

  useEffect(() => {
    var Bheader = document.querySelector(".big_logo_mobile ");
    var ima = document.querySelector(".big_logo_mobile img");

    window.addEventListener("scroll", function () {
      Bheader.classList.toggle("bye", window.scrollY > 0);
      ima.classList.toggle("bye", window.scrollY > 0);
    })
  }, [])

  useEffect(() => {
    initLang()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <>
      <Helmet>
        <title>KreativeTree</title>
        <meta name="keywords" content="KreativeTree, agencia web, Artículos, marketing, pauta." />
        <meta name="description" content="ven con nosotros ala mejor agencia de marketing para ti " />
      </Helmet>

      <div className='home'>
        <div className="contenedor_home">

          <section className="div1" id="1a">
            <div className="leyenda">
              <h5 className='subtitle'>{t('in.label')} <span>Kreative Tree</span> {t('weBelieve.label')}</h5>
              <p className='par1'>{t('everyDream.label')}</p>
              <a className="boton" href={(localStorage.getItem('lng') === 'es') ? env.LINK_WHATS : env.LINK_WHATS_EN} target='_blanck' >{t('achieveDream.label')}</a>
            </div>
          </section>

          <Pasos />

          <section className="div3" id="3a">
            <div className="bu1"></div>
            <div className="bu2"></div>
            <div className="content">
              <div className="jet">
                <img src={jet} alt="imagen de cohete" />
              </div>
              <div className="years">
                <div className="diez">
                  <img src={m10} alt="imagen de mas diez" />
                </div>
                <p className='prf_10'>{t('ourProject.label')}</p>
                <a className="boton" href={(localStorage.getItem('lng') === 'es') ? env.LINK_WHATS : env.LINK_WHATS_EN} target='_blanck' >{t('iNeed.label')}</a>
              </div>
            </div>
          </section>

          <CarruselServices />

          <DivServices />

          <Prices />

          <CarruselBlog />

          <Contact />

        </div>
      </div>

      <Footer />
    </>
  )
}

export default View