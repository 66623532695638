export function SubmitSheetL(inputs = {}) {

  const url = "https://script.google.com/macros/s/AKfycbz1j26On2XLTdO-7p_2t5uZhNBDvpdwtcZ7KZ4sEZIqfBPhZx9pmGNlsm_nd-K0A5nNBw/exec"
  const formDatab = new FormData();
  formDatab.append('Name', inputs.Name);
  formDatab.append('Ext_', inputs.Ext_);
  formDatab.append('Phone', inputs.Phone);
  formDatab.append('Email', inputs.Email);
  formDatab.append('Zipcode', inputs.Zipcode);
  formDatab.append('Landing', inputs.Landing);
  console.log('formDatab more ======>> ', formDatab);
  fetch(url, {
    method: "POST",
    body: formDatab,
  }
  ).then((res) => res.json())
    .then((data) => {
      console.log(data)
    })
    .catch((error) => {
      console.log(error)
    })
}
//sheet form
// https://docs.google.com/spreadsheets/d/17Jq-Cc1ki3VOOfXs0_J9eoktJwJ79HTFDAZp_1TE1-k/edit#gid=759236455

//App Script to sheet form

// const sheets = SpreadsheetApp.openByUrl("https://docs.google.com/spreadsheets/d/17Jq-Cc1ki3VOOfXs0_J9eoktJwJ79HTFDAZp_1TE1-k/edit#gid=759236455");
// const sheet = sheets.getSheetByName("landings");
// function doPost(e) {
//   let data = e.parameter;
//   sheet.appendRow([data.Name, data.Ext_, data.Phone, data.Email, data.Zipcode, data.Landing]);
//   return ContentService.createTextOutput("Your message was successfully sent to the Googlesheet database!");
// }

// https://script.google.com/macros/s/AKfycbz1j26On2XLTdO-7p_2t5uZhNBDvpdwtcZ7KZ4sEZIqfBPhZx9pmGNlsm_nd-K0A5nNBw/exec