import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/scss/utilities/slider.scss";

/** Import de la raduccion */
import '../i18n'
import { useTranslation } from 'react-i18next'

/**
 * Carousel Componente del carrusel general adaptativo para los banner landing
 * @alias Carousel
 * @namespace
 */
const Carousel = ({ children }) => {

  const { t } = useTranslation();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className='contenedor '>
      <div className="carrusel_landing" id="">
        <Slider {...settings}>
          {children}
        </Slider>
      </div>
    </div>
  )
}

export default Carousel