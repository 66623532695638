import React from 'react'
// import $ from "jquery";

// Trasnlation 
import '../i18n'
import { useTranslation } from 'react-i18next'
import FormContact from '../components/Formcontact';



/**
 * Contact componente de contacto que incluye el formulario principal
 * @alias Contact
 */
const Contact = () => {

  const { t } = useTranslation();


  return (

    <div className='contenedor' id='contact'>
      <div className="div10">
        <div className="contact">
          <div className="message_contact">
            <div className="message">
              <h5 className="tit_message">{t('weWant.label')}</h5>
              <p className="prf_message">{t('areYouWilling.label')} {t('on.label')}<b>Kreative Tree</b> {t('onHelp.label')} {t('talkTo.label')}</p>
            </div>
          </div>
          <FormContact />
        </div>
      </div>
    </div>
  )
}

export default Contact