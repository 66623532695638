import React from 'react'

// Trasnlation 
import '../../i18n'
import { useTranslation } from 'react-i18next';

/**
 * CardPlanGold: Card de la lista prices servicio Oro--> {@link Prices} <-- vista Prices
 * @alias CardPlanGold
 * @namespace
 * @param {Object} plan. - Objeto del plan que contiene los parametros necesadios Ej:
 * { price: 441, pieces: 23, linkES: 'https://wa.link/fhj207', linkEN: 'https://wa.link/fhj207' }
 */
const CardPlanGold = ({ plan }) => {
  const { t } = useTranslation();
  return (
    <div className="plan plan_oro">
      <div className="medalla">
        <i className="icon icon-medallaG"></i>
      </div>
      <div className="name_plan">{t('goldPlan.label')}</div>
      <div className="price_plan">$ <span>{plan.price}</span> usd</div>
      <div className="serv_plan">
        <div className="flex">
          <div className="icon_s">
            <i className="icon icon-estrategia"></i>
          </div>
          <p className="p_serv">{t('strategy.label')}</p>
        </div>
        <div className="flex">
          <div className="icon_s">
            <i className="icon icon-redes"></i>
          </div>
          <p className="p_serv">{plan.pieces} {t('pieces.label')}</p>
        </div>
        <div className="flex">
          <div className="icon_s">
            <i className="icon icon-pauta"></i>
            <div className="small_icons">
              <i className="icon icon-smallFace"></i>
              <i className="icon icon-smallInsta"></i>
              <i className="icon icon-smallGoogle"></i>
              <i className="icon icon-smallTiktok"></i>
            </div>
          </div>
          <p className="p_serv">{t('advMetaGooTik.label')}</p>
        </div>
        <div className="flex">
          <div className="icon_s">
            <i className="icon icon-pageLand"></i>
          </div>
          <p className="p_serv">{t('landingPage.label')}</p>
        </div>
      </div>
      <a className="boton_blue" href={(localStorage.getItem('lng') === 'es') ? plan.linkES : plan.linkEN} target='_blanck' >{t('starNow.label')}</a>
    </div>
  )
}

export default CardPlanGold
