import React from 'react'

import env from '../../environtments'

const SocialContact = ({ t }) => {
  return (
    <div className="social_info">
      <div className="social1">
        <h5 className="subtitle">{t('folowUs.label')}</h5>
        <div className="social_networks">
          <a href="https://www.facebook.com/KreativeTree.studio " target='_blank' rel='noreferrer nonopener '>
            <i className="icon icon-faceBUB"></i>
          </a>
          <a href="https://www.Instagram.com/kreativetree.studio " target='_blank' rel='noreferrer nonopener '>
            <i className="icon icon-instaBUB"></i>
          </a>
          <a href="https://www.tiktok.com/@kreativetreestudio" target='_blank' rel='noreferrer nonopener '>
            <i className="icon icon-tiktokBUB"></i>
          </a>
          <a href="">
            <i className="icon icon-youtuBUB"></i>
          </a>
        </div>
      </div>
      <div className="social2">
        <h5 className="subtitle">{t('writingUs.label')}</h5>
        <label htmlFor="" className="label_contac label_footer">
          <i className="icon icon-whatsapp"></i>
          <input type="text" />
          <a className="" href={(localStorage.getItem('lng') === 'es') ? env.LINK_WHATS : env.LINK_WHATS_EN} target='_blank' rel='noreferrer nonopener '><i className="icon icon-send"></i></a>
        </label>
      </div>
    </div>
  )
}

export default SocialContact
