
import Routes from './router/router';

// Trasnlation 
import './i18n'
import { useTranslation } from 'react-i18next'

/** 
 * Componentes Globales 
 */
import Header from './components/Header'
import CopyR from './components/Copy'
import Footer from './components/Footer';


/**
 * App Componente es el comtenedor principal de toda la pagina 
 * @alias App
 */
function App() {

  const { i18n } = useTranslation()
  /**
   * Inicializa la pagina en el idiona establecido en localstorage si no lo tiene lo define como español
   */
  const initLang = () => {
    if (localStorage.getItem('lng')) {
      i18n.changeLanguage(localStorage.getItem('lng'))
    } else {
      window.localStorage.setItem('lng', 'es')
      i18n.changeLanguage('es')
    }
  }

  return (
    <div className="App" onLoad={initLang}>
      <Header />
      <Routes />

      <CopyR />
    </div>
  );
}

export default App;
