import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

/**
 * Views
 */
import View from '../views/View'
import NotFound from '../views/NotFound'
import Redes from '../views/Redes'
import PagePauta from '../views/Pauta'

/**
 * Router redirigido a las principales vistas de la web
 */
export default function RutesLandings() {
  return (

    <Switch>
      <Route exact path="/" component={View} />
      <Route exact path="/paquete_basico" component={Redes} />
      <Route exact path="/pauta_digital" component={PagePauta} />
      <Route component={NotFound} />
    </Switch>

  )
}