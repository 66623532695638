import React, { useState, useRef } from 'react'
import { useLocation } from 'react-router-dom';
import $ from "jquery";

//helpers
import { SubmitSheet } from "../helpers/sheetForm";
import { sendEmail } from '../helpers/mail'

// Trasnlation 
import '../i18n'
import { useTranslation } from 'react-i18next'

//constantes externas
import env from '../environtments'
import { País_Prefijo_telefónico } from '../helpers/listServices';

/**
 * objeto inicial de data para el formulario de contacto home
 */
const initData_contact = {
  Landing: '', Name: '', Ext_: '+1', Phone: '', Email: '', Message: '',
}

/**
 * Contact componente de contacto que incluye el formulario principal
 * @alias Contact
 */
const FormContact = () => {

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const form = useRef()

  const [datacontact, setDatacontact] = useState(initData_contact)
  const [send, setSend] = useState(false)
  const linky = env.LINK_CONTACT

  const hanChange = (e) => {
    $(".invalide").removeClass('invalide')
    setDatacontact({ ...datacontact, [e.target.name]: e.target.value });
  }
  /**
   * Funcion de validaciones por patrones y contenido del formulario de contacto
   */
  const validation = () => {
    let patternMail = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i
    let patternPhone = /^(\+\d{1,3}( )?)?((\(\d{1,3}\))|\d{1,3})[- .]?\d{3,4}[- .]?\d{4}$/

    let error = false

    if (datacontact.Name.trim() == '' || datacontact.Name == null || datacontact.Name == undefined) {
      $(".input_contact[name='Name']").addClass('invalide')
      error = true
    }
    if (datacontact.Phone.trim() == '' || datacontact.Phone == null || patternPhone.test(datacontact.Phone.trim()) === false) {
      $("input[name='Phone']").addClass('invalide')
      error = true
    }
    if (datacontact.Email.trim() == '' || datacontact.Email == null || patternMail.test(datacontact.Email.trim()) === false) {
      $("input[name='Email']").addClass('invalide')
      error = true
    }

    return error
  }
  /**
   * Funcion de submit de envio de datos del formulario
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validation()) {
      SubmitSheet(datacontact)
      sendEmail(form.current)
      setDatacontact(initData_contact)
      setSend(true)
      setTimeout(() => {
        setSend(false)
        window.open(linky, '_blank')
      }, 2000);
    }

  }

  return (
    <form className="form_contact" autoComplete='off' onSubmit={handleSubmit} ref={form}>
      <input className=" input_contact" type="hidden" name="Landing" id="" value={pathname === '' || pathname === '/' ? 'home' : pathname} />
      <label className="label_contact" htmlFor="">
        <i className="icon icon-user"></i>
        <input className="input_contact" type="text" name="Name" id="" placeholder={t('name.label')} onChange={hanChange} value={datacontact.Name} />
      </label>
      <div className="row">
        <label className="label_contact ext" htmlFor="">
          <i className="icon icon-phone"></i>
          <select className="select input_contact" name="Ext_" id="" placeholder="+57" onChange={hanChange} value={datacontact.Ext_} >
            {País_Prefijo_telefónico && País_Prefijo_telefónico.map((el, i) => (
              <option className='option' key={el.pais + i} value={el.prefijo} >{el.prefijo}</option>
            ))}
          </select>
          <i className="icon icon-caretUp"></i>
          <i className="icon icon-caretDown"></i>
          {/* <input className="input_contact" type="number" name="ext_c" id="" placeholder="+57" /> */}
        </label>
        <label className="label_contact teleph" htmlFor="">
          <input className=" input_contact" type="number" name="Phone" id="" placeholder={t('phone.label')} title='phone to max length 10 digits' onChange={hanChange} value={datacontact.Phone} />
        </label>
      </div>
      <label className="label_contact" htmlFor="">
        <i className="icon icon-email"></i>
        <input className="input_contact" type="email" name="Email" id="" placeholder={t('email.label')} title='Email ex: correo@correo.com' onChange={hanChange} value={datacontact.Email} />
      </label>
      <textarea className="scroll-y" name="Message" id="" placeholder={t('bigChallenge.label')} onChange={hanChange} value={datacontact.Message}></textarea>
      <button className="boton">{t('requAdvice.label')}</button>
      {send && <p className='sendOk'>Tu informacion fue enviada correctamente 👌</p>}
    </form>
  )
}

export default FormContact