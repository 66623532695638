import React, { useState } from 'react'
import Modal from './Modal';

import marca_emi from '../assets/images/marca_cont/PortafolioKTS_Marca_emilan.png'
import marca_emi_mob from '../assets/images/marca_cont/PortafolioKTS_emilan_Mobile.png'
import marca_luci from '../assets/images/marca_cont/PortafolioKTS_Marca_Luciagentes.png'
import marca_luci_mob from '../assets/images/marca_cont/PortafolioKTS_luciagentes_Mobile.png'
import marca_siri from '../assets/images/marca_cont/PortafolioKTS_Marca_Sirimiri.png'
import marca_siri_mob from '../assets/images/marca_cont/PortafolioKTS_sirimiri_Mobile.png'
import marca_anay from '../assets/images/marca_cont/PortafolioKTS_Marca_Anayiri.png'
import marca_anay_mob from '../assets/images/marca_cont/PortafolioKTS_anayiri_Mobile.png'
import marca_bbt from '../assets/images/marca_cont/PortafolioKTS_Marca_BBT.png'
import marca_bbt_mob from '../assets/images/marca_cont/PortafolioKTS_bbtTodo_Mobile.png'
import marca_vet from '../assets/images/marca_cont/PortafolioKTS_Marca_TEV.png'
import marca_vet_mob from '../assets/images/marca_cont/PortafolioKTS_vending_Mobile.png'
import marca_clean from '../assets/images/marca_cont/PortafolioKTS_Marca_Cleandaz.png'
import marca_clean_mob from '../assets/images/marca_cont/PortafolioKTS_cleandaz_Mobile.png'

/**
 * ModalsMarks Componente rederiza los modales de las cards de marcas en la tercera opcion del portafolio empresarial de kreative tree
 * @alias ModalsMarks
 */
const ModalsMarks = ({ modalActive, openModal, setOpenModal }) => {


  return (
    <>
      {
        openModal.luci &&
        <Modal onClose={() => modalActive('', setOpenModal)} classes='modal-marcas'>
          <div className="marca_present">
            <h2 className="titleportafolio">
              luciagentes
            </h2>
            <p className="description">Soluciones para agentes de seguros</p>
            <img src={marca_luci} alt="portafolio marca" className="desktop" />
            <img src={marca_luci_mob} alt="portafolio marca" className="mobile" />
          </div>
        </Modal>
      }
      {
        openModal.emi &&
        <Modal onClose={() => modalActive('', setOpenModal)} classes='modal-marcas'>
          <div className="marca_present">
            <h2 className="titleportafolio">
              Emilan
            </h2>
            <p className="description">Agencia de seguros</p>
            <img src={marca_emi} alt="portafolio marca" className="desktop" />
            <img src={marca_emi_mob} alt="portafolio marca" className="mobile" />
          </div>
        </Modal>
      }
      {
        openModal.siri &&
        <Modal onClose={() => modalActive('', setOpenModal)} classes='modal-marcas'>
          <div className="marca_present">
            <h2 className="titleportafolio">
              Sirimiri
            </h2>
            <p className="description">Tienda en línea</p>
            <img src={marca_siri} alt="portafolio marca" className="desktop" />
            <img src={marca_siri_mob} alt="portafolio marca" className="mobile" />
          </div>
        </Modal>
      }
      {
        openModal.anay &&
        <Modal onClose={() => modalActive('', setOpenModal)} classes='modal-marcas'>
          <div className="marca_present">
            <h2 className="titleportafolio">
              Anayiri
            </h2>
            <p className="description">Marca de mochilas wayuu</p>
            <img src={marca_anay} alt="portafolio marca" className="desktop" />
            <img src={marca_anay_mob} alt="portafolio marca" className="mobile" />
          </div>
        </Modal>
      }
      {
        openModal.bbt &&
        <Modal onClose={() => modalActive('', setOpenModal)} classes='modal-marcas'>
          <div className="marca_present">
            <h2 className="titleportafolio">
              BBT todo
            </h2>
            <p className="description">Licorera</p>
            <img src={marca_bbt} alt="portafolio marca" className="desktop" />
            <img src={marca_bbt_mob} alt="portafolio marca" className="mobile" />
          </div>
        </Modal>
      }
      {
        openModal.vet &&
        <Modal onClose={() => modalActive('', setOpenModal)} classes='modal-marcas'>
          <div className="marca_present">
            <h2 className="titleportafolio">
              Transportes Especiales Vending
            </h2>
            <p className="description">Servicio de transporte de mercancias</p>
            <img src={marca_vet} alt="portafolio marca" className="desktop" />
            <img src={marca_vet_mob} alt="portafolio marca" className="mobile" />
          </div>
        </Modal>
      }
      {
        openModal.clean &&
        <Modal onClose={() => modalActive('', setOpenModal)} classes='modal-marcas'>
          <div className="marca_present">
            <h2 className="titleportafolio">
              Cleandaz
            </h2>
            <p className="description">Servicios de organización y limpieza</p>
            <img src={marca_clean} alt="portafolio marca" className="desktop" />
            <img src={marca_clean_mob} alt="portafolio marca" className="mobile" />
          </div>
        </Modal>
      }

    </>
  )
}

export default ModalsMarks
