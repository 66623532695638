import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      className="animated"
      id="freepik_stories-mobile-marketing"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      {...props}
    >
      <style>
        {
          "@keyframes floating{0%,to{opacity:1;transform:translateY(0)}50%{transform:translateY(-10px)}}@keyframes wind{0%{transform:rotate(0deg)}25%{transform:rotate(1deg)}75%{transform:rotate(-1deg)}}svg#freepik_stories-mobile-marketing:not(.animated) .animable{opacity:0}"
        }
      </style>
      <g
        id="freepik--Floor--inject-2"
        className="animable"
        style={{
          transformOrigin: "250px 333.49px"
        }}
      >
        <ellipse
          id="freepik--floor--inject-2"
          cx={250}
          cy={333.49}
          rx={236}
          ry={136.23}
          style={{
            transformOrigin: "250px 333.49px"
          }}
          className="animable"
          fill="#f5f5f5"
        />
      </g>
      <g
        id="freepik--Shadow--inject-2"
        className="animable"
        style={{
          transformOrigin: "244.906px 337.47px"
        }}
      >
        <path
          id="freepik--shadow--inject-2"
          d="M41.12 306.5l257.44 148.63a11.08 11.08 0 0010 0l140.14-80.91c2.76-1.6 2.76-4.18 0-5.78L191.26 219.81a11.08 11.08 0 00-10 0L41.12 300.72c-2.77 1.6-2.77 4.18 0 5.78z"
          style={{
            transformOrigin: "244.906px 337.47px"
          }}
          className="animable"
          fill="#e0e0e0"
        />
      </g>
      <g
        id="freepik--Plant--inject-2"
        className="animable"
        style={{
          transformOrigin: "50.2845px 247.065px"
        }}
      >
        <g
          id="freepik--Plants--inject-2"
          className="animable"
          style={{
            transformOrigin: "50.2845px 247.065px"
          }}
        >
          <path
            d="M60.46 290.43l10.36-4.67s4.52-20.73 4.57-37.13c.06-23-11.9-52-30.14-47.33s5.06 30.5 8.81 50.44 6.4 38.69 6.4 38.69z"
            style={{
              transformOrigin: "56.8731px 245.615px"
            }}
            id="elvzlje0kovap"
            className="animable"
            fill="#7519ff"
          />
          <g id="elv18bqf6f0so">
            <path
              d="M60.46 290.43l10.36-4.67s4.52-20.73 4.57-37.13c.06-23-11.9-52-30.14-47.33s5.06 30.5 8.81 50.44 6.4 38.69 6.4 38.69z"
              style={{
                transformOrigin: "56.8731px 245.615px"
              }}
              className="animable"
              id="ely2k8h9wmrpn"
              opacity={0.15}
            />
          </g>
          <path
            d="M65.85 288.57a.59.59 0 01-.51-.67c6.85-50.54-15.82-78.6-16-78.88a.59.59 0 01.08-.84.6.6 0 01.84.08c.23.28 23.23 28.75 16.31 79.8a.6.6 0 01-.72.51z"
            style={{
              transformOrigin: "58.5341px 248.314px"
            }}
            id="elmhkb35z8e9c"
            className="animable"
            fill="#fff"
          />
          <path
            d="M54 293.33l7.32-3.33s2.64-14.27-2.65-32.47c-4.6-15.84-18.57-30-26.5-28.13s-8.3 7.34-5.13 13.42S51.56 264.79 54 293.33z"
            style={{
              transformOrigin: "43.5788px 261.282px"
            }}
            id="el9wzsmsrrcy6"
            className="animable"
            fill="#7519ff"
          />
          <g id="eli0e1n1py82">
            <path
              d="M54 293.33l7.32-3.33s2.64-14.27-2.65-32.47c-4.6-15.84-18.57-30-26.5-28.13s-8.3 7.34-5.13 13.42S51.56 264.79 54 293.33z"
              style={{
                transformOrigin: "43.5788px 261.282px"
              }}
              className="animable"
              id="el2r9tfdx0xqk"
              fill="#fff"
              opacity={0.2}
            />
          </g>
          <path
            d="M57.78 292.17a.6.6 0 01-.53-.65c3.38-32.06-24.54-56.77-24.82-57a.59.59 0 11.78-.89c.29.25 28.67 25.37 25.23 58a.6.6 0 01-.66.54z"
            style={{
              transformOrigin: "45.478px 262.828px"
            }}
            id="el4n18jee7wwo"
            className="animable"
            fill="#fff"
          />
        </g>
      </g>
      <g
        id="freepik--Icons--inject-2"
        className="animable"
        style={{
          transformOrigin: "380.469px 135.245px",
          animation: "1.5s Infinite linear floating",
          animationDelay: "0s"
        }}
      >
        <g
          id="freepik--like-icon--inject-2"
          className="animable"
          style={{
            transformOrigin: "377.795px 135.407px"
          }}
        >
          <path
            d="M399.15 114.75a5.38 5.38 0 00-.22-1.61l5.85-6.7-7.5-4.21c-.71-.49-1.72-.55-3.09.25l-13 7.55-1.75 1a35 35 0 00.62-4.57c.05-.64.06-1.25.06-1.86l.24.12 4.39-5.13-7.5-4.22c-2.52-1.54-6.78 1.5-6.78 2.73 0 1.48.64 9.23 0 11.45s-7.34 17.53-7.34 17.53v31.9l24.45-14.14c3.21-1.86 5.44-5.82 5.44-8.5a8.39 8.39 0 00-.65-3.5c2.08-1.2 4.15-5.54 4.15-7.38 0-2.78-.95-3.5-.95-3.5a10.56 10.56 0 003.58-7.21z"
            style={{
              transformOrigin: "383.955px 126.962px"
            }}
            id="elnvffnpiq87"
            className="animable"
            fill="#7519ff"
          />
          <g id="elti83cqbqd8l">
            <path
              d="M399.15 114.75a5.38 5.38 0 00-.22-1.61l5.85-6.7-7.5-4.21c-.71-.49-1.72-.55-3.09.25l-13 7.55-1.75 1a35 35 0 00.62-4.57c.05-.64.06-1.25.06-1.86l.24.12 4.39-5.13-7.5-4.22c-2.52-1.54-6.78 1.5-6.78 2.73 0 1.48.64 9.23 0 11.45s-7.34 17.53-7.34 17.53v31.9l24.45-14.14c3.21-1.86 5.44-5.82 5.44-8.5a8.39 8.39 0 00-.65-3.5c2.08-1.2 4.15-5.54 4.15-7.38 0-2.78-.95-3.5-.95-3.5a10.56 10.56 0 003.58-7.21z"
              style={{
                transformOrigin: "383.955px 126.962px"
              }}
              className="animable"
              id="eleygwel066wq"
              opacity={0.1}
            />
          </g>
          <path
            style={{
              transformOrigin: "366.98px 145.175px"
            }}
            id="el3qk762odjoe"
            className="animable"
            fill="#e0e0e0"
            d="M370.86 163.37L363.12 158.9 363.1 126.98 370.83 131.45 370.86 163.37z"
          />
          <path
            style={{
              transformOrigin: "366.98px 145.175px"
            }}
            id="el9zlb4vcpo9a"
            className="animable"
            fill="#7519ff"
            d="M370.86 163.37L363.12 158.9 363.1 126.98 370.83 131.45 370.86 163.37z"
          />
          <g id="elubjugjbv3ic">
            <path
              style={{
                transformOrigin: "366.98px 145.175px"
              }}
              className="animable"
              id="elbc3nkde9z9c"
              opacity={0.3}
              d="M370.86 163.37L363.12 158.9 363.1 126.98 370.83 131.45 370.86 163.37z"
            />
          </g>
          <path
            d="M404.53 130.45c0 4.12-2.35 5.57-4.43 6.77a8.66 8.66 0 01.63 3.52c0 2.66-2.22 6.64-5.42 8.48l-24.45 14.14v-31.91s6.72-15.31 7.35-17.54 0-10 0-11.43 6.07-5.54 8-1.3 2.09 8.56 1 14.2l14.78-8.55c3.12-1.8 4.4.78 4.4 2.8 0 2.87-1.34 5.7-3.12 6.78 0 0 3.4.17 3.4 4.13a8.37 8.37 0 01-3.28 6.45s1.14.7 1.14 3.46z"
            style={{
              transformOrigin: "388.765px 131.337px"
            }}
            id="el3d2phgffo6k"
            className="animable"
            fill="#7519ff"
          />
          <path
            style={{
              transformOrigin: "362px 154.215px"
            }}
            id="elokemncdd16j"
            className="animable"
            fill="#37474f"
            d="M356.66 175.87L367.34 169.71 367.31 132.56 356.66 138.72 356.66 175.87z"
          />
          <path
            style={{
              transformOrigin: "352.79px 155.065px"
            }}
            id="eldz2pxhi10i"
            className="animable"
            fill="#263238"
            d="M356.66 175.87L348.92 171.41 348.92 134.26 356.66 138.72 356.66 175.87z"
          />
          <path
            style={{
              transformOrigin: "358.115px 133.41px"
            }}
            id="elf2905i1zx1n"
            className="animable"
            fill="#455a64"
            d="M359.58 128.1L367.31 132.56 356.66 138.72 348.92 134.26 359.58 128.1z"
          />
          <path
            d="M378.17 113.91c-.63 2.23-7.35 17.54-7.35 17.54l-7.72-4.35s6.7-15.32 7.34-17.53 0-10 0-11.45l7.74 4.36c-.01 1.52.64 9.22-.01 11.43z"
            style={{
              transformOrigin: "370.78px 114.785px"
            }}
            id="el43z8pzgz36l"
            className="animable"
            fill="#7519ff"
          />
          <g id="elzij0dad1kk">
            <path
              d="M378.17 113.91c-.63 2.23-7.35 17.54-7.35 17.54l-7.72-4.35s6.7-15.32 7.34-17.53 0-10 0-11.45l7.74 4.36c-.01 1.52.64 9.22-.01 11.43z"
              style={{
                transformOrigin: "370.78px 114.785px"
              }}
              className="animable"
              id="elnndz0gtyoei"
              opacity={0.2}
            />
          </g>
        </g>
        <g
          id="freepik--heart-icon--inject-2"
          className="animable"
          style={{
            transformOrigin: "437.964px 175.538px"
          }}
        >
          <path
            d="M468.91 146.35l-7.36-4.59c-2.72-1.74-6.86-1.56-11.56 1-6.5 3.47-12.24 12.23-14.72 19.66l-5.27-3.28c-2.67-1.93-6.42-2.34-10.65-.08-.52.28-1 .59-1.56.91l-.81.55c-.23.16-.47.31-.7.48s-.72.55-1.07.83l-.4.31c-.41.34-.82.7-1.22 1.07l-.18.17c-.45.41-.88.84-1.31 1.28a34.3 34.3 0 00-3.6 4.39c-3 4.3-4.9 9.1-5 13.36-.26 7.93 4.47 13.15 10.38 16.58l5.81 3.61a41.44 41.44 0 0011.14 5l9.67 2.91L450.67 197c9.73-12.9 21.41-30.78 21.8-42.74.13-3.81-1.23-6.52-3.56-7.91z"
            style={{
              transformOrigin: "437.984px 175.564px"
            }}
            id="el0tbdmpb7bt8c"
            className="animable"
            fill="#7519ff"
          />
          <path
            d="M419.57 202.58l-5.73-3.58c-5.9-3.43-10.64-8.65-10.38-16.58.14-4.25 2.07-9.07 5.05-13.36l7.55 4.69c-3 4.29-4.91 9.11-5.05 13.36-.23 7.12 3.53 12.05 8.56 15.47z"
            style={{
              transformOrigin: "411.51px 185.82px"
            }}
            id="elqg6shtys2ab"
            className="animable"
            fill="#7519ff"
          />
          <g id="elj6jhmf4uxk">
            <g
              style={{
                transformOrigin: "411.51px 185.82px"
              }}
              className="animable"
              id="elibaqlzlf9d"
              opacity={0.3}
            >
              <path
                d="M419.57 202.58l-5.73-3.58c-5.9-3.43-10.64-8.65-10.38-16.58.14-4.25 2.07-9.07 5.05-13.36l7.55 4.69c-3 4.29-4.91 9.11-5.05 13.36-.23 7.12 3.53 12.05 8.56 15.47z"
                id="el16bgpds6eg"
                className="animable"
                style={{
                  transformOrigin: "411.51px 185.82px"
                }}
              />
            </g>
          </g>
          <path
            d="M468.88 146.3c-2.73-1.58-6.78-1.33-11.34 1.11-7.84 4.18-14.6 16.06-15.82 24-.74-6.91-6.94-11.87-14.78-7.67a32.24 32.24 0 00-10.88 10l-7.55-4.69.38-.54c.11-.15.21-.29.33-.44l.84-1.1a35.24 35.24 0 012.6-2.87c.22-.24.46-.46.69-.68l.5-.46a29.08 29.08 0 015.54-3.94 11.8 11.8 0 015.9-1.53 8.49 8.49 0 014.75 1.61l5.23 3.22c2.47-7.42 8.22-16.17 14.73-19.64 4.7-2.52 8.83-2.7 11.55-1z"
            style={{
              transformOrigin: "438.695px 157.153px"
            }}
            id="elmh5cdopq4g"
            className="animable"
            fill="#7519ff"
          />
          <g id="elw5y4amhexy">
            <path
              d="M468.88 146.3c-2.73-1.58-6.78-1.33-11.34 1.11-7.84 4.18-14.6 16.06-15.82 24-.74-6.91-6.94-11.87-14.78-7.67a32.24 32.24 0 00-10.88 10l-7.55-4.69.38-.54c.11-.15.21-.29.33-.44l.84-1.1a35.24 35.24 0 012.6-2.87c.22-.24.46-.46.69-.68l.5-.46a29.08 29.08 0 015.54-3.94 11.8 11.8 0 015.9-1.53 8.49 8.49 0 014.75 1.61l5.23 3.22c2.47-7.42 8.22-16.17 14.73-19.64 4.7-2.52 8.83-2.7 11.55-1z"
              style={{
                transformOrigin: "438.695px 157.153px"
              }}
              className="animable"
              id="el3e6y93189rn"
              opacity={0.1}
            />
          </g>
        </g>
        <g
          id="freepik--twitter-icon--inject-2"
          className="animable"
          style={{
            transformOrigin: "318.955px 100.264px"
          }}
        >
          <path
            d="M349.45 69.31l-7.4-4.6-2.84 5.72 4 4.37s.24.86 2.19-.65a22.91 22.91 0 004.05-4.84z"
            style={{
              transformOrigin: "344.33px 69.8769px"
            }}
            id="elbsy46u08yzg"
            className="animable"
            fill="#7519ff"
          />
          <g id="elngm60gb41n">
            <path
              d="M349.45 69.31l-7.4-4.6-2.84 5.72 4 4.37s.24.86 2.19-.65a22.91 22.91 0 004.05-4.84z"
              style={{
                transformOrigin: "344.33px 69.8769px"
              }}
              className="animable"
              id="elacneh819ddi"
              opacity={0.3}
            />
          </g>
          <path
            d="M343.86 79.42c0 9-2.59 20-7.39 30.12-5.48 11.58-13.82 22.15-24.4 27.81-6.06 3.24-11.61 3.92-16.21 2.48l-7.4-4.6c4.8-1.83 9.67-5.79 13.87-11.24l-4.54-2.83a7.22 7.22 0 01-3-4.59 10.88 10.88 0 003.08-1.48l-3-1.88c-2.27-1.41-3.55-4.61-3.34-8.79a7.56 7.56 0 004.79-.83 5.42 5.42 0 01-1.31-.68c-3.5-2.49-4.09-9.26-1.39-16.26l7.38 4.59c4.08 1.1 9 .7 14.2-1.74-1.27-7.93 4.08-19.08 11-22.78 3.08-1.64 5.81-1.43 7.67.26a41.6 41.6 0 006.91-7l7.4 4.6a28.13 28.13 0 01-5 10.26 35.87 35.87 0 006.21-5.49 56.25 56.25 0 01-5.53 10.07z"
            style={{
              transformOrigin: "318.925px 100.264px"
            }}
            id="elqvahrwy5ukr"
            className="animable"
            fill="#7519ff"
          />
          <path
            d="M343.86 79.42c0 9-2.59 20-7.39 30.12-5.48 11.58-13.82 22.15-24.4 27.81-6.06 3.24-11.61 3.92-16.21 2.48 5.65-2.16 11.39-7.25 16-14.27-4.68 2.38-8.5.46-9.68-4.39a12.85 12.85 0 004.84-2.82c-5.1 1.39-8.46-2.78-8.13-9.33a7.59 7.59 0 004.8-.83 5.58 5.58 0 01-1.07-.52c-3.71-2.34-4.4-9.27-1.63-16.42.79 3.73 7.71 6.61 13.69 6.52 5.78-.09 7.77-3.45 7.88-3.66-1.27-7.93 4.09-19.08 11-22.78 3.07-1.64 5.8-1.43 7.67.25a41.16 41.16 0 006.91-7 28.13 28.13 0 01-5 10.26 35.87 35.87 0 006.21-5.49 56.25 56.25 0 01-5.49 10.07z"
            style={{
              transformOrigin: "322.605px 102.564px"
            }}
            id="ellpmxue1f5n"
            className="animable"
            fill="#7519ff"
          />
          <g id="elubr1flk2iyk">
            <path
              d="M348.19 64.54a41.72 41.72 0 01-6.9 7 4.94 4.94 0 00-1.65-1L333.88 67a41.6 41.6 0 006.91-7z"
              style={{
                transformOrigin: "341.035px 65.77px"
              }}
              className="animable"
              id="elay6mt90m0kb"
              opacity={0.1}
            />
          </g>
          <g id="eln3qdckxzdze">
            <path
              d="M307.06 118.35a12.85 12.85 0 01-4.84 2.82l-7.39-4.6a10.88 10.88 0 003.08-1.48l4.19 2.6.13.09.19.12a5.91 5.91 0 004.64.45z"
              style={{
                transformOrigin: "300.945px 118.13px"
              }}
              className="animable"
              id="elxuqg90sants"
              opacity={0.3}
            />
          </g>
          <g id="eld4dbb9g35hb">
            <path
              d="M322.61 94.1c-.22.39-2.26 3.54-7.86 3.63-6 .09-12.9-2.79-13.69-6.52a17.46 17.46 0 003.88.58 22.63 22.63 0 0010.32-2.32z"
              style={{
                transformOrigin: "311.835px 93.601px"
              }}
              className="animable"
              id="elophn7h5o1fg"
              opacity={0.1}
            />
          </g>
          <g id="el8poev6gza3i">
            <path
              d="M301 91.25l-7.39-4.59c-2.7 7-2.11 13.77 1.39 16.26l.5.31.68.43 6.46 4c-3.64-2.32-4.38-9.25-1.64-16.41z"
              style={{
                transformOrigin: "297.28px 97.16px"
              }}
              className="animable"
              id="elkcx8p3mo2b"
              opacity={0.2}
            />
          </g>
          <g id="elhjsu56akdjq">
            <path
              d="M303.73 108.19a7.59 7.59 0 01-4.8.83l-7.39-4.6a7.51 7.51 0 004.66-.77l6.46 4a6.2 6.2 0 001.07.54z"
              style={{
                transformOrigin: "297.635px 106.381px"
              }}
              className="animable"
              id="elnxn2lnnl9t"
              opacity={0.3}
            />
          </g>
          <g id="eliqj8c7afwd">
            <path
              d="M311.9 125.56c-4.65 7-10.39 12.11-16 14.27l-7.4-4.6c4.8-1.84 9.67-5.79 13.87-11.24l2.88 1.79c1.75 1.1 4.05 1.1 6.65-.22z"
              style={{
                transformOrigin: "300.2px 131.91px"
              }}
              className="animable"
              id="elvsspjvx9ao"
              opacity={0.3}
            />
          </g>
          <g id="elkx2mvgfl0se">
            <path
              d="M339.69 70.59c-1.68-.69-3.79-.49-6.08.73-6.91 3.7-12.27 14.85-11 22.78l-7.39-4.6c-1.27-7.93 4.08-19.08 11-22.78 3.08-1.64 5.81-1.43 7.67.26z"
              style={{
                transformOrigin: "327.361px 79.8475px"
              }}
              className="animable"
              id="elsan0oav4u0f"
              opacity={0.2}
            />
          </g>
          <g id="el8tjs7hpsg5w">
            <path
              d="M302.1 117.69l-4.19-2.6-3-1.88c-2.27-1.41-3.55-4.61-3.34-8.79l7.39 4.6c-.23 4.06.98 7.2 3.14 8.67z"
              style={{
                transformOrigin: "296.824px 111.055px"
              }}
              className="animable"
              id="elg9l7n3nz90k"
              opacity={0.2}
            />
          </g>
          <g id="eln26hi6yidd">
            <path
              d="M305 125.64l-2.67-1.64-4.54-2.82a7.2 7.2 0 01-3-4.6l7.39 4.6a7.32 7.32 0 002.82 4.46z"
              style={{
                transformOrigin: "299.895px 121.11px"
              }}
              className="animable"
              id="eltfdy0r0h2a"
              opacity={0.2}
            />
          </g>
        </g>
      </g>
      <g
        id="freepik--Target--inject-2"
        className="animable"
        style={{
          transformOrigin: "430.14px 283.272px"
        }}
      >
        <g
          id="freepik--target--inject-2"
          className="animable"
          style={{
            transformOrigin: "430.14px 283.272px"
          }}
        >
          <path
            d="M442.43 255.5l1.1-1.78-5.59-3.67c-4.79-3.4-11.73-3.25-19.44 1.23-14.38 8.36-26 28.63-25.95 45.26 0 7.88 2.66 13.53 6.95 16.41l5.52 3.64.92-1.51c3.77.26 8.11-.86 12.73-3.55 14.39-8.36 26-28.62 26-45.26a24.7 24.7 0 00-2.24-10.77z"
            style={{
              transformOrigin: "418.612px 282.139px"
            }}
            id="elptim4uyjx5"
            className="animable"
            fill="#7519ff"
          />
          <g id="el2bpys4zmc82">
            <path
              d="M442.43 255.5l1.1-1.78-5.59-3.67c-4.79-3.4-11.73-3.25-19.44 1.23-14.38 8.36-26 28.63-25.95 45.26 0 7.88 2.66 13.53 6.95 16.41l5.52 3.64.92-1.51c3.77.26 8.11-.86 12.73-3.55 14.39-8.36 26-28.62 26-45.26a24.7 24.7 0 00-2.24-10.77z"
              style={{
                transformOrigin: "418.612px 282.139px"
              }}
              className="animable"
              id="elmt2p6tcez6f"
              opacity={0.2}
            />
          </g>
          <path
            d="M450.32 270c.05 16.63-11.57 36.9-26 45.26s-26.08 1.65-26.12-15 11.57-36.9 25.95-45.26 26.12-1.62 26.17 15z"
            style={{
              transformOrigin: "424.26px 285.133px"
            }}
            id="elr7u9c9tjlif"
            className="animable"
            fill="#7519ff"
          />
          <path
            d="M446 272.54c0 13.86-9.64 30.75-21.62 37.72s-21.74 1.37-21.78-12.49 9.64-30.75 21.63-37.72 21.71-1.38 21.77 12.49z"
            style={{
              transformOrigin: "424.3px 285.154px"
            }}
            id="el5ra4l179l33"
            className="animable"
            fill="#fff"
          />
          <path
            d="M441.62 275.05c0 11.09-7.72 24.6-17.3 30.18s-17.37 1.11-17.4-10 7.71-24.6 17.3-30.18 17.38-1.05 17.4 10z"
            style={{
              transformOrigin: "424.27px 285.145px"
            }}
            id="eld8y03u3k4ad"
            className="animable"
            fill="#7519ff"
          />
          <path
            d="M437.3 277.58c0 8.32-5.79 18.45-13 22.64s-13 .82-13.06-7.49 5.78-18.46 13-22.64 13.03-.82 13.06 7.49z"
            style={{
              transformOrigin: "424.27px 285.157px"
            }}
            id="el9p94jwpohp"
            className="animable"
            fill="#fff"
          />
          <path
            d="M424.25 275.11c-4.8 2.79-8.67 9.55-8.65 15.09s3.91 7.79 8.71 5 8.67-9.55 8.65-15.09-3.91-7.79-8.71-5z"
            style={{
              transformOrigin: "424.28px 285.155px"
            }}
            id="el43t2nsenxas"
            className="animable"
            fill="#7519ff"
          />
          <path
            d="M424.26 280.13a9.64 9.64 0 00-4.32 7.55c0 2.77 1.95 3.89 4.35 2.5a9.63 9.63 0 004.33-7.55c-.01-2.77-1.96-3.89-4.36-2.5z"
            style={{
              transformOrigin: "424.28px 285.155px"
            }}
            id="elo7puenh4ah"
            className="animable"
            fill="#fff"
          />
          <path
            style={{
              transformOrigin: "463.03px 306.885px"
            }}
            id="elb6vywtp1q9h"
            className="animable"
            fill="#7519ff"
            d="M467.73 307.55L461.93 305.14 458.33 304.97 458.33 305.62 463.83 308.8 467.73 308.2 467.73 307.55z"
          />
          <path
            style={{
              transformOrigin: "463.03px 306.56px"
            }}
            id="el51s6v8kh2ix"
            className="animable"
            fill="#7519ff"
            d="M458.33 304.97L461.93 305.14 467.73 307.55 463.83 308.15 458.33 304.97z"
          />
          <g id="elhgw7aa8cf7n">
            <path
              style={{
                transformOrigin: "465.78px 308.175px"
              }}
              className="animable"
              id="el0oidbjtb1d79"
              opacity={0.2}
              d="M463.83 308.8L463.83 308.15 467.73 307.55 467.73 308.2 463.83 308.8z"
            />
          </g>
          <g id="elkg0904uix7m">
            <path
              style={{
                transformOrigin: "461.08px 306.885px"
              }}
              className="animable"
              id="elalf7x769ooh"
              opacity={0.1}
              d="M463.83 308.15L458.33 304.97 458.33 305.62 463.83 308.8 463.83 308.15z"
            />
          </g>
          <path
            style={{
              transformOrigin: "429.165px 288.43px"
            }}
            id="el2xgmjusra98"
            className="animable"
            fill="#37474f"
            d="M433.62 287.59L424.71 285.15 424.71 286.39 428.63 291.71 430.78 289.65 430.78 289.65 433.62 288.83 433.62 287.59z"
          />
          <path
            style={{
              transformOrigin: "429.165px 287.81px"
            }}
            id="elvyuz4izc5j"
            className="animable"
            fill="#455a64"
            d="M424.71 285.15L428.63 290.47 430.78 288.42 430.78 288.42 433.62 287.59 424.71 285.15z"
          />
          <path
            style={{
              transformOrigin: "429.705px 290.065px"
            }}
            id="elirsxbwz08c9"
            className="animable"
            fill="#263238"
            d="M428.63 290.47L428.63 291.71 430.78 289.65 430.78 288.42 428.63 290.47z"
          />
          <path
            style={{
              transformOrigin: "432.2px 288.62px"
            }}
            id="el2ah0n86rowi"
            className="animable"
            fill="#263238"
            d="M430.78 288.42L433.62 287.59 433.62 288.83 430.78 289.65 430.78 288.42z"
          />
          <path
            d="M431.16 288.41a.4.4 0 00-.42 0 1.33 1.33 0 00-.6 1 .41.41 0 00.18.39l36.28 21 .82-1.48z"
            style={{
              transformOrigin: "448.779px 299.575px"
            }}
            id="elyzl205vja0s"
            className="animable"
            fill="#455a64"
          />
          <path
            d="M430.27 289a1.24 1.24 0 00-.13.51.41.41 0 00.18.39l36.28 21 .37-.67z"
            style={{
              transformOrigin: "448.554px 299.95px"
            }}
            id="eltjeeaokydks"
            className="animable"
            fill="#37474f"
          />
          <path
            d="M467 309.41a1.32 1.32 0 00-.59 1c0 .38.26.54.59.35a1.3 1.3 0 00.59-1c.01-.38-.25-.54-.59-.35z"
            style={{
              transformOrigin: "467px 310.085px"
            }}
            id="el3errhwxrh1r"
            className="animable"
            fill="#263238"
          />
          <path
            style={{
              transformOrigin: "460.695px 308.235px"
            }}
            id="el0j3tvw0mgl1r"
            className="animable"
            fill="#7519ff"
            d="M463.44 309.02L463.44 309.02 463.44 308.37 457.95 305.19 457.95 305.85 458.24 307.92 462.4 311.28 463.44 309.03 463.44 309.02z"
          />
          <g id="elutfe8d6jtm">
            <path
              style={{
                transformOrigin: "458.1px 306.555px"
              }}
              className="animable"
              id="elwd5uom4w90h"
              opacity={0.2}
              d="M457.95 305.19L458.25 307.27 458.24 307.92 457.95 305.85 457.95 305.19z"
            />
          </g>
          <g id="el9hwrwggdeo">
            <path
              style={{
                transformOrigin: "462.92px 309.82px"
              }}
              className="animable"
              id="elbm32brka52b"
              opacity={0.2}
              d="M463.44 308.37L462.4 310.62 462.4 311.27 463.44 309.02 463.44 308.37z"
            />
          </g>
          <g id="elqzb37wabwg">
            <path
              style={{
                transformOrigin: "460.32px 309.27px"
              }}
              className="animable"
              id="el8hr5mx6wdcc"
              opacity={0.1}
              d="M458.25 307.27L462.4 310.62 462.4 311.27 458.24 307.92 458.25 307.27z"
            />
          </g>
          <path
            style={{
              transformOrigin: "462.795px 262.115px"
            }}
            id="el8iprvwa4uof"
            className="animable"
            fill="#7519ff"
            d="M467.49 261.45L461.69 263.86 458.1 264.03 458.1 263.38 463.59 260.2 467.49 260.8 467.49 261.45z"
          />
          <path
            style={{
              transformOrigin: "462.795px 262.44px"
            }}
            id="elo4xhkl8yzlr"
            className="animable"
            fill="#7519ff"
            d="M458.1 264.03L461.69 263.86 467.49 261.45 463.59 260.85 458.1 264.03z"
          />
          <g id="elbzeaqj8e1vk">
            <path
              style={{
                transformOrigin: "465.54px 260.825px"
              }}
              className="animable"
              id="el79w5w1drgz2"
              opacity={0.2}
              d="M463.59 260.2L463.59 260.85 467.49 261.45 467.49 260.8 463.59 260.2z"
            />
          </g>
          <g id="eljvzph58ypmn">
            <path
              style={{
                transformOrigin: "460.845px 262.115px"
              }}
              className="animable"
              id="el6v45nxt9nu3"
              opacity={0.1}
              d="M463.59 260.85L458.1 264.03 458.1 263.38 463.59 260.2 463.59 260.85z"
            />
          </g>
          <path
            style={{
              transformOrigin: "428.93px 280.575px"
            }}
            id="elh53kcvdy4l8"
            className="animable"
            fill="#37474f"
            d="M433.38 281.41L424.48 283.85 424.48 282.61 428.39 277.3 430.54 279.35 430.54 279.35 433.38 280.17 433.38 281.41z"
          />
          <path
            style={{
              transformOrigin: "428.93px 281.19px"
            }}
            id="elp23phgbwiji"
            className="animable"
            fill="#455a64"
            d="M424.48 283.85L428.39 278.53 430.54 280.59 430.54 280.59 433.38 281.41 424.48 283.85z"
          />
          <path
            style={{
              transformOrigin: "429.465px 278.945px"
            }}
            id="el8sy4a6zp99"
            className="animable"
            fill="#263238"
            d="M428.39 278.53L428.39 277.3 430.54 279.35 430.54 280.59 428.39 278.53z"
          />
          <path
            style={{
              transformOrigin: "431.96px 280.38px"
            }}
            id="elwmuz7ei985"
            className="animable"
            fill="#263238"
            d="M430.54 280.59L433.38 281.41 433.38 280.17 430.54 279.35 430.54 280.59z"
          />
          <path
            d="M430.92 280.59a.38.38 0 01-.42 0 1.33 1.33 0 01-.6-1 .42.42 0 01.18-.39l36.28-21 .82 1.47z"
            style={{
              transformOrigin: "448.539px 269.427px"
            }}
            id="ely0wr46q5fue"
            className="animable"
            fill="#455a64"
          />
          <path
            d="M430 280a1.09 1.09 0 01-.14-.51.42.42 0 01.18-.39l36.28-21 .37.66z"
            style={{
              transformOrigin: "448.274px 269.05px"
            }}
            id="el5gdkhytj3of"
            className="animable"
            fill="#37474f"
          />
          <path
            d="M466.77 259.59a1.31 1.31 0 01-.59-1c0-.38.26-.54.59-.35a1.31 1.31 0 01.59 1c0 .38-.26.54-.59.35z"
            style={{
              transformOrigin: "466.77px 258.915px"
            }}
            id="el4s3axzpcuig"
            className="animable"
            fill="#263238"
          />
          <path
            style={{
              transformOrigin: "460.455px 260.765px"
            }}
            id="el2r3lvgcnstv"
            className="animable"
            fill="#7519ff"
            d="M463.2 259.98L463.2 259.98 463.2 260.63 457.71 263.81 457.71 263.15 458 261.08 462.16 257.72 463.2 259.98 463.2 259.98z"
          />
          <g id="el25wciif6wey">
            <path
              style={{
                transformOrigin: "457.86px 262.445px"
              }}
              className="animable"
              id="elbdsdv4qdrqs"
              opacity={0.2}
              d="M457.71 263.81L458.01 261.73 458 261.08 457.71 263.15 457.71 263.81z"
            />
          </g>
          <g id="elwz76k09jor">
            <path
              style={{
                transformOrigin: "462.68px 259.18px"
              }}
              className="animable"
              id="el6vnnvclmrg8"
              opacity={0.2}
              d="M463.2 260.63L462.16 258.38 462.16 257.73 463.2 259.98 463.2 260.63z"
            />
          </g>
          <g id="eljviidah5hs">
            <path
              style={{
                transformOrigin: "460.08px 259.73px"
              }}
              className="animable"
              id="elbdytwdk1g0t"
              opacity={0.1}
              d="M458.01 261.73L462.16 258.38 462.16 257.73 458 261.08 458.01 261.73z"
            />
          </g>
        </g>
      </g>
      <g
        id="freepik--speech-bubbles--inject-2"
        className="animable"
        style={{
          transformOrigin: "336.705px 236.472px"
        }}
      >
        <g
          id="freepik--speech-bubble--inject-2"
          className="animable"
          style={{
            transformOrigin: "360.755px 252.292px"
          }}
        >
          <g
            id="freepik--speech-bubble--inject-2"
            className="animable"
            style={{
              transformOrigin: "360.755px 252.292px"
            }}
          >
            <path
              d="M363.73 279.23l-4.31-11.05 3.47-10V252l-10.62 6.13v6.13l4.57 10.85a.67.67 0 00.31.27z"
              style={{
                transformOrigin: "358px 265.615px"
              }}
              id="elfketdkdcvl9"
              className="animable"
              fill="#7519ff"
            />
            <g id="elwtkixxywpw">
              <path
                d="M363.73 279.23l-4.31-11.05 3.47-10V252l-10.62 6.13v6.13l4.57 10.85a.67.67 0 00.31.27z"
                style={{
                  transformOrigin: "358px 265.615px"
                }}
                className="animable"
                id="elmib174q02j"
                opacity={0.2}
              />
            </g>
            <path
              d="M389.36 232.3a7 7 0 00-3.18-5.51L385 226a7.05 7.05 0 00-6.36 0l-43.27 25a7 7 0 00-3.18 5.51v14.62a7 7 0 003.18 5.51l1.22.75a7 7 0 006.36 0l16-9.25 4.54 10.86a.68.68 0 001.23-.11l4.82-16.89 16.64-9.61a7 7 0 003.18-5.51z"
              style={{
                transformOrigin: "360.775px 252.292px"
              }}
              id="el4vys568nenj"
              className="animable"
              fill="#7519ff"
            />
            <g id="el0cdafrphgg76">
              <path
                d="M332.15 271.15a7 7 0 003.18 5.51l1.22.75a7.1 7.1 0 005.82.26c-1.5.56-2.64-.28-2.64-2.1V261a6.46 6.46 0 01.93-3.14l-7.58-4.42a6.46 6.46 0 00-.93 3.13z"
                style={{
                  transformOrigin: "337.26px 265.806px"
                }}
                className="animable"
                id="el6ui4vdslm65"
                opacity={0.2}
              />
            </g>
            <g id="el1fehrpso0ov">
              <path
                d="M389.26 231.4c-.37-1.35-1.61-1.78-3.08-.94l-43.27 25a6.18 6.18 0 00-2.24 2.37l-7.58-4.41a6.45 6.45 0 012.24-2.38l43.26-25a7.07 7.07 0 016.37 0l1.22.75a7.1 7.1 0 013.08 4.61z"
                style={{
                  transformOrigin: "361.175px 241.556px"
                }}
                className="animable"
                id="eln3y9btyjgod"
                fill="#fff"
                opacity={0.5}
              />
            </g>
          </g>
          <path
            d="M345.64 270.69a1.61 1.61 0 01-1.18 0 .54.54 0 01-.22-.46 3.09 3.09 0 01.25-1.11 1.58 1.58 0 01.56-.81.91.91 0 01.43-.08h.43a2.58 2.58 0 001.16-.34 3.23 3.23 0 00.91-.78 1.63 1.63 0 00.39-1.08 1 1 0 00-.2-.66.83.83 0 00-.5-.23 5.36 5.36 0 00-.85 0 2.83 2.83 0 01-1.86-.46 2.36 2.36 0 01-.57-1.85 5.14 5.14 0 011-2.94 7.74 7.74 0 012.57-2.34 5.3 5.3 0 011.22-.53 2.32 2.32 0 011-.14c.21 0 .31.16.31.41a3.49 3.49 0 01-.2 1 1.34 1.34 0 01-.48.77 1.91 1.91 0 01-.49.15 3.46 3.46 0 00-1.1.38 2.43 2.43 0 00-.92.82 1.63 1.63 0 00-.26.8c0 .42.11.67.33.77a2.8 2.8 0 001 .12h.39a2.43 2.43 0 011.68.48 2.36 2.36 0 01.51 1.75 5.42 5.42 0 01-.4 2 7 7 0 01-1.23 2 8.09 8.09 0 01-2.05 1.67 6.91 6.91 0 01-1.63.69z"
            style={{
              transformOrigin: "347.602px 263.837px"
            }}
            id="elui0xktznvs"
            className="animable"
            fill="#fff"
          />
          <path
            d="M352.13 266.54v-10.8c0-.36.33-.73 1-1.1l.71-.41c.64-.37 1-.38 1 0v4.17l2.89-1.67v-4.17c0-.36.33-.72 1-1.1l.69-.4c.65-.38 1-.38 1 0v10.81c0 .36-.33.72-1 1.1l-.69.4c-.65.38-1 .38-1 0v-4.4l-2.89 1.67V265c0 .36-.32.73-1 1.1l-.71.41c-.67.38-1 .39-1 .03z"
            style={{
              transformOrigin: "356.275px 258.789px"
            }}
            id="ele2niby8gg4u"
            className="animable"
            fill="#fff"
          />
          <path
            d="M361.33 261.4a1.56 1.56 0 01.05-.33l2.6-12.24c.08-.36.42-.71 1-1.06l1.1-.64c.62-.35 1-.4 1-.13l2.6 9.23v.29c0 .25-.25.51-.73.79l-.89.52c-.54.31-.84.36-.9.16l-.44-1.81-2.56 1.48-.46 2.32c0 .27-.31.54-.79.82l-.88.5c-.43.32-.7.35-.7.1zm5.22-7.11l-.76-3.14q-.12-.54-.21-1.17l-.08.05c-.06.48-.12.95-.2 1.4l-.75 4z"
            style={{
              transformOrigin: "365.505px 254.197px"
            }}
            id="eljjwjk5z95y"
            className="animable"
            fill="#fff"
          />
          <path
            d="M370.73 255.8v-10.63a1.08 1.08 0 01.16-.6 1.49 1.49 0 01.52-.46l2.81-1.63a3.63 3.63 0 012.5-.67c.63.14.95.82.95 2a5.63 5.63 0 01-1.61 3.77v.07a.93.93 0 01.68.29 1.88 1.88 0 01.45.75l1 2.66.05.18q0 .45-1.05 1.05l-.59.35c-.59.34-.93.38-1 .13l-.89-2.69a.58.58 0 00-.35-.33 1.07 1.07 0 00-.62.21l-.4.23v3.81c0 .36-.34.74-1 1.13l-.58.33c-.69.4-1.03.41-1.03.05zm3.54-8.32a1.7 1.7 0 00.64-.74 2.72 2.72 0 00.26-1.23c0-.47-.09-.76-.26-.88a.63.63 0 00-.69.06l-.89.52V248z"
            style={{
              transformOrigin: "374.485px 248.926px"
            }}
            id="elw960bgzmlna"
            className="animable"
            fill="#fff"
          />
          <path
            d="M379.59 251c-.1 0-.15-.2-.15-.43v-10.42a1.17 1.17 0 01.15-.61 1.32 1.32 0 01.47-.43l5-2.87c.17-.1.29-.11.37 0a.94.94 0 01.12.58v.52a1.78 1.78 0 01-.12.71.89.89 0 01-.37.42l-3 1.71v2.45l2.63-1.52c.17-.1.3-.1.37 0a.92.92 0 01.12.57v.52a1.75 1.75 0 01-.12.71.84.84 0 01-.37.42l-2.63 1.52v2.73l3-1.72c.17-.1.29-.1.36 0a1.06 1.06 0 01.11.58v.52a2 2 0 01-.11.71.84.84 0 01-.36.41l-5 2.88a.57.57 0 01-.47.04z"
            style={{
              transformOrigin: "382.498px 243.597px"
            }}
            id="elpa6ytu89a1"
            className="animable"
            fill="#fff"
          />
        </g>
        <g
          id="freepik--speech-bubble--inject-2"
          className="animable"
          style={{
            transformOrigin: "315.995px 221.968px"
          }}
        >
          <g
            id="freepik--speech-bubble--inject-2"
            className="animable"
            style={{
              transformOrigin: "315.995px 221.968px"
            }}
          >
            <path
              d="M319 249.5l-4.31-11.05 3.47-10v-6.13l-10.61 6.13v6.12l4.56 10.85a.69.69 0 00.31.28z"
              style={{
                transformOrigin: "313.275px 235.91px"
              }}
              id="el50ovt9ndkmx"
              className="animable"
              fill="#7519ff"
            />
            <g id="el3i9rvq7byna">
              <path
                d="M319 249.5l-4.31-11.05 3.47-10v-6.13l-10.61 6.13v6.12l4.56 10.85a.69.69 0 00.31.28z"
                style={{
                  transformOrigin: "313.275px 235.91px"
                }}
                className="animable"
                id="elxsrduong6us"
                opacity={0.2}
              />
            </g>
            <path
              d="M347.92 200.62a7 7 0 00-3.18-5.51l-1.22-.75a7.05 7.05 0 00-6.36 0l-49.93 28.83a7 7 0 00-3.18 5.51v14.61a7.07 7.07 0 003.18 5.52l1.22.74a7 7 0 006.36 0l19.35-11.15 4.56 10.84a.68.68 0 001.23-.12l4.81-16.84 20-11.55a7 7 0 003.18-5.51z"
              style={{
                transformOrigin: "315.995px 221.968px"
              }}
              id="el0l5oryqymnsn"
              className="animable"
              fill="#7519ff"
            />
            <g id="el0chka02mmk4o">
              <path
                d="M284.05 243.31a7.07 7.07 0 003.18 5.52l1.22.74a7.1 7.1 0 005.82.26c-1.5.57-2.64-.28-2.64-2.09v-14.62a6.57 6.57 0 01.93-3.14l-7.56-4.42a6.57 6.57 0 00-.93 3.14z"
                style={{
                  transformOrigin: "289.16px 237.946px"
                }}
                className="animable"
                id="elbd6nbjjluzb"
                opacity={0.2}
              />
            </g>
            <g id="el7qdcqnywsoe">
              <path
                d="M347.82 199.72c-.37-1.35-1.61-1.78-3.08-.94l-49.93 28.83a6.29 6.29 0 00-2.24 2.38l-7.57-4.42a6.47 6.47 0 012.25-2.38l49.91-28.83a7.07 7.07 0 016.37 0l1.22.75a7.06 7.06 0 013.07 4.61z"
                style={{
                  transformOrigin: "316.41px 211.796px"
                }}
                className="animable"
                id="elxkczctjy8uj"
                fill="#fff"
                opacity={0.5}
              />
            </g>
          </g>
          <path
            d="M296.81 241.93v-9.57a1 1 0 01.14-.55 1.23 1.23 0 01.42-.39l4.18-2.41c.15-.09.26-.09.33 0a.85.85 0 01.11.52v.47a1.67 1.67 0 01-.11.64.79.79 0 01-.33.37l-2.37 1.37v2.22l2.23-1.29c.15-.09.26-.09.34 0a.84.84 0 01.1.51v.47a1.62 1.62 0 01-.1.64.84.84 0 01-.34.38l-2.23 1.29v4c0 .32-.3.65-.88 1l-.61.35c-.59.29-.88.3-.88-.02z"
            style={{
              transformOrigin: "299.402px 235.556px"
            }}
            id="elj9q9lbokoy"
            className="animable"
            fill="#fff"
          />
          <path
            d="M303 233.52c0-3.62 1.32-6.2 4-7.74 1.29-.74 2.28-.87 2.95-.38s1 1.67 1 3.53a10 10 0 01-1 4.7 7.54 7.54 0 01-2.95 3c-2.73 1.56-4 .52-4-3.11zm4.87.33a3.72 3.72 0 00.5-1.34 11.85 11.85 0 00.17-2.19 8.74 8.74 0 00-.17-2c-.11-.43-.28-.69-.5-.76a1.1 1.1 0 00-.88.2 2.69 2.69 0 00-.89.82 3.53 3.53 0 00-.51 1.35 10.9 10.9 0 00-.18 2.18 8.09 8.09 0 00.18 2c.11.44.28.69.51.76a1.17 1.17 0 00.89-.21 2.55 2.55 0 00.83-.81z"
            style={{
              transformOrigin: "306.978px 231.221px"
            }}
            id="elkbof5h4lpk"
            className="animable"
            fill="#fff"
          />
          <path
            d="M312.47 233.19c-.09 0-.14-.17-.14-.38v-9.57c0-.32.3-.66.89-1l.6-.36c.59-.34.88-.34.88 0v8.19l2.27-1.31c.16-.09.28-.1.35 0a.88.88 0 01.1.52v.47a1.65 1.65 0 01-.1.64.8.8 0 01-.35.38l-4.07 2.35c-.2.09-.34.12-.43.07z"
            style={{
              transformOrigin: "314.878px 227.42px"
            }}
            id="elt0uenbi17us"
            className="animable"
            fill="#fff"
          />
          <path
            d="M318.67 229.62c-.09-.05-.14-.17-.14-.39v-9.57c0-.32.3-.65.88-1l.61-.35c.59-.34.88-.35.88 0v8.19l2.27-1.3c.16-.1.27-.11.35 0a.84.84 0 01.1.51v.47a1.62 1.62 0 01-.1.64.94.94 0 01-.35.39l-4.07 2.34c-.2.08-.34.11-.43.07z"
            style={{
              transformOrigin: "321.078px 223.844px"
            }}
            id="elzregcuhqgkc"
            className="animable"
            fill="#fff"
          />
          <path
            d="M324.14 221.29q0-5.44 4-7.74c1.3-.75 2.28-.88 2.95-.38s1 1.67 1 3.53a9.87 9.87 0 01-1 4.7 7.47 7.47 0 01-2.95 3q-3.99 2.33-4-3.11zm4.88.32a3.65 3.65 0 00.5-1.34 11.85 11.85 0 00.16-2.18 8.74 8.74 0 00-.16-2c-.11-.44-.28-.69-.5-.77a1.18 1.18 0 00-.89.21 2.64 2.64 0 00-.88.81 3.83 3.83 0 00-.52 1.36 10.8 10.8 0 00-.18 2.17 8.09 8.09 0 00.18 2c.12.44.29.7.52.77a1.15 1.15 0 00.88-.21 2.6 2.6 0 00.87-.82z"
            style={{
              transformOrigin: "328.118px 218.985px"
            }}
            id="el9fv5hazloes"
            className="animable"
            fill="#fff"
          />
          <path
            d="M334.7 220.46a.15.15 0 01-.2-.13l-2-8.6a.53.53 0 010-.13.41.41 0 000-.1c0-.26.3-.57.9-.91l.44-.26c.6-.35.93-.38 1-.09l.71 5.2.14 1 .14 1.11.27-2a3.4 3.4 0 00.07-.48l.93-6.3a.58.58 0 01.19-.35 2.43 2.43 0 01.53-.38l.76-.44a1.81 1.81 0 01.52-.23c.11 0 .17 0 .19.14l.91 5.23.34 2.14.21-1.75.09-.75.73-6c0-.34.36-.68 1-1l.35-.21q.9-.51.9-.12a1.66 1.66 0 010 .24l-2 10.92c0 .23-.28.48-.71.73l-1 .58c-.4.23-.63.26-.68.07l-1-4.76-.17-1.53-.26 1.7-1.05 6c0 .23-.27.47-.68.71l-1 .58a1.58 1.58 0 01-.57.17z"
            style={{
              transformOrigin: "338.16px 212.675px"
            }}
            id="elq17h59s7hq"
            className="animable"
            fill="#fff"
          />
        </g>
      </g>
      <g
        id="freepik--Envelopes--inject-2"
        className="animable"
        style={{
          transformOrigin: "262.217px 188.891px"
        }}
      >
        <g
          id="freepik--Mails--inject-2"
          className="animable"
          style={{
            transformOrigin: "262.217px 188.891px"
          }}
        >
          <g
            id="freepik--Letter--inject-2"
            className="animable"
            style={{
              transformOrigin: "251.792px 182.761px"
            }}
          >
            <path
              d="M229.33 173.21l42-24.25a.86.86 0 011.05 0c.25.2 2.62 1.45 2.88 1.64a1.34 1.34 0 01.44 1.13v37.8a3.47 3.47 0 01-1.45 2.77l-7.37 4.26-38.55-22.25a2.82 2.82 0 011-1.1z"
              style={{
                transformOrigin: "252.018px 172.671px"
              }}
              id="elqr89mszilz"
              className="animable"
              fill="#7519ff"
            />
            <g id="el2r6j6xssw1g">
              <path
                d="M229.33 173.21l42-24.25a.86.86 0 011.05 0c.25.2 2.62 1.45 2.88 1.64a1.34 1.34 0 01.44 1.13v37.8a3.47 3.47 0 01-1.45 2.77l-7.37 4.26-38.55-22.25a2.82 2.82 0 011-1.1z"
                style={{
                  transformOrigin: "252.018px 172.671px"
                }}
                className="animable"
                id="elw1iqen3bbc"
                fill="#fff"
                opacity={0.5}
              />
            </g>
            <path
              d="M231.28 216.61c-.29-.19-2.68-1.51-2.94-1.69a1.33 1.33 0 01-.46-1.14V176a3.58 3.58 0 01.46-1.67l38.55 22.25-34.63 20a.9.9 0 01-.98.03z"
              style={{
                transformOrigin: "247.382px 195.535px"
              }}
              id="eld3tlv4ajdij"
              className="animable"
              fill="#7519ff"
            />
            <g id="ely3ohhcl29of">
              <path
                d="M231.28 216.61c-.29-.19-2.68-1.51-2.94-1.69a1.33 1.33 0 01-.46-1.14V176a3.58 3.58 0 01.46-1.67l38.55 22.25-34.63 20a.9.9 0 01-.98.03z"
                style={{
                  transformOrigin: "247.382px 195.535px"
                }}
                className="animable"
                id="el6b3tsl5js3f"
                opacity={0.2}
              />
            </g>
            <path
              d="M232.26 216.55l42-24.25a3.47 3.47 0 001.45-2.77v-37.8c0-1.06-.65-1.55-1.45-1.09l-42 24.25a3.47 3.47 0 00-1.45 2.77v37.8c0 1.06.65 1.54 1.45 1.09z"
              style={{
                transformOrigin: "253.26px 183.593px"
              }}
              id="elwnr2hhogzv"
              className="animable"
              fill="#7519ff"
            />
            <g id="elyg5tys5yr9">
              <path
                d="M230.81 215.46v-.75l17.37-28.78-17.18-9.5.13-.31 21.39 8.88c.45.18 1-.15 1.49-.86l21.33-33.52a1 1 0 01.13.15l-17.12 29.26 17.36 8.73v.74a3.87 3.87 0 01-.43 1.71l-17.81-9.67-2.53 4.31c-1 1.71-2.36 2.5-3.36 1.94l-2.52-1.4-17.78 30.19a1.35 1.35 0 01-.47-1.12z"
                style={{
                  transformOrigin: "253.258px 183.6px"
                }}
                className="animable"
                id="el51xdpog564u"
                opacity={0.2}
              />
            </g>
          </g>
          <g
            id="freepik--letter--inject-2"
            className="animable"
            style={{
              transformOrigin: "262.225px 188.889px"
            }}
          >
            <path
              d="M239.76 179.34l42-24.25a.89.89 0 011.06 0c.24.21 2.62 1.46 2.88 1.64a1.36 1.36 0 01.44 1.14v37.8a3.5 3.5 0 01-1.45 2.77l-7.38 4.25-38.54-22.25a2.88 2.88 0 01.99-1.1z"
              style={{
                transformOrigin: "262.458px 178.802px"
              }}
              id="eld9o49xfl1x"
              className="animable"
              fill="#7519ff"
            />
            <g id="ele8096dwzgfb">
              <path
                d="M239.76 179.34l42-24.25a.89.89 0 011.06 0c.24.21 2.62 1.46 2.88 1.64a1.36 1.36 0 01.44 1.14v37.8a3.5 3.5 0 01-1.45 2.77l-7.38 4.25-38.54-22.25a2.88 2.88 0 01.99-1.1z"
                style={{
                  transformOrigin: "262.458px 178.802px"
                }}
                className="animable"
                id="eli8wlxgp3k4"
                fill="#fff"
                opacity={0.5}
              />
            </g>
            <path
              d="M241.7 222.74c-.28-.18-2.67-1.51-2.93-1.68a1.34 1.34 0 01-.46-1.15v-37.8a3.65 3.65 0 01.46-1.67l38.54 22.25-34.62 20a.92.92 0 01-.99.05z"
              style={{
                transformOrigin: "257.807px 201.65px"
              }}
              id="elf29kpi2fmmo"
              className="animable"
              fill="#7519ff"
            />
            <g id="elkm0bjd3y0pn">
              <path
                d="M241.7 222.74c-.28-.18-2.67-1.51-2.93-1.68a1.34 1.34 0 01-.46-1.15v-37.8a3.65 3.65 0 01.46-1.67l38.54 22.25-34.62 20a.92.92 0 01-.99.05z"
                style={{
                  transformOrigin: "257.807px 201.65px"
                }}
                className="animable"
                id="ela05imi8ig1b"
                opacity={0.2}
              />
            </g>
            <path
              d="M242.69 222.69l42-24.25a3.5 3.5 0 001.45-2.77v-37.8c0-1.07-.65-1.56-1.45-1.1l-42 24.25a3.5 3.5 0 00-1.45 2.77v37.8c0 1.07.65 1.56 1.45 1.1z"
              style={{
                transformOrigin: "263.69px 189.73px"
              }}
              id="elxychqvbf9db"
              className="animable"
              fill="#7519ff"
            />
            <g id="elzn2fmbtmuc8">
              <path
                d="M241.24 221.59v-.74l17.36-28.78-17.14-9.5a2.88 2.88 0 01.13-.31l21.35 8.9c.46.19 1-.15 1.49-.86l21.33-33.51a.58.58 0 01.13.14l-17.11 29.27 17.36 8.72v.75a3.7 3.7 0 01-.44 1.7l-17.81-9.67-2.52 4.3c-1 1.71-2.36 2.5-3.36 2l-2.53-1.4-17.78 30.14a1.38 1.38 0 01-.46-1.15z"
                style={{
                  transformOrigin: "263.688px 189.765px"
                }}
                className="animable"
                id="el6rollaqvpv4"
                opacity={0.2}
              />
            </g>
          </g>
          <g
            id="freepik--letter--inject-2"
            className="animable"
            style={{
              transformOrigin: "272.642px 195.027px"
            }}
          >
            <path
              d="M250.18 185.48l42-24.25a.88.88 0 011.06 0c.24.2 2.61 1.45 2.87 1.64a1.31 1.31 0 01.44 1.13v37.8a3.44 3.44 0 01-1.45 2.77l-7.37 4.26-38.55-22.25a2.82 2.82 0 011-1.1z"
              style={{
                transformOrigin: "272.869px 184.941px"
              }}
              id="el5khri1bs9eq"
              className="animable"
              fill="#7519ff"
            />
            <g id="elx0x7pwm3sz">
              <path
                d="M250.18 185.48l42-24.25a.88.88 0 011.06 0c.24.2 2.61 1.45 2.87 1.64a1.31 1.31 0 01.44 1.13v37.8a3.44 3.44 0 01-1.45 2.77l-7.37 4.26-38.55-22.25a2.82 2.82 0 011-1.1z"
                style={{
                  transformOrigin: "272.869px 184.941px"
                }}
                className="animable"
                id="elcb4cci8xt"
                fill="#fff"
                opacity={0.5}
              />
            </g>
            <path
              d="M252.13 228.88c-.29-.19-2.68-1.51-2.94-1.69a1.33 1.33 0 01-.46-1.14v-37.8a3.58 3.58 0 01.46-1.67l38.55 22.25-34.63 20a.9.9 0 01-.98.05z"
              style={{
                transformOrigin: "268.232px 207.791px"
              }}
              id="elmyewa1d6r7"
              className="animable"
              fill="#7519ff"
            />
            <g id="elnwi6edvin5">
              <path
                d="M252.13 228.88c-.29-.19-2.68-1.51-2.94-1.69a1.33 1.33 0 01-.46-1.14v-37.8a3.58 3.58 0 01.46-1.67l38.55 22.25-34.63 20a.9.9 0 01-.98.05z"
                style={{
                  transformOrigin: "268.232px 207.791px"
                }}
                className="animable"
                id="el2mffpxze3a6"
                opacity={0.2}
              />
            </g>
            <path
              d="M253.11 228.82l42-24.25a3.44 3.44 0 001.45-2.77V164c0-1.06-.64-1.55-1.45-1.09l-42 24.25a3.47 3.47 0 00-1.45 2.77v37.8c0 1.06.65 1.55 1.45 1.09z"
              style={{
                transformOrigin: "274.11px 195.865px"
              }}
              id="elqrcaa8idjo8"
              className="animable"
              fill="#7519ff"
            />
            <g id="elaeis4eyz2me">
              <path
                d="M251.66 227.73V227L269 198.2l-17.15-9.5.13-.31 21.36 8.91c.45.18 1-.15 1.49-.86l21.33-33.52a1.79 1.79 0 01.13.14l-17.09 29.27 17.36 8.73v.74a3.9 3.9 0 01-.43 1.71l-17.81-9.67-2.52 4.31c-1 1.71-2.37 2.5-3.37 1.94l-2.52-1.4-17.78 30.19a1.35 1.35 0 01-.47-1.15z"
                style={{
                  transformOrigin: "274.107px 195.9px"
                }}
                className="animable"
                id="el3zkdxalk2ho"
                opacity={0.2}
              />
            </g>
          </g>
        </g>
      </g>
      <g
        id="freepik--Device--inject-2"
        className="animable"
        style={{
          transformOrigin: "246.75px 327.18px"
        }}
      >
        <g
          id="freepik--Mobile--inject-2"
          className="animable"
          style={{
            transformOrigin: "246.75px 327.18px"
          }}
        >
          <g
            id="freepik--mobile--inject-2"
            className="animable"
            style={{
              transformOrigin: "246.75px 327.18px"
            }}
          >
            <path
              d="M433.31 372.84l-119.68 69.09a12.92 12.92 0 01-12.49.24L60.19 303c-3.8-2.19-6.88-7.09-6.88-11s3.08-8.75 6.87-10.95l119.25-68.75a15.25 15.25 0 0113.74 0L433.31 351c3.8 2.19 6.88 7.09 6.88 10.95s-3.08 8.7-6.88 10.89z"
              style={{
                transformOrigin: "246.75px 327.164px"
              }}
              id="el1jvqa3zjn1"
              className="animable"
              fill="#7519ff"
            />
            <g id="elc4nzi4rhe8i">
              <path
                d="M179.43 212.3L60.18 281.14c-3.79 2.2-6.87 7.1-6.87 10.95s3.08 8.76 6.88 11l241 139.13a13.48 13.48 0 006 1.48V278.09l-114-65.79a15.25 15.25 0 00-13.76 0z"
                style={{
                  transformOrigin: "180.25px 327.18px"
                }}
                className="animable"
                id="el4xngiijmqni"
                opacity={0.5}
              />
            </g>
            <g id="elxekv6kzwvi">
              <path
                d="M313.63 441.93l119.68-69.09c3.8-2.19 6.88-7.09 6.88-10.94s-3.08-8.76-6.88-10.95l-126.19-72.86v165.56a12.7 12.7 0 006.51-1.72z"
                style={{
                  transformOrigin: "373.655px 360.87px"
                }}
                className="animable"
                id="el33cklg01i8l"
                opacity={0.4}
              />
            </g>
            <path
              d="M310.78 429.62a13.41 13.41 0 01-12.12 0l-238.5-137.7c-5.13-3-5.13-7.8 0-10.76l119.26-68.86a15.25 15.25 0 0113.74 0L433.3 351c3.78 2.18 3.78 5.75 0 7.93z"
              style={{
                transformOrigin: "246.224px 320.866px"
              }}
              id="elj95leb7fpqd"
              className="animable"
              fill="#7519ff"
            />
            <g id="el5x0k5kx4ilf">
              <path
                d="M310.78 429.62a13.41 13.41 0 01-12.12 0l-238.5-137.7c-5.13-3-5.13-7.8 0-10.76l119.26-68.86a15.25 15.25 0 0113.74 0L433.3 351c3.78 2.18 3.78 5.75 0 7.93z"
                style={{
                  transformOrigin: "246.224px 320.866px"
                }}
                className="animable"
                id="elhk9y2nzfks5"
                opacity={0.3}
              />
            </g>
            <path
              d="M299.2 415.76a14.45 14.45 0 01-13.06 0L80.91 297.27c-3.61-2.08-3.61-5.46 0-7.54L193.1 225a14.51 14.51 0 0113.06 0l205.23 118.45c3.59 2.07 3.59 5.47 0 7.54z"
              style={{
                transformOrigin: "246.143px 320.384px"
              }}
              id="elm96uyxotf3o"
              className="animable"
              fill="#37474f"
            />
            <path
              d="M199.23 223.43a13.09 13.09 0 00-6.13 1.57L80.91 289.73c-3.61 2.08-3.61 5.46 0 7.54l22.24 12.84 96.08-61.36z"
              style={{
                transformOrigin: "138.716px 266.77px"
              }}
              id="elyqci4bun6e"
              className="animable"
              fill="#263238"
            />
            <path
              d="M206.24 229.86a14.51 14.51 0 00-13.06 0L81 294.63a6.39 6.39 0 00-1.72 1.37 7.1 7.1 0 001.63 1.27l205.23 118.49a14.45 14.45 0 0013.06 0L411.39 351a6.6 6.6 0 001.69-1.35 6.55 6.55 0 00-1.61-1.29z"
              style={{
                transformOrigin: "246.18px 322.814px"
              }}
              id="eljbw5dsedlw"
              className="animable"
              fill="#455a64"
            />
            <path
              d="M121.92 259.43L141.8 248c1.19-.68 1.25-1.76.13-2.4a4.59 4.59 0 00-4.16.08l-19.88 11.48c-1.19.68-1.25 1.76-.13 2.4a4.59 4.59 0 004.16-.13z"
              style={{
                transformOrigin: "129.845px 252.568px"
              }}
              id="elyd0xi6kboa"
              className="animable"
              fill="#37474f"
            />
            <path
              d="M156.06 239.75a1.23 1.23 0 000-2.33 4.47 4.47 0 00-4 0 1.23 1.23 0 000 2.33 4.47 4.47 0 004 0z"
              style={{
                transformOrigin: "154.06px 238.585px"
              }}
              id="el016r17h44rv5h"
              className="animable"
              fill="#37474f"
            />
            <path
              d="M164.12 235.09a1.23 1.23 0 000-2.33 4.47 4.47 0 00-4 0 1.23 1.23 0 000 2.33 4.47 4.47 0 004 0z"
              style={{
                transformOrigin: "162.12px 233.925px"
              }}
              id="elnj97bm6r6pi"
              className="animable"
              fill="#37474f"
            />
            <path
              d="M99.64 272.32a1.23 1.23 0 000-2.33 4.47 4.47 0 00-4 0 1.23 1.23 0 000 2.33 4.47 4.47 0 004 0z"
              style={{
                transformOrigin: "97.64px 271.155px"
              }}
              id="elhdgt8h644h"
              className="animable"
              fill="#37474f"
            />
            <path
              d="M107.7 267.66a1.23 1.23 0 000-2.33 4.47 4.47 0 00-4 0 1.23 1.23 0 000 2.33 4.47 4.47 0 004 0z"
              style={{
                transformOrigin: "105.7px 266.495px"
              }}
              id="eletxa704qu2r"
              className="animable"
              fill="#37474f"
            />
            <path
              d="M135.77 338.8a4.59 4.59 0 012.15 3.56 1.37 1.37 0 01-.59 1.26l-1.51.87a1.57 1.57 0 01-1.55-.17l-27.94-16.14a4.59 4.59 0 01-2.15-3.56 1.38 1.38 0 01.54-1.23c.27-.17 1.29-.75 1.55-.9a1.59 1.59 0 011.56.17z"
              style={{
                transformOrigin: "121.05px 333.49px"
              }}
              id="ela3wz0ggbtiu"
              className="animable"
              fill="#7519ff"
            />
            <g id="elruscdu4yzy">
              <path
                d="M135.77 338.8a4.59 4.59 0 012.15 3.56 1.37 1.37 0 01-.59 1.26l-1.51.87a1.57 1.57 0 01-1.55-.17l-27.94-16.14a4.59 4.59 0 01-2.15-3.56 1.38 1.38 0 01.54-1.23c.27-.17 1.29-.75 1.55-.9a1.59 1.59 0 011.56.17z"
                style={{
                  transformOrigin: "121.05px 333.49px"
                }}
                className="animable"
                id="elheen1u371ze"
                opacity={0.4}
              />
            </g>
            <path
              d="M134.27 339.66l-27.94-16.13c-1.19-.69-2.15-.2-2.15 1.09a4.59 4.59 0 002.15 3.56l27.94 16.14c1.19.68 2.15.19 2.15-1.09a4.6 4.6 0 00-2.15-3.57z"
              style={{
                transformOrigin: "120.3px 333.922px"
              }}
              id="elu4tvlfh10h"
              className="animable"
              fill="#7519ff"
            />
            <g id="el1jroc8vb4uk">
              <path
                d="M134.27 339.66l-27.94-16.13c-1.19-.69-2.15-.2-2.15 1.09a4.59 4.59 0 002.15 3.56l27.94 16.14c1.19.68 2.15.19 2.15-1.09a4.6 4.6 0 00-2.15-3.57z"
                style={{
                  transformOrigin: "120.3px 333.922px"
                }}
                className="animable"
                id="el2layeh6rm55"
                opacity={0.3}
              />
            </g>
            <path
              d="M98.37 317.2a4.62 4.62 0 012.14 3.57 1.32 1.32 0 01-.59 1.25c-.26.14-1.23.73-1.5.88a1.6 1.6 0 01-1.55-.18l-13.17-7.61a4.59 4.59 0 01-2.15-3.56 1.38 1.38 0 01.54-1.23l1.55-.9a1.55 1.55 0 011.55.18z"
              style={{
                transformOrigin: "91.0309px 316.156px"
              }}
              id="ellmquyvq0ey"
              className="animable"
              fill="#7519ff"
            />
            <g id="el72cxbv3yej6">
              <path
                d="M98.37 317.2a4.62 4.62 0 012.14 3.57 1.32 1.32 0 01-.59 1.25c-.26.14-1.23.73-1.5.88a1.6 1.6 0 01-1.55-.18l-13.17-7.61a4.59 4.59 0 01-2.15-3.56 1.38 1.38 0 01.54-1.23l1.55-.9a1.55 1.55 0 011.55.18z"
                style={{
                  transformOrigin: "91.0309px 316.156px"
                }}
                className="animable"
                id="eln04q9ehaxb"
                opacity={0.4}
              />
            </g>
            <path
              d="M96.87 318.07l-13.17-7.61c-1.19-.68-2.15-.2-2.15 1.09a4.59 4.59 0 002.15 3.56l13.17 7.61c1.19.69 2.15.2 2.15-1.09a4.59 4.59 0 00-2.15-3.56z"
              style={{
                transformOrigin: "90.285px 316.592px"
              }}
              id="elknr1emzhgk"
              className="animable"
              fill="#7519ff"
            />
            <g id="el2hgehy1w2nn">
              <path
                d="M96.87 318.07l-13.17-7.61c-1.19-.68-2.15-.2-2.15 1.09a4.59 4.59 0 002.15 3.56l13.17 7.61c1.19.69 2.15.2 2.15-1.09a4.59 4.59 0 00-2.15-3.56z"
                style={{
                  transformOrigin: "90.285px 316.592px"
                }}
                className="animable"
                id="ellklqeyegw4s"
                opacity={0.3}
              />
            </g>
          </g>
        </g>
        <g
          id="freepik--Message--inject-2"
          className="animable"
          style={{
            transformOrigin: "249.762px 324.607px"
          }}
        >
          <g
            id="freepik--message--inject-2"
            className="animable"
            style={{
              transformOrigin: "307.98px 351.239px"
            }}
          >
            <path
              d="M246.17 362.78v.81a4.49 4.49 0 002 3.51l43.51 25.12a4.47 4.47 0 004.06 0l72.05-41.6a4.47 4.47 0 002-3.51v-.81a4.49 4.49 0 00-2-3.51l-43.51-25.12a4.47 4.47 0 00-4.06 0l-72.05 41.6a4.47 4.47 0 00-2 3.51z"
              style={{
                transformOrigin: "307.98px 354.945px"
              }}
              id="el2a9dcv9bh47"
              className="animable"
              fill="#7519ff"
            />
            <path
              d="M246.17 362.78v.81a4.49 4.49 0 002 3.51l43.51 25.12a4.47 4.47 0 004.06 0l72.05-41.6a4.47 4.47 0 002-3.51v-.81a4.49 4.49 0 00-2-3.51l-43.51-25.12a4.47 4.47 0 00-4.06 0l-72.05 41.6a4.47 4.47 0 00-2 3.51z"
              style={{
                transformOrigin: "307.98px 354.945px"
              }}
              id="elg6lhvz5btf5"
              className="animable"
              fill="#7519ff"
            />
            <g id="elibdstci3sg">
              <path
                d="M367.82 342.79l-43.51-25.12a4.47 4.47 0 00-4.06 0L293.74 333v59.74a4.09 4.09 0 002-.49l72.05-41.6a4.47 4.47 0 002-3.51v-.81a4.49 4.49 0 00-1.97-3.54z"
                style={{
                  transformOrigin: "331.765px 354.961px"
                }}
                className="animable"
                id="ell6mlw0zceo"
                fill="#fff"
                opacity={0.3}
              />
            </g>
            <g id="ell2dpmiwqxir">
              <path
                d="M367.82 342.79l-43.51-25.12a4.47 4.47 0 00-4.06 0L293.74 333v59.74a4.09 4.09 0 002-.49l72.05-41.6a4.47 4.47 0 002-3.51v-.81a4.49 4.49 0 00-1.97-3.54z"
                style={{
                  transformOrigin: "331.765px 354.961px"
                }}
                className="animable"
                id="elbpl9bmru8g"
                opacity={0.15}
              />
            </g>
            <g id="elajk0oi1o6om">
              <path
                d="M293.74 333l-45.54 26.3a4.47 4.47 0 00-2 3.51v.81a4.49 4.49 0 002 3.51l43.51 25.12a4.07 4.07 0 002 .49z"
                style={{
                  transformOrigin: "269.97px 362.87px"
                }}
                className="animable"
                id="el7rbmhf5eoxr"
                fill="#fff"
                opacity={0.3}
              />
            </g>
            <g id="elgntyxa3f047">
              <path
                d="M293.74 333l-45.54 26.3a4.47 4.47 0 00-2 3.51v.81a4.49 4.49 0 002 3.51l43.51 25.12a4.07 4.07 0 002 .49z"
                style={{
                  transformOrigin: "269.97px 362.87px"
                }}
                className="animable"
                id="elab4e44tumif"
                opacity={0.2}
              />
            </g>
            <path
              d="M284.74 382.71l-36.54-21.1a1.23 1.23 0 010-2.34l72.05-41.6a4.47 4.47 0 014.06 0l43.51 25.12a1.23 1.23 0 010 2.34L355.87 352l-4.19 2.42-55.91 32.28a4.47 4.47 0 01-4.06 0l-3.54-2.05z"
              style={{
                transformOrigin: "308.01px 352.185px"
              }}
              id="el4z61ipfkmll"
              className="animable"
              fill="#7519ff"
            />
            <g id="ela04x1au58ns">
              <path
                d="M284.74 382.71l-36.54-21.1a1.23 1.23 0 010-2.34l72.05-41.6a4.47 4.47 0 014.06 0l43.51 25.12a1.23 1.23 0 010 2.34L355.87 352l-4.19 2.42-55.91 32.28a4.47 4.47 0 01-4.06 0l-3.54-2.05z"
                style={{
                  transformOrigin: "308.01px 352.185px"
                }}
                className="animable"
                id="elt7u6sh7rbc"
                fill="#fff"
                opacity={0.3}
              />
            </g>
            <g id="elsm3huxenaep">
              <path
                d="M317.92 347.66l49.9-4.87-49.46 2.8 5.95-27.92a4.23 4.23 0 00-2.21-.47l-9.33 37.8-65.39 5.39a1.35 1.35 0 00.82 1.27l52.8-3.12-7.27 28.68 9.27-28.79 9-.53a4.83 4.83 0 003-1.24 4.83 4.83 0 001.52-2.62z"
                style={{
                  transformOrigin: "307.6px 352.206px"
                }}
                className="animable"
                id="elftpmdxmlgsm"
                opacity={0.15}
              />
            </g>
            <g id="el93606t5h7rr">
              <path
                d="M263 359.05l49.79-4.1 1.78-7.14 1-4 6.58-26.64a4.13 4.13 0 00-1.86.47l-36 20.8-36 20.8a1.45 1.45 0 00-.82 1.07l10-.82z"
                style={{
                  transformOrigin: "284.81px 338.74px"
                }}
                className="animable"
                id="elfimjitx5b6s"
                fill="#fff"
                opacity={0.3}
              />
            </g>
            <path
              d="M314 311.71c4.55-2.63 11.93-2.63 16.48 0s4.55 6.89 0 9.51-11.93 2.63-16.48 0-4.51-6.88 0-9.51z"
              style={{
                transformOrigin: "322.247px 316.463px"
              }}
              id="elitutupin5"
              className="animable"
              fill="#f28f8f"
            />
            <path
              d="M316.52 318a.6.6 0 01-.34-.12.21.21 0 01-.09-.23l.68-3c0-.07 0-.16.08-.27a.49.49 0 01.25-.27l1.66-1a.72.72 0 01.37-.09.7.7 0 01.37.09l8.83 5.1a.23.23 0 01.15.21.25.25 0 01-.15.21l-1.79 1a.72.72 0 01-.37.09.7.7 0 01-.37-.09l-6-3.44-.4 1.65c0 .08-.08.15-.19.2a.84.84 0 01-.39.06z"
              style={{
                transformOrigin: "322.282px 316.37px"
              }}
              id="el1slk6lte6bq"
              className="animable"
              fill="#fafafa"
            />
          </g>
          <path
            d="M204.64 346.33l32.23-18.6 22 5.45a2.37 2.37 0 002.47-3.71l-8.26-11.12 32.22-18.61c2.38-1.38 2.38-3.63 0-5l-64.45-37.24a9.64 9.64 0 00-8.66 0l-80.67 46.62c-2.38 1.37-2.38 3.62 0 5L196 346.33a9.57 9.57 0 008.64 0z"
            style={{
              transformOrigin: "208.41px 301.917px"
            }}
            id="el8g2it50ojpx"
            className="animable"
            fill="#fafafa"
          />
          <path
            d="M164.85 297.27a25.9 25.9 0 016.05-2.58 28 28 0 015.77-1 20.49 20.49 0 015.15.31 18 18 0 014.18 1.33q2 1 4.12 2.19t3.78 2.37a8 8 0 012.3 2.42 4.23 4.23 0 01.55 3 7.09 7.09 0 01-1.75 3.29 17.26 17.26 0 01-4.52 3.47 27 27 0 01-6 2.61 26.36 26.36 0 01-5.74 1 20.48 20.48 0 01-5.15-.32 17.79 17.79 0 01-4.19-1.32q-2-1-4.11-2.19t-3.79-2.38a7.8 7.8 0 01-2.29-2.41 4.25 4.25 0 01-.55-3 7.47 7.47 0 011.71-3.33 16.66 16.66 0 014.48-3.46zm20.45 6.78a9.27 9.27 0 00-1.27-.83l-1.92-1.11-1.93-1.11a10.21 10.21 0 00-1.43-.73 8.71 8.71 0 00-3.49-.78 8.18 8.18 0 00-4.28 1.32c-1.59.92-2.36 1.74-2.3 2.47a2.89 2.89 0 001.36 2 12.65 12.65 0 001.23.85c.58.37 1.21.75 1.88 1.13s1.32.75 2 1.09a14.68 14.68 0 001.48.71 8.71 8.71 0 003.49.78 8.23 8.23 0 004.28-1.32c1.59-.92 2.36-1.74 2.29-2.48a2.84 2.84 0 00-1.39-1.99zm14.23-24.82a2.42 2.42 0 01.19-1.27 3.45 3.45 0 011.42-1.22l4.93-2.85a4.46 4.46 0 011.83-.61 2.71 2.71 0 011.57.29 1.23 1.23 0 01.27.25 1 1 0 01.18.39l5.67 50.17a2.47 2.47 0 01-.2 1.26 3.28 3.28 0 01-1.41 1.23l-4.93 2.85a4.38 4.38 0 01-1.83.6 2.63 2.63 0 01-1.57-.28 1 1 0 01-.28-.26 1.17 1.17 0 01-.18-.39zm25.91 11.92a26.38 26.38 0 016.05-2.59 27.34 27.34 0 015.77-1 20.49 20.49 0 015.15.31 18 18 0 014.18 1.33q2 1 4.12 2.18t3.78 2.38a8 8 0 012.3 2.41 4.25 4.25 0 01.55 3 7.14 7.14 0 01-1.75 3.31 17.48 17.48 0 01-4.52 3.47 26.35 26.35 0 01-6 2.6 25.59 25.59 0 01-5.73 1 20.49 20.49 0 01-5.15-.31 18.26 18.26 0 01-4.19-1.33c-1.34-.65-2.71-1.37-4.11-2.18s-2.66-1.6-3.79-2.38a8 8 0 01-2.29-2.42 4.21 4.21 0 01-.55-3 7.47 7.47 0 011.71-3.33 16.46 16.46 0 014.47-3.45zm20.45 6.77a9.47 9.47 0 00-1.27-.82l-1.92-1.1-1.93-1.11a11.83 11.83 0 00-1.43-.74 9.05 9.05 0 00-3.49-.78 8.42 8.42 0 00-4.29 1.32c-1.58.92-2.35 1.75-2.29 2.48a2.89 2.89 0 001.36 2 13.11 13.11 0 001.22.86c.59.37 1.22.75 1.89 1.13s1.32.75 2 1.09 1.14.57 1.48.71a9 9 0 003.49.78 8.35 8.35 0 004.28-1.33c1.59-.91 2.35-1.74 2.29-2.47a2.88 2.88 0 00-1.39-2.02z"
            style={{
              transformOrigin: "208px 301.802px"
            }}
            id="el3z1w4zxl5qx"
            className="animable"
            fill="#7519ff"
          />
        </g>
      </g>
      <g
        id="freepik--Megaphone--inject-2"
        className="animable animator-active"
        style={{
          transformOrigin: "148.895px 126.02px",
          animation: "1.5s Infinite linear wind",
          animationDelay: "0s"
        }}
      >
        <g
          id="freepik--megaphone--inject-2"
          className="animable"
          style={{
            transformOrigin: "148.895px 126.02px"
          }}
        >
          <path
            d="M83.75 101.58L96.87 94v60.36c0 2.27 2.63 4.54 2.63 4.54l-13.12 7.58s-2.63-2.28-2.63-4.55z"
            style={{
              transformOrigin: "91.625px 130.24px"
            }}
            id="elj0qg85uky6j"
            className="animable"
            fill="#37474f"
          />
          <path
            d="M67.8 101.45l5.53 7v26.23a39.07 39.07 0 01-6 20.75l19 11a7 7 0 01-2.63-4.55v-51.22z"
            style={{
              transformOrigin: "76.83px 133.94px"
            }}
            id="elrnfd49bxxsr"
            className="animable"
            fill="#455a64"
          />
          <path
            style={{
              transformOrigin: "76.855px 162.495px"
            }}
            id="el57gzww8y214"
            className="animable"
            fill="#7519ff"
            d="M67.33 155.48L67.33 158.51 86.38 169.51 86.38 166.48 67.33 155.48z"
          />
          <path
            style={{
              transformOrigin: "92.94px 164.205px"
            }}
            id="el36svswf2l44"
            className="animable"
            fill="#7519ff"
            d="M86.38 169.51L99.5 161.93 99.5 158.9 86.38 166.48 86.38 169.51z"
          />
          <g id="ely8pemqlsse">
            <path
              style={{
                transformOrigin: "92.94px 164.205px"
              }}
              className="animable"
              id="elbplcbc0xv2w"
              opacity={0.1}
              d="M86.38 169.51L99.5 161.93 99.5 158.9 86.38 166.48 86.38 169.51z"
            />
          </g>
          <path
            d="M83.15 69.71c-2.23-1.34-5.33-1.17-8.76.81l-.13.07C67.48 74.52 62 84 62 91.87V92c0 4 1.48 6.86 3.84 8.08l9.93 5.84 16.56-30.79z"
            style={{
              transformOrigin: "77.165px 87.3814px"
            }}
            id="elka5yzv8yem"
            className="animable"
            fill="#7519ff"
          />
          <g id="eld5f121mxcqp">
            <path
              d="M83.15 69.71c-2.23-1.34-5.33-1.17-8.76.81l-.13.07C67.48 74.52 62 84 62 91.87V92c0 4 1.48 6.86 3.84 8.08l9.93 5.84 16.56-30.79z"
              style={{
                transformOrigin: "77.165px 87.3814px"
              }}
              className="animable"
              id="elzxv5fjelfeg"
              fill="#fafafa"
              opacity={0.8}
            />
          </g>
          <g id="elkiaf1tsv6u">
            <path
              d="M65.82 100.1l9.93 5.84 5.5-10.21-18.13-10.47A21.77 21.77 0 0062 91.87V92c0 4.07 1.46 6.88 3.82 8.1z"
              style={{
                transformOrigin: "71.625px 95.6px"
              }}
              className="animable"
              id="elydtnfiil12"
              fill="#7519ff"
              opacity={0.2}
            />
          </g>
          <path
            d="M104.59 77.26c-2.52-1.44-5.92-1.39-9.68.45-.4.19-.8.4-1.2.64s-.95.57-1.41.89A24.08 24.08 0 0090.05 81a30.91 30.91 0 00-3.78 3.88 37 37 0 00-6.94 13.21 26.07 26.07 0 00-1 7c0 4.79 1.64 8.19 4.31 9.85l-9.49-5.47a12.23 12.23 0 01-5.6-10.57 25.66 25.66 0 01.94-6.66 36.71 36.71 0 017-13.52 30.23 30.23 0 013.78-3.88A24 24 0 0181.54 73c.47-.32.94-.62 1.41-.89s.81-.44 1.22-.63a12.16 12.16 0 0110.74.18l.27.15.34.2z"
            style={{
              transformOrigin: "86.0683px 92.6291px"
            }}
            id="el89sfhag8oot"
            className="animable"
            fill="#7519ff"
          />
          <g id="elmy4js19av9s">
            <path
              d="M73.13 109.41a12.23 12.23 0 01-5.6-10.57 25.66 25.66 0 01.94-6.66l10.84 5.9a26.07 26.07 0 00-1 7c0 4.79 1.64 8.19 4.31 9.85z"
              style={{
                transformOrigin: "75.0733px 103.555px"
              }}
              className="animable"
              id="elm9hggwzvpo"
              opacity={0.1}
            />
          </g>
          <path
            d="M109.11 87.24c0 9.83-6.89 21.77-15.4 26.68-4.27 2.48-8.15 2.69-10.94 1l-.15-.09c-2.67-1.66-4.31-5.06-4.31-9.85 0-9.82 6.89-21.77 15.4-26.68 4.24-2.45 8.09-2.69 10.88-1.09s4.52 5.11 4.52 10.03z"
            style={{
              transformOrigin: "93.71px 96.106px"
            }}
            id="el71v00daq7wm"
            className="animable"
            fill="#7519ff"
          />
          <g id="elgf8gejzq0w9">
            <path
              d="M109.11 87.24c0 9.83-6.89 21.77-15.4 26.68-4.27 2.48-8.15 2.69-10.94 1l-.15-.09c-2.67-1.66-4.31-5.06-4.31-9.85 0-9.82 6.89-21.77 15.4-26.68 4.24-2.45 8.09-2.69 10.88-1.09s4.52 5.11 4.52 10.03z"
              style={{
                transformOrigin: "93.71px 96.106px"
              }}
              className="animable"
              id="el8694s5ll8ml"
              opacity={0.2}
            />
          </g>
          <path
            d="M156.34 84.7s-15.54 8-28.81 31a105.2 105.2 0 00-6.3 12.82c-6.66 16.35-6.11 27.62-6.11 27.62-4.63-11.35-10.39-23.09-20-32.08a144.75 144.75 0 00-11.81-9.88c-2.54-1.48-4-4.68-4-9.1A26.75 26.75 0 0181 96a35.21 35.21 0 012.64-5.64A30.5 30.5 0 0194.2 79.21c3.83-2.21 7.35-2.53 9.89-1.07a145 145 0 0014.46 5.29c12.6 3.82 25.65 2.93 37.79 1.27z"
            style={{
              transformOrigin: "117.825px 116.695px"
            }}
            id="elgcqlm69h1mm"
            className="animable"
            fill="#7519ff"
          />
          <g id="elrhyfllgcx6i">
            <path
              d="M156.34 84.7s-15.54 8-28.81 31a105.2 105.2 0 00-6.3 12.82c-6.66 16.35-6.11 27.62-6.11 27.62-4.63-11.35-10.39-23.09-20-32.08a144.75 144.75 0 00-11.81-9.88c-2.54-1.48-4-4.68-4-9.1A26.75 26.75 0 0181 96a35.21 35.21 0 012.64-5.64A30.5 30.5 0 0194.2 79.21c3.83-2.21 7.35-2.53 9.89-1.07a145 145 0 0014.46 5.29c12.6 3.82 25.65 2.93 37.79 1.27z"
              style={{
                transformOrigin: "117.825px 116.695px"
              }}
              className="animable"
              id="elf90r9xt3bve"
              fill="#fafafa"
              opacity={0.8}
            />
          </g>
          <g id="el0hzi8ckmewe8">
            <path
              d="M121.23 128.48c-6.66 16.35-6.11 27.62-6.11 27.62-4.63-11.35-10.39-23.09-20-32.08a144.75 144.75 0 00-11.81-9.88c-2.54-1.48-4-4.68-4-9.1A26.75 26.75 0 0181 96c11.81 5.88 30.86 16.75 40.23 32.48z"
              style={{
                transformOrigin: "100.27px 126.05px"
              }}
              className="animable"
              id="eljz53yj6gc9"
              fill="#7519ff"
              opacity={0.2}
            />
          </g>
          <path
            d="M185.62 106.87c0 10.25-3.16 21.46-8.41 31.82-6.73 13.31-16.88 25.11-28.21 31.67-20.25 11.69-36.66 2.21-36.66-21.16a61.94 61.94 0 012.43-16.57A87.18 87.18 0 01136.94 95q2.15-2.11 4.41-4c.63-.52 1.27-1 1.91-1.51.91-.69 1.82-1.35 2.75-2s1.99-1.21 2.99-1.78c20.2-11.71 36.62-2.21 36.62 21.16z"
            style={{
              transformOrigin: "148.98px 128.031px"
            }}
            id="elin8403h7orm"
            className="animable"
            fill="#7519ff"
          />
          <g id="elv9hgf86x5s">
            <path
              d="M177.21 138.69C170.48 152 160.33 163.8 149 170.36c-20.25 11.69-36.66 2.21-36.66-21.16a61.94 61.94 0 012.43-16.57c1.37 3 6.29 7.34 8.5 4.31a56 56 0 00-2.13 14.7c0 6.72 1.5 12.14 4.33 15.68a12 12 0 009.86 4.56c4 0 8.68-1.47 13.49-4.25 9.67-5.58 18.56-15.61 24.87-27-.04.03 1.22 1.09 3.52-1.94z"
              style={{
                transformOrigin: "144.775px 153.998px"
              }}
              className="animable"
              id="eltnkkc501a7"
              opacity={0.1}
            />
          </g>
          <path
            d="M183.11 108.18c0 10.32-3.62 22-9.46 32.48-6.31 11.36-15.2 21.39-24.87 27-4.81 2.78-9.47 4.25-13.49 4.25a12 12 0 01-9.86-4.56c-2.83-3.54-4.33-9-4.33-15.68a56 56 0 012.13-14.7c.51-1.9 1.12-3.8 1.81-5.7a82.39 82.39 0 014.32-9.81l1-2c.59-1.08 1.2-2.15 1.84-3.2a82.82 82.82 0 017.23-10.17l.09-.1a73.37 73.37 0 015.47-5.88q2.06-2 4.18-3.66.9-.72 1.8-1.38c.73-.53 1.47-1 2.21-1.53s1.46-.92 2.19-1.35c4.82-2.79 9.49-4.25 13.5-4.25 8.94.06 14.24 7.58 14.24 20.24z"
            style={{
              transformOrigin: "152.105px 129.929px"
            }}
            id="elv1pri1w4jlr"
            className="animable"
            fill="#7519ff"
          />
          <g id="el3cl5vstaoiv">
            <path
              d="M183.11 108.18c0 10.32-3.62 22-9.46 32.48-6.31 11.36-15.2 21.39-24.87 27-4.81 2.78-9.47 4.25-13.49 4.25a12 12 0 01-9.86-4.56c-2.83-3.54-4.33-9-4.33-15.68a56 56 0 012.13-14.7c.51-1.9 1.12-3.8 1.81-5.7a82.39 82.39 0 014.32-9.81l1-2c.59-1.08 1.2-2.15 1.84-3.2a82.82 82.82 0 017.23-10.17l.09-.1a73.37 73.37 0 015.47-5.88q2.06-2 4.18-3.66.9-.72 1.8-1.38c.73-.53 1.47-1 2.21-1.53s1.46-.92 2.19-1.35c4.82-2.79 9.49-4.25 13.5-4.25 8.94.06 14.24 7.58 14.24 20.24z"
              style={{
                transformOrigin: "152.105px 129.929px"
              }}
              className="animable"
              id="elzbqu2shl8kk"
              opacity={0.3}
            />
          </g>
          <path
            d="M152 113.92l-12.86 24c-3.62-1.36-9.73-3.53-14.71-4.8.2-.64.42-1.26.66-1.9a81.5 81.5 0 014.32-9.81c.34-.66.69-1.31 1-2 .59-1.08 1.2-2.15 1.84-3.19a82.07 82.07 0 017.23-10.17.48.48 0 00.09-.11c.39-.46.78-.92 1.17-1.36a83.45 83.45 0 0011.26 9.34z"
            style={{
              transformOrigin: "138.215px 121.25px"
            }}
            id="el5ltje3pub7l"
            className="animable"
            fill="#7519ff"
          />
          <g id="elnj86c12bz6i">
            <g
              style={{
                transformOrigin: "138.215px 121.25px"
              }}
              className="animable"
              id="elp6cccj2n0o"
              opacity={0.6}
            >
              <path
                d="M152 113.92l-12.86 24c-3.62-1.36-9.73-3.53-14.71-4.8.2-.64.42-1.26.66-1.9a81.5 81.5 0 014.32-9.81c.34-.66.69-1.31 1-2 .59-1.08 1.2-2.15 1.84-3.19a82.07 82.07 0 017.23-10.17.48.48 0 00.09-.11c.39-.46.78-.92 1.17-1.36a83.45 83.45 0 0011.26 9.34z"
                style={{
                  transformOrigin: "138.215px 121.25px"
                }}
                id="elaaym7aopcpk"
                className="animable"
                fill="#fafafa"
              />
            </g>
          </g>
          <path
            d="M140.17 137.87c-2.91 0-4.64-2.35-4.64-6.28v-.12c0-6.18 4.35-13.73 9.71-16.82l.1-.06a8.41 8.41 0 014.06-1.26c2.9 0 4.64 2.35 4.64 6.29v.12c0 6.18-4.36 13.72-9.71 16.81l-.1.06a8.3 8.3 0 01-4.06 1.26z"
            style={{
              transformOrigin: "144.785px 125.6px"
            }}
            id="el1velpfdmx8n"
            className="animable"
            fill="#7519ff"
          />
          <g id="eljibbcr3k9r">
            <g
              style={{
                transformOrigin: "144.785px 125.6px"
              }}
              className="animable"
              id="el5l3lu9xkbny"
              opacity={0.8}
            >
              <path
                d="M140.17 137.87c-2.91 0-4.64-2.35-4.64-6.28v-.12c0-6.18 4.35-13.73 9.71-16.82l.1-.06a8.41 8.41 0 014.06-1.26c2.9 0 4.64 2.35 4.64 6.29v.12c0 6.18-4.36 13.72-9.71 16.81l-.1.06a8.3 8.3 0 01-4.06 1.26z"
                style={{
                  transformOrigin: "144.785px 125.6px"
                }}
                id="elvsd3mmysxq"
                className="animable"
                fill="#fafafa"
              />
            </g>
          </g>
          <path
            d="M154 70.24a2.89 2.89 0 01-2.89-2.89v-28a2.89 2.89 0 015.78 0v28a2.89 2.89 0 01-2.89 2.89z"
            style={{
              transformOrigin: "154px 53.35px"
            }}
            id="elbzp0var1ctc"
            className="animable"
            fill="#e0e0e0"
          />
          <path
            d="M128.59 77.06a2.88 2.88 0 01-2.5-1.44l-14-24.22a2.89 2.89 0 015-2.9l14 24.22a2.89 2.89 0 01-2.51 4.34z"
            style={{
              transformOrigin: "121.591px 62.0613px"
            }}
            id="elmpsp7qxh8hf"
            className="animable"
            fill="#e0e0e0"
          />
          <path
            d="M102.12 211.84a2.9 2.9 0 01-2.5-4.34l14-24.22a2.89 2.89 0 115 2.89l-14 24.22a2.9 2.9 0 01-2.5 1.45z"
            style={{
              transformOrigin: "109.153px 196.786px"
            }}
            id="elc5uucrro5n"
            className="animable"
            fill="#e0e0e0"
          />
          <path
            d="M154 215.58a2.89 2.89 0 01-2.89-2.89v-28a2.89 2.89 0 115.78 0v28a2.89 2.89 0 01-2.89 2.89z"
            style={{
              transformOrigin: "154px 198.69px"
            }}
            id="eln1ai2cawlkl"
            className="animable"
            fill="#e0e0e0"
          />
          <path
            d="M193.48 189.44A2.89 2.89 0 01191 188l-14-24.22a2.89 2.89 0 015-2.89l14 24.22a2.9 2.9 0 01-2.5 4.34z"
            style={{
              transformOrigin: "186.526px 174.499px"
            }}
            id="elzhnl6xmvf2g"
            className="animable"
            fill="#e0e0e0"
          />
          <path
            d="M222.33 160.57a2.9 2.9 0 01-1.44-.38l-24.22-14a2.89 2.89 0 112.9-5l24.21 14a2.89 2.89 0 01-1.45 5.39z"
            style={{
              transformOrigin: "210.225px 150.69px"
            }}
            id="eli7rqqcsynp"
            className="animable"
            fill="#e0e0e0"
          />
          <path
            d="M232.9 121.14h-28a2.89 2.89 0 110-5.78h28a2.89 2.89 0 110 5.78z"
            style={{
              transformOrigin: "218.9px 118.25px"
            }}
            id="elbrt8qg5zjml"
            className="animable"
            fill="#e0e0e0"
          />
          <path
            d="M198.12 95.69a2.89 2.89 0 01-1.45-5.4l24.22-14a2.89 2.89 0 112.89 5l-24.21 14a2.85 2.85 0 01-1.45.4z"
            style={{
              transformOrigin: "210.27px 85.7653px"
            }}
            id="elzecg4nfn8e"
            className="animable"
            fill="#e0e0e0"
          />
          <path
            d="M179.49 77.06a2.89 2.89 0 01-2.49-4.34l14-24.22a2.89 2.89 0 015 2.9l-14 24.22a2.88 2.88 0 01-2.51 1.44z"
            style={{
              transformOrigin: "186.498px 62.0626px"
            }}
            id="elr5399jzrkvc"
            className="animable"
            fill="#e0e0e0"
          />
        </g>
      </g>
      <defs>
        <filter id="active" height="200%">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius={2}
          />
          <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
          <feMerge>
            <feMergeNode in="OUTLINE" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="hover" height="200%">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius={2}
          />
          <feFlood floodColor="red" floodOpacity={0.5} result="PINK" />
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
          <feMerge>
            <feMergeNode in="OUTLINE" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
          <feColorMatrix values="0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 1 0" />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgComponent
