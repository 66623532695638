import emailjs from '@emailjs/browser';

const YOUR_SERVICE_ID = 'service_gf6f9fl'
// const YOUR_SERVICE_ID_Y = 'service_jks65m9'
const YOUR_TEMPLATE_ID = 'template_onbwuom'
const YOUR_PUBLIC_KEY = 'VWuhhWq5A4vLwmuDy'

export const sendEmail = (form) => {

  emailjs.sendForm(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, form, YOUR_PUBLIC_KEY)
    .then((result) => {
      console.log(result.text);
    }, (error) => {
      console.log(error.text);
    });
};

// https://dashboard.emailjs.com/admin/templates/z7dyk4q