import { useEffect } from 'react';

// libraries
import Segment from "segment-js"

var ease = window.ease

/**
 * ButtonX. Componente el boton de el header en version mobile con el efecto rotatorio y las acciones correcpondientes --> {@link Header} <-- Vista contenedora
 * @alias ButtonX
 * @namespace
 */
function ButtonX({openClose}) {

  useEffect(() => {
    
    var beginAC = 80,
      endAC = 320,
      beginB = 80,
      endB = 320;

    function inAC(s) {
      s.draw('80% - 240', '80%', 0.3, {
        delay: 0.1,
        callback: function () {
          inAC2(s)
        }
      });
    }

    function inAC2(s) {
      s.draw('100% - 545', '100% - 305', 0.6, {
        easing: ease.ease('elastic-out', 1, 0.3)
      });
    }

    function inB(s) {
      s.draw(beginB - 60, endB + 60, 0.1, {
        callback: function () {
          inB2(s)
        }
      });
    }

    function inB2(s) {
      s.draw(beginB + 120, endB - 120, 0.3, {
        easing: ease.ease('bounce-out', 1, 0.3)
      });
    }

    /* Out animations (to burger icon) */

    function outAC(s) {
      s.draw('90% - 240', '90%', 0.1, {
        easing: ease.ease('elastic-in', 1, 0.3),
        callback: function () {
          outAC2(s)
        }
      });
    }

    function outAC2(s) {
      s.draw('20% - 240', '20%', 0.3, {
        callback: function () {
          outAC3(s)
        }
      });
    }

    function outAC3(s) {
      s.draw(beginAC, endAC, 0.7, {
        easing: ease.ease('elastic-out', 1, 0.3)
      });
    }

    function outB(s) {
      s.draw(beginB, endB, 0.7, {
        delay: 0.1,
        easing: ease.ease('elastic-out', 2, 0.4)
      });
    }

    /* Awesome burger default */

    var pathA = document.getElementById('pathA'),
      pathB = document.getElementById('pathB'),
      pathC = document.getElementById('pathC'),
      segmentA = new Segment(pathA, beginAC, endAC),
      segmentB = new Segment(pathB, beginB, endB),
      segmentC = new Segment(pathC, beginAC, endAC),
      trigger = document.getElementById('menu-icon-trigger'),
      toCloseIcon = true,
      wrapper = document.getElementById('menu-icon-wrapper');

    wrapper.style.visibility = 'visible';

    trigger.onclick = function () {
      if (toCloseIcon) {
        inAC(segmentA);
        inB(segmentB);
        inAC(segmentC);
      } else {
        outAC(segmentA);
        outB(segmentB);
        outAC(segmentC);
      }
      toCloseIcon = !toCloseIcon;
    };

  }, [])

  return (
      <div id="menu-icon-wrapper" className="menu-icon-wrapper" style={{visibility: "hidden"}}>
        <svg width="1000px" height="1000px">
          <path id="pathA" d="M 300 400 L 700 400 C 900 400 900 750 600 850 A 400 400 0 0 1 200 200 L 800 800"></path>
          <path id="pathB" d="M 300 500 L 700 500"></path>
          <path id="pathC" d="M 700 600 L 300 600 C 100 600 100 200 400 150 A 400 380 0 1 1 200 800 L 800 200"></path>
        </svg>
        <button id="menu-icon-trigger" className="menu-icon-trigger" onClick={()=> openClose()}></button>
      </div>
  );
}

export default ButtonX;
