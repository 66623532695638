// @ts-nocheck
import axios from 'axios';

import env from '../environtments.js';

/**
 * Constantes que alimentan las direcciones de la Api
 */
const URL_API_BLOG = env.API_URL_BLOG
const URL_API_BLOG_EN = env.API_URL_BLOG_EN
const URL_API_BLOG_CAT = env.API_URL_BLOG_CAT
const URL_API_BLOG_MEDIA = env.API_URL_BLOG_MEDIA
const URL_API_BLOG_TOKEN = env.API_URL_BLOG_TOKEN


/**
 * Conponente para manejar los pedidos a la api del blog 
 * @alias getinApiBlog
 */
export const getinApiBlog = async (method, data = '') => {

  switch (method) {
    case 'getAll':
      return await axios.get(URL_API_BLOG + data).then(res => {
        return res.data;
      }).catch(err => {
        return { status: 'Error' };
      });
    case 'getAllEn':
      return await axios.get(URL_API_BLOG_EN + data).then(res => {
        return res.data;
      }).catch(err => {
        return { status: 'Error' };
      });
    case 'getMedia':
      return await axios.get(URL_API_BLOG_MEDIA + data).then(res => {
        return res.data;
      }).catch(err => {
        return { status: 'Error' };
      });
    case 'getAllCat':
      return await axios.get(URL_API_BLOG_CAT, data).then(res => {
        return res.data;
      }).catch(err => {
        return { status: 'Error' };
      });
    case 'postBlog':
      return await axios.post(URL_API_BLOG_TOKEN, {
        "username": "adm_krea",
        "password": "N3YrQ9YUd0L0%S6RyH"
      }).then(async (res) => {
        return await axios.post(URL_API_BLOG + data, null, {
          headers: {
            Authorization: 'Bearer ' + res.data.token,
          }
        }).then(res => {
          return res.data;
        }).catch(err => {
          return { status: 'Error' };
        });
      }).catch(err => {
        return { status: 'Error' };
      });

    default: break;
  }
}