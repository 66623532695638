import React from 'react'

/** Import Trasnlations */
import '../i18n'
import { useTranslation } from 'react-i18next'

/**Import titulo del componente en imagen */
import terminos from "../assets/images/title_terminos.png";
import { useEffect } from 'react';

/**
 * Componente que muestra los terminos politicas de tratamiento de datos y terminos y condiciones  data de kreative tree
  * @alias PoliticasTrataDatos 
  */
const PoliticasTrataDatos = () => {

  const { t, i18n } = useTranslation();
  /**
   * Funcion inicializadora de lenguaje de la vista 
   * @alias initLang
   */
  const initLang = () => {
    if (localStorage.getItem('lng')) {
      i18n.changeLanguage(localStorage.getItem('lng'))
    } else {
      window.localStorage.setItem('lng', 'es')
      i18n.changeLanguage('es')
    }
  }

  useEffect(() => {
    initLang()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='terminos contenedor'>
      <div className={(localStorage.getItem('lng') === 'es') ? "img_term terminos_img" : "img_term terminos_img_ing"}>
        {/* <img src={terminos} alt="" /> */}
      </div>
      <h3>{t('Term_subtitulo1.label')}</h3>
      <p>{t('Term_par2.label')}</p>
      <p>{t('Term_par3.label')}</p>
      <p>{t('Term_par4.label')}</p>
      <p>{t('Term_par5.label')}</p>
      <p><ul>
        <li>{t('Term_par5_listP1.label')}</li>
        <li>{t('Term_par5_listP2.label')}</li>
        <li>{t('Term_par5_listP3.label')}.</li>
        <li>{t('Term_par5_listP4.label')}
          <ul>
            <li>{t('Term_par5_listP4_subLp1.label')}</li>
            <li>{t('Term_par5_listP4_subLp2.label')}</li>
            <li>{t('Term_par5_listP4_subLp3.label')}</li>
          </ul>
        </li>
      </ul>
      </p>
      <p>{t('Term_par6.label')}</p>
      <p><ul>
        <li>{t('Term_par6_listP1.label')}</li>
        <li>{t('Term_par6_listP2.label')} <br />{t('Term_par6_listP2_2.label')}
          <ul>
            <li>{t('Term_par6_listP2_subLp1.label')}</li>
            <li>{t('Term_par6_listP2_subLp2.label')}</li>
            <li>{t('Term_par6_listP2_subLp3.label')}
              <ul>
                <li>{t('Term_par6_listP2_subLp3_s1.label')}</li>
                <li>{t('Term_par6_listP2_subLp3_s2.label')}</li>
                <li>{t('Term_par6_listP2_subLp3_s3.label')}</li>
                <li>{t('Term_par6_listP2_subLp3_s4.label')}</li>
                <li>{t('Term_par6_listP2_subLp3_s5.label')}</li>
                <li>{t('Term_par6_listP2_subLp3_s6.label')}</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>{t('Term_par6_listP3.label')}</li>
        <li>{t('Term_par6_listP4.label')}</li>
        <li>{t('Term_par6_listP5.label')}</li>
        <li>{t('Term_par6_listP6.label')}</li>
        <li>{t('Term_par6_listP7.label')}</li>
        <li>{t('Term_par6_listP8.label')}</li>
        <li>{t('Term_par6_listP9.label')}</li>
      </ul>
      </p>
      <h3>{t('Term_subtitulo2.label')}</h3>
      <p>{t('Term_par7.label')}</p>
      <p>{t('Term_par8.label')}</p>
      <p>{t('Term_par9.label')}</p>
      <p>{t('Term_par10.label')}</p>
      <p>{t('Term_par11.label')}</p>
      <p>{t('Term_par12.label')}</p>
      <p>{t('Term_par13.label')}</p>
      <p>{t('Term_par14.label')}</p>
      <h3>{t('Term_subtitulo3.label')}</h3>
      <p>{t('Term_par15.label')}</p>
      <h3>{t('Term_subtitulo4.label')}</h3>
      <p>{t('Term_par16.label')}</p>
      <p>
        <ul>
          <li>{t('Term_par16_listP1.label')}</li>
          <li>{t('Term_par16_listP2.label')}</li>
          <li>{t('Term_par16_listP3.label')}</li>
          <li>{t('Term_par16_listP4.label')}</li>
        </ul>
      </p>
      <h3>{t('Term_subtitulo5.label')}</h3>
      <p>{t('Term_par17.label')}</p>
      <p>{t('Term_par18.label')}</p>
      <p>{t('Term_par19.label')}</p>
      <p>{t('Term_par20.label')}</p>
      <p>{t('Term_par21.label')}</p>
      <h3>{t('Term_subtitulo6.label')}</h3>
      <p>{t('Term_par22.label')}</p>
      <p>{t('Term_par23.label')}</p>
      <p>{t('Term_par24.label')}</p>
      <p>{t('Term_par25.label')}</p>
      <p>{t('Term_par26.label')}</p>
      <h3>{t('Term_subtitulo7.label')}</h3>
      <p>{t('Term_par27.label')}</p>
      <p className='fecha'>{t('Term_par28_fechaCrea.label')}</p>
      <p className='fecha'>{t('Term_par29_fechaUpda.label')}</p>

    </div>
  )
}

export default PoliticasTrataDatos