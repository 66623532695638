import React, { useEffect, useState } from "react";
import $ from 'jquery'

// // Router
// import { useHistory } from 'react-router-dom';

// // Redux
// import { connect } from 'react-redux';
// import env from '../environtments';

// Trasnlation 
import '../i18n'
import { useTranslation } from 'react-i18next'

// const URL_API = env.API_URL;

// // librerias
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";

/*   IMAGENES    */
//titulos
import tit_div6 from '../assets/images/nuestro_equipo.svg'
import tit_div6ING from '../assets/images/ourteam.svg'

//fotos del equipo
import eri from '../assets/images/equipo/Erika.png'
// import nataC from '../assets/images/equipo/Natalia_Cardona@2x.png'
// import lau from '../assets/images/equipo/Laura_Osma@2x.png'
import dani from '../assets/images/equipo/Danimar.png'
// import mario from '../assets/images/equipo/Mario_Doria@2x.png'
// import tania from '../assets/images/equipo/Tania_Gonsalez@2x.png'
import nata from '../assets/images/equipo/NataG.png'
import mari from '../assets/images/equipo/Marisol.png'
// import calos from '../assets/images/equipo/Carlos_Barrera@2x.png'
import yomar from '../assets/images/equipo/Yomar.png'
import julian from '../assets/images/equipo/Julian.png'
// import caro from '../assets/images/equipo/Carolina_Sanchez2.png'
import yorman from '../assets/images/equipo/Yorman.png'
// import juan from '../assets/images/equipo/Esteban_Catano2.png'
import ana from '../assets/images/equipo/Ana.png'
// import juanjo from '../assets/images/equipo/juanjosePatino.png'
import santiago from '../assets/images/equipo/Erik.png'


//componentes
import CarruselCompaneros from "../components/Carrusel_compa";


const equipo_trabajo = [
  { id: 1, imagen: eri, nombre: 'Érika Serna', arroba: 'Érika S', cargo: 'coordinadora de marketing', correo: 'erika@emilan.co', gustos: { color: 'Rojo', comida: 'El pollo', pelicula: 'Intensamente', feliz: 'El café' }, position: 'Marketing Manager' },
  // { id: 2, imagen: nataC, nombre: 'Natalia Cardona', arroba: 'Natalia C', cargo: 'Asistente de Marketing', correo: 'nataliacardona@emilan.co', gustos: { color: 'Morado', comida: 'Las salchipapas', pelicula: 'Juego de gemelas ', feliz: 'Estar con mi hija y comer papitas' }, position: 'Marketing Assistant' },
  // { id: 3, imagen: lau, nombre: 'Laura Osma', arroba: 'Laura O', cargo: 'Creadora de Contenido', correo: 'laura@emilan.co', gustos: { color: 'Rosa', comida: 'Pollo con papas', pelicula: 'Mulan', feliz: 'Amar y sentirme amada' }, position: 'Content Creator' },
  { id: 4, imagen: dani, nombre: 'Danimar Castillo', arroba: 'Danimar C', cargo: 'Copywriter', correo: 'danimar@emilan.co', gustos: { color: 'Negro y morado', comida: 'Pasticho y pizza', pelicula: 'Patch Adams', feliz: 'Mi familia' }, position: 'Copywriter' },
  // { id: 5, imagen: mario, nombre: 'Mario Doria', arroba: 'Mario D', cargo: 'Estratega de pauta', correo: 'mario@emilan.co', gustos: { color: 'Rojo', comida: 'Pizza y pasta', pelicula: 'la vida secreta de walter mitty', feliz: 'La tranquilidad ' }, position: 'Ads strategist' },
  // { id: 6, imagen: tania, nombre: 'Tania Gonzales', arroba:'Tania G', cargo: 'Analista E-Commerce',correo:'tania@emilan.co', gustos: {color:'Blanco', comida: 'Hamburguesa con mucha mostaza', pelicula:'Enredados', feliz:'Los días soleados junto a las personas y mascotas que amo'},position:'eCommerce analyst'},
  { id: 7, imagen: nata, nombre: 'Natalia Garavito', arroba: 'Natalia G', cargo: 'Líder de Diseño', correo: 'natalia@emilan.co', gustos: { color: 'Morado', comida: 'Los mariscos', pelicula: 'En busca de la felicidad', feliz: 'Viajar y conocer lugares nuevos' }, position: 'Design Leader' },
  { id: 8, imagen: mari, nombre: 'Marisol Galvis', arroba: 'Marisol G', cargo: 'Diseñadora junior', correo: 'marisol@emilan.co', gustos: { color: 'Amarillo', comida: 'Hamborguesa', pelicula: 'Forrest Gump', feliz: 'La tranquilidad de los pueblos, las risas con los amigos, los domingos con los papás y la cerveza fría a cualquier hora del día.' }, position: 'Junior Designer' },
  // { id: 9, imagen: calos, nombre: 'Carlos Barrera', arroba:'Carlos B', cargo: 'Desarrollador Senior',correo:'carlos@emilan.co', gustos: {color:'Azul Oscuro', comida: 'Sudadito de pollo', pelicula:'Volver al Futuro ', feliz:'Mi esposa y mis hijos'},position:'Senior Developer'},
  { id: 10, imagen: yomar, nombre: 'Yomar Barrera', arroba: 'Yomar B', cargo: 'Desarrollador junior', correo: 'yomar@emilan.co', gustos: { color: 'Azul rey', comida: 'Garbanzos', pelicula: 'Jhon wick', feliz: 'Mís hijos, mi familia, mis amigos, aprender algo nuevo.' }, position: 'Junior Developer' },
  { id: 11, imagen: julian, nombre: 'Julián Lopez', arroba: 'Julián L', cargo: 'Desarrollador junior', correo: 'julian@emilan.co', gustos: { color: 'Azul', comida: 'Pizza', pelicula: 'Mr. nobody', feliz: 'La tranquilidad' }, position: 'Junior Developer' },
  // { id: 12, imagen: caro, nombre: 'Carolina sanchez', arroba: 'Carolina S', cargo: 'Diseñadora junior', correo: '', gustos: { color: 'Morado', comida: 'El atún', pelicula: 'El diablo viste a la moda', feliz: 'Los animales, pero más que todos los perritos ' }, position: 'Junior Designer' },
  { id: 13, imagen: yorman, nombre: 'Yorman Rodrigez', arroba: 'YormanR', cargo: 'Analista aCommerce', correo: '', gustos: { color: 'Negro', comida: 'La pasta en todas sus presentaciones', pelicula: 'La saga de marvel', feliz: 'Mi Familia' }, position: 'eCommerce Assistant' },
  // { id: 14, imagen: juan, nombre: 'Esteban Cataño', arroba: 'EstebanC', cargo: 'Creador de Contenido', correo: '', gustos: { color: 'Negro', comida: 'Las hamburguesas !!!!', pelicula: 'Harry Potter', feliz: 'Compartir con mi familia y amigos.' }, position: 'Content Creator' },
  // { id: 15, imagen: karina, nombre: 'Karina G. ', arroba: 'KarinaG', cargo: 'Asistente aCommerce', correo: '', gustos: { color: 'Azul y Verde', comida: 'Comida de mar', pelicula: 'What dreams may come', feliz: 'Mis hijos.' }, position: 'eCommerce Assistant' },
  // { id: 16, imagen: juanjo, nombre: 'Juan José Patiño.', arroba: 'JuanjoP', cargo: 'Diseñador junior', correo: '', gustos: { color: 'Verde & Azul', comida: 'Las pastas', pelicula: 'The Perks of Being a Wallflower', feliz: 'Me hace feliz estar con mi familia, dormir, progresar, dar ideas geniales, sentirme cómodo, la naturaleza, escuchar música a todo volumen.' }, position: 'Junior Designer' },
  { id: 17, imagen: santiago, nombre: 'Santiago Garcia.', arroba: 'SantiagoG', cargo: 'Asistente aCommerce', correo: '', gustos: { color: 'Negro', comida: 'Asiática (Sushi y Arroz)', pelicula: 'One day', feliz: 'Mis perritos Valerio y Mia' }, position: 'eCommerce Assistant' },
  { id: 18, imagen: ana, nombre: 'Ana Pacifico.', arroba: 'AnaP', cargo: 'Asistente de Pauta', correo: 'ana@emilan.co', gustos: { color: 'Verde limón', comida: 'Pastas y las papas', pelicula: 'La razón de estar contigo', feliz: 'Subir montañas (Trekking) y bailar.' }, position: 'commercial guide assistant' },
]

/**
 * Vista principal del equipo que muestra todos los colaboradores se encuentra en la ruta /nosotros del menu principal
 * @alias Nosotros
 */
const Nosotros = () => {

  const [equipo, setEquipo] = useState(equipo_trabajo)
  const [compa, setCompa] = useState(null)
  const { t, i18n } = useTranslation();

  /** Inicializa el lenguaje de renderizado */
  const initLang = () => {
    if (localStorage.getItem('lng')) {
      i18n.changeLanguage(localStorage.getItem('lng'))
    } else {
      window.localStorage.setItem('lng', 'es')
      i18n.changeLanguage('es')
    }
  }
  useEffect(() => {
    initLang()
  }, [])

  /**
   * funcion que sirve para escoger el compañero a mostrar en el recuadro principal en la vista nosotros de la web
   * @param {event} e interpreta el evento que dispara al escoger el compañero corepondiente
   * @param {object} opt | escoge el compañero en la lista de opciones  
   */
  const changeCompa = function (e, opt) {
    $('.div_foto').removeClass('active')
    if ($(e.target).hasClass('foto')) {
      $(e.target).parent().addClass('active')
    }
    setCompa(opt)
    $('.imagen_colaborador').addClass('show')
    setTimeout(() => {
      $('.imagen_colaborador').removeClass('show')
    }, 500);
  }

  return (
    <div className='contenedor'>

      <div className="div6">
        <div className="bu1"></div>
        <div className="bu2"></div>
        <div className="bu3"></div>
        <div className="bu4"></div>
        <div className="bu5"></div>
        <div className="bu6"></div>

        <div className="titulo">
          {(localStorage.getItem('lng') === 'es') ?
            <img src={tit_div6} alt="Nuestro equipo" /> :
            <img src={tit_div6ING} alt="Our team" />
          }
        </div>
        {compa ?
          <div className="perfil">
            <div className="imagen_colaborador">
              <img src={compa.imagen} alt={compa.arroba} className={"imagen_" + compa.arroba} />
            </div>
            <div className="info_colaborador">
              <h6 className="saludo">{t('hi.label')}</h6>
              <h6 className="nombre">{t('am.label')} {compa.nombre}<span></span></h6>
              {localStorage.getItem('lng') === 'es' ? <p className="cargo">{compa.cargo}</p> : <p className="cargo">{compa.position}</p>}
              <div className="separador"></div>
              <div className="gustos">
                <p className="gusto"> <span>{t('soHappy.label')}</span> {compa.gustos.feliz}</p>
                <p className="gusto"> <span>{t('favColor.label')}</span> {compa.gustos.color}</p>
                <p className="gusto"> <span>{t('favFood.label')}</span> {compa.gustos.comida}</p>
                <p className="gusto"> <span>{t('favMovie.label')}</span> {compa.gustos.pelicula}</p>
              </div>
            </div>
          </div>
          :
          <div className="perfil">
            <div className="imagen_colaborador">
              <img src={eri} alt="erika serna" />
            </div>
            <div className="info_colaborador">
              <h6 className="saludo">{t('hi.label')}</h6>
              <h6 className="nombre">{t('am.label')} érika serna<span>(Éri)</span></h6>
              {localStorage.getItem('lng') === 'es' ? <p className="cargo">Coordinadora de Marketing</p> : <p className="cargo">Marketing Manager</p>}
              <div className="separador"></div>
              <div className="gustos">
                <p className="gusto"> <span>{t('soHappy.label')}</span> El café</p>
                <p className="gusto"> <span>{t('favColor.label')}</span> Rojo</p>
                <p className="gusto"> <span>{t('favFood.label')}</span> El pollo</p>
                <p className="gusto"> <span>{t('favMovie.label')}</span> Intensamente</p>
              </div>
            </div>
          </div>
        }
        <div className="perfiles divCompaneros">
          {equipo && equipo.map((compa, i) => (
            <div className={"mini_perfil"} key={i} >
              <div className={compa.nombre == 'Érika Serna' ? "div_foto active" : "div_foto"} onClick={(e) => changeCompa(e, compa)}>
                <img src={compa.imagen} alt={compa.nombre} className={"foto imag_" + compa.arroba} />
              </div>
              <div className="nombre">{compa.arroba}</div>
              {localStorage.getItem('lng') === 'es' ? <p className="cargo">{compa.cargo}</p> : <p className="cargo">{compa.position}</p>}
            </div>
          ))}
        </div>
        <CarruselCompaneros changeCompa={changeCompa} equipo={equipo} />
      </div>

    </div>
  )
}

export default Nosotros