import React from 'react'

/**
 * Copy Componente, muertra los derechos copyright de la empresa --> {@link App} <-- Vista contenedora
 * @alias Copy
 * @namespace
 */
const Copy = () => {
  return (
    <div className="copy">
      <p className=''>COPYRIGHT © 2022 Kreative Tree Studio</p>
    </div>
  )
}

export default Copy