import React from 'react'

// Trasnlation 
import '../../i18n'
import { useTranslation } from 'react-i18next'

/**
 * TerminosPLanes Componente que genera una lista de enlaces que dirigen a los terminos y condiciones del manejo de nuestros servicios--> {@link PoliticasHabeasData} <-- vista habeas data
 * @alias TerminosPLanes
 * @namespace
 * @param {function} func. - funcion de despliege de la lista
 * @param {String} class1. - estilos del div principal
 * @param {String} class2. - estilos del subtitulo
 * @param {String} class3. - estilos de la lista de enlacees
 */
const TerminosPLanes = ({ func, class1, class2, class3 }) => {

  const { t } = useTranslation();

  return (
    <div className={class1}>
      <h6 className={class2} onClick={func}>
        {t('terms.label')}
      </h6>
      <div className={class3}>
        <ul>
          <li>
            <a href='politica-datos' target='_blank' rel='noopener noreferrer'>{t('poliTratDatos.label')}</a>
          </li>
          <li>
            <a href='habeas-data' target='_blank' rel='noopener noreferrer'>{t('autHabeasData.label')}</a>
          </li>
          <li>
            <a href='politica-privacidad' target='_blank' rel='noopener noreferrer'>{t('poliPrivacid.label')}</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default TerminosPLanes
