import React from 'react'
import $ from 'jquery'

// Trasnlation 
import '../i18n'
import { useTranslation } from 'react-i18next'

/**
 * Langs  Componente que se encarga de la eleccion del lenguaje en el que se muestra la pagina --> {@link Header} <-- Vista contenedora
 * @alias Langs
 * @namespace
 */
const Langs = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    $('.bt').removeClass('active');
    $('.' + lang).addClass('active');
    localStorage.setItem('lng', lang);
    $('.botones').css('display', 'none');
  };
  const openLanguage = () => {
    $('.botones').css('display', 'flex');
  };

  return (
    <div className='langs'>
      {localStorage.getItem('lng') === 'es' ? <div className="idioma" onClick={() => openLanguage()}>
        <div className='flag flag-es'></div>
        <span>{t('es.label')}</span>
        <i className="icon icon-caretDown"></i>
      </div> :
        <div className="idioma" onClick={() => openLanguage()}>
          <div className='flag flag-en'></div>
          <span>{t('en.label')}</span>
          <i className="icon icon-caretDown"></i>
        </div>}

      <div className='botones'>
        <button
          className={
            localStorage.getItem('lng') === 'es' ? 'bt es active' : 'bt es'
          }
          onClick={() => changeLanguage('es')}
          type='button'
        >
          <div className='flag flag-es'></div>
          <span>{t('spanish.label')}</span>
        </button>
        <button
          className={
            localStorage.getItem('lng') === 'en' ? 'bt en active' : 'bt en'
          }
          onClick={() => changeLanguage('en')}
          type='button'
        >
          <div className='flag flag-en'></div>
          <span>{t('english.label')}</span>
        </button>
      </div>
    </div>
  )
}

export default Langs