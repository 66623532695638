import React from 'react'

// Trasnlation 
import '../../i18n'
import { useTranslation } from 'react-i18next';

/**
 * CardPlanCustom: Card de la lista prices servicio a la medida--> {@link Prices} <-- vista Prices
 * @alias CardPlanCustom
 * @namespace
 * @param {Object} plan. - Objeto del plan que contiene los parametros necesadios Ej:
 * { price: 441, pieces: 23, linkES: 'https://wa.link/fhj207', linkEN: 'https://wa.link/fhj207' }
 */
const CardPlanCustom = ({ plan }) => {
  const { t } = useTranslation();
  return (
    <div className="plan plan_medida">
      <div className="medalla">
        <i className="icon icon-medalla"></i>
      </div>
      <div className="name_plan">{t('ownPlan.label')}</div>
      <div className="serv_plan">
        <div className="flex">
          <p className="p_serv">{t('weMakeReality.label')}</p>
        </div>
      </div>
      <a className="boton_blue" href={(localStorage.getItem('lng') === 'es') ? plan.linkES : plan.linkEN} target='_blanck' >{t('haveQuote.label')}</a>
    </div>
  )
}

export default CardPlanCustom
